import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import { connect } from 'react-redux';
import Request from '../../components/Utils/Request';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageUnregisterUserAdd extends React.Component<Props, State> {
    state: State = {
        form: {
            email: '',
            first_name : '',
            last_name: '',
            wechat_id: '',
            role : 'CRM_USER'
        },
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };

    submit = () => {
        const form = this.state.form;
        Request({
            url: '/user/crm_user',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form,
        }).then(({data: res}) => {
            if(res.status === 200) {
                toast(res.message || 'Add crm user successfully!');
                this.setState({
                    form: {
                        email: '',
                        first_name : '',
                        last_name: '',
                        wechat_id: '',
                        role : 'CRM_USER'
                    }
                })
            }
            else {
                let errors = [];
                for(let i in res.message ) {
                    errors.push(res.message[i]);
                }
                toast(errors.join(',') || 'Add failed')
            }
        });
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
    }

    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Add Unregistered User</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Detailed Information</h4>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyEmail">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyEmail"
                                                            required
                                                            value={form.email}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    email: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyFirstname">
                                                            Firstname
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyFirstname"
                                                            value={form.first_name}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    first_name: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyLastname">
                                                            Lastname
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyLastname"
                                                            value={form.last_name}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    last_name: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyWechatId">Wechat ID</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyWechatId"
                                                            value={form.wechat_id}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    wechat_id: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageUnregisterUserAdd;
