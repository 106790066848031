import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import { connect } from 'react-redux';
import Request from '../../components/Utils/Request';
import {toast} from "react-toastify";
import moment from 'moment';
import Select from '../../components/Select/Select';
import { playbackType } from 'shared/components/Utils/TypesData'

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageMWebinarEventUpdate extends React.Component<Props, State> {
    state: State = {
        event_id: '',
        image: null,
        event_link: '',
        form: {
            title: '',
            sub_title: '',
            summary_title: '',
            summary_content: '',
            event_time: '',
            duration: '1',
            speaker_name: '',
            speaker_description: '',
            profile_image_url: '',
            streaming_url: '',
            playback_url: '',
            annouce_at_home_page: 0,
            meeting_id: '',
            passcode: '',
            video_id: 0
        },
        marketVideos: [],
        playback_type: '2'
    };

    setForm = (form) => {
        this.setState({
            form
        })
    }
    showWebinarEvent = () => {
    }
    //获取input file的文件地址
    getObjectURL = (file) => {
    　　var url = null;
    　　if (window.createObjectURL != undefined) {//basic
    　　    url = window.createObjectURL(file);
    　　} else if (window.URL != undefined) {//mozilla(firefox)兼容火狐
    　　    url = window.URL.createObjectURL(file);
    　　} else if (window.webkitURL != undefined) {//webkit or chrome
    　　    url = window.webkitURL.createObjectURL(file);
    　　}
    　　return url;
　　}
    upload = async () => {
        const { event_id, image } = this.state
        const formData = new FormData();
        if(!image) {
            return false;
        }
        formData.append('parent_id', event_id);
        formData.append('image_type', 'WEBINAR');
        formData.append('file', image);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data;
        });
        // this.getUser(userInfo.id);
    };
    submit = () => {
        const { form } = this.state
        Request({
            url: '/webinar/' + form.id,
            method: 'PUT',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then(res => {
            if(res.status === 200) {
                // 200的状态值就进行跳转
                toast(res.message || 'Update the webinar event successfully!')
                this.props.history.push({
                    pathname: '/PageMWebinarEvents'
                })
            }
            // else {
            //     let errors = [];
            //     for(let i in res.message ) {
            //         errors.push(res.message[i]);
            //     }
            //     toast(errors.join(',') || 'Update failed')
            // }
        });
    };
    /**
     * 方法一：用原生的document.execCommand(‘copy’)的方式实现，移动端和PC端都可以使用
     * 效果：点击复制按钮，复制想复制的区域，例如复制邀请码功能实现
     */
    handleCopy = () => {
        const range = document.createRange();
        range.selectNode(document.getElementById('cp')); //获取复制内容的 id 选择器
        const selection = window.getSelection();  //创建 selection对象
        if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
        selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
        document.execCommand('copy'); //复制选中的文字到剪贴板
        selection.removeRange(range); // 移除选中的元素
        toast('Copied to clipboard!');
    }
    /* */
    getVideos = async () => {
        Request({
            url: '/market/streaming_top?limit=100',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            this.setState({
                marketVideos: res.data.map(item => {
                    return {
                        ...item,
                        name: item.title,
                        value: item.id
                    }
                })
            });
        });
    };
    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.getVideos().then()
            if (this.props.location.state) {
                const { title, sub_title, summary_title, summary_content, event_time, duration, speaker_name, speaker_description, profile_image_url, streaming_url, playback_url, video_id, annouce_at_home_page, meeting_id, passcode, id} = this.props.location.state;
                this.setForm({ title, sub_title, summary_title, summary_content, event_time, duration, speaker_name, speaker_description, profile_image_url, streaming_url, playback_url, video_id, annouce_at_home_page, meeting_id, passcode, id, user_last_updated: this.props.userInfo.id });
            }
        }
    }

    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { event_link, image, form, marketVideos, playback_type } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="breadcrumb_content">
                                            <h2 className="breadcrumb_title">Add Webinar Event</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Detailed Information</h4>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyTitle">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyTitle"
                                                            required
                                                            value={form.title}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    title: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySubTitle">
                                                            SubTitle
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertySubTitle"
                                                            value={form.sub_title}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    sub_title: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-3">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Play Back Type</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={playback_type}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    playback_type: e.value
                                                                })
                                                            }}
                                                            options={playbackType}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-9" style={{display: playback_type == '1' ? '' : 'none'}} >
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Play Back URL (zoom link)
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="propertyStreamUrl"
                                                                required
                                                                value={form.playback_url}
                                                                onChange={(e) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        playback_url: e.target.value,
                                                                    })
                                                                }}
                                                            />
                                                            {/* <div className="input-group-append" style={{display: form.video_id ? '' : 'none'}}>
                                                                <span className="input-group-text" id="basic-addon2">{'&id=' + form.video_id}</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9" style={{display: playback_type == '2' ? '' : 'none'}} >
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Select a market video</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            title="Select a Market video"
                                                            defaultValue={form.video_id}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        playback_url: window.location.origin + '/PageMarket?state=' + e.state + '&city=' + e.city + '&id=' + e.value,
                                                                        video_id: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={marketVideos}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyStreamUrl" style={{
                                                                width: '100%'
                                                            }}>
                                                            Streaming Url
                                                            <span className="my_profile_setting_input form-group form-check" style={{
                                                                float: 'right', margin: '0'
                                                            }}>
                                                                <input type="checkbox" checked={form.annouce_at_home_page == 1} onChange={(event) => {
                                                                        this.setForm({
                                                                            ...form,
                                                                            annouce_at_home_page: event.target.checked ? 1 : 0,
                                                                        })
                                                                    }} className="form-check-input" name="propertyStreamUrl" id="propertyAnnounceHome" />
                                                                <label className="form-check-label" htmlFor="propertyAnnounceHome">Announce At Home Page？</label>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyStreamUrl"
                                                            required
                                                            value={form.streaming_url}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    streaming_url: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySummaryTitle">
                                                            Meeting ID
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertySummaryTitle"
                                                            value={form.meeting_id}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    meeting_id: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySummaryTitle">
                                                            Passcode
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertySummaryTitle"
                                                            value={form.passcode}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    passcode: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySummaryTitle">
                                                            Summary Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertySummaryTitle"
                                                            value={form.summary_title}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    summary_title: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySummaryContent">
                                                            Summary Content
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertySummaryContent"
                                                            value={form.summary_content}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    summary_content: e.target.value,
                                                                })
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyEventTime">Event Time (PST)</label>
                                                        <input
                                                            id="propertyEventTime"
                                                            name="title"
                                                            className="form-control"
                                                            required={true}
                                                            type="datetime-local"
                                                            maxLength={200}
                                                            value={
                                                                form.event_time
                                                            }
                                                            onChange={(e) =>
                                                                this.setForm({
                                                                    ...form,
                                                                    event_time: moment(
                                                                        e.target.value.toString()
                                                                    ).format(
                                                                        'YYYY-MM-DD HH:mm'
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDuration">
                                                            Duration (Hours)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyDuration"
                                                            value={form.duration}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    duration: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group" style={{
                                                        marginBottom: '0'
                                                    }}>
                                                        <label htmlFor="propertyLastname">
                                                            Speaker Profile Image
                                                        </label>
                                                    </div>
                                                    <div className="wrap-custom-file" style={{
                                                        border: '1px solid #f1f1f1',
                                                        borderRadius: '10px'
                                                    }}>
                                                        <input
                                                            type="file"
                                                            name="image1"
                                                            id="image1"
                                                            accept=".gif, .jpg, .png"
                                                            onChange={async (e) => {
                                                                for (const file of e.currentTarget.files) {
                                                                    this.setForm({
                                                                        ...form,
                                                                        profile_image_url: this.getObjectURL(file)
                                                                    })
                                                                    this.setState({
                                                                        image: file
                                                                    })
                                                                    // await this.upload(file);
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor="image1"
                                                            style={{
                                                                backgroundImage: `url(${form.profile_image_url})`,
                                                                backgroundSize: 'cover',
                                                            }}
                                                        >
                                                            <span style={{
                                                                width: 'auto',
                                                                padding: '0 10px',
                                                                left: '5px'
                                                            }}>
                                                                <i className="flaticon-download" />{' '}
                                                                Upload Speaker Profile Image{' '}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <p>*minimum 260px x 260px</p>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySpeaker">
                                                            Speaker
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertySpeaker"
                                                            value={form.speaker_name}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    speaker_name: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertySpeakerDescription">
                                                            Speaker Description
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertySpeakerDescription"
                                                            value={form.speaker_description}
                                                            style={{
                                                                height: '130px'
                                                            }}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    speaker_description: e.target.value,
                                                                })
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-12 col-xl-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyLastname">
                                                            Speaker Profile Image
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyLastname"
                                                            value={form.profile_image_url}
                                                            // onChange={(e) => {
                                                            //     this.setForm({
                                                            //         ...form,
                                                            //         profile_image_url: e.target.value,
                                                            //     })
                                                            // }}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        {event_link && (<div className="my_profile_setting_input">
                                            <button className="btn btn2" onClick={(e) => {
                                                this.handleCopy()
                                            }}>Copy Event Link</button>
                                            <label id="cp" className="ml15"> {event_link} </label>
                                        </div>)}
                                        
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMWebinarEventUpdate;
