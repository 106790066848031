import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { State, Props } from './interface';

@withTranslation()
class StepItem extends React.Component<Props, State, WithTranslation> {
    render() {
        const { t, title, bgColor, marginLeft } = this.props;
        return (
            <div
                className="stepper-wrapper"
                style={{
                    marginLeft,
                }}
            >
                <svg viewBox="0 0 400 150">
                    <polygon
                        points="0,0 50,75 0,150 350,150 400,75 350,0"
                        style={{
                            fill: bgColor,
                            stroke: bgColor,
                        }}
                    ></polygon>
                </svg>
                <div className="stepper-title">{title}</div>
            </div>
        );
    }
}

export default StepItem;
