import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";

class ContactUs extends React.Component<Props, State> {
    state: State = {
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
    };

    handleSubmit(e) {
        e.preventDefault();
        console.log('form ==> ', this.state, process.env);
        const form = this.state;
        Request({
            url: '/contact/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    toast(data.message);
                    message.success({
                        content: data.message,
                        duration: 3,
                        className: 'success',
                    });
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                <div className="form_grid">
                                    <h4 className="mb5">Send Us An Email</h4>
                                    <p>
                                        We are here to help and answer any questions you might have
                                    </p>
                                    <form
                                        className="contact_form"
                                        id="contact_form"
                                        name="contact_form"
                                        // noValidate={true}
                                        onSubmit={(e) => this.handleSubmit(e)}
                                        method="post"
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        required={true}
                                                        type="text"
                                                        placeholder="Name"
                                                        value={this.state.name}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        className="form-control required email"
                                                        required={true}
                                                        type="email"
                                                        placeholder="Email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="phone"
                                                        name="phone"
                                                        className="form-control required phone"
                                                        required={true}
                                                        type="phone"
                                                        placeholder="Phone"
                                                        value={this.state.phone}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="subject"
                                                        name="subject"
                                                        className="form-control subject"
                                                        required={true}
                                                        type="text"
                                                        placeholder="Subject"
                                                        value={this.state.subject}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <textarea
                                                        id="message"
                                                        name="message"
                                                        className="form-control required"
                                                        rows={8}
                                                        required={true}
                                                        placeholder="Your Message"
                                                        value={this.state.message}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                                <div className="form-group mb0">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-thm"
                                                    >
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div className="contact_localtion">
                                    <h4>Contact Us</h4>
                                    <p>
                                        We are reimaging how you buy, sell and invest in real
                                        estate. It’s now easier to get everything done worry-free,
                                        with TopSky Home.
                                    </p>
                                    <div className="content_list">
                                        <h5>Address</h5>
                                        <p>
                                            19900 MacArthur Blvd #1120, Irvine <br />
                                            California 92612
                                        </p>
                                    </div>
                                    <div className="content_list">
                                        <h5>Phone</h5>
                                        <p>
                                            <a href="tel:866-998-6742">866-998-6742</a>
                                        </p>
                                    </div>
                                    <div className="content_list">
                                        <h5>Mail</h5>
                                        <p>
                                            <a href="mailto: info@topskyhome.com">
                                                info@topskyhome.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p0 mt50">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="h600" id="map-canvas"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default ContactUs;
