/* eslint-disable security/detect-object-injection */
const routes = {
    home: '/',
    PageAddNewProperty: '/PageAddNewProperty',
    PageListingHalfMap: '/PageListingHalfMap',
    PageMBuilders: '/PageMBuilders',
    PageMBuilderAdd: '/PageMBuilderAdd',
    PageMBuilderUpdate: '/PageMBuilderUpdate',
    PageMCommunities: '/PageMCommunities',
    PageMCommunityAdd: '/PageMCommunityAdd',
    PageMCommunityUpdate: '/PageMCommunityUpdate',
    PageMFloorplans: '/PageMFloorplans',
    PageMFloorplanAdd: '/PageMFloorplanAdd',
    PageMFloorplanUpdate: '/PageMFloorplanUpdate',
    PageMyProperties: '/PageMyProperties',
    PageMRentals: '/PageMRentals',
    PageApprovalProperties: '/PageApprovalProperties',
    PageCompare: '/PageCompare',
    PageListingSingle: '/PageListingSingle',
    PageMessage: '/PageMessage',
    PageMyProfile: '/PageMyProfile',
    PageUpdateProperty: '/PageUpdateProperty',
    PageTerms: '/PageTerms',
    PagePrivatePolicy: '/PagePrivatePolicy',
    PageAbout: '/PageAbout',
    PageResearch: '/PageResearch',
    PageComingSoon: '/PageComingSoon',
    PageLearn: '/PageLearn',
    PageBuy: '/PageBuy',
    PageSell: '/PageSell',
    PageAgent: '/PageAgent',
    PageMarket: '/PageMarket',
    PageDataByZip: '/PageDataByZip',
    PageDataByMetro: '/PageDataByMetro',
    PageCalculator: '/PageCalculator',
    PageContactUs: '/PageContactUs',
    PageTopSkyClub: '/PageTopSkyClub',
    MarketingVideos: '/MarketingVideos',
    BuyAdmin: '/BuyAdmin',
    SellAdmin: '/SellAdmin',
    ContactAdmin: '/ContactAdmin',
    UsersAdmin: '/UsersAdmin',
    UsersReport: '/QuestionnaireReport',
    IframCalendly: '/IframCalendly',
    PageFreeConsultation: '/PageFreeConsultation',
    PageSubscribeEmail: '/PageSubscribeEmail',
    PageUnregisterUserAdd: '/PageUnregisterUserAdd',
    PageMWebinarEventAdd: '/PageMWebinarEventAdd',
    PageMWebinarEventUpdate: '/PageMWebinarEventUpdate',
    PageMWebinarLanding: '/PageMWebinarLanding',
    PageMWebinarCalendar: '/PageMWebinarCalendar',
    PageMWebinarEvents: '/PageMWebinarEvents',
    PageCommunitySingle: '/PageCommunitySingle',
    PageCommunityIndex: '/PageCommunityIndex',
    PageMSpeakers: '/PageMSpeakers',
    PageMSpeakerAdd: '/PageMSpeakerAdd',
    PageMAgentDoc: '/PageMAgentDoc',
    PageDataByPowerbi: '/PageDataByPowerbi'
};

export const getRoute = (
    path: string,
    params?: { [key: string]: string | number },
    routesConfig: any = routes
) =>
    path.split('.').reduce((routeBranch: any, pathItem: string) => {
        if (routeBranch && routeBranch[pathItem]) {
            const route = routeBranch[pathItem];
            if (typeof route === 'string') {
                if (!params || typeof params === 'undefined') {
                    return route;
                }

                return Object.entries(params).reduce((replaced, [key, value]) => {
                    return replaced.replace(`:${key}`, String(value));
                }, route);
            }
            return routeBranch[pathItem];
        }
    }, routesConfig);

export default routes;
