import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import { connect } from 'react-redux';

@connect(({ userInfo }) => {
    return { userInfo };
})
class DataByMetro extends React.Component {
    headerBar;
    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
        this.headerBar;
    }

    private checkUser = (props) => {
        const user = props.userInfo;
        if (user && user.id) {
            if (typeof window !== 'undefined') {
                var divElement = document.getElementById('viz1714951926713');
                var vizElement = divElement.getElementsByTagName('object')[0];
                if (divElement.offsetWidth > 800) {
                    vizElement.style.width = '1000px';
                    vizElement.style.height = '1250px';
                } else if (divElement.offsetWidth > 500) {
                    vizElement.style.width = '1000px';
                    vizElement.style.height = '1250px';
                } else {
                    vizElement.style.width = '100%';
                    vizElement.style.height = '2050px';
                }
                var scriptElement = document.createElement('script');
                scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
                vizElement.parentNode.insertBefore(scriptElement, vizElement);
            }
        } else {
            this.headerBar.setLoginShow();
        }
    };

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        this.checkUser(nextProps);
    }

    componentDidMount(): void {
        this.checkUser(this.props);
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e) => {
                        this.headerBar = e;
                    }}
                />
                <section className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-12">
                                <div className="about_content">
                                    <h3> Housing Data By Metropolitan Statistical Area(MSA)</h3>
                                    <p>
                                        Narrow down your investment region by comparing data for
                                        different metropolitan area
                                    </p>
                                    <div
                                        className="tableauPlaceholder"
                                        id="viz1714951926713"
                                        style={{ position: 'relative' }}
                                    >
                                        <noscript>
                                            <a href="#">
                                                <img
                                                    alt=" "
                                                    src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;ToshaHomeAnalysis-MSA_17149517935290&#47;MetricsRank&#47;1_rss.png"
                                                    style={{ border: 'none' }}
                                                />
                                            </a>
                                        </noscript>
                                        <object className="tableauViz" style={{ display: 'none' }}>
                                            <param
                                                name="host_url"
                                                value="https%3A%2F%2Fpublic.tableau.com%2F"
                                            />
                                            <param name="embed_code_version" value="3" />
                                            <param name="site_root" value="" />
                                            <param
                                                name="name"
                                                value="ToshaHomeAnalysis-MSA_17149517935290&#47;MetricsRank"
                                            />
                                            <param name="tabs" value="yes" />
                                            <param name="toolbar" value="yes" />
                                            <param
                                                name="static_image"
                                                value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;ToshaHomeAnalysis-MSA_17149517935290&#47;MetricsRank&#47;1.png"
                                            />
                                            <param name="animate_transition" value="yes" />
                                            <param name="display_static_image" value="yes" />
                                            <param name="display_spinner" value="yes" />
                                            <param name="display_overlay" value="yes" />
                                            <param name="display_count" value="yes" />
                                            <param name="language" value="en" />
                                        </object>
                                    </div>
                                    <script type="text/javascript"> </script>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Site />
                <Footer />
            </div>
        );
    }
}

export default DataByMetro;
