import * as React from 'react';
import { Props, State } from './interface';
import SideBar from '../../components/SideBar/SideBar';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Request from '../../components/Utils/Request';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WithUtils } from '../../components/Utils/Utils';
import Pager from '../../components/Pager/Pager';
import { withRouter } from 'react-router';
import './index.less';

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class PageMWebinarEvents extends React.Component<Props, State> {
    state: State = {
        data: [],
        all: [],
        list: [],
        pager: 0,
        searchKey: '',
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
            this.getData().then();
        }
    }

    deleteProperty = async (id) => {
        await Request({
            url: '/webinar/' + id,
            method: 'DELETE',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            return res;
        });
        await this.getData();
    };

    getData = async () => {
        const params = {
            user_entered: this.props.userInfo.id,
        };

        Request({
            url:
                '/webinar?' +
                Object.entries(params)
                    .map((p) => `${p[0]}=${p[1]}`)
                    .join('&'),
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 10);
            this.setState({
                data: res.data,
                all: data,
                list: data[this.state.pager],
            });
        });
    };

    changePage = (pager) => {
        this.setState({
            list: this.state.all[pager],
            pager,
        });
    };

    render(): React.ReactNode {
        const { list, all, pager } = this.state;
        const $this = this;
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {
                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                let dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (let i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {
                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        function renderList() {
            return list?.map((item) => {
                const img = item.profile_image_url || 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png';
                return (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <th
                            scope="row"
                            onClick={() => {
                                // @ts-ignore
                                $this.props.history.push({
                                    pathname: '/PageMWebinarLanding',
                                    search: `id=${item.id}`,
                                });
                            }}
                        >
                            <div className="feat_property list favorite_page style2 mwebinar">
                                <div className="thumb">
                                    <img className="mwebinar-img" src={img} alt="" />
                                </div>
                                <div className="mwebinar-title" title={item.title}>{item.title}</div>
                            </div>
                        </th>
                        <td>{item.event_time}</td>
                        <td>{item.speaker_name}</td>
                        <td>
                            <ul className="view_edit_delete_list mb0">
                                <li
                                    className="list-inline-item"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                >
                                    <a
                                        onClick={() => {
                                            $this.props.history.push({
                                                pathname: '/PageMWebinarEventUpdate',
                                                state: item,
                                            });
                                        }}
                                    >
                                        <span className="flaticon-edit" />
                                    </a>
                                </li>
                                <li
                                    className="list-inline-item"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                >
                                    <a
                                        onClick={() => {
                                            $this.deleteProperty(item.id).then();
                                        }}
                                    >
                                        <span className="flaticon-garbage" />
                                    </a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4">
                                            <h2 className="breadcrumb_title">Manage WebinarEvents</h2>
                                    </div>
                                    <div className="col-lg-8 col-xl-8">
                                        <div className="candidate_revew_select style2 text-right mb30-991">
                                            <ul className="mb0">
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2 my_profile_setting_input">
                                                            <button className="btn btn2 float-right" onClick={(e)=> {
                                                                window.location.href = "/PageMWebinarEventAdd"
                                                            }}>+ New WebinarEvent</button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2">
                                                            <input
                                                                className="form-control mr-sm-2"
                                                                type="search"
                                                                placeholder="Search Webinar"
                                                                aria-label="Search"
                                                                value={this.state.searchKey}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        searchKey: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                            <button
                                                                className="btn my-2 my-sm-0"
                                                                type="submit"
                                                                onClick={() => {
                                                                    const {
                                                                        data,
                                                                        searchKey,
                                                                    } = this.state;
                                                                    const list = data.filter(
                                                                        (item) => {
                                                                            return (
                                                                                item.name
                                                                                    .toLowerCase()
                                                                                    .indexOf(
                                                                                        searchKey.toLowerCase()
                                                                                    ) >= 0
                                                                            );
                                                                        }
                                                                    );
                                                                    const all = _.chunk(list, 10);
                                                                    this.setState({
                                                                        all: all,
                                                                        list: all[0],
                                                                        pager: 0,
                                                                    });
                                                                }}
                                                            >
                                                                <span className="flaticon-magnifying-glass" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mb40">
                                            <div className="property_table">
                                                <div className="table-responsive mt0">
                                                    <table className="table table-sm table-striped">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Title</th>
                                                                <th scope="col">Event Time</th>
                                                                <th scope="col">Speaker</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {renderList()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mbp_pagination">
                                                    <Pager
                                                        pager={pager}
                                                        all={all}
                                                        changePage={(e) => {
                                                            this.changePage(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMWebinarEvents;
