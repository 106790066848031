import * as React from "react";
import logo1 from "../../assets/home/header-logo2.png";
import { Props, State } from "./Interface";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import routes from "routes";
import { connect } from "react-redux";
import * as Utils from 'components/Utils/Utils';
import Request from 'components/Utils/Request';
import {toast} from "react-toastify";
import './sideBar.less';

import { Menu } from 'antd';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined, LaptopOutlined, NotificationOutlined 
} from '@ant-design/icons';

@withRouter
@connect(({ userInfo }) => {
    return { userInfo };
})
class SideBar extends React.Component<Props, State> {
    state: State = {
        urlParams: {
            // user_id: '',
            // email: '',
            token: ''
        },
        isTokenSetting: false,
        urlPathName: ''
    }
    constructor(props) {
        super(props);
        this.state.urlParams = {
            // user_id: Utils.getUrlParams('user_id', props.location.search),
            // email: Utils.getUrlParams('email', props.location.search),
            token: Utils.getUrlParams('token', props.location.search)
        };
        let loc = props.location.pathname || '';
        let path = loc.replace('/', '');
        this.state.urlPathName = path
    }
    errorToast() {
        // toast('The unsubscribe link is invalid, please login to your account to apply subscription updates');
        // setTimeout(() => {
        //     window.location.href = '/';
        // }, 2000);
    }
    componentDidMount(): void {
        const user = this.props && this.props.userInfo;
        // let pathName = window.location.pathname;
        // this.setState({
        //     urlPathName: pathName.replace('/', '')
        // })
        if (!(user && user.id)) {
            const {urlParams} = this.state;
            let url = window.location.href;
            let isSubscribe = url.indexOf('PageSubscribeEmail');
            if(isSubscribe) {
                // console.log('sss01==>', isSubscribe, urlParams.user_id , urlParams.email , urlParams.token)
                // if(urlParams.user_id && urlParams.email && urlParams.token) {
                if(urlParams.token) {
                    // console.log('sss02==>', urlParams.user_id && urlParams.email && urlParams.token)
                    //解析token  encodeURIComponent(btoa(str));  加密为先base64，后URL encode
                    // let time = atob(decodeURIComponent(urlParams.token));
                    // let today = new Date().getTime();
                    // let tokenTime = new Date(time).getTime();
                    
                    // console.log('sss03==>', Math.abs(today - tokenTime)/1000/60/60/24)
                    // if(Math.abs(today - tokenTime)/1000/60/60/24 > 90) {
                    //     this.errorToast();
                    // }
                    // else {// 向后台发送认证请求
                        // console.log('sss04==>request')
                    Request({
                        url: `/user/validate_subscribe_token`,
                        method: 'POST',
                        data: {
                            token: urlParams.token
                        },
                        headers: {
                            'x-api-key': process.env.X_API_KEY,
                        },
                    }).then(({ data: res }) => {
                        // 确认返回的email是否一致。
                        // console.log('sss05==>', res)
                        if(res.user_id) {
                            Request({
                                url: `/user/` + res.user_id,
                                method: 'GET',
                                headers: {
                                    'x-api-key': process.env.X_API_KEY,
                                },
                            }).then(({ data: res2 }) => {
                                // 确认返回的email是否一致。
                                // console.log('sss06==>', res2)
                                if(res2.status === 200) {
                                    this.setState({
                                        isTokenSetting: true
                                    });
                                    this.props.sendStatusToSubscribe({
                                        isTokenSetting: true,
                                        user_id: res.user_id,
                                        new_home_marketing: res2.data.new_home_marketing !='false',
                                        research_report: res2.data.research_report !='false',
                                        tosha_events: res2.data.tosha_events !='false',
                                    });
                                }
                                else {
                                    this.errorToast();
                                }
                            });
                        }
                        else {
                            this.errorToast();
                        }
                    });
                        
                    // }
                }
                else {
                    this.errorToast();
                }
            }
            else {
                window.location.href = '/';
                if(user?.role != "ADMIN") {
                    window.location.href = '/';
                }
            }
            
        }
    }

    render(): React.ReactNode {
        let { userInfo } = this.props;
        let { isTokenSetting, urlPathName } = this.state;
        const { SubMenu } = Menu;
        const isAgent = userInfo?.role === "AGENT";
        const isAdmin = userInfo?.role === "ADMIN";
        
        const matchList = {
            'PageMessage': 'sub1',
            'PageMAgentDoc': 'sub3',
            'PageMBuilders': 'sub2',
            'PageMCommunities': 'sub2',
            'PageMFloorplans': 'sub2',
            'PageMyProperties': 'sub2',
            'PageAddNewProperty': 'sub2',
            'PageApprovalProperties': 'sub2',
            'MarketingVideos': 'sub3',
            'PageMWebinarEvents': 'sub3',
            'BuyAdmin': 'sub3',
            'SellAdmin': 'sub3',
            'ContactAdmin': 'sub3',
            'UsersAdmin': 'sub3',
            'UsersReport': 'sub3',
            'PageMAgentDoc': 'sub3',
            'PageMyProfile': 'sub4',
            'PageSubscribeEmail': 'sub4',
        }
        let matchKey = matchList[urlPathName];
        return (
            <div className="dashboard_sidebar_menu d-md-none dn-992" className={(isTokenSetting ? 'd-none' : '') + 'dashboard_sidebar_menu dn-992'}>
                <div className="sidebar-menu"
                    style={{
                        width: "17%",
                        minWidth: "200px",
                        top: '94px',
                        left: '0'
                    }}>
                    <Menu
                        mode="inline" theme="dark"
                        defaultSelectedKeys={[urlPathName]}
                        defaultOpenKeys={[matchKey]}
                        className={"manageMenu"}
                        style={{ height: '100%', borderRight: 0 }}
                        >
                        <SubMenu key="sub1" icon={<UserOutlined />} title="Main">
                            <Menu.Item key="PageMessage" onClick={(e)=> {window.location.href = "/PageMessage"}}>Message</Menu.Item>
                        </SubMenu>
                        {(isAgent) && (
                            <SubMenu key="sub3" icon={<LaptopOutlined />} title="Agent Portal">
                                <Menu.Item key="PageMAgentDoc" onClick={(e)=> {window.location.href = "/PageMAgentDoc"}}>Agent Documents</Menu.Item>
                            </SubMenu>
                        )}
                        {(isAgent || isAdmin) && (
                            <SubMenu key="sub2" icon={<LaptopOutlined />} title="Manage Listings">
                                <Menu.Item key="PageMBuilders" onClick={(e)=> {window.location.href = "/PageMBuilders"}}>Builders</Menu.Item>
                                <Menu.Item key="PageMCommunities" onClick={(e)=> {window.location.href = "/PageMCommunities"}}>Communities</Menu.Item>
                                <Menu.Item key="PageMFloorplans" onClick={(e)=> {window.location.href = "/PageMFloorplans"}}>Floorplans</Menu.Item>
                                <Menu.Item key="PageMyProperties" onClick={(e)=> {window.location.href = "/PageMyProperties"}}>My Properties</Menu.Item>
                                <Menu.Item key="PageAddNewProperty" onClick={(e)=> {window.location.href = "/PageAddNewProperty"}}>New Property</Menu.Item>
                                {(isAdmin) && (<Menu.Item key="PageApprovalProperties" onClick={(e)=> {window.location.href = "/PageApprovalProperties"}}>Approval Property</Menu.Item>)}
                            </SubMenu>
                        )}
                        {(isAdmin) && (
                            <SubMenu key="sub3" icon={<NotificationOutlined />} title="Marketing Tools">
                                <Menu.Item key="MarketingVideos" onClick={(e)=> {window.location.href = "/MarketingVideos"}}>Marketing Videos</Menu.Item>
                                <Menu.Item key="PageMWebinarEvents" onClick={(e)=> {window.location.href = "/PageMWebinarEvents"}}>Webinar Events</Menu.Item>
                                <Menu.Item key="BuyAdmin" onClick={(e)=> {window.location.href = "/BuyAdmin"}}>Buy Form</Menu.Item>
                                <Menu.Item key="SellAdmin" onClick={(e)=> {window.location.href = "/SellAdmin"}}>Sell Form</Menu.Item>
                                <Menu.Item key="ContactAdmin" onClick={(e)=> {window.location.href = "/ContactAdmin"}}>Contact Form</Menu.Item>
                                <Menu.Item key="UsersAdmin" onClick={(e)=> {window.location.href = "/UsersAdmin"}}>Users Report</Menu.Item>
                                <Menu.Item key="UsersReport"><Link to={routes.UsersReport}>Questionnaire Report</Link></Menu.Item>
                                <Menu.Item key="PageMAgentDoc" onClick={(e)=> {window.location.href = "/PageMAgentDoc"}}>Agent Documents</Menu.Item>
                            </SubMenu>
                        )}
                        
                        <SubMenu key="sub4" icon={<NotificationOutlined />} title="Manage Account">
                            <Menu.Item key="PageMyProfile" onClick={(e)=> {window.location.href = "/PageMyProfile"}}>My Profile</Menu.Item>
                            <Menu.Item key="PageSubscribeEmail" onClick={(e)=> {window.location.href = "/PageSubscribeEmail"}}>Subscribe Email</Menu.Item>
                            <Menu.Item key="Logout" onClick={(e)=> {
                                window.localStorage.removeItem("token");
                                window.localStorage.removeItem("app/set-user-info");
                                setTimeout(() => {
                                    window.location.href = "/";
                                }, 100);
                            }}>Logout</Menu.Item>
                        </SubMenu>
                    </Menu>
                </div>

                <ul
                    className="sidebar-menu"
                    style={{
                        width: "17%",
                        minWidth: "200px",
                        top: '94px',
                        left: '0',
                        display: 'none'
                    }}
                >
                    {/* <li
                        className="header"
                        onClick={() => {
                            this.props.history.push("/");
                        }}
                        style={{
                            padding: "15px 55px"
                        }}
                    >
                        <img
                            src={logo1}
                            alt="header-logo2.png"
                            style={{
                                height: "65px"
                            }}
                        />
                    </li> */}
                    <li className="title">
                        <span>Main</span>
                    </li>
                    {/*<li className="treeview">*/}
                    {/*<a href="page-dashboard.html">*/}
                    {/*<i className="flaticon-layers"></i>*/}
                    {/*<span> Dashboard</span>*/}
                    {/*</a>*/}
                    {/*</li>*/}
                    <li className="treeview">
                        <Link to={"/PageMessage"}>
                            <i className="flaticon-envelope" />
                            <span> Message</span>
                        </Link>
                    </li>
                    {(userInfo?.role === "AGENT" && userInfo?.role != "ADMIN") && (
                        <>
                            <li className="title">
                                <span>Agent Portal</span>
                            </li>
                            {/* <li className="treeview">
                                <Link to={routes.PageUnregisterUserAdd}>
                                    <i className="flaticon-plus" />
                                    <span>Unregistered user</span>
                                </Link>
                            </li> */}
                            <li className="treeview">
                                <Link to={routes.PageMAgentDoc}>
                                    <i className="flaticon-plus" />
                                    <span>Agent Documents</span>
                                </Link>
                            </li>
                        </>
                    )}
                    {(userInfo?.role === "ADMIN" || userInfo?.role === "AGENT") && (
                        <>
                            <li className="title">
                                <span>Manage Listings</span>
                            </li>
                            <li className="treeview">
                                <Link to={"/PageMBuilders"}>
                                    <i className="flaticon-home" />
                                    <span>Builders</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={"/PageMCommunities"}>
                                    <i className="flaticon-home" />
                                    <span>Communities</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={"/PageMFloorplans"}>
                                    <i className="flaticon-home" />
                                    <span>Floorplans</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={"/PageMyProperties"}>
                                    <i className="flaticon-home" />
                                    <span>My Properties</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={'/PageAddNewProperty'}>
                                    <i className="flaticon-home"/>
                                    <span>New Property</span>
                                </Link>
                            </li>
                        </>
                    )}
                    {(userInfo?.role === "ADMIN") && (
                        <>
                            <li className="treeview">
                                <Link to={'/PageApprovalProperties'}>
                                    <i className="flaticon-home"/>
                                    <span>Approval Property</span>
                                </Link>
                            </li>
                        </>
                    )}
                    {(userInfo?.role === "ADMIN") && (
                        <>
                            <li className="title">
                                <span>Marketing Tools</span>
                            </li>
                            <li className="treeview">
                                <Link to={routes.MarketingVideos}>
                                    <i className="flaticon-photo-camera" />
                                    <span>Marketing Videos</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.BuyAdmin}>
                                    <i className="flaticon-money-bag" />
                                    <span>Buy Form</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.SellAdmin}>
                                    <i className="flaticon-invoice" />
                                    <span>Sell Form</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.ContactAdmin}>
                                    <i className="flaticon-envelope" />
                                    <span>Contact Form</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.UsersAdmin}>
                                    <i className="flaticon-user-1" />
                                    <span>Users Report</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.UsersReport}>
                                    <i className="flaticon-user-1" />
                                    <span>Questionnaire Report</span>
                                </Link>
                            </li>
                            {/* <li className="treeview">
                                <Link to={routes.PageUnregisterUserAdd}>
                                    <i className="flaticon-plus" />
                                    <span>Unregistered user</span>
                                </Link>
                            </li> */}
                            <li className="treeview">
                                <Link to={routes.PageMWebinarEvents}>
                                    <i className="flaticon-play" />
                                    <span>Webinar Events</span>
                                </Link>
                            </li>
                            <li className="treeview">
                                <Link to={routes.PageMAgentDoc}>
                                    <i className="flaticon-plus" />
                                    <span>Agent Documents</span>
                                </Link>
                            </li>
                            {/* <li className="treeview">
                                <Link to={routes.PageMSpeakers}>
                                    <i className="flaticon-play" />
                                    <span>Speakers</span>
                                </Link>
                            </li> */}
                        </>
                    )}

                    <li className="title">
                        <span>Manage Account</span>
                    </li>
                    <li>
                        <Link to={"/PageMyProfile"}>
                            <i className="flaticon-user" /> <span>My Profile</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/PageSubscribeEmail"}>
                            <i className="flaticon-envelope" /> <span>Subscribe Email</span>
                        </Link>
                    </li>
                    <li>
                        <a
                            href={""}
                            onClick={() => {
                                window.localStorage.removeItem("token");
                                window.localStorage.removeItem("app/set-user-info");
                                setTimeout(() => {
                                    window.location.href = "/";
                                }, 100);
                            }}
                        >
                            <i className="flaticon-logout" /> <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default SideBar;
