import * as React from 'react';
import { Props, State } from './interface';
import SideBar from '../../components/SideBar/SideBar';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Request from '../../components/Utils/Request';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WithUtils } from '../../components/Utils/Utils';
import Pager from '../../components/Pager/Pager';
import { withRouter } from 'react-router';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class PageMCommunities extends React.Component<Props, State> {
    state: State = {
        data: [],
        all: [],
        list: [],
        pager: 0,
        searchKey: '',
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
            this.getData().then();
        }
    }

    deleteProperty = async (id) => {
        await Request({
            url: '/community/' + id,
            method: 'DELETE',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            return res;
        });
        await this.getData();
    };

    getData = async () => {
        const params = {
            user_entered: this.props.userInfo.id,
        };

        Request({
            url:
                '/community?' +
                Object.entries(params)
                    .map((p) => `${p[0]}=${p[1]}`)
                    .join('&'),
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 5);
            this.setState({
                data: res.data,
                all: data,
                list: data[this.state.pager],
            });
        });
    };

    changePage = (pager) => {
        this.setState({
            list: this.state.all[pager],
            pager,
        });
    };

    changeStatus = (item) => {
        Request({
            url: '/community/' + item.id,
            method: 'PUT',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: item,
        }).then((res) => {
            if(res?.data.status === 200) {
                // 200的状态值就进行跳转
                toast.success(res.data.message || 'Update the Community successfully!')
            }
            // if (res.data.data && res.data.data.id) {
            //     this.setForm({
            //         ...this.state.form,
            //         id: res.data.data.id,
            //     });
            // }
        });
    };
    render(): React.ReactNode {
        const { list, all, pager } = this.state;
        const $this = this;
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {
                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                let dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (let i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {
                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        function renderList() {
            return list?.map((item) => {
                const img = item.images && item.images.length > 0 ? item.images[0] : '';
                return (
                    <tr key={item.id}>
                        {/* <th
                            scope="row"
                            onClick={() => {
                                // @ts-ignore
                                $this.props.history.push({
                                    pathname: '/PageListingSingle',
                                    search: `id=${item.id}&title=${item.title}`,
                                });
                            }}
                        >
                            <div className="feat_property list favorite_page style2">
                                <div className="thumb">
                                    <img className="img-whp" src={img} alt="" />
                                    <div className="thmb_cntnt">
                                        <ul className="tag mb0">
                                            {item.is_featured == 'Y' ? (
                                                <li className="list-inline-item dn">Featured</li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <div className="tc_content">
                                        <h4>{item.title}</h4>
                                        <p>
                                            <span className="flaticon-placeholder" />
                                            {item.address}
                                        </p>
                                        <a className="fp_price text-thm" href="#">
                                            ${toThousands(item.listing_price)}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </th> */}
                        <td>{item.name}</td>
                        <td>{item.zip}</td>
                        <td>{item.builder_info && item.builder_info.name}</td>
                        <td>{item.address}</td>
                        <td>{item.home_type}</td>
                        <td>
                            <ul className="view_edit_delete_list mb0">
                                <li className="list-inline-item" data-toggle="tooltip" data-placement="top" title="Edit">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={item.status == 'APPROVED'}
                                        onClick={(status) => {
                                            item.status = status ? 'APPROVED' : 'PENDDING';
                                            $this.setState({
                                                list: list
                                            })
                                            $this.changeStatus(item);
                                        }}
                                        />
                                </li>
                                <li className="list-inline-item" data-toggle="tooltip" data-placement="top" title="Edit">
                                    <a
                                        onClick={() => {
                                            $this.props.history.push({
                                                pathname: '/PageMCommunityUpdate',
                                                state: item,
                                            });
                                        }}
                                    >
                                        <span className="flaticon-edit" />
                                    </a>
                                </li>
                                <li className="list-inline-item" data-toggle="tooltip" data-placement="top" title="Edit">
                                    <a
                                        onClick={() => {
                                            $this.deleteProperty(item.id).then();
                                        }}
                                    >
                                        <span className="flaticon-garbage" />
                                    </a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 mb10">
                                        <div className="breadcrumb_content style2 mb30-991">
                                            <h2 className="breadcrumb_title">Manage Communities</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8">
                                        <div className="candidate_revew_select style2 text-right mb30-991">
                                            <ul className="mb0">
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2 my_profile_setting_input">
                                                            <button className="btn btn2 float-right" onClick={(e)=> {
                                                                window.location.href = "/PageMCommunityAdd"
                                                            }}>+ New Community</button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2">
                                                            <input
                                                                className="form-control mr-sm-2"
                                                                type="search"
                                                                placeholder="Search Community"
                                                                aria-label="Search"
                                                                value={this.state.searchKey}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        searchKey: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                            <button
                                                                className="btn my-2 my-sm-0"
                                                                type="submit"
                                                                onClick={() => {
                                                                    const {
                                                                        data,
                                                                        searchKey,
                                                                    } = this.state;
                                                                    const list = data.filter(
                                                                        (item) => {
                                                                            return (
                                                                                item.name
                                                                                    .toLowerCase()
                                                                                    .indexOf(
                                                                                        searchKey.toLowerCase()
                                                                                    ) >= 0
                                                                            );
                                                                        }
                                                                    );
                                                                    const all = _.chunk(list, 5);
                                                                    this.setState({
                                                                        all: all,
                                                                        list: all[0],
                                                                        pager: 0,
                                                                    });
                                                                }}
                                                            >
                                                                <span className="flaticon-magnifying-glass" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mb40">
                                            <div className="property_table">
                                                <div className="table-responsive mt0">
                                                    <table className="table table-sm table-striped">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Zipcode</th>
                                                                <th scope="col">builder</th>
                                                                <th scope="col">address</th>
                                                                <th scope="col">home type</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {renderList()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mbp_pagination">
                                                    <Pager
                                                        pager={pager}
                                                        all={all}
                                                        changePage={(e) => {
                                                            this.changePage(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMCommunities;
