import React from 'react';
import $ from 'jquery';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import assets from './assets';
import {WithUtils} from 'components/Utils/Utils';
import Carousel from '../../components/Carousel/Carousel';
import SliderButton from './SliderButton/SliderButton';
import Request from '../../components/Utils/Request';
import {State, Props} from './interface';
import {withSSR, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import AutoComplete from 'react-google-autocomplete';
import {withRouter} from 'react-router';
import Agent from '../../components/Agent/Agent';

import austin from 'shared/assets/images/home/img_austin.jpg';
import atlanta from 'shared/assets/images/home/img_atlanta.jpg';
import raleigh from 'shared/assets/images/home/img_raleigh.jpg';
import tampa from 'shared/assets/images/home/img_tampa.jpg';
import orlando from 'shared/assets/images/home/img_orlando.jpg';
import dallas from 'shared/assets/images/home/img_dallas.jpg';
import losangles from 'shared/assets/images/home/img_losangles.jpg';
import phoneix from 'shared/assets/images/home/img_phoneix.jpg';
import sanantonio from 'shared/assets/images/home/img_sanantonio.jpg';
import map from 'shared/assets/images/home/us_name.jpg';
import inverst_01 from 'shared/assets/images/home/home_inverst_01.png';
import inverst_02 from 'shared/assets/images/home/home_inverst_02.png';
import usmap from 'shared/assets/images/background/us-map.jpg';
 
import licenseImg from '../../assets/images/license/sell.jpg';

import {Select, Carousel as AntDCarousel} from 'antd';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import './home.less';
import StepItem from 'components/StepItem/StepItem';

const {Option} = Select;
import '../../assets/css/bootstrap.min.less'
import '../../assets/css/style.less'
import '../../assets/css/responsive.less'
import {ToastProvider} from 'react-toast-notifications';
import { element } from 'prop-types';
import { setJSAPI } from 'components/Utils/Utils';
import {toast} from "react-toastify";

// @ts-ignore
@connect(({userInfo}) => {
    return {userInfo};
})
@WithUtils(['Request'])
@withTranslation()
@withRouter
class App extends React.Component<Props, State> {
    state: State = {
        properties: [],
        communities: [],
        searchForm: {},
        slider: -1,
        playVideo: false,
        address: '',
        city: '',
        state: '',
        zip: '',
        name: '',
        phone: '',
        email: '',
        information: ''
    };

    setProperties = (properties: Array) => {
        this.setState({
            properties: properties || [],
        });
    };
    
    setCommunities = (communities: Array) => {
        this.setState({
            communities: communities || [],
        });
    };

    setSearchForm = (form: Object) => {
        this.setState({
            searchForm: form,
        });
    };

    headerBar;

    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
    }

    search = () => {
        Request({
            // url: '/property?is_featured=Y&approval_status=APPROVED&limit=12',
            url: '/property?limit=12&approval_status=APPROVED',
            method: 'GET'
        }).then((res) => {
            const data = res.data;
            window.localStorage.setItem(
                'properties',
                JSON.stringify({
                    time: new Date().getTime(),
                    data: data?.properties
                })
            );
            this.setProperties(data?.properties);
        });
    };
    searchCommunities = () => {
        Request({
            url: '/community/themes?status=APPROVED',
            method: 'GET'
        }).then((res) => {
            const data = res.data;
            window.localStorage.setItem(
                'communities',
                JSON.stringify({
                    time: new Date().getTime(),
                    data: data.data
                })
            );
            this.setCommunities(data.data);
        });
    };
    getProperties = () => {
        const data = window.localStorage.getItem('properties');
        if (data) {
            const cache = JSON.parse(data);
            if (new Date().getTime() - cache.time < 1000 * 60 * 5) {
                this.setProperties(cache?.data);
                return;
            }
        }
        this.search();
    }
    getCommunities = () => {
        const data = window.localStorage.getItem('communities');
        if (data) {
            const cache = JSON.parse(data);
            if (new Date().getTime() - cache.time < 1000 * 60 * 5) {
                this.setCommunities(cache?.data);
                return;
            }
        }
        this.searchCommunities();
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.getProperties();
            this.getCommunities();
        }
        setJSAPI();
    }
    
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    
    handleSellSubmit(e) {
        e.preventDefault();
        const form = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            information: this.state.information
        };
        Request({
            url: '/sell/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    toast(data.message);
                    // message.success({
                    //     content: data.message,
                    //     duration: 3,
                    //     className: 'success',
                    // });
                    this.setState({
                        name: '',
                        phone: '',
                        email: '',
                        information: ''
                    })
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    render(): React.ReactNode {
        const {properties, communities} = this.state;
        const {t} = this.props;
        const contentStyle: React.CSSProperties = {
            margin: 0,
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };
        const onChange = (currentSlide: number) => {
            console.log(currentSlide);
        };
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {
                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                var dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {
                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        const routeToLanding = (item) => {
            window.location.href = '/PageCommunitySingle?id=' + item.id;
        };
        
        return (
            <React.Fragment>
                <HeaderBar
                    animate={true}
                    onRef={(e) => {
                        this.headerBar = e;
                    }}
                />
                {/*Home Design*/}
                <section className="home-one home1-overlay home1_bgi1">
                    <div className="container">
                        {/* <div className="home-title-section">
                            <div className="home-title"><label className="blue">B</label>uy SMART</div>
                            <div className="home-subtitle"><label className="blue">S</label>earch nationwide</div>
                            <div className="home-subtitle"><label className="blue">M</label>arket analysis</div>
                            <div className="home-subtitle"><label className="blue">AI</label> and machine learning</div>
                            <div className="home-subtitle"><label className="blue">R</label>einvent real estate</div>
                            <div className="home-subtitle"><label className="blue">T</label>eam support</div>
                        
                            <button className="home-title-btn"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/PageFreeConsultation'
                                    });
                                }}>Contact Us</button>
                        </div>
                        <div className="stepper home-stepper">
                            <StepItem
                                {...this.props}
                                title="Research"
                                bgColor="rgba(10, 35, 83, 0.85)"
                                marginLeft={0}
                            />
                            <StepItem
                                {...this.props}
                                title="Analyze"
                                bgColor="#e7cda4"
                                marginLeft={-10}
                            />
                            <StepItem
                                {...this.props}
                                title="Buy"
                                bgColor="rgba(10, 35, 83, 0.85)"
                                marginLeft={-10}
                            />
                            <StepItem
                                {...this.props}
                                title="Manage"
                                bgColor="#e7cda4"
                                marginLeft={-10}
                            />
                            <StepItem
                                {...this.props}
                                title="Sell"
                                bgColor="rgba(10, 35, 83, 0.85)"
                                marginLeft={-10}
                            />
                        </div> */}
                        <div className="row posr">
                            <div className="col-lg-12">
                                <div className="home_content">
                                    
                                    
                                    <div className="home_adv_srch_opt">
                                        <div
                                            className="tab-content home1_adsrchfrm"
                                            id="pills-tabContent"
                                        >
                                            <div
                                                className="tab-pane fade show active"
                                                id="pills-home"
                                                role="tabpanel"
                                                aria-labelledby="pills-home-tab"
                                            >
                                                <div className="home1-advnc-search">
                                                    <ul className="h1ads_1st_list mb0">
                                                        <li className="list-inline-item">
                                                            <div className="form-group" />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className="search_option_two"></div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className="form-group">
                                                                <AutoComplete
                                                                    className="form-control"
                                                                    style={{
                                                                        width: '290px',
                                                                    }}
                                                                    apiKey={
                                                                        'AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk'
                                                                    }
                                                                    types={[]}
                                                                    onPlaceSelected={(e) => {
                                                                        if (!e.geometry) return;
                                                                        const location =
                                                                            e.geometry.location;
                                                                        const addr = {};
                                                                        e.address_components.forEach(
                                                                            (item) => {
                                                                                addr[item.types[0]] = item;
                                                                            }
                                                                        );
                                                                        // console.log(e);
                                                                        this.setSearchForm({
                                                                            ...this.state
                                                                                .searchForm,
                                                                            latitude: location.lat(),
                                                                            longitude: location.lng(),
                                                                            distance: 20,
                                                                            address:
                                                                                (addr.street_number && addr.route) ?
                                                                                addr.street_number
                                                                                    ?.long_name +
                                                                                ' ' +
                                                                                addr.route?.long_name : e.formatted_address,
                                                                        });
                                                                    }}
                                                                />
                                                                <label htmlFor="exampleInputEmail">
                                                                    <span className="flaticon-maps-and-flags" />
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <SliderButton
                                                                min={1}
                                                                max={6}
                                                                onChange={(e) => {
                                                                    this.setSearchForm({
                                                                        ...this.state.searchForm,
                                                                        bedrooms_min: e[0],
                                                                        bedrooms_max: e[1],
                                                                    });
                                                                }}
                                                                onClose={() => {
                                                                    this.setState({
                                                                        slider: -1,
                                                                    });
                                                                }}
                                                                onOpen={() => {
                                                                    this.setState({
                                                                        slider: 0,
                                                                    });
                                                                }}
                                                                show={this.state.slider === 0}
                                                                name={'BedRoom'}
                                                                unit={''}
                                                            />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <SliderButton
                                                                min={1}
                                                                max={6}
                                                                onChange={(e) => {
                                                                    this.setSearchForm({
                                                                        ...this.state.searchForm,
                                                                        bathrooms_min: e[0],
                                                                        bathrooms_max: e[1],
                                                                    });
                                                                }}
                                                                onClose={() => {
                                                                    this.setState({
                                                                        slider: -1,
                                                                    });
                                                                }}
                                                                onOpen={() => {
                                                                    this.setState({
                                                                        slider: 1,
                                                                    });
                                                                }}
                                                                show={this.state.slider === 1}
                                                                name={'BathRoom'}
                                                                unit={''}
                                                            />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div
                                                                className={'my-selector'}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    padding: '5px 0'
                                                                }}
                                                            >
                                                                <Select
                                                                    defaultValue={'Price Min'}
                                                                    style={{ width: '50%' }}
                                                                    size={'large'}
                                                                    onChange={(e) => {
                                                                        this.setSearchForm({
                                                                            ...this.state.searchForm,
                                                                            listing_price_min: e,
                                                                        });
                                                                    }}
                                                                >
                                                                    {(() => {
                                                                        const arr = [];
                                                                        for (let i = 0; i <= 1000 * 1000 * 10; i < 1000 * 1000 ? i += 50 * 1000 : i += 500 * 1000) {
                                                                            arr.push(i)
                                                                        }
                                                                        return arr.map((province) => (
                                                                            <Option
                                                                                key={province}
                                                                            >
                                                                                {province < 1000 * 1000 ? +(province / 1000).toFixed(2) + 'K' : +(province / 1000 / 1000).toFixed(2) + 'M'}
                                                                            </Option>
                                                                        ))
                                                                    })()}
                                                                </Select>
                                                                <div style={{
                                                                    width: '20px',
                                                                    textAlign: 'center',
                                                                    lineHeight: '40px'
                                                                }}>to</div>
                                                                <Select
                                                                    defaultValue={'Price Max'}
                                                                    style={{ width: '50%' }}
                                                                    size={'large'}
                                                                    onChange={(e) => {
                                                                        this.setSearchForm({
                                                                            ...this.state.searchForm,
                                                                            listing_price_max: e,
                                                                        });
                                                                    }}
                                                                >
                                                                    {(() => {
                                                                        const arr = [];
                                                                        for (let i = 50 * 1000; i <= 1000 * 1000 * 10; i < 1000 * 1000 ? i += 50 * 1000 : i += 500 * 1000) {
                                                                            arr.push(i)
                                                                        }
                                                                        return arr.map((province) => (
                                                                            <Option
                                                                                key={province}
                                                                            >
                                                                                {province < 1000 * 1000 ? +(province / 1000).toFixed(2) + 'K' : +(province / 1000 / 1000).toFixed(2) + 'M'}
                                                                            </Option>
                                                                        ))
                                                                    })()}
                                                                </Select>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className="search_option_button">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-thm"
                                                                    onClick={() => {
                                                                        this.props.history.push({
                                                                            pathname:
                                                                                '/PageListingHalfMap',
                                                                            search: Object.entries(this.state
                                                                                .searchForm).map(item => item.join('=')).join('&'),
                                                                        });
                                                                    }}
                                                                >
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Feature Properties */}
                {properties.length >= 3 && (
                    <section id="feature-property" className="feature-property bgc-f7 pt60">
                        {/* <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <a href="#feature-property">
                                        <div className="mouse_scroll">
                                            <div className="icon">
                                                <h4>Scroll Down</h4>
                                                <p>to discover more</p>
                                            </div>
                                            <div className="thumb">
                                                <img src={assets.mouse} alt="mouse.png" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main-title text-center mb40">
                                        <a className="view-all" href="/PageListingHalfMap?isFeatured=1" >VIEW ALL<span className="flaticon-next"></span></a>
                                        <h2>{t('Featured Properties')}</h2>
                                        <p>{t('Handpicked properties by our team.')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mobile-hidden">
                            <AntDCarousel afterChange={onChange} dots={{'className': 'carousel-home'}}>
                                {(() => {
                                    const init = [];
                                    for (let i = 0; i < properties.length; i = i + 3) {
                                        if(i % 3 == 0) {
                                            init.push(i);
                                        }
                                    }
                                    return init.map((index, num) => (
                                        <div key={index}>
                                            <div className="col-lg-12 offset-lg-12">
                                                <div className="row">
                                                    {(() => {
                                                        const arr = [];
                                                        for(let index2 = index; index2 < index + 3 && index2 < properties.length; index2++) {
                                                            arr.push(properties[index2])
                                                        }
                                                        return arr.map((item) => (
                                                            <div className="col-md-4 col-lg-4 mb10" onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: '/PageListingSingle',
                                                                    search: `id=${item.id}`,
                                                                });
                                                            }}>
                                                                <div className="feat_property">
                                                                    <div className="thumb">
                                                                        <img className="img-whp" src={item.images&&item.images.length ? item.images[0] : 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png'} alt="fp1.jpg" />
                                                                        {item.exclusive == 'Y' ? <div className="list-line-exclusive">EXCLUSIVE</div> : ''}
                                                                        <div className="thmb_cntnt">
                                                                            <ul className="tag mb0">
                                                                                <li className="list-inline-item"><a href="#">{item.type.replace('_', ' ')}</a></li>
                                                                                <li className={(item.status && item.status.toLowerCase()) + " list-inline-item"}><a href="#">{item.status}</a></li>
                                                                            </ul>
                                                                            {/* <ul className="icon mb0">
                                                                                <li className="list-inline-item"><a href="#"><span className="flaticon-transfer-1"></span></a></li>
                                                                                <li className="list-inline-item"><a href="#"><span className="flaticon-heart"></span></a></li>
                                                                            </ul> */}
                                                                            <a className="fp_price" href="javascrpt:void(0);">${toThousands(item.listing_price || 0)}<small></small></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="details">
                                                                        <div className="tc_content">
                                                                            <p className="text-thm">{item.type.replace('_', ' ')}</p>
                                                                            <h4 className="prop-title" title={item.title}>{item.title}</h4>
                                                                            {/* <p><span className="flaticon-placeholder"></span> {item.address}, {item.city}, {item.state}</p> */}
                                                                            <ul className="prop_details mb0">
                                                                                <li className="list-inline-item"><a href="#">Beds: {item.bedrooms}</a></li>
                                                                                <li className="list-inline-item"><a href="#">Baths: {item.bathrooms}</a></li>
                                                                                <li className="list-inline-item"><a href="#">Sq Ft: {item.size}</a></li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="fp_footer">
                                                                            {/* <ul className="fp_meta float-left mb0">
                                                                                <li className="list-inline-item"><a href="#"><img src="images/property/pposter1.png" alt="pposter1.png" /></a></li>
                                                                                <li className="list-inline-item"><a href="#">Ali Tufan</a></li>
                                                                            </ul> */}
                                                                            <div className="fp_pdate float-right">built {item.year_built}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                        
                                    
                                })()}
                            </AntDCarousel>
                        </div>
                        <div className="mobile-show">
                            <div className="row">
                                {(() => {
                                    return properties.slice(0, 4).map((item, index) => (
                                        <div className="mb10 col-md-6" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/PageListingSingle',
                                                search: `id=${item.id}`,
                                            });
                                        }} key={index}>
                                            <div className="feat_property">
                                                <div className="thumb">
                                                    <img className="img-whp" src={item.images&&item.images.length ? item.images[0] : 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png'} alt="fp1.jpg" />
                                                    {item.exclusive == 'Y' ? <div className="list-line-exclusive">EXCLUSIVE</div> : ''}
                                                    <div className="thmb_cntnt">
                                                        <ul className="tag mb0">
                                                            <li className="list-inline-item"><a href="#">{item.type.replace('_', ' ')}</a></li>
                                                            <li className={(item.status && item.status.toLowerCase()) + " list-inline-item"}><a href="#">{item.status}</a></li>
                                                        </ul>
                                                        {/* <ul className="icon mb0">
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-transfer-1"></span></a></li>
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-heart"></span></a></li>
                                                        </ul> */}
                                                        <a className="fp_price" href="javascrpt:void(0);">${toThousands(item.listing_price || 0)}<small></small></a>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <div className="tc_content">
                                                        <p className="text-thm">{item.type.replace('_', ' ')}</p>
                                                        <h4 className="prop-title" title={item.title}>{item.title}</h4>
                                                        {/* <p><span className="flaticon-placeholder"></span> {item.address}, {item.city}, {item.state}</p> */}
                                                        <ul className="prop_details mb0">
                                                            <li className="list-inline-item"><a href="#">Beds: {item.bedrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Baths: {item.bathrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Sq Ft: {item.size}</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="fp_footer">
                                                        {/* <ul className="fp_meta float-left mb0">
                                                            <li className="list-inline-item"><a href="#"><img src="images/property/pposter1.png" alt="pposter1.png" /></a></li>
                                                            <li className="list-inline-item"><a href="#">Ali Tufan</a></li>
                                                        </ul> */}
                                                        <div className="fp_pdate float-right">built {item.year_built}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                })()}
                            </div>
                        </div>
                        {/* <div className="container ovh">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="main-title text-center mb40">
                                        <h2>{t('Featured Properties')}</h2>
                                        <p>{t('Handpicked properties by our team.')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 row">
                                {properties.map((item) => {
                                    return (
                                        <div className="col-md-6 col-lg-4" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/PageListingSingle',
                                                search: `id=${item.id}&title=${item.title}`,
                                            });
                                        }}>
                                            <div className="feat_property home7">
                                                <div className="thumb">
                                                    <img className="img-whp" src={item.images[0]} alt="fp1.jpg" />
                                                    <div className="thmb_cntnt">
                                                        <ul className="tag mb0">
                                                            <li className="list-inline-item"><a href="#">{item.type.replace('_', ' ')}</a></li>
                                                            <li className="list-inline-item"><a href="#">{item.status}</a></li>
                                                        </ul>
                                                        <ul className="icon mb0">
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-transfer-1"></span></a></li>
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-heart"></span></a></li>
                                                        </ul>
                                                        <a className="fp_price" href="javascrpt:void(0);">${toThousands(item.sold_price)}<small></small></a>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <div className="tc_content">
                                                        <p className="text-thm">{item.type.replace('_', ' ')}</p>
                                                        <h4 className="prop-title" title={item.title}>{item.title}</h4>
                                                        <p><span className="flaticon-placeholder"></span> {item.address}, {item.city}, {item.state}</p>
                                                        <ul className="prop_details mb0">
                                                            <li className="list-inline-item"><a href="#">Beds: {item.bedrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Baths: {item.bathrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Sq Ft: {item.size}</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="fp_footer">
                                                        <ul className="fp_meta float-left mb0">
                                                            <li className="list-inline-item"><a href="#"><img src="images/property/pposter1.png" alt="pposter1.png" /></a></li>
                                                            <li className="list-inline-item"><a href="#">Ali Tufan</a></li>
                                                        </ul>
                                                        <div className="fp_pdate float-right">built {item.year_built}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                } */}
                                    
                                    {/* <AntDCarousel size={3}>
                                        {properties
                                            ?.filter((item) => item.is_featured === 'Y')
                                            .map((item) => {
                                                return (
                                                    <div
                                                        className="item"
                                                        key={item.id}
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: '/PageListingSingle',
                                                                search: `id=${item.id}&title=${item.title}`,
                                                            });
                                                        }}
                                                    >
                                                        <div className="feat_property">
                                                            <div className="thumb">
                                                                <img
                                                                    className="img-whp"
                                                                    src={item.images[0]}
                                                                    alt="fp1.jpg"
                                                                    width={'360px'}
                                                                    height={'230px'}
                                                                />
                                                                <div className="thmb_cntnt">
                                                                    <ul className="tag mb0">
                                                                        <li className="list-inline-item">
                                                                            <a href="#">Featured</a>
                                                                        </li>
                                                                    </ul>
                                                                    <a className="fp_price">
                                                                        $
                                                                        {toThousands(
                                                                            item.listing_price
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="details">
                                                                <div className="tc_content">
                                                                    <p className="text-thm">
                                                                        {(item.type)}
                                                                    </p>
                                                                    <h4
                                                                        style={{
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                        }}
                                                                    >
                                                                        {item.title}
                                                                    </h4>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            <span className="flaticon-placeholder" />
                                                                            {item.city}, {item.state}
                                                                        </p>
                                                                        <p>
                                                                            {item.created_at
                                                                                ? item.created_at.split(
                                                                                    ' '
                                                                                )[0]
                                                                                : ''}
                                                                        </p>
                                                                    </div>
                                                                    <ul className="prop_details mb0">
                                                                        <li className="list-inline-item">
                                                                            <a href="#">
                                                                                Beds: {item.bedrooms}
                                                                            </a>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <a href="#">
                                                                                Baths: {item.bathrooms}
                                                                            </a>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <a href="#">
                                                                                Sq Ft:{' '}
                                                                                {toThousands(item.size)}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </AntDCarousel> */}
                                {/* </div>
                            </div>
                        </div> */}
                    </section>
                )}
                
                <section className="feature-property bgc-f7 pt60 pb0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="terms_condition_grid">
                                                <div className="grids">
                                                    <div className="container">
                                                        <h3 className="color-primary">
                                                            Sell with us or get a cash offer
                                                        </h3>

                                                        <h5 className="color-golden">
                                                            Topsky help you sell your home, your way.  1%-2% commission only.
                                                        </h5>
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => this.handleSellSubmit(e)}
                                                        method="post" className="mt15"
                                                    >
                                                        <div className="form1 form-container container">
                                                            <div className="row">                                                                   
                                                                <div className="form-group col-lg-6 col-md-6">
                                                                    <label
                                                                        htmlFor="name"
                                                                        className="label-text"
                                                                    >
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        id="name"
                                                                        name="name"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.name}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-lg-6 col-md-6">
                                                                    <label
                                                                        htmlFor="phone"
                                                                        className="label-text"
                                                                    >
                                                                        Telephone number
                                                                    </label>
                                                                    <input
                                                                        id="phone"
                                                                        name="phone"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.phone}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="email"
                                                                        className="label-text"
                                                                    >
                                                                        Email
                                                                    </label>
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        type="email"
                                                                        className="form-control"
                                                                        required={true}
                                                                        value={this.state.email}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="email"
                                                                        className="label-text"
                                                                    >
                                                                        City or zipcode of the property
                                                                    </label>
                                                                    <input
                                                                        id="information"
                                                                        name="information"
                                                                        type="text"
                                                                        className="form-control"
                                                                        required={true}
                                                                        value={this.state.information}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12 mt10">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn2 btn-block w-100"
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div
                                    style={{
                                        backgroundImage: `url(${licenseImg})`,
                                        backgroundSize: 'cover',
                                        backgroundPositionY: 'bottom',
                                    }}
                                    className="w-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/*communities*/}
                {communities.length >= 3 && (
                    <section id="feature-community" className="feature-property bgc-f7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main-title text-center mb40">
                                        <a className="view-all" href="/PageCommunityIndex" >VIEW ALL<span className="flaticon-next"></span></a>
                                        <h2>Featured Communities</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mobile-hidden-comm">
                            <AntDCarousel afterChange={onChange} dots={{'className': 'carousel-home'}}>
                                {(() => {
                                    const init2 = [];
                                    for (let j = 0; j < communities.length; j = j + 3) {
                                        if(j % 3 == 0) {
                                            init2.push(j);
                                        }
                                    }
                                    return init2.map((index) => (
                                        <div key={index}>
                                            <div className="col-lg-12 offset-lg-12">
                                                <div className="row">
                                                        {(() => {
                                                            const arr = [];
                                                            for(let index2 = index; index2 < index + 3 && index2 < communities.length; index2++) {
                                                                arr.push(communities[index2])
                                                            }
                                                            return arr.map((item, index3) => {
                                                                // return tampaVideos.map((item, index) => {
                                                                // if (index < count)
                                                                let clsName = 'comm-box';
                                                                if(index3%2==1) {
                                                                    clsName += ' even';
                                                                }
                                                                let imgs = item && item.images && item.images[0]? item.images[0]:"https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png";
                                                                return (
                                                                    <div className="col-lg-4 col-xl-4" data-id={index3} key={index3}>
                                                                        <div className={clsName} style={{backgroundImage: 'url(' + imgs + ')'}}>
                                                                            <div className="comm-box-inner" onClick={() => {
                                                                                routeToLanding(item);
                                                                            }}>
                                                                                <div className="inner-content">
                                                                                    <div className="s-title" title={item.name}>{item.name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        })()}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                })()}
                            </AntDCarousel>
                        </div>
                        <div className="container mobile-show-comm pb50">
                            <AntDCarousel afterChange={onChange} dots={{'className': 'carousel-home'}}>
                                {(() => {
                                    const init2 = [];
                                    for (let j = 0; j < communities.length; j = j + 1) {
                                        if(j % 1 == 0) {
                                            init2.push(j);
                                        }
                                    }
                                    return init2.map((index) => (
                                        <div key={index}>
                                            <div className="col-lg-12 offset-lg-12">
                                                <div className="row">
                                                        {(() => {
                                                            const arr = [];
                                                            for(let index2 = index; index2 < index + 1 && index2 < communities.length; index2++) {
                                                                arr.push(communities[index2])
                                                            }
                                                            return arr.map((item, index3) => {
                                                                // return tampaVideos.map((item, index) => {
                                                                // if (index < count)
                                                                let clsName = 'comm-box';
                                                                if(index3%2==1) {
                                                                    clsName += ' even';
                                                                }
                                                                let imgs = item && item.images && item.images[0]? item.images[0]:"https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png";
                                                                return (
                                                                    <div className="col-lg-4 col-xl-4" data-id={index3} key={index3}>
                                                                        <div className={clsName} style={{backgroundImage: 'url(' + imgs + ')'}}>
                                                                            <div className="comm-box-inner" onClick={() => {
                                                                                routeToLanding(item);
                                                                            }}>
                                                                                <div className="inner-content">
                                                                                    <div className="s-title" title={item.name}>{item.name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        })()}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                })()}
                            </AntDCarousel>
                        </div>
                    </section>
                )}
                {/*Who we are*/}
                {/* <section id="why-chose" className="whychose_us pb30">
                    <div className="container">
                        <div className="row row-home pb60">
                            <div className="col-md-12 col-lg-4 col-xl-4">
                                <div className="main-title text-center">
                                    <h2>Who we are</h2>
                                    <p>Fast Growing Company</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8 col-xl-8">
                                <img
                                    className="img-map"
                                    src={map}
                                    alt="fp1.jpg"
                                />
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-9 col-lg-6 col-xl-6 hidden">
                                <div className="row row-home">
                                    <div className="col-md-9 col-lg-4 col-xl-4">
                                        <div className="why_chose_us home-who">
                                            <div className="icon">
                                                <span className="flaticon-money-bag"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-lg-8 col-xl-8">
                                        <div className="why_chose_us home-who">
                                            <div className="details">
                                                <h4>$150M<label className="label">+</label></h4>
                                                <p>Total transaction volume(8 months)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-lg-6 col-xl-6 hidden">
                                <div className="row row-home">
                                    <div className="col-md-9 col-lg-4 col-xl-4">
                                        <div className="why_chose_us home-who">
                                            <div className="icon">
                                                <span className="flaticon-house"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-lg-8 col-xl-8">
                                        <div className="why_chose_us home-who">
                                            <div className="details">
                                                <h4>400<label className="label">+</label></h4>
                                                <p>house sold(8 months)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-9 col-lg-6 col-xl-6 hidden">
                                <div className="row row-home">
                                    <div className="col-md-9 col-lg-4 col-xl-4">
                                        <div className="why_chose_us home-who">
                                            <div className="icon">
                                                <span className="flaticon-house-1"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-lg-8 col-xl-8">
                                        <div className="why_chose_us home-who">
                                            <div className="details">
                                                <h4>4<label className="label">+</label></h4>
                                                <p>home brokerages</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-lg-6 col-xl-6 hidden">
                                <div className="row row-home">
                                    <div className="col-md-9 col-lg-4 col-xl-4">
                                        <div className="why_chose_us home-who">
                                            <div className="icon">
                                                <span className="flaticon-user-1"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-lg-8 col-xl-8">
                                        <div className="why_chose_us home-who">
                                            <div className="details">
                                                <h4>2000<label className="label">+</label></h4>
                                                <p>members in network</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    {/* </div>
                </section> */}
                {/*Where to Invest*/}
                <section id="why-chose" className="whychose_us pb30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>Where to Invest</h2>
                                    <p>Register to Search Housing Data</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 col-xl-6">
                                <div className="why_chose_us pb40"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/PageDataByMetro'
                                    });
                                }}>
                                    <div className="icon">
                                        <span className="flaticon-building"></span>
                                    </div>
                                    <div className="details">
                                        <h4>By Metropolitan Area</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                                <div className="why_chose_us pb40"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/PageDataByZip'
                                    });
                                }}>
                                    <div className="icon">
                                        <span className="flaticon-maps-and-flags"></span>
                                    </div>
                                    <div className="details">
                                        <h4>By Zip Code</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Why Chose Us*/}
                <section id="why-chose" className="whychose_us bgc-f7 pb30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>Why Choose Us</h2>
                                    <p>Worry-free One-step Solution</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-4 mb10">
                                <div className="why_chose_us pb40">
                                    <div className="icon">
                                        <span className="flaticon-profit"></span>
                                    </div>
                                    <div className="details">
                                        <h4>Full Services </h4>
                                        <p>
                                            From education to deal sourcing and negotiation, and
                                            from loan assistance to long/short-term rentals
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4 mb10">
                                <div className="why_chose_us pb40">
                                    <div className="icon">
                                        <span className="flaticon-high-five"></span>
                                    </div>
                                    <div className="details">
                                        <h4>Extensive Research</h4>
                                        <p>
                                            Real estate market research from metro to county, to
                                            zip
                                            code, to census tract, and to individual properties
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4 mb10">
                                <div className="why_chose_us pb40">
                                    <div className="icon">
                                        <span className="flaticon-home-1"></span>
                                    </div>
                                    <div className="details">
                                        <h4>Data Driven</h4>
                                        <p>
                                            AI/Machine Learning core based matching and
                                            recommendation engine for max return and min risk
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/*play videos*/}
                {/*<section className="home-play-video">
                    <div className="container">
                        <div className="row bg-img3 home-three">
                             <div className="col-lg-8">
                                <div className="home3_home_content">
                                    <h1>Your Property, Our Priority.</h1>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="home3_home_content">
                                    <a
                                        className="popup_video_btn popup-iframe popup-youtube"
                                        onClick={() => {
                                            this.setState({
                                                playVideo: true,
                                            });
                                        }}
                                    >
                                        <i className="flaticon-play"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <div
                    style={{
                        display: this.state.playVideo ? 'block' : 'none',
                        width: '100vw',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        overflow: 'hidden',
                    }}
                    onClick={() => {
                        this.setState({
                            playVideo: false,
                        });
                    }}
                >
                    <div className={'mfp-container mfp-iframe-holder'}>
                        <div className={'mfp-content'}>
                            <div className={'mfp-iframe-scaler'}>
                                <iframe
                                    className="mfp-iframe"
                                    src={'//www.youtube.com/embed/K1eHklfmaSo'}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*tosh tools*/}
                <div style={{
                    width: '100%',
                    overflowX: 'hidden'
                }} className="topsky-tools">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <a>
                                    <div className="mouse_scroll">
                                        <div className="icon">
                                            <h4>Scroll Down</h4>
                                            <p>to discover more</p>
                                        </div>
                                        <div className="thumb">
                                            <img src={assets.mouse} alt="mouse.png" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </div>
                    {/*
                    <div className="container pb30 pt100">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="main-title text-center">
                                    <h2>TopSky Tools</h2>
                                    <p>Housing Data by Metropolipolitan / Zip Code</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-banner-wrapper home10 no-padding">
                                    <Carousel size={1}>
                                        <a href="/PageDataByZip">
                                            <div
                                                className="slide slide-one self-slide bg-home-slider1"
                                                style={{
                                                    // height: '620px',
                                                    //backgroundColor: '#eee',
                                                    //backgroundImage: "url(assets/images/research/chart1.jpg)",
                                                }}
                                            >
                                                <div className="container">
                                                    <div className="row home-content">
                                                        <div
                                                            className="col-lg-12 text-center p0">
                                                            <h2 className="banner_top_title">
                                                                Housing Data By Zip Code
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="/PageDataByMetro">
                                            <div
                                                className="slide slide-one self-slide bg-home-slider2"
                                                style={{
                                                    // height: '620px',
                                                    //backgroundColor: '#eee',
                                                    //backgroundImage: sliderc2,
                                                }}
                                            >
                                                <div className="container">
                                                    <div className="row home-content">
                                                        <div
                                                            className="col-lg-12 text-center p0">
                                                            <h2 className="banner_top_title">
                                                                Housing Data By Metropolitan
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                </div>

                {/*Property Cities*/}
                {/* {<section id="property-city" className="property-city pb30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>{t('Red-Hot Market')}</h2>
                                    <p>Our Footprints</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {[
                                ['Austin', austin],
                                ['Dallas', dallas],
                                ['Atlanta', atlanta],
                                ['Raleigh', raleigh],
                                ['Los Angles', losangles],
                                ['Orlando', orlando],
                                ['Tampa', tampa],
                                ['Phoneix', phoneix],
                                ['San Antonio', sanantonio],
                            ].map((item) => {
                                return (
                                    <div
                                        className={
                                            item[0] === 'New York'
                                                ? 'col-lg-8 col-xl-8'
                                                : 'col-lg-4 col-xl-4'
                                        }
                                        key={item[0]}
                                        onClick={() => {
                                            return false;
                                            this.props.history.push({
                                                pathname: '/PageDataByZip',
                                                search: Object.entries({
                                                    city: item[0],
                                                }).map(item => item.join('=')).join('&'),
                                            });
                                        }}
                                    >
                                        <div className="properti_city city-home">
                                            <div className="thumb">
                                                <img
                                                    className="img-fluid w100"
                                                    src={item[1]}
                                                    alt=""
                                                    style={{
                                                        height: '244px',
                                                    }}
                                                />
                                            </div>
                                            <div className="overlay">
                                                <div className="details">
                                                    <h4>{item[0]}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            className={'row'}
                            style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                            }}
                        >
                            {'San Francisco Bay Area, Los Angeles, San Diego, Sacramento, Seattle, Phoenix, Dallas, Austin, Memphis, Nashville, Chicago, Salt Lake City, Tampa, Orlando, Atlanta, Raleigh, New York'
                                .split(',')
                                .map((item) => {
                                    return (
                                        <div
                                            key={item}
                                            style={{
                                                border: '1px solid #1392E2',
                                                borderRadius: '15px',
                                                padding: '0 10px',
                                                margin: '2px',
                                                height: '30px',
                                                lineHeight: '30px',
                                            }}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </section>} */}

                {/*Our Testimonials
                <section id="our-testimonials" className="our-testimonial">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2 className="color-white">{t('Testimonials')}</h2>
                                    <p className="color-white">{t('Here could be a nice sub title')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <Carousel size={1}>
                                    <div className="item">
                                        <div className="testimonial_grid">
                                            <div className="thumb">
                                                <img src={assets.jpg1} alt="1.jpg" />
                                            </div>
                                            <div className="details">
                                                <h4>Augusta Silva</h4>
                                                <p>Sales Manager</p>
                                                <p className="mt25">
                                                    Aliquam dictum elit vitae mauris facilisis at
                                                    dictum urna dignissim donec vel lectus vel
                                                    felis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_grid">
                                            <div className="thumb">
                                                <img src={assets.jpg1} alt="1.jpg" />
                                            </div>
                                            <div className="details">
                                                <h4>Augusta Silva</h4>
                                                <p>Sales Manager</p>
                                                <p className="mt25">
                                                    Aliquam dictum elit vitae mauris facilisis at
                                                    dictum urna dignissim donec vel lectus vel
                                                    felis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_grid">
                                            <div className="thumb">
                                                <img src={assets.jpg1} alt="1.jpg" />
                                            </div>
                                            <div className="details">
                                                <h4>Augusta Silva</h4>
                                                <p>Sales Manager</p>
                                                <p className="mt25">
                                                    Aliquam dictum elit vitae mauris facilisis at
                                                    dictum urna dignissim donec vel lectus vel
                                                    felis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_grid">
                                            <div className="thumb">
                                                <img src={assets.jpg1} alt="1.jpg" />
                                            </div>
                                            <div className="details">
                                                <h4>Augusta Silva</h4>
                                                <p>Sales Manager</p>
                                                <p className="mt25">
                                                    Aliquam dictum elit vitae mauris facilisis at
                                                    dictum urna dignissim donec vel lectus vel
                                                    felis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_grid">
                                            <div className="thumb">
                                                <img src={assets.jpg1} alt="1.jpg" />
                                            </div>
                                            <div className="details">
                                                <h4>Augusta Silva</h4>
                                                <p>Sales Manager</p>
                                                <p className="mt25">
                                                    Aliquam dictum elit vitae mauris facilisis at
                                                    dictum urna dignissim donec vel lectus vel
                                                    felis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
                */}

                {/*Our Blog
                <section className="our-blog bgc-f7 pb30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>{t('Articles & Tips')}</h2>
                                    <p>{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-4">
                                <div className="for_blog feat_property">
                                    <div className="thumb">
                                        <img className="img-whp" src={assets.bh1} alt="bh1.jpg" />
                                    </div>
                                    <div className="details">
                                        <div className="tc_content">
                                            <p className="text-thm">Business</p>
                                            <h4>
                                                Skills That You Can Learn In The Real Estate Market
                                            </h4>
                                        </div>
                                        <div className="fp_footer">
                                            <ul className="fp_meta float-left mb0">
                                                <li className="list-inline-item">
                                                    <a href="#">
                                                        <img
                                                            src={assets.pposter1}
                                                            alt="pposter1.png"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="#">Ali Tufan</a>
                                                </li>
                                            </ul>
                                            <a className="fp_pdate float-right" href="#">
                                                7 August 2019
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4">
                                <div className="for_blog feat_property">
                                    <div className="thumb">
                                        <img className="img-whp" src={assets.bh2} alt="bh2.jpg" />
                                    </div>
                                    <div className="details">
                                        <div className="tc_content">
                                            <p className="text-thm">Business</p>
                                            <h4>
                                                Bedroom Colors You’ll Never{' '}
                                                <br className="dn-1199" /> Regret
                                            </h4>
                                        </div>
                                        <div className="fp_footer">
                                            <ul className="fp_meta float-left mb0">
                                                <li className="list-inline-item">
                                                    <a href="#">
                                                        <img
                                                            src={assets.pposter1}
                                                            alt="pposter1.png"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="#">Ali Tufan</a>
                                                </li>
                                            </ul>
                                            <a className="fp_pdate float-right" href="#">
                                                7 August 2019
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4">
                                <div className="for_blog feat_property">
                                    <div className="thumb">
                                        <img className="img-whp" src={assets.bh3} alt="bh3.jpg" />
                                    </div>
                                    <div className="details">
                                        <div className="tc_content">
                                            <p className="text-thm">Business</p>
                                            <h4>5 Essential Steps for Buying an Investment</h4>
                                        </div>
                                        <div className="fp_footer">
                                            <ul className="fp_meta float-left mb0">
                                                <li className="list-inline-item">
                                                    <a href="#">
                                                        <img
                                                            src={assets.pposter1}
                                                            alt="pposter1.png"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="#">Ali Tufan</a>
                                                </li>
                                            </ul>
                                            <a className="fp_pdate float-right" href="#">
                                                7 August 2019
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                */}

                {/*Our Partners
                <section id="our-partners" className="our-partners">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>Our Partners</h2>
                                    <p>We only work with the best companies around the globe</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg">
                                <div className="our_partner">
                                    <img className="img-fluid" src={assets.png1} alt="1.png" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg">
                                <div className="our_partner">
                                    <img className="img-fluid" src={assets.png2} alt="2.png" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg">
                                <div className="our_partner">
                                    <img className="img-fluid" src={assets.png3} alt="3.png" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg">
                                <div className="our_partner">
                                    <img className="img-fluid" src={assets.png4} alt="4.png" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg">
                                <div className="our_partner">
                                    <img className="img-fluid" src={assets.png5} alt="5.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                */}

                <Agent headerBar={this.headerBar}/>

                {/*Our Footer*/}
                <Site/>

                {/*Our Footer Bottom Area*/}
                <Footer/>

            </React.Fragment>
        );
    }
}

export default App;
