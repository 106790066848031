import * as React from 'react';
import { useState } from 'react';

const Select = (props: {
    class?: string;
    buttonClass?: string;
    style?: object;
    options: Array<{
        name: string;
        value: string;
    }>;
    defaultValue?: string;
    onSelect: Function;
    title?: string;
}) => {
    const [show, setShow] = useState(false);
    const [active, setActive] = useState({ name: '', value: '' });
    if(props.defaultValue) {
        const itemFind = props.options.find(item => {return item.value == props.defaultValue})
        active.name = (itemFind && itemFind.name) || ''
        active.value = props.defaultValue
    }
    // console.log('select', active)
    return (
        <div
            className={'dropdown bootstrap-select ' + props.class + (show ? ' show' : '')}
            style={props.style}
        >
            <button
                type="button"
                className={
                    props.buttonClass
                        ? `dropdown-toggle ${props.buttonClass}`
                        : `btn dropdown-toggle btn-light`
                }
                data-toggle="dropdown"
                role="button"
                title="Status"
                onClick={() => {
                    setShow(!show);
                }}
            >
                <div className="filter-option">
                    <div className="filter-option-inner">
                        <div className="filter-option-inner-inner">
                            {active.name ? active.name : (props.title || 'Property Type')}
                        </div>
                    </div>
                </div>
            </button>
            <div
                className={'dropdown-menu ' + (show ? 'show' : '')}
                role="combobox"
                style={
                    show
                        ? {
                              maxHeight: '566px',
                              overflow: 'hidden',
                              minHeight: '122px',
                              position: 'absolute',

                              transform: 'translate3d(0px, 52px, 0px)',
                              top: '0px',
                              left: '0px',
                              willChange: 'transform',
                              display: show ? 'block' : 'none',
                          }
                        : {}
                }
            >
                <div
                    className={'inner ' + (show ? 'show' : '')}
                    role="listbox"
                    aria-expanded="true"
                    tabIndex="-1"
                    style={{
                        maxHeight: '548px',
                        overflowY: 'auto',
                        minHeight: '104px',
                        display: show ? 'block' : 'none',
                        overflowX: 'hidden',
                    }}
                >
                    <ul className={'dropdown-menu inner ' + (show ? 'show' : '')}>
                        {props.options.map((item, index) => {
                            return (
                                <li
                                    key={`item.value-${index}`}
                                    className={
                                        '' + (active.value === item.value ? 'selected active' : '')
                                    }
                                    onClick={() => {
                                        props.onSelect(item);
                                        setActive(item);
                                        setShow(false);
                                    }}
                                >
                                    <a
                                        role="option"
                                        className={
                                            'dropdown-item' +
                                            (active.value === item.value ? ' selected active' : '')
                                        }
                                    >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">{item.name}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Select;
