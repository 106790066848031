import React from 'react';
import { message } from 'antd';

// require('dotenv').config();
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import StepItem from 'components/StepItem/StepItem';
import { State, Props } from './interface';
import Request from 'components/Utils/FormRequest';
import licenseImg from '../../assets/images/agency/agent.jpg';
import {toast} from "react-toastify";

class buy extends React.Component<Props, State> {
    state: State = {
        address: '',
        city: '',
        state: '',
        zip: '',
        buy_county: '',
        name: '',
        phone: '',
        email: '',
    };

    handleSubmit(e) {
        e.preventDefault();
        const form = this.state;
        Request({
            url: '/buy/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    toast(data.message);
                    // message.success({
                    //     content: data.message,
                    //     duration: 3,
                    //     className: 'success',
                    // });
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="ml-5 mr-5">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="form_grid">
                                            <div className="grids mb30 text-left pl-5 pr-5 ml-5 mr-5">
                                                <div className="container">
                                                    <p className="color-primary">
                                                        Get your referral commission during 2 years period for every purchase/sell your referred buyers/sellers finish with us, and get extra money when you list your pre-market/off-market deals with us
                                                    </p>
                                                    <h4 className="mb15">
                                                        Dear Agent
                                                    </h4>
                                                    <p className="">
                                                        TopSky Home is a data-based tech-driven Real Estate Company, we are dedicated to providing one-stop full service for investors from all over the world.
                                                        We have thousands of club members who are ready buyers including cash buyers. Currently, we are very actively looking for Pre-Listed and Off-Market Listings and we'd love to collaborate with top agents like you.
                                                        Our buyers are experienced remote investors who can make decisions quickly.
                                                    </p>

                                                    <p className="">
                                                        TopSky Home is also affiliated with North America Real Estate Academy, which is the largest Chinese online-education platform that provides real estate investment related courses.
                                                        The academy currently has over 10,000 members.
                                                    </p>
                                                    <p className="">
                                                        Thank you for your time. We are looking forward to having a chance to work with you soon
                                                    </p>
                                                    <p className="">
                                                        Sincerely,<br />
                                                        TopSky Home
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <a
                                                                type="button"
                                                                className="btn dbxshad btn-lg btn-thm rounded"
                                                                href="/PageContactUs"
                                                            >
                                                                Refer a Buyer/Seller
                                                                </a>
                                                        </div>
                                                        <div className="col-6">
                                                            <a
                                                                type="button"
                                                                className="btn dbxshad btn-lg btn-thm rounded"
                                                                href="/PageContactUs"
                                                            >
                                                                Pre-Listed and Off-Market Deals
                                                                </a>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="grids mb100">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div
                                    style={{
                                        backgroundImage: `url(${licenseImg})`,
                                        backgroundSize: 'cover',
                                        height:100
                                    }}
                                    className="w-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default buy;
