import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageDataByPowerbi extends React.Component {
    headerBar;
    constructor(props) { 
        super(props);
        this.headerBar = React.createRef();
        this.headerBar;
    }

    private checkUser = (props) => {
        const user = props.userInfo;
        if (!user || (user.role != 'AGENT' && user.role != 'ADMIN')) {
            alert('You don\'t have permission to access this page. Please email to info@topskyhome.com ');
            window.location.href = '/';
            return false;
        }
        
        return true;
        if (user && user.id) {
            if (typeof window !== 'undefined') {
                var divList = ['viz1701891298001']; //['viz1611719030603', 'viz1696562767517', 'viz1696563345302', 'viz1696563369020', 'viz1696563382084']
                for(var key in divList) {
                    var divElement = document.getElementById(divList[key]);
                    var vizElement = divElement.getElementsByTagName('object')[0];
                    if (divElement.offsetWidth > 800) {
                        vizElement.style.width = '1000px';
                        vizElement.style.height = '880px';
                    } else if (divElement.offsetWidth > 500) {
                        vizElement.style.width = '1000px';
                        vizElement.style.height = '880px';
                    } else {
                        vizElement.style.width = '100%';
                        vizElement.style.height = '1750px';
                    }
                    if(parseInt(key)+1 == divList.length) {
                        var scriptElement = document.createElement('script');
                        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
                        vizElement.parentNode.insertBefore(scriptElement, vizElement);
                    }
                }
            }
        } else {
            this.headerBar.setLoginShow();
        }
    };
    
    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        // this.checkUser(nextProps);
    }

    componentDidMount(): void {
        // this.checkUser(this.props);
    }

    render() {
        const cB = (key => {
            console.log(key);
        })

        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e) => {
                        this.headerBar = e;
                    }}
                />
                <section className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-12">
                                <div className="about_content">
                                    <h3> Data by Power BI </h3>
                                    <Tabs defaultActiveKey="1" onChange={cB}>
                                        <TabPane tab="Community Analysis" key="1">
                                            <div>
                                                <iframe title="south-california-map" width="100%" height="800" src="https://app.powerbi.com/view?r=eyJrIjoiMjU3NzdjZDItOWY0NC00MGM5LThmODgtZDNlYWY3NWY2ODk1IiwidCI6IjQ5NTkyZTZjLWNhOTMtNDI2My05MWM3LTNiYzNjODJmYzY4ZSIsImMiOjZ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Comparison of House Type" key="2">
                                            <div>
                                                <iframe title="south-california-map" width="100%" height="800" src="https://app.powerbi.com/view?r=eyJrIjoiMjU3NzdjZDItOWY0NC00MGM5LThmODgtZDNlYWY3NWY2ODk1IiwidCI6IjQ5NTkyZTZjLWNhOTMtNDI2My05MWM3LTNiYzNjODJmYzY4ZSIsImMiOjZ9&pageName=ReportSection5a689d113f21da4048fd" frameborder="0" allowFullScreen="true"></iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Austin Analysis" key="3">
                                            <div>
                                                <iframe title="AllData_v3" width="100%" height="800"  src="https://app.powerbi.com/view?r=eyJrIjoiMzYzYTU5ZjktMzVlNS00NDI0LWEwNmItZmE2OGUxNjE0YmQxIiwidCI6IjQ5NTkyZTZjLWNhOTMtNDI2My05MWM3LTNiYzNjODJmYzY4ZSIsImMiOjZ9" frameborder="0" allowFullScreen="true"></iframe>
                                            </div>
                                        </TabPane>
                                    </Tabs>,
                                    
                                    {/* <p>
                                        After you finalize your targeted metropolitan, dig into the
                                        region by comparing data for different zip code
                                    </p> */}
                                    
                                      
                                    <script type="text/javascript"> </script>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Site />
                <Footer />
            </div>
        );
    }
}

export default PageDataByPowerbi;
