import * as React from 'react';
import { WithUtils } from '../Utils/Utils';

const Pager = (props) => {
    const { pager, all, UUID, changePage } = props;
    const btnList = [];
    if (pager >= 0 && all.length > pager) {
        let i = 0;
        btnList.push(
            <li
                className={'page-item ' + (0 === pager ? 'active' : '')}
                key={UUID()}
                onClick={() => {
                    changePage(0);
                }}
            >
                <a className="page-link">1</a>
            </li>
        );
        if (pager > 3) {
            btnList.push(
                <li
                    className={'page-item'}
                    key={UUID()}
                    onClick={() => {
                        changePage(pager - 3);
                    }}
                >
                    <a className="page-link">...</a>
                </li>
            );
        }
        while (i < all.length) {
            const ind = i;
            if (Math.abs(pager - i) < 3 && i !== 0 && i !== all.length - 1)
                btnList.push(
                    <li
                        className={'page-item ' + (i === pager ? 'active' : '')}
                        key={UUID()}
                        onClick={() => {
                            changePage(ind);
                        }}
                    >
                        <a className="page-link">{i + 1}</a>
                    </li>
                );
            i++;
        }
        if (pager < all.length - 3) {
            btnList.push(
                <li
                    className={'page-item'}
                    key={UUID()}
                    onClick={() => {
                        changePage(pager + 3);
                    }}
                >
                    <a className="page-link">...</a>
                </li>
            );
        }
        if (all.length > 1)
            btnList.push(
                <li
                    className={'page-item ' + (all.length - 1 === pager ? 'active' : '')}
                    key={UUID()}
                    onClick={() => {
                        changePage(all.length - 1);
                    }}
                >
                    <a className="page-link">{all.length}</a>
                </li>
            );
    }
    return (
        <ul className="page_navigation" key={UUID()}>
            <li
                className={"page-item " + (pager > 0 ? '' : 'disabled')}
                key={UUID()}
                onClick={() => {
                    if (pager > 0) {
                        changePage(pager - 1);
                    }
                }}
            >
                <a className="page-link">
                    {' '}
                    <span className="flaticon-left-arrow" />
                </a>
            </li>
            {btnList}
            <li className={"page-item " + (pager < all.length - 1 ? '' : 'disabled')} key={UUID()}
                onClick={() => {
                    if (pager < all.length - 1) {
                        changePage(pager + 1);
                    }
                }}>
                <a
                    className="page-link"
                >
                    <span className="flaticon-right-arrow" />
                </a>
            </li>
        </ul>
    );
};

export default WithUtils('UUID')(Pager);
