import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import GoogleMap from 'shared/components/GoogleMap/GoogleMap';
import { connect } from 'react-redux';
import Select from '../../components/Select/Select';
import Request from '../../components/Utils/Request';
import AutoComplete from 'react-google-autocomplete';
import {message} from "antd";
import {types} from 'shared/components/Utils/TypesData'
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageMBuilderAdd extends React.Component<Props, State> {
    state: State = {
        form: {
            name: '',
            email: '',
            phone: '',
            region: '',
            sales_manager: '',
            sales_office_address: '',
            sales_office_city: '',
            sales_office_state: '',
            sales_office_zip: 0,
            website: '',
            
            latitude: 0,
            longitude: 0,
            county: '',

            hide_full_address: 'N'
        },
        images: [],
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };

    queryImages = () => {
        if (this.state.form.id)
        Request({
            url: '/image',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                parent_id: this.state.form.id,
                image_type: 'BUILDER',
            },
        }).then((res) => {
            this.setState({
                images: res.data.data
            })
        });
    };

    upload = async (file) => {
        const formData = new FormData();
        formData.append('parent_id', this.state.form.id);
        formData.append('image_type', 'BUILDER');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data
        });
        this.queryImages();
    };

    submit = () => {
        const form = this.state.form;
        Request({
            url: '/builder',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then((res) => {
            console.log(res, res.data, res.data.status)
            if (res.data.status == 200) {
                // 200的状态值就进行跳转
                toast(res.message || 'Add the builder successfully!')
                this.props.history.push({
                    pathname: '/PageMBuilders'
                })
            }
        });
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.queryImages()
        }
    }

    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form, images } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Add New Builder</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Create Listing</h4>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Name
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.name}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        name: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyUrl">
                                                            <span className="text-danger">*</span> Website
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyUrl"
                                                            required
                                                            value={form.website}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    website: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Region
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.region}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        region: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Manager
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.sales_manager}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        sales_manager: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Phone
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={form.phone}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        phone: +e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Email
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.email}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        email: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-lg-12">
                                                    <div className="my_profile_setting_textarea">
                                                        <label htmlFor="propertyDescription">
                                                            Description
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertyDescription"
                                                            rows="7"
                                                            value={form.description}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        description: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div> 
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Type</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        type: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={types}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Price
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.listing_price}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        listing_price: +e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>
                                        {/* <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label htmlFor="propertyStreamUrl" style={{
                                                            width: '100%'
                                                        }}>
                                                        <h4 className="mb30" style={{
                                                            display: 'inline-block'
                                                        }}>Office address</h4>
                                                    </label>
                                                    <div className="my_profile_setting_input form-group">
                                                        <AutoComplete
                                                            className="form-control"
                                                            apiKey={
                                                                'AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk'
                                                            }
                                                            types={[]}
                                                            onPlaceSelected={(e) => {
                                                                if (!e.geometry) return;
                                                                console.log("hello", e, e.geometry);
                                                                const location =
                                                                    e.geometry.location;
                                                                const addr = {};
                                                                e.address_components.forEach(
                                                                    (item) => {
                                                                        addr[
                                                                            item.types[0]
                                                                            ] = item;
                                                                    }
                                                                );
                                                                this.setForm({
                                                                    ...this.state.form,
                                                                    latitude: location.lat(),
                                                                    longitude: location.lng(),
                                                                    sales_office_address:
                                                                        addr.street_number?.long_name + ' ' + addr.route?.long_name,
                                                                    sales_office_state:
                                                                        addr?.administrative_area_level_1?.short_name ||
                                                                        addr.political?.short_name,
                                                                    sales_office_zip:
                                                                    addr.postal_code?.short_name,
                                                                    sales_office_city: addr.locality?.long_name,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyState">
                                                            County / State
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyState"
                                                            value={form.sales_office_state}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyCity">City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyCity"
                                                            value={form.sales_office_city}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="zipCode">Zip</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="zipCode"
                                                            value={form.sales_office_zip}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <div className="h400 bdrs8" id="map-canvas">
                                                            <GoogleMap
                                                                children={[]}
                                                                centerMark={true}
                                                                center={{
                                                                    lat: form.latitude,
                                                                    lng: form.longitude,
                                                                }}
                                                                onEvent={(e) => {
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLat">
                                                            Latitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLat"
                                                            value={form.latitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLong">
                                                            Longitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLong"
                                                            value={form.longitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* {
                                            (() => {
                                                if (typeof form.id !== 'undefined') {
                                                    return (
                                                        <div className="my_dashboard_review mt30">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="mb30">Property media</h4>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <ul className="mb0">
                                                                        {
                                                                            images?.map(item => {
                                                                                return (
                                                                                    <li className="list-inline-item" key={item.id}>
                                                                                        <div className="portfolio_item">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={item.src}
                                                                                                alt="404"
                                                                                            />
                                                                                            <div
                                                                                                className="edu_stats_list"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Delete"
                                                                                                data-original-title="Delete"
                                                                                            >
                                                                                                <a onClick={() => {

                                                                                                }}>
                                                                                                    <span className="flaticon-garbage" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="portfolio_upload" onClick={() => {
                                                                        document.getElementById('myfile').click()
                                                                    }}>
                                                                        <input type="file" id="myfile" multiple="multiple" onChange={async (e) => {
                                                                            for (const file of e.currentTarget.files) {
                                                                                await this.upload(file)
                                                                            }
                                                                        }}/>
                                                                        <div className="icon">
                                                                            <span className="flaticon-download" />
                                                                        </div>
                                                                        <p>Drag and drop images here</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        } */}
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMBuilderAdd;
