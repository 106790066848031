import { produce } from 'immer';
import { ActionTypes } from './actions';
import { Action, Compare } from './types';
export const initialState: Compare = Object.freeze({
    properties: [],
});

const reducer = (state: Compare = initialState, action: Action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.COMPARE_ADD:
                if (
                    draft.properties.length < 3 &&
                    draft.properties.filter((item) => {
                        return item.id == action.payload.data.id;
                    }).length == 0
                ) {
                    draft.properties.push(action.payload.data);
                    window.localStorage.setItem('COMPARE', JSON.stringify(draft.properties));
                }
                return;

            case ActionTypes.COMPARE_DEL:
                draft.properties = draft.properties.filter((item) => {
                    return item.id !== action.payload.data.id;
                });
                window.localStorage.setItem('COMPARE', JSON.stringify(draft.properties));
                return;

            case ActionTypes.COMPARE_INIT:
                if (action.payload)
                    draft.properties = action.payload.data ? action.payload.data : [];
                return;
        }
    });

export default reducer;
