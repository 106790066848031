import * as React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import data from '../HeaderBar/HeaderMenuData';
import './Footer.less';
@withRouter
@connect(({ userInfo }) => {
    return { userInfo };
})
@withTranslation()
class Footer extends React.Component {
    render(): React.ReactNode {
        const { t, i18n } = this.props;

        return <section className="footer_middle_area pt20 pb20">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-6">
                        <div className="footer_menu_widget">
                            <ul>
                                {
                                    data.map(item => {
                                        return (
                                            <li className="list-inline-item" key={item.name}>
                                                <a href={item.href}>{t('nav.' + item.name)}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6">
                        <div className="footer_menu_widget copyright-widget text-right">
                            <ul>
                                <li className="list-inline-item">
                                    <a href='/PageTerms'>Terms of Use</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href='/PagePrivatePolicy'>Private Policy</a>
                                </li>
                                <li className="list-inline-item"><p>© 2021 TopSky Home.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default Footer;
