import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import Request from 'shared/components/Utils/Request';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar/SideBar';
import {message} from "antd";

@connect(({ userInfo }) => {
    return { userInfo };
})
class Page extends React.Component<Props, State> {
    state: State = {
        conversation: [],
        checkedConv: {},
        message: '',
    };
    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            this.initialData().then(() => {
                if (this.props.location.state) {
                    const conv = this.state.conversation.map(item=>item.subject.replace('Property Inquiry:', '')).indexOf(this.props.location.state.subject);
                    if (
                        conv < 0
                    )
                        this.createConv(this.props.location.state).then();
                    else
                        this.checkConv(conv).then()
                }
            });
        }
    }

    createConv = async (state) => {
        const { conversation } = this.state;
        const res = await Request({
            url: '/conversation',
            method: 'post',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                subject: 'Property Inquiry:' + state.subject,
                member1: this.props.userInfo.id,
                member2: state.owner.id,
                reference_num: state.id,
                reference_type: 'PROPERTY',
            },
        });
        this.initialData().then(() => {
            const conv = conversation.map(item=>item.subject.replace('Property Inquiry:', '')).indexOf(state.subject);
            if (conv >= 0) {
                this.checkConv(conv).then()
            }
        });
    };

    sendMessage = async () => {
        const { checkedConv, message } = this.state;
        this.setState({
            message: '',
            checkedConv: {
                ...checkedConv,
                messages: [
                    ...checkedConv.messages,
                    {
                        isMe: true,
                        sender_id: this.props.userInfo.id,
                        receiver_id: checkedConv.conv_user.id,
                        conv_id: checkedConv.id,
                        message,
                    },
                ],
            },
        });
        // if (checkedConv.member1)
        await Request({
            url: '/message',
            method: 'post',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                sender_id: this.props.userInfo.id,
                receiver_id: checkedConv.conv_user.id,
                conv_id: checkedConv.id,
                message,
            },
        });
        checkedConv.messages = await this.queryMessage(checkedConv.id);
        this.setState({
            checkedConv: checkedConv,
        });
    };

    queryUser = async (id) => {
        const {
            data: { data: conv_user, messages, status },
        } = await Request({
            url: '/user/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        });
        if (status === 200) {
            return conv_user;
        }
    };

    queryMessage = async (conv_id) => {
        const {
            data: { data: message },
        } = await Request({
            url: '/message',
            method: 'get',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                conv_id,
            },
        });
        return message.map((item) => {
            return {
                ...item,
                isMe: this.props.userInfo.id === item.sender_id,
            };
        });
    };

    initialData = async () => {
        const { data: conversation } = await Request({
            url: '/conversation',
            method: 'get',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                user_id: this.props.userInfo.id,
            },
        });
        if (conversation.data.length === 0) return;
        this.setState({
            conversation: conversation.data,
            checkedConv: conversation.data[0],
        });
        await this.checkConv(0);
    };

    checkConv = async (index) => {
        const { conversation } = this.state;
        const { id } = this.props.userInfo;
        conversation[index].messages = await this.queryMessage(conversation[index].id);
        conversation[index].conv_user = await this.queryUser(
            conversation[index].member1 === id
                ? conversation[index].member2
                : conversation[index].member1
        );
        this.setState({
            conversation,
            checkedConv: conversation[index],
        });
    };

    render(): React.ReactNode {
        const { conversation, checkedConv, message = '' } = this.state;
        const myPhoto = this.props.userInfo.profile_image_src,
            hisPhoto = checkedConv?.conv_user?.profile_image_src;
        const checkConv = this.checkConv;
        // console.log(conversation, checkedConv);
        function renderMessage() {
            return checkedConv && checkedConv.messages
                ? checkedConv.messages.map((item) => {
                      if (item.isMe)
                          return (
                              <li className="media reply" key={item.id}>
                                  <div className="media-body text-right">
                                      <div className="date_time">{item.updated_at}</div>
                                      <p>{item.message}</p>
                                  </div>
                                  <img
                                      className="img-fluid align-self-start mr-3"
                                      src={item.isMe ? myPhoto : hisPhoto}
                                      alt=""
                                      width={'60px'}
                                  />
                              </li>
                          );
                      return (
                          <li className="media sent" key={item.id}>
                              <img
                                  className="img-fluid align-self-start mr-3"
                                  src={item.isMe ? myPhoto : hisPhoto}
                                  alt=""
                                  width={'60px'}
                              />
                              <div className="media-body">
                                  <div className="date_time">{item.updated_at}</div>
                                  <p>{item.message}</p>
                              </div>
                          </li>
                      );
                  })
                : '';
        }
        function renderConversation() {
            return conversation.map((item, index) => {
                return (
                    <li className="contact" key={item.id}>
                        <a
                            onClick={() => {
                                checkConv(index).then();
                            }}
                        >
                            <div className="wrap">
                                <span className="contact-status online" />
                                <img
                                    className="img-fluid"
                                    src={item?.member2_profile_image == myPhoto ? item?.member1_profile_image : item?.member2_profile_image}
                                    alt="avatar"
                                    style={{
                                        height: '50px'
                                    }}
                                />
                                <div
                                    className="meta"
                                    style={{
                                        maxWidth: '250px',
                                    }}
                                >
                                    <h5 className="name">{item.subject}</h5>
                                    <p
                                        className="preview"
                                        style={{
                                            overflow: 'hidden',
                                            width: '140px',
                                        }}
                                    >
                                        {item.messages && item.messages.length
                                            ? item.messages[item.messages.length - 1].message
                                            : ''}
                                    </p>
                                </div>
                                {/*{item.reference_num > 0 ? (*/}
                                    {/*<div className="m_notif">{item.reference_num}</div>*/}
                                {/*) : (*/}
                                    {/*''*/}
                                {/*)}*/}
                            </div>
                        </a>
                    </li>
                );
            });
        }
        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="message_container">
                                            <div className="inbox_user_list">
                                                <div className="iu_heading">
                                                    <div className="candidate_revew_search_box">
                                                        <form className="form-inline">
                                                            <input
                                                                className="form-control"
                                                                type="search"
                                                                placeholder="Serach"
                                                                aria-label="Search"
                                                            />
                                                            <button
                                                                className="btn"
                                                                type="submit"
                                                                style={{
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <span className="flaticon-magnifying-glass" />
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <ul>{renderConversation()}</ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-xl-8">
                                        <div className="message_container">
                                            <div className="user_heading">
                                                <a>
                                                    <div className="wrap">
                                                        <span className="contact-status online" />
                                                        <img
                                                            className="img-fluid"
                                                            src={hisPhoto}
                                                            alt=""
                                                            width={'60px'}
                                                        />
                                                        <div className="meta">
                                                            <h5 className="name">
                                                                {checkedConv?.conv_user?.name ||
                                                                    checkedConv?.conv_user?.email}
                                                            </h5>
                                                            <p className="preview"/>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="inbox_chatting_box">
                                                <ul className="chatting_content">
                                                    {renderMessage()}
                                                </ul>
                                            </div>
                                            <div className="mi_text">
                                                <div className="message_input">
                                                    <form className="form-inline">
                                                        <input
                                                            className="form-control"
                                                            type="search"
                                                            placeholder="Enter text here..."
                                                            aria-label="Search"
                                                            value={message}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    message: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                        <button
                                                            className="btn"
                                                            type="button"
                                                            onClick={() => {
                                                                if (message) {
                                                                    this.sendMessage().then();
                                                                }
                                                                return false;
                                                            }}
                                                        >
                                                            Send Message
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
