import React from "react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Footer from "../../components/Footer/Footer";
import Site from "../../components/Site/Site";
import Agent from "../../components/Agent/Agent";

class learn extends React.Component {
    render() {
        return (
            <div className="wrapper" style={{
                paddingTop: '60px'
            }}>
                <HeaderBar animate={false}/>
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/9Iqci6xOmPY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/M2ZFkitmo6U" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/K1eHklfmaSo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/_a1OnP6GJuk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/bmFIgtLTV_k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/hJq-tJ-a9ME" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>

                        </div>
                    </div>
                </section>
                <Site />
                <Footer/>
            </div>
        );
    }
}

export default learn;
