import * as React from 'react';
import { Props, State } from './Interface';
import { WithUtils } from '../Utils/Utils';

@WithUtils('UUID')
class Slider extends React.Component<Props, State> {
    total: number = this.props.max - this.props.min;
    state: State = {
        left: this.props.initVal
            ? ((this.props.initVal.left - this.props.min) / this.total) * 100
            : 0,
        width: this.props.initVal
            ? ((this.props.initVal.right - this.props.initVal.left) / this.total) * 100
            : 100,
        id: '',
        leftLock: false,
        rightLock: false,
    };

    widthPX: number = 0;
    MOUSE_UP_LISTENER = undefined;
    MOUSE_MOVE_LISTENER = undefined;

    componentWillUnmount(): void {
        if (typeof window !== 'undefined') {
            window.removeEventListener('mouseup', this.MOUSE_UP_LISTENER);
            window.removeEventListener('mousemove', this.MOUSE_MOVE_LISTENER);
        }
    }

    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.setState({
                id: this.props.UUID(),
            });
            this.MOUSE_UP_LISTENER = window.addEventListener('mouseup', this.mouseUp);
            this.MOUSE_MOVE_LISTENER = window.addEventListener('mousemove', this.mouseMove);
        }
    }

    mouseUp = () => {
        this.setState({
            leftLock: false,
            rightLock: false,
        });
    };

    mouseMove = (e) => {
        const { onChange, min, format = (e) => e } = this.props;
        if (this.state.leftLock && e.buttons === 1) {
            e.preventDefault();
            const l = (e.movementX / this.widthPX) * 100;
            const all = this.state.left + this.state.width;
            const left =
                this.state.left + l > 0
                    ? this.state.left + l >= all
                        ? all
                        : this.state.left + l
                    : 0;
            const width = all - left > 0 ? all - left : 0;
            this.setState({
                left,
                width,
            });
            onChange([
                format((this.total * left) / 100 + min),
                format((this.total * (left + width)) / 100 + min),
            ]);
        }
        if (this.state.rightLock && e.buttons === 1) {
            e.preventDefault();
            const r = (e.movementX / this.widthPX) * 100;
            const width =
                this.state.width + r > 0
                    ? this.state.width + r + this.state.left < 100
                        ? this.state.width + r
                        : 100 - this.state.left
                    : 0;
            this.setState({
                width,
            });
            onChange([
                format((this.total * this.state.left) / 100 + min),
                format((this.total * (this.state.left + width)) / 100 + min),
            ]);
        }
    };

    render(): React.ReactNode {
        const { left, width, id } = this.state;

        return (
            <div
                className="slider-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                id={id}
            >
                <div
                    className="ui-slider-range ui-corner-all ui-widget-header"
                    style={{ left: left + '%', width: width + '%' }}
                />
                <span
                    className="ui-slider-handle ui-corner-all ui-state-default"
                    style={{ left: left + '%' }}
                    onMouseDown={() => {
                        this.setState({
                            leftLock: true,
                        });
                        this.widthPX = document.getElementById(this.state.id).offsetWidth;
                    }}
                />
                <span
                    className="ui-slider-handle ui-corner-all ui-state-default"
                    style={{ left: left + width + '%' }}
                    onMouseDown={() => {
                        this.setState({
                            rightLock: true,
                        });
                        this.widthPX = document.getElementById(this.state.id).offsetWidth;
                    }}
                />
            </div>
        );
    }
}

export default Slider;
