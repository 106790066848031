import * as React from 'react';
import { Props, State } from './Interface';
import { WithUtils } from 'shared/components/Utils/Utils';
import Slider from 'shared/components/Slider/Slider';

@WithUtils('UUID')
class SliderButton extends React.Component<Props, State> {
    state: State = {
        id: '',
        val0: this.props.format ? this.props.format(this.props.min) : this.props.min + '',
        val1: this.props.format ? this.props.format(this.props.max) : this.props.max + '',
        show: this.props.show,
        offset: 0,
    };

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // console.log(nextProps);
        this.setState({
            show: nextProps.show
        })
    }

    componentWillUnmount(): void {
        this.setState = ()=>false;
    }

    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.setState({
                id: this.props.UUID(),
            });
        }
    }

    showSlider() {
        const offset = document.getElementById(this.state.id).offsetLeft;
        const show = this.state.show;
        this.setState({
            show: !show,
            offset: offset - 120,
        });
        if (!show) {
            this.props.onOpen()
        } else {
            this.props.onClose()
        }
    }

    render(): React.ReactNode {
        const { min, max, onChange, name, unit, format } = this.props;
        const { id, show, val0, val1, offset } = this.state;
        return (
            <div className="small_dropdown2" id={id}>
                <div
                    id="prncgs"
                    className="btn dd_btn"
                    onClick={(e) => {
                        this.showSlider();
                    }}
                >
                    <span>{!(val0 == (format ? format(min) : min) && val1 == (format ? format(max) : max)) ? val0 + '---' + val1 + unit : name}</span>
                    <label htmlFor="exampleInputEmail2">
                        <span className="fa fa-angle-down" />
                    </label>
                </div>
                <div
                    className="dd_content2"
                    style={{
                        display: show ? 'block' : 'none',
                        left: offset + 'px',
                        margin: 0,
                    }}
                >
                    <div className="pricing_acontent">
                        <input
                            type="text"
                            className="amount"
                            value={unit ? (val0 ? val0 : min) + unit : val0 + ''}
                            placeholder={min}
                            readOnly
                        />
                        <input
                            type="text"
                            className="amount2"
                            value={unit ? (val1 ? val1 : max) + unit : val1 + ''}
                            placeholder={max}
                            readOnly
                        />
                        <Slider
                            min={min}
                            max={max}
                            format={(e) => {
                                return format ? format(e) : Math.floor(e);
                            }}
                            onChange={(e) => {
                                this.setState({
                                    val0: e[0],
                                    val1: e[1],
                                });
                                onChange(e);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SliderButton;
