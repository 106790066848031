import React from "react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Footer from "../../components/Footer/Footer";
import Site from "../../components/Site/Site";
import Agent from "../../components/Agent/Agent";
import PrivatePolicy from "../../components/Agent/PrivatePolicy";

class terms extends React.Component {
    render() {
        return (
            <div className="wrapper" style={{
                paddingTop: '60px'
            }}>
                <HeaderBar animate={false}/>
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="terms_condition_grid">
                                    <PrivatePolicy />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Agent/>
                <Site />
                <Footer/>
            </div>
        );
    }
}

export default terms;
