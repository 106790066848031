import * as React from 'react';
import { Props, State } from './Interface';
import { FunctionComponent } from 'react';
import './submit_modal.less';

export const SubmitModal: FunctionComponent<Props> = ({ modal, onClose, children }) => {
    return (
        <div id="modal">
            <div
                style={{
                    position:"absolute",
                    display: 'block',
                    width: '1903px',
                    maxWidth: '100%',
                    height: '94px',
                    float: 'none',
                    //top: '300px',
                }}
            />
            <div
                className={
                    'sign_up_modal modal fade bd-example-modal-lg ' +
                    (modal.syncShow ? 'show submit_modal' : '')
                }
                role="dialog"
                style={
                    modal.show
                        ? {
                              display: 'block',
                              paddingRight: '17px',
                          }
                        : {}
                }
            >
                <div className="modal-dialog modal-lg w-50" role="document">
                    <div className="modal-content">
                        <div
                            className="modal-header"
                            style={{
                                top: '-25px',
                                right: '-25px',
                            }}
                        >
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container pb20">
                            <div className="tab-content container" id="myTabContent">
                                <div
                                    className={'row mt25 tab-pane fade show active'}
                                    id="home"
                                    role="content"
                                    aria-labelledby="home-tab"
                                >
                                    <div className="col">{children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
