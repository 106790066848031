// import React, { Suspense } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, withRouter } from 'react-router-dom';
import favicon from '../shared/assets/favicon-ts.png';
import Home from './pages/Home';
import PageAddNewProperty from './pages/PageAddNewProperty';
import PageUpdateProperty from './pages/PageUpdateProperty';
import PageListingHalfMap from './pages/PageListingHalfMap';
import PageMBuilders from './pages/PageMBuilders';
import PageMBuilderAdd from './pages/PageMBuilderAdd';
import PageMBuilderUpdate from './pages/PageMBuilderUpdate';
import PageMCommunities from './pages/PageMCommunities';
import PageMCommunityAdd from './pages/PageMCommunityAdd';
import PageMCommunityUpdate from './pages/PageMCommunityUpdate';
import PageMFloorplans from './pages/PageMFloorplans';
import PageMFloorplanAdd from './pages/PageMFloorplanAdd';
import PageMFloorplanUpdate from './pages/PageMFloorplanUpdate';
import PageMyProperties from './pages/PageMyProperties';
import PageMRentals from './pages/PageMRentals';
import PageApprovalProperties from './pages/PageApprovalProperties';
import PageCompare from './pages/PageCompare';
import PageListingSingle from './pages/PageListingSingle';
import PageMessage from './pages/PageMessage';
import PageMyProfile from './pages/PageMyProfile';
import PageTerms from './pages/PageTerms';
import PagePrivatePolicy from './pages/PagePrivatePolicy';
import PageAbout from './pages/PageAbout';
import PageResearch from './pages/PageResearch';
import PageComingSoon from './pages/PageComingSoon';
import PageLearn from './pages/PageLearn';
import PageBuy from './pages/PageBuy';
import PageSell from './pages/PageSell';
import PageAgent from './pages/PageAgent';
import PageDataByMetro from './pages/PageDataByMetro';
import PageDataByZip from './pages/PageDataByZip';
import PageMarket from './pages/PageMarket';
import PageCalculator from './pages/PageCalculator';
import PageContactUs from './pages/PageContactUs';
import PageTopSkyClub from './pages/PageTopSkyClub';
import MarketingVideos from './pages/MarketingVideos';
import BuyAdmin from './pages/BuyAdmin';
import SellAdmin from './pages/SellAdmin';
import ContactAdmin from './pages/ContactAdmin';
import UsersAdmin from './pages/UsersAdmin';
import UsersReport from './pages/QuestionnaireReport';
import IframCalendly from './pages/IframCalendly';
import PageFreeConsultation from './pages/PageFreeConsultation';
import PageSubscribeEmail from './pages/PageSubscribeEmail';
import PageUnregisterUserAdd from './pages/PageUnregisterUserAdd';
import PageMWebinarEventAdd from './pages/PageMWebinarEventAdd';
import PageMWebinarEventUpdate from './pages/PageMWebinarEventUpdate';
import PageMWebinarLanding from './pages/PageMWebinarLanding';
import PageMWebinarCalendar from './pages/PageMWebinarCalendar';
import PageMWebinarEvents from './pages/PageMWebinarEvents';
import PageCommunityIndex from './pages/PageCommunityIndex';
import PageCommunitySingle from './pages/PageCommunitySingle';
import PageMSpeakers from './pages/PageMSpeakers';
import PageMSpeakerAdd from './pages/PageMSpeakerAdd';
import PageMAgentDoc from './pages/PageMAgentDoc';
import PageDataByPowerbi from './pages/PageDataByPowerbi';

import routes from './routes';
import withStyles from 'isomorphic-style-loader/withStyles';
// @ts-ignore
import less from './App.less';

// Does not yet work with server side rendering:
// const Home = React.lazy(() => import('./pages/Home'));
// const Page1 = React.lazy(() => import('./pages/Page-1'));
// const Page2 = React.lazy(() => import('./pages/Page-2'));
// @ts-ignore
@withRouter
@withStyles(less)
class App extends React.Component {
    render() {
        return (
            // <Suspense fallback={<div>Loading</div>}>
            <div>
                <Helmet
                    defaultTitle="TopSky Home"
                    titleTemplate="%s – React SSR Starter – TypeScript Edition"
                    link={[{ rel: 'icon', type: 'image/png', href: favicon }]}
                />
                {/*<h1>*/}
                {/*<ReactLogo className={css.reactLogo} /> React + Express – SSR Starter – TypeScript*/}
                {/*Edition*/}
                {/*</h1>*/}

                <Switch>
                    <Route exact path={routes.home} component={Home} />
                    <Route exact path={routes.PageAddNewProperty} component={PageAddNewProperty} />
                    <Route exact path={routes.PageUpdateProperty} component={PageUpdateProperty} />
                    <Route exact path={routes.PageListingHalfMap} component={PageListingHalfMap} />
                    <Route exact path={routes.PageMBuilders} component={PageMBuilders} />
                    <Route exact path={routes.PageMSpeakers} component={PageMSpeakers} />
                    <Route exact path={routes.PageMBuilderAdd} component={PageMBuilderAdd} />
                    <Route exact path={routes.PageMSpeakerAdd} component={PageMSpeakerAdd} />
                    <Route exact path={routes.PageMBuilderUpdate} component={PageMBuilderUpdate} />
                    <Route exact path={routes.PageMCommunities} component={PageMCommunities} />
                    <Route exact path={routes.PageMCommunityAdd} component={PageMCommunityAdd} />
                    <Route exact path={routes.PageMCommunityUpdate} component={PageMCommunityUpdate} />
                    <Route exact path={routes.PageMFloorplans} component={PageMFloorplans} />
                    <Route exact path={routes.PageMFloorplanAdd} component={PageMFloorplanAdd} />
                    <Route exact path={routes.PageMFloorplanUpdate} component={PageMFloorplanUpdate} />
                    <Route exact path={routes.PageMyProperties} component={PageMyProperties} />
                    <Route exact path={routes.PageMRentals} component={PageMRentals} />
                    <Route exact path={routes.PageApprovalProperties} component={PageApprovalProperties} />
                    <Route exact path={routes.MarketingVideos} component={MarketingVideos} />
                    <Route exact path={routes.BuyAdmin} component={BuyAdmin} />
                    <Route exact path={routes.SellAdmin} component={SellAdmin} />
                    <Route exact path={routes.ContactAdmin} component={ContactAdmin} />
                    <Route exact path={routes.UsersAdmin} component={UsersAdmin} />
                    <Route exact path={routes.PageCompare} component={PageCompare} />
                    <Route exact path={routes.PageListingSingle} component={PageListingSingle} />
                    <Route exact path={routes.PageMessage} component={PageMessage} />
                    <Route exact path={routes.PageMyProfile} component={PageMyProfile} />
                    <Route exact path={routes.PageTerms} component={PageTerms} />
                    <Route exact path={routes.PagePrivatePolicy} component={PagePrivatePolicy} />
                    <Route exact path={routes.PageAbout} component={PageAbout} />
                    <Route exact path={routes.PageResearch} component={PageResearch} />
                    <Route exact path={routes.PageComingSoon} component={PageComingSoon} />
                    <Route exact path={routes.PageLearn} component={PageLearn} />
                    <Route exact path={routes.PageBuy} component={PageBuy} />
                    <Route exact path={routes.PageSell} component={PageSell} />
                    <Route exact path={routes.PageAgent} component={PageAgent} />
                    <Route exact path={routes.PageDataByMetro} component={PageDataByMetro} />
                    <Route exact path={routes.PageDataByZip} component={PageDataByZip} />
                    <Route exact path={routes.PageMarket} component={PageMarket} />
                    <Route exact path={routes.PageCalculator} component={PageCalculator} />
                    <Route exact path={routes.PageSubscribeEmail} component={PageSubscribeEmail} />
                    <Route exact path={routes.PageContactUs} component={PageContactUs} />
                    <Route exact path={routes.PageTopSkyClub} component={PageTopSkyClub} />
                    <Route exact path={routes.UsersReport} component={UsersReport} />
                    <Route exact path={routes.IframCalendly} component={IframCalendly} />
                    <Route exact path={routes.PageFreeConsultation} component={PageFreeConsultation} />
                    <Route exact path={routes.PageUnregisterUserAdd} component={PageUnregisterUserAdd} />
                    <Route exact path={routes.PageMWebinarEventAdd} component={PageMWebinarEventAdd} />
                    <Route exact path={routes.PageMWebinarEventUpdate} component={PageMWebinarEventUpdate} />
                    <Route exact path={routes.PageMWebinarLanding} component={PageMWebinarLanding} />
                    <Route exact path={routes.PageMWebinarCalendar} component={PageMWebinarCalendar} />
                    <Route exact path={routes.PageMWebinarEvents} component={PageMWebinarEvents} />
                    <Route exact path={routes.PageCommunitySingle} component={PageCommunitySingle} />
                    <Route exact path={routes.PageCommunityIndex} component={PageCommunityIndex} />
                    <Route exact path={routes.PageMAgentDoc} component={PageMAgentDoc} />
                    <Route exact path={routes.PageDataByPowerbi} component={PageDataByPowerbi} />
                    <Route render={() => '404!'} />
                </Switch>
                {/*<h2>{t('router-headline')}</h2>*/}
                {/*<ul>*/}
                {/*<li>*/}
                {/*<Link to="/">{t('nav.home')}</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*<Link to="/page-1">{t('nav.page-1')}</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*<Link to="/page-2">{t('nav.page-2')}</Link>*/}
                {/*</li>*/}
                {/*</ul>*/}
            </div>
            // </Suspense>
        );
    }
}

export default App;
