import * as React from 'react';
import { Props, State } from './interface';
import SideBar from '../../components/SideBar/SideBar';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Request from '../../components/Utils/Request';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WithUtils } from '../../components/Utils/Utils';
import Pager from '../../components/Pager/Pager';
import { withRouter } from 'react-router';
import { Collapse } from "antd";
import './index.less'
import Item from 'antd/lib/list/Item';
const { Panel } = Collapse;

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class PageMAgentDoc extends React.Component<Props, State> {
    state: State = {
        list: [],
        all: [],
        playVideo: false,
        playSrc: '',
        playPdf: false,
        playSrcPdf: '',
        searchKey: ''
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            this.getData().then();
        }
    }

    getData = async () => {
        Request({
            url:
                '/agent/documents',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            this.setState({
                list: res.data,
                all: res.data
            });
        });
    };

    search = (searchKey) => {
        const {
            all
        } = this.state;
        const list = all.filter(
            (item) => {
                let booleanTag = item.tags.filter((item2) => {
                    return (
                        item2.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0
                    );
                })
                return (
                    item.title.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0 || booleanTag.length > 0
                );
            }
        );
        this.setState({
            list: list
        });
    }
    
    /**
     * 下载文件（支持跨域【服务端的文件支持跨域访问】）
     * @path 下载地址
     * @name 下载文件的名字
     */
    downloadFile (path, name) {
        // 是否支持a的download。请使用Chrome 14+或Firefox 20+
        const isSupportDownload = 'download' in document.createElement('a');
        // 是否支持 Blob。请使用Chrome 20+或Firefox 13+
        const isSupportBlob = 'Blob' in window;
        if(!isSupportDownload){
            alert('当前浏览器不支持 a 的download属性。请使用Chrome 14+或Firefox 20+');return;
        }

        if(!isSupportBlob){
            alert('当前浏览器不支持 Blob。请使用Chrome 20+或Firefox 13+');return;
        }

        if(!path){
            alert('path 不能为空');return;
        }
        // 将 \ 转换为 /
        let _path =path.replace(/\\/g,'/');
alert(111223)
        //获取文件的内容
        let xhr = new XMLHttpRequest();
        xhr.open('get', _path);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (this.status === 200 || this.status === 304) {
                // 将文件的内容转换为 URL
                let url = URL.createObjectURL(this.response);
                //使用 a 标签下载文件
                let aLink = document.createElement('a');
                aLink.style.display = 'none';
                aLink.href = url;
                //设置下载文件名
                let _urlName =_path.substring(_path.lastIndexOf('/')+1);
                _urlName && ( _urlName = _urlName.split('?')[0]);
                aLink.download = name || ( _urlName  || 'undefined' );

                document.body.appendChild(aLink);
                aLink.click();
                document.body.removeChild(aLink);
                URL.revokeObjectURL(url);
            }
        };
        xhr.send();
    }
    render(): React.ReactNode {
        const { list, playSrc, playSrcPdf } = this.state;
        const onChange = (key: string | string[]) => {
            console.log(key);
        };

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />
                <div
                    style={{
                        display: this.state.playVideo ? 'block' : 'none',
                        width: '100vw',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        overflow: 'hidden',
                        background: 'rgba(0,0,0,0.2)'
                    }}
                    onClick={() => {
                        this.setState({
                            playVideo: false,
                            playSrc: ''
                        });
                    }}
                >
                    <div className={'mfp-container mfp-iframe-holder video-mfp'}>
                        <div className={'mfp-content'}>
                            <div className="mfp-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        this.setState({
                                            playVideo: false,
                                            playSrc: ''
                                        });
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="line"></div>
                            </div>
                            <div className={'mfp-iframe-scaler'}>
                                <iframe
                                    className="mfp-iframe"
                                    src={playSrc}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: this.state.playPdf ? 'block' : 'none',
                        width: '100vw',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        overflow: 'hidden',
                        background: 'rgba(0,0,0,0.2)'
                    }}
                    onClick={() => {
                        this.setState({
                            playPdf: false,
                            playSrcPdf: ''
                        });
                    }}
                >
                    <div className={'mfp-container mfp-iframe-holder video-mfp pdf-mfp'}>
                        <div className={'mfp-content'}>
                            <div className="mfp-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        this.setState({
                                            playPdf: false,
                                            playSrcPdf: ''
                                        });
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="line"></div>
                            </div>
                            <div className={'mfp-iframe-scaler'}>
                                <iframe
                                    className="mfp-iframe"
                                    src={playSrcPdf}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 mb10">
                                        <div className="breadcrumb_content style2 mb30-991">
                                            <h2 className="breadcrumb_title">Agent Documents</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8">
                                        <div className="candidate_revew_select style2 text-right mb30-991">
                                            <ul className="mb0">
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2">
                                                            <input
                                                                className="form-control mr-sm-2"
                                                                type="search"
                                                                placeholder="Filter by keyword"
                                                                aria-label="Search"
                                                                value={this.state.searchKey}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        searchKey: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                            <button
                                                                className="btn my-2 my-sm-0"
                                                                type="submit"
                                                                onClick={() => {
                                                                    this.search(this.state.searchKey);
                                                                }}
                                                            >
                                                                <span className="flaticon-magnifying-glass" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="document-title">
                                            Standard Template
                                        </div>
                                        <Collapse defaultActiveKey={['0']} onChange={onChange}>
                                            {(() => {
                                                return list?.filter((item, index)=> {
                                                    return item.category_id == 1;
                                                }).map((item, index) => (
                                                    <Panel header={item.title} key={index}>
                                                        <div>
                                                            <label>Tags:  </label>
                                                            {
                                                                (() => {
                                                                    return item.tags?.map((item4, index4) => (
                                                                        <a onClick={(e) => {
                                                                            this.setState({
                                                                                searchKey: item4,
                                                                            });
                                                                            this.search(item4);
                                                                        }} href="#!" className="break tag" key={index4}>{item4}</a>
                                                                    ))
                                                                })()
                                                            }
                                                        </div>
                                                        {
                                                            (() => {
                                                                return item.videos?.map((item2, index2) => (
                                                                    <div key={index2}>
                                                                        <label>Video {index2+1}: </label>
                                                                        <a onClick={(e) => {
                                                                        this.setState({
                                                                            playVideo: true,
                                                                            playSrc: item2.body
                                                                        });
                                                                    }} href="#!" className="break">{item2.title}</a></div>
                                                                ))
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                return item.files?.map((item3, index3) => (
                                                                    <div key={index3}>
                                                                        <label>File {index3+1}: </label>
                                                                        <a onClick={(e) => {
                                                                            if(item3.body.indexOf('.pdf') >= 0) {
                                                                                this.setState({
                                                                                    playPdf: true,
                                                                                    playSrcPdf: item3.body
                                                                                });
                                                                            }
                                                                            else {
                                                                                // window.open(item3.body)
                                                                                console.log(item3.body, item3.title)
                                                                                this.downloadFile(item3.body, item3.title)
                                                                            }
                                                                    }} href="#!" className="break">{item3.title}</a></div>
                                                                ))
                                                            })()
                                                        }
                                                    </Panel>
                                                ))
                                            })()}
                                        </Collapse>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="document-title">
                                            Training Material
                                        </div>
                                        <Collapse defaultActiveKey={['0']} onChange={onChange}>
                                            {(() => {
                                                return list?.filter((item, index)=> {
                                                    return item.category_id == 2;
                                                }).map((item, index) => (
                                                    <Panel header={item.title} key={index}>
                                                        <div>
                                                            <label>Tags:  </label>
                                                            {
                                                                (() => {
                                                                    return item.tags?.map((item4, index4) => (
                                                                        <a onClick={(e) => {
                                                                            this.setState({
                                                                                searchKey: item4,
                                                                            });
                                                                            this.search(item4);
                                                                        }} href="#!" className="break tag" key={index4}>{item4}</a>
                                                                    ))
                                                                })()
                                                            }
                                                        </div>
                                                        {
                                                            (() => {
                                                                return item.videos?.map((item2, index2) => (
                                                                    <div key={index2}>
                                                                        <label>Video {index2+1}: </label>
                                                                        <a onClick={(e) => {
                                                                        this.setState({
                                                                            playVideo: true,
                                                                            playSrc: item2.body
                                                                        });
                                                                    }} href="#!" className="break">{item2.title}</a></div>
                                                                ))
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                return item.files?.map((item3, index3) => (
                                                                    <div key={index3}>
                                                                        <label>File {index3+1}: </label>
                                                                        <a onClick={(e) => {
                                                                            if(item3.body.indexOf('.pdf') >= 0) {
                                                                                this.setState({
                                                                                    playPdf: true,
                                                                                    playSrcPdf: item3.body
                                                                                });
                                                                            }
                                                                            else {
                                                                                window.open(item3.body)
                                                                            }
                                                                    }} href="#!" className="break">{item3.title}</a></div>
                                                                ))
                                                            })()
                                                        }
                                                    </Panel>
                                                ))
                                            })()}
                                        </Collapse>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMAgentDoc;
