import * as React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './Site.less';
@withRouter
@connect(({ userInfo }) => {
    return { userInfo };
})
@withTranslation()
class Site extends React.Component {
    render(): React.ReactNode {
        const { t, i18n } = this.props;

        return <section className="footer_one">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-6 pr0 pl0">
                        <div className="footer_about_widget">
                            <h4>About Site</h4>
                            <p>
                                We are reimaging how you buy, sell and invest in real estate. It’s now easier to get everything done worry-free, with TopSky Home.
                            </p>
                        </div>
                    </div>
                    {/*
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="footer_qlink_widget">
                            <h4>Quick Links</h4>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="#">About Us</a>
                                </li>
                                <li>
                                    <a href="#">Terms & Conditions</a>
                                </li>
                                <li>
                                    <a href="#">User’s Guide</a>
                                </li>
                                <li>
                                    <a href="#">Support Center</a>
                                </li>
                                <li>
                                    <a href="#">Press Info</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    */}
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-6">
                        <div className="footer_contact_widget">
                            <h4>Contact Us</h4>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="mailto: info@topskyhome.com">info@topskyhome.com</a>
                                </li>
                                <li style={{color: '#8a99b3',}}>
                                    TopSky Home Inc<br/>
                                </li>
                                <li style={{color: '#8a99b3',}}>
                                    19900 MacArthur Blvd #1120,
                                    Irvine, California 92612
                                </li>
                                <li>
                                    <a href="tel:866-998-6742">866-998-6742</a>
                                </li>
                                {/*<li>*/}
                                    {/*<a href="#">+1 246-345-0695</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                    {/*
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="footer_social_widget">
                            <h4>Follow us</h4>
                            <ul className="mb30">
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-facebook" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-twitter" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-instagram" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-pinterest" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-dribbble" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa fa-google" />
                                    </a>
                                </li>
                            </ul>
                            <h4>Subscribe</h4>
                            <form className="footer_mailchimp_form">
                                <div className="form-row align-items-center">
                                    <div className="col-auto">
                                        <input
                                            type="email"
                                            className="form-control mb-2"
                                            id="inlineFormInput"
                                            placeholder="Your email"
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-2"
                                        >
                                            <i className="fa fa-angle-right" />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </section>;
    }
}

export default Site;
