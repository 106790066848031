import _ from 'lodash';

const values = [
    'single_family',
    'mobile',
    'multi_family',
    'condos',
    'condo_townhome_rowhome_coop',
    'townhomes',
    'condo_townhome',
    'duplex_triplex',
    'farm',
    'coop',
    'apartment',
    'land',
    'condop',
];

export default values;

export const types = values.map((item) => {
    return {
        name: formatType(item),
        value: item,
    };
});

export function formatType(type) {
    if (typeof type == 'string') {
        const types = type.split('_').map((t) => {
            const arr = t.split('');
            arr[0] = arr[0].toString().toUpperCase();
            return arr.join('');
        });
        return types.join(' ');
    }
}

export function formatCities(cities: Array<any>) {
    return cities.map((city) => ({
        name: city.city,
        value: city.state_id,
        state: city.state_name,
    }));
}

export function formatStates(cities: Array<any>) {
    return cities.map((city) => ({
        name: city.state_name,
        value: city.state_id,
        city: city.city,
    }));
}

export function extractAllStates(states) {
    return _.sortedUniqBy(states, 'state_id');
}

export function citiesByState(cities) {
    return _.groupBy(cities, 'state_id');
}

export const commSize = [
    {name: '<100', value:'<100'},
    {name: '100-250', value: '100-250'},
    {name: '250-500', value: '250-500'},
    {name: '500-1000', value: '500-1000'},
    {name: '>1000', value: '>1000'},
    {name: '>2000', value: '>2000'},
    {name: '>3000', value: '>3000'},
    {name: '>4000', value: '>4000'}
]
export const typePurchase = [
    {name: 'No Restriction', value:'No Restriction'},
    {name: 'Queue', value: 'Queue'},
    {name: 'Lottery', value: 'Lottery'},
    {name: 'Bid', value: 'Bid'},
    {name: 'Other', value: 'Other'}
]
export const typeDeposit = [
    {name: '<=3%', value:'<=3%'},
    {name: '<=5%', value: '<=5%'},
    {name: '<=10%', value: '<=10%'},
    {name: '<=20%', value: '<=20%'},
    {name: '>20%', value: '>20%'}
]
export const developmentPhase = [
    {name: 'Pre-sale / Coming soon', value:'Pre-sale / Coming soon'},
    {name: 'Grand Opening', value: 'Grand Opening'},
    {name: 'Early Phase (<25% sold)', value: 'Early Phase (<25% sold)'},
    {name: 'Mid Phase (25-75% sold)', value: 'Mid Phase (25-75% sold)'},
    {name: 'Late Phase (>75 sold)', value: 'ate Phase (>75 sold)'},
    {name: 'Closing out', value: 'Closing out'}
]
export const typicPriceRange = [
    {name: '<200', value:'<200'},
    {name: '200-300', value: '200-300'},
    {name: '250-350', value: '250-350'},
    {name: '300-400', value: '300-400'},
    {name: '350-450', value: '350-450'},
    {name: '400-500', value: '400-500'},
    {name: '450-550', value: '450-550'},
    {name: '500-600', value: '500-600'},
    {name: '>600', value: '>600'}
]
export const typicHomeSize = [
    {name: '<1000', value:'<1000'},
    {name: '1000-1500', value: '1000-1500'},
    {name: '1250-1750', value: '1250-1750'},
    {name: '1500-2000', value: '1500-2000'},
    {name: '1750-2250', value: '1750-2250'},
    {name: '2000-2500', value: '2000-2500'},
    {name: '2250-2750', value: '2250-2750'},
    {name: '2500-3000', value: '2500-3000'},
    {name: '>3000', value: '>3000'}
]
export const typicLotSize = [
    {name: '<1000', value:'<1000'},
    {name: '1000-1500', value: '1000-1500'},
    {name: '1250-1750', value: '1250-1750'},
    {name: '1500-2000', value: '1500-2000'},
    {name: '1750-2250', value: '1750-2250'},
    {name: '2000-2500', value: '2000-2500'},
    {name: '2250-2750', value: '2250-2750'},
    {name: '2500-3000', value: '2500-3000'},
    {name: '>3000', value: '>3000'}
]
export const rentalRestrictions = [
    {name: 'No Restrictions', value:'No Restrictions'},
    {name: '10%-20%', value: '10%-20%'},
    {name: '< 10% cap', value: '< 10% cap'},
    {name: 'No Rental', value: 'No Rental'}
]

export const crimeLevel = [
    {name: '1', value:'1'},
    {name: '2', value: '2'},
    {name: '3', value: '3'},
    {name: '4', value: '4'},
    {name: '5', value: '5'}
]

export const statusProperty = [{name: 'FOR SALE',value:'For Sale'}, {name: 'SOLD',value:'Sold'}, {name: 'PENDING',value:'Pending'}, {name: 'COMING SOON',value:'Coming Soon'}]

export const playbackType = [
    {name: 'URL', value:'1'},
    {name: 'MARKET SOURCE', value: '2'}
]

















