import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";
import "./index.less";
import { setUserInfo } from '../../store/userInfo/actions';

import {connect} from 'react-redux';
@connect(({userInfo}) => {
    return {userInfo};
})
class PageMWebinarCalendar extends React.Component<Props, State> {
    headerBar;
    PASS_CODE = 'GETRICH';
    code;
    state: State = {
        list: [],
        isUser: false,
        codeModal: {
            show: false,
            syncShow: false,
        }
    };
    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
        this.code = React.createRef();
        this.headerBar;
    }
    getWebniarEventList = async () => {
        Request({
            url: '/webinar',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET'
        })
        .then(async (res: any) => {
            const rs = res.data;
            if (res && rs && rs.data) {
                this.setState({
                    list: rs.data
                })
            }
        })
        .catch((err) => {
            console.log('error ==> ', err)
        });
    }
    formatEventTime = (event_time) => {
        const WeekArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let d = new Date(event_time)
        let week = WeekArr[d.getDay()]
        let month = d.toDateString().split(' ')[1]
        let date = d.getDate()
        let year = d.getFullYear()
        let hours = d.getHours()
        let hour = (hours < 2 ? '0' : '') + hours
        let minutes = d.getMinutes()
        let minute = (minutes < 2 ? '0' : '') + minutes
        let seconds = d.getSeconds()
        let second = (seconds < 2 ? '0' : '') + seconds
        return {
            dd: date,
            mm: month,
            yy: year,
            date: week + ', ' + month + ' ' + date + ', ' + year,
            time: hour + ':' + minute + ':' + second + ' Pacific Daylight Time'
        }
    }
    
    componentDidMount(): void {
        this.getWebniarEventList()
        const isUser = this.props.userInfo && (this.props.userInfo.role == 'MEMBER' || this.props.userInfo.role == 'ADMIN' || this.props.userInfo.role == 'AGENT') ? true : false;
        // console.log('n',this.props.userInfo, isUser)
        this.setState({
            isUser
        })
    }

    /* 确认用户是否登录，如未登录要弹窗 */
    private checkUser = (props) => {
        const user = props.userInfo;
        if (!(user && user.id)) {
            this.headerBar.setLoginShow();
            return false;
        } 
        // else if (user.role != 'MEMBER' && user.role != 'ADMIN') {
        //     // if(confirm('Do you have a member code？')) {
        //     //     console.log(11)
        //     // }
        //     this.setState({
        //         codeModal: {
        //             show: true,
        //             syncShow: true
        //         }
        //     });
        //     return false;
        //     // toast( 'This page is for TopSky Club members only, please join and visit again, thank you!');
        //     // message.warning({
        //     //     content:
        //     //         'This page is for TopSky Club members only, please join and visit again, thank you!',
        //     //     duration: 5,
        //     //     className: 'ant-message-warning',
        //     // });
        //     // setTimeout(function () {
        //     //     window.location = '/PageTopSkyClub';
        //     // }, 5000);
        // }
        return true;
    };
    
    handleHasCode() {
        const myCode = this.code.current.value;
        if (myCode) {
            console.log('Click has Code:' + myCode);
            if (myCode == this.PASS_CODE) {
                this.handleUpdateUser(this.props, myCode);
            } else {
                toast('Invalid code.');
            }
        } else {
            toast('Please input the code first.');
        }
    }
    handleNoCode(e) {
        console.log('Click no Code');
        window.location.href = '/PageTopSkyClub';
    }
    private handleUpdateUser = (props, code) => {
        const {userInfo, dispatch } = props;
        Request({
            url: '/user/' + userInfo.id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'PUT',
            data: {
                role: 'MEMBER',
                member_code: code,
            },
        })
            .then(async (res) => {
                if (res && res.data) {
                    const memberRole = res.data.data.role;
                    if (memberRole == 'MEMBER') {
                        toast('Your account status is updated, thank you for being a valueble member of TopSky Club!');
                        // message.success({
                        //     content:
                        //         'Your account status is updated, thank you for being a valueble member of TopSky Club!',
                        //     duration: 3,
                        //     className: 'success-notice-green',
                        // });
                        dispatch(setUserInfo(res.data.data))
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 1000);
                    }
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };
    render() {
        let { list=[], isUser, codeModal } = this.state;
        
        const routeToLanding = (item) => {
            // this.props.history.push({
            //     pathname: '/PageMWebinarLanding?id=' + item.id
            // });
            window.location.href = '/PageMWebinarLanding?id=' + item.id;
        };
        const renderList = () => {
            let now=new Date();
            let _first = false;
            let _pass = false;
            let i = 0;
            for(; i < list.length; i++) {
                let banner_time = new Date(list[i].event_time.replace(/-/g, '/') + ' PST'); 
                if(banner_time.getTime() < now.getTime()) {
                    break;
                }
            }
            let newList = list.slice(0, i).reverse().concat(list.slice(i));

            return newList.map((item, index) => {
                // return tampaVideos.map((item, index) => {
                // if (index < count)
                let clsName = 'webinar-box';
                if(index%2==1) {
                    clsName += ' even';
                }
                const date = this.formatEventTime(item.event_time.replace(/-/g, '/'));
                let banner_time = new Date(item.event_time.replace(/-/g, '/') + ' PST'); // 后台存储为UTC时间
                let difference_time =  banner_time.getTime() - now.getTime() + 3 * 60 * 60 * 1000;
                if(difference_time <= 0){
                    clsName += ' pass';
                }
                return (
                    <div className="col-lg-12 col-xl-12" data-id={index} key={index}>
                        {(() => {
                            if(difference_time > 0 && !_first) {
                                _first = true;
                                return (<div className="col-lg-12 col-xl-12 coming-title">
                                    COMING SOON
                                </div>)
                            }
                            else if(difference_time < 0 && !_pass) {
                                _pass = true;
                                return (<div className="col-lg-12 col-xl-12 coming-title">
                                    PAST
                                </div>)
                            }
                        })()}
                        <div className={clsName}>
                            <div className="webinar-box-inner">
                                <div className="inner-day" onClick={() => { routeToLanding(item); }}>
                                    <div className="day">{date.dd}</div>
                                    <div className="month">{date.mm}</div>
                                    <div className="year">{date.yy}</div>
                                </div>
                                <div className="inner-content">
                                    <div onClick={() => { routeToLanding(item); }}>
                                        <div className="s-title" title={item.title}>{item.title}</div>
                                        <div className="s-title2" title={item.sub_title}>{item.sub_title}</div>
                                        <div className="s-time">{item.speaker}</div>
                                        <div className="s-time">{date.time}<b> Duration</b> {item.duration}hour{item.duration > 1 ? 's': ''}</div>
                                    </div>
                                    <div className="s-time s-video s-991" style={{display: item.playback_url ? '' : 'none'}} onClick={(e) => {
                                        if(this.checkUser(this.props)) {
                                            window.open(item.playback_url + (item.video_id ? '&id=' + item.video_id + '&refer=webinar' : ''));
                                        }
                                        e.stopPropagation();
                                        e.cancelBubble = true;
                                        return false;
                                    }}><i className="flaticon-play" /> Click to Play back video.</div>
                                    <div className="s-992" style={{display: item.playback_url ? '' : 'none'}}>
                                        <button onClick={(e) => {
                                            if(this.checkUser(this.props)) {
                                                window.open(item.playback_url + (item.video_id ? '&id=' + item.video_id + '&refer=webinar' : ''));
                                            }
                                            e.stopPropagation();
                                            e.cancelBubble = true;
                                            return false;
                                        }}>Watch the Video</button>
                                    </div>
                                </div>
                                <div className="inner-image" onClick={() => { routeToLanding(item); }}>
                                    <img src={item.profile_image_url? item.profile_image_url:"https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png"}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        };
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e: any) => {
                        this.headerBar = e;
                    }}
                />
                <div className={
                        'sign_up_modal modal fade bd-example-modal-lg ' +
                        (codeModal.syncShow ? 'show' : '')
                    } tabIndex="-1" role="dialog" style={
                        codeModal.show ? {display: 'block',  paddingRight: '17px'}: {}
                    }
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body container pb20">
                                <div className="form_grid">
                                    <h4 className="mb5">Do you have a code?</h4>
                                    <p>
                                        <small>
                                            Code is provided in wechat group for existing TopSky club
                                            members
                                        </small>
                                    </p>
                                    <form onSubmit={() => this.handleHasCode()}>
                                        <div className="form1 form-container container">
                                            <div className="row ">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            id="code"
                                                            name="code"
                                                            className="form-control"
                                                            required={true}
                                                            type="text"
                                                            ref={this.code}
                                                            placeholder="Pass Code"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            className="btn btn-lg btn-thm"
                                                            onClick={() => this.handleHasCode()}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"></div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            className="btn dbxshad btn-lg btn-thm white"
                                                            onClick={(e) => this.handleNoCode(e)}
                                                        >
                                                            I Don't Have Code
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="list-title pt20 pb20">ALL WEBINAR</div>
                        <div className="row">
                            {renderList()}
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default PageMWebinarCalendar;
