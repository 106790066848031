import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { message, notification } from 'antd';
import { WithUtils } from 'components/Utils/Utils';
import SideBar from 'components/SideBar/SideBar';
import HeaderBar from 'components/HeaderBar/HeaderBar';
import Pager from 'components/Pager/Pager';
import Request from 'components/Utils/Request';
import { SubmitModal } from 'components/SubmitModal/SubmitModal';
import { ModalState } from 'components/SubmitModal/Interface';
import { Props, State } from './interface';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class Page extends React.Component<Props, State> {
    _mounted = false;
    state: State = {
        questions: [],
        allData: [],
        data: [],
        all: [],
        pager: 0,
        searchKey: '',
        modal: {
            show: false,
            syncShow: false,
        },
        subject: '',
        messageText: '',
        email: '',
        id: '',
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount(): void {
        this._mounted = false;
        this.setState = () => {};
    }

    componentDidMount(): void {
        this._mounted = true;
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
            this.getData().then();
        }
    }

    getData = async () => {
        Request({
            url: `/question/get_user_questions?subject=TOSHA_CLUB`,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            console.log('get users ===============> ', res.data);
            //let sortedData = _.orderBy(res.data, 'created_at', 'desc');
            const data = _.chunk(res.data, 10);
            this.setState({
                allData: res.data,
                data: res.data,
                all: data,
            });

        });
    };

    changePage = (pager) => {
        this.setState({
            pager,
        });
    };

    setModal = (modal: ModalState) => {
        this.setState({ modal });
    };

    setModalShow = (item) => {
        Request({
            url: `/question?user_id=${item.id}&subject=TOSHA_CLUB`,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            console.log('get users ===============> ', res.data);

            let sortedData = _.orderBy(res.data, 'id', 'asc');
            console.log("sorted Data", sortedData);
            this.setState({
                questions: sortedData,
            });
        });

        this.setModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setModal({
                show: true,
                syncShow: true,
            });
        }, 100);
        this.setState({
            id: item.id,
            email: item.email,
            messageText: '',
        });
    };

    onCloseModal = () => {
        this.setModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setModal({
                show: false,
                syncShow: false,
            });
        }, 300);
    };

    // sendMessage = () => {
    //     /*notification.success({
    //         message: 'Notification Title',
    //         style: {zIndex: 3000000},
    //         top: 200,
    //         duration: 30,
    //         description:
    //             'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    //         onClick: () => {
    //             console.log('Notification Clicked!');
    //         },
    //     });*/
    //     let { subject, email, messageText, id } = this.state;
    //     Request({
    //         url: `/user/contact`,
    //         method: 'POST',
    //         headers: {
    //             'x-api-key': process.env.X_API_KEY,
    //         },
    //         data: {
    //             id,
    //             email,
    //             subject,
    //             message: messageText,
    //         },
    //     }).then(async (res) => {
    //         const data = res.data;
    //         console.log('res ============> ', data);
    //         if (data.status === 200) {
    //             await this.getData();
    //             this.onCloseModal();
    //             if (this._mounted)
    //                 toast(data.message || 'success');
    //             // message.success({
    //             //     content: data.message || 'success',
    //             //     duration: 0.5,
    //             //     className: 'success-notice-green',
    //             // });
    //         }
    //     });
    // };

    render(): React.ReactNode {
        const { all, pager, modal, questions } = this.state;
        const resultData = all[pager];
        const that = this;
        function return_questions(){
            return questions?.map((item) => {
                return (
                    <div key={item.id}>
                        <div className="row" style={{fontWeight: 'bold'}}>
                            {item.id + "." + item.question}
                        </div>
                        { item.answer !== null ?
                            <div className="row" style={{fontWeight: 'bold'}}>
                                {item.answer.answer}
                            </div>    : <div></div>
                        }
                        <br/>
                    </div>
                );
            });
        }
        function renderList() {
            return resultData?.map((item) => {
                return (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.email}</td>
                        <td>{item.created_at}</td>
                        <td>
                            <div className="form-container" style={{ width: 70 }}>
                                <button
                                    type="button"
                                    className="btn btn2 btn-block w-100"
                                    onClick={() => that.setModalShow(item)}
                                >
                                    Action
                                </button>
                            </div>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mb40">
                                            <div className="property_table">
                                                <div className="table-responsive mt0">
                                                    <table className="table table-sm table-striped">
                                                        <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Id</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Submit Date/Create At</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>{renderList()}</tbody>
                                                    </table>
                                                </div>
                                                <div className="mbp_pagination">
                                                    <Pager
                                                        pager={pager}
                                                        all={all}
                                                        changePage={(e) => {
                                                            this.changePage(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
                <div>
                    <SubmitModal modal={modal} onClose={this.onCloseModal}>
                        <div className="login_form">
                            {/*<div className="heading">
                            <h4>Title</h4>
                        </div>*/}
                            <div className="form-group">
                                {return_questions()}
                            </div>

                        </div>
                    </SubmitModal>
                </div>

            </div>
        );
    }
}

export default Page;
