import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import GoogleMap from 'shared/components/GoogleMap/GoogleMap';
import { connect } from 'react-redux';
import Select from '../../components/Select/Select';
import Request from '../../components/Utils/Request';
import AutoComplete from 'react-google-autocomplete';
import { Collapse } from "antd";
import { types } from 'shared/components/Utils/TypesData';
import * as Utils from '../../components/Utils/Utils';
import {toast} from "react-toastify";
import {statusProperty} from '../../components/Utils/TypesData';

const { Panel } = Collapse;

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageAddNewProperty extends React.Component<Props, State> {
    state: State = {
        form: {
            title: '',
            floorplan_id: 0,
            address: '',
            real_address: '',
            city: '',
            description: '',
            status: 'For Sale',
            latitude: 0,
            longitude: 0,
            parking: 0,
            type: '',
            user_entered: this.props.userInfo.id,
            zip: 0,
            state: '',
            size: 0,
            bathrooms: 1,
            bedrooms: 1,
            stories: 1,
            year_built: 2000,
            sold_price: 0,
            listing_price: 0,
            county: '',
            tags: '',
            market_research_file: 0,
            kitchen_material: '',
            floor_material: '',
            heat: '0',
            air_condition: '0',
            garage: '0',
            hide_full_address: 'N',
            place_id: '',
            incentive: '',

            tax_rate: '',
            estimated_rent: '',
            hoa: '',
            cdd: '',
            images_from: '',
            exclusive: 'N',

            flooring_tile: '', flooring_hardwood: '', flooring_vinyl: '', flooring_carpet: '',
            kitchen_granite: '', kitchen_marble: '', kitchen_quartz: '', kitchen_laminate: '',
            appliances_refrigerator:  '', appliances_electric_stove: '', appliances_gas_stove: '', appliances_microwave: '',
            other_washer: '', other_dryer: '',
            heat_furnace: '', heat_heat_pump: '',
            ac_ac: '', ac_central_ac: '',
            exterior_brick: '', exterior_stone: '', exterior_stucco: '', exterior_vinyl: '', exterior_wood: '', exterior_composite: '', exterior_fiber_cement: '',
            high_ceiling: ''
        },
        images: [],
        floorplans: []
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };

    queryImages = () => {
        if (this.state.form.id)
            Request({
                url: '/image',
                method: 'GET',
                headers: {
                    'x-api-key': process.env.X_API_KEY,
                },
                params: {
                    parent_id: this.state.form.id,
                    image_type: 'PROPERTY',
                },
            }).then((res) => {
                this.setState({
                    images: res.data.data
                })
            });
    };

    upload = async (file) => {
        const formData = new FormData();
        formData.append('parent_id', this.state.form.id);
        formData.append('image_type', 'PROPERTY');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data
        });
        this.queryImages();
    };

    getFloorplans = async () => {
        Request({
            url: '/floorplan',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 5);
            this.setState({
                floorplans: res.data.map(item => {
                    return {
                        ...item,
                        value: item.id
                    }
                })
            });
        });
    };
    submit = () => {
        const form = this.state.form;
        Request({
            url: '/property',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then((res) => {
            if (res.data.data && res.data.data.id) {
                // 200的状态值就进行跳转
                toast.success(res.message || 'Add the Property successfully!')
                this.props.history.push({
                    pathname: '/PageUpdateProperty',
                    state: {
                        ...this.state.form,
                        id: res.data.data.id
                    },
                });
            }

        });
    };

    componentWillUnmount(): void {
        this.setState = () => { };
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.queryImages()
            this.getFloorplans().then()
        }
        Request({
            url: '/api/schools?zipcode=94501&page=0',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            this.setState({
                schoolOptions: res.data.map((item) => {
                    return {
                        ...item,
                        label: item.name,
                        value: item['nces-id']
                    }
                })
            })
        });
    }
    
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form, images, floorplans } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Add New Property</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Create Listing</h4>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Title
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.title}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        title: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Floorplan</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            title="Select a floorplan"
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        floorplan_id: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={floorplans}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" id="propertyTitle" checked={form.exclusive == 'Y'} onChange={(event) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    exclusive: event.target.checked ? 'Y' : 'N',
                                                                })
                                                            }} />
                                                            <label className="form-check-label" htmlFor="propertyTitle">Is it TopSky exclusive property? Check to indicate yes!</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Status</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            title="Select a status"
                                                            defaultValue={form.status}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        status: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={statusProperty}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_textarea">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Description
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertyDescription"
                                                            rows="7"
                                                            placeHolder="It must be at least 5 characters in length."
                                                            value={form.description}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        description: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label><span className="text-danger">*</span> Type</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        type: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={types}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Price
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.listing_price}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        listing_price: +e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Incentive
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.incentive}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        incentive: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Tax Rate (%)
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.tax_rate}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        tax_rate: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Estimated Rent ($/m)
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.estimated_rent}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        estimated_rent: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            HOA ($/m)
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.hoa}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        hoa: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            CDD ($/m)
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.cdd}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        cdd: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Images from
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.images_from}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        images_from: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label htmlFor="propertyStreamUrl" style={{
                                                        width: '100%'
                                                    }}>
                                                        <h4 className="mb30" style={{
                                                            display: 'inline-block'
                                                        }}>Location</h4>
                                                        <span className="my_profile_setting_input form-group form-check" style={{
                                                            float: 'right'
                                                        }}>
                                                            <input type="checkbox" checked={form.hide_full_address == 'Y'} onChange={(event) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    hide_full_address: event.target.checked ? 'Y' : 'N',
                                                                })
                                                            }} className="form-check-input" name="propertyStreamUrl" id="propertyAnnounceHome" />
                                                            <label className="form-check-label" htmlFor="propertyAnnounceHome">Do not display detailed address</label>
                                                        </span>
                                                    </label>
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyAddress">
                                                            <span className="text-danger">*</span> Address
                                                        </label>
                                                        <AutoComplete
                                                            className="form-control"
                                                            id="propertyAddress"
                                                            types={[]}
                                                            onPlaceSelected={(e) => {
                                                                if (!e.geometry) return;
                                                                const location =
                                                                    e.geometry.location;
                                                                const addr = {};
                                                                e.address_components.forEach(
                                                                    (item) => {
                                                                        addr[
                                                                            item.types[0]
                                                                        ] = item;
                                                                    }
                                                                );
                                                                this.setForm({
                                                                    ...this.state.form,
                                                                    latitude: location.lat(),
                                                                    longitude: location.lng(),
                                                                    address:
                                                                        addr.street_number ?.long_name + ' ' + addr.route ?.long_name,
                                                                    state:
                                                                        addr ?.administrative_area_level_1 ?.short_name ||
                                                                            addr.political ?.short_name,
                                                                    zip:
                                                                        addr.postal_code ?.short_name,
                                                                    city: addr.locality ?.long_name,
                                                                });
                                                                
                                                                if(addr.postal_code?.short_name) {
                                                                    const placeId = Utils.getGooglePlaceId(addr.postal_code?.short_name)
                                                                    this.setForm({
                                                                        ...this.state.form,
                                                                        place_id: placeId
                                                                    })
                                                                }
                                                                else {
                                                                    // 提示要选一个有zipCode的位置
                                                                }
                                                            }}
                                                        />
                                                        {/*<label htmlFor="propertyAddress">*/}
                                                        {/*Address*/}
                                                        {/*</label>*/}
                                                        {/*<input*/}
                                                        {/*type="text"*/}
                                                        {/*className="form-control"*/}
                                                        {/*id="propertyAddress"*/}
                                                        {/*value={form.address}*/}
                                                        {/*onChange={() => {*/}
                                                        {/**/}
                                                        {/*}}*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyState">
                                                            County / State
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyState"
                                                            value={form.state}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyCity">City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyCity"
                                                            value={form.city}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="zipCode">Zip</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="zipCode"
                                                            value={form.zip}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <div className="h400 bdrs8" id="map-canvas">
                                                            <GoogleMap
                                                                children={[]}
                                                                centerMark={true}
                                                                center={{
                                                                    lat: form.latitude,
                                                                    lng: form.longitude,
                                                                }}
                                                                placeId={form.place_id}
                                                                zoom={12}
                                                                onEvent={(e) => {
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLat">
                                                            Latitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLat"
                                                            value={form.latitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLong">
                                                            Longitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLong"
                                                            value={form.longitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="realAddress">
                                                            Real address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="realAddress"
                                                            value={form.real_address}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        real_address: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Detailed Information</h4>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyASize">
                                                            Area Size
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyASize"
                                                            value={form.size}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    size: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="bedRooms">Bedrooms</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="bedRooms"
                                                            value={form.bedrooms}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    bedrooms: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="bathRooms">Bathrooms</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="bathRooms"
                                                            value={form.bathrooms}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    bathrooms: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="stories">Stories</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="stories"
                                                            value={form.stories}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    stories: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="year_built">Year Built</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="year_built"
                                                            value={form.year_built}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    year_built: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="sold_price">Sold Price</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="sold_price"
                                                            value={form.sold_price}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    sold_price: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="county">County</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="county"
                                                            value={form.county}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    county: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="tags">Tags</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="tags"
                                                            value={form.tags}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    tags: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="market_research_file">Market Research File</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="market_research_file"
                                                            value={form.market_research_file}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    market_research_file: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="garage">Garages</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="garage"
                                                            value={form.garage}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    garage: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="parking">Parking</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="parking"
                                                            value={form.parking}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    parking: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                                
                                                <div className="mb30">
                                                    <Collapse defaultActiveKey={['0']} ghost>
                                                        <Panel header="More" key="1">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Flooring:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyTitle" checked={form.flooring_tile == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    flooring_tile: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyTitle">Title</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyHardwood" checked={form.flooring_hardwood == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    flooring_hardwood: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyHardwood">Hardwood</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyFloorVinyl" checked={form.flooring_vinyl == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    flooring_vinyl: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyFloorVinyl">Vinyl</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyCarpet" checked={form.flooring_carpet == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    flooring_carpet: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyCarpet">Carpet</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Kitchen Countertop material:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyGranite" checked={form.kitchen_granite == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    kitchen_granite: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyGranite">Granite</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyMarble" checked={form.kitchen_marble == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    kitchen_marble: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyMarble">Marble</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyQuartz" checked={form.kitchen_quartz == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    kitchen_quartz: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyQuartz">Quartz</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyLaminate" checked={form.kitchen_laminate == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    kitchen_laminate: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyLaminate">Laminate</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Appliances:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyRefrigerator" checked={form.appliances_refrigerator == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    appliances_refrigerator: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyRefrigerator">Refrigerator</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyElectricStove" checked={form.appliances_electric_stove == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    appliances_electric_stove: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyElectricStove">Electric Stove</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyGasStove" checked={form.appliances_gas_stove == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    appliances_gas_stove: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyGasStove">Gas Stove</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyMicrowave" checked={form.appliances_microwave == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    appliances_microwave: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyMicrowave">Microwave</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Other Appliances:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyWasher" checked={form.other_washer == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    other_washer: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyWasher">Washer</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyDryer" checked={form.other_dryer == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    other_dryer: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyDryer">Dryer</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Heating:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyFurnace" checked={form.heat_furnace == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    heat_furnace: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyFurnace">Furnace</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyHeatPump" checked={form.heat_heat_pump == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    heat_heat_pump: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyHeatPump">Heat Pump</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Cooling:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyAC" checked={form.ac_ac == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    ac_ac: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyAC">Air Condition</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyCentralAC" checked={form.ac_central_ac == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    ac_central_ac: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyCentralAC">Central AC</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">Exterior Material:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyBrick" checked={form.exterior_brick == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_brick: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyBrick">Brick</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyStone" checked={form.exterior_stone == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_stone: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyStone">Stone</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyStucco" checked={form.exterior_stucco == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_stucco: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyStucco">Stucco</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyVinyl" checked={form.exterior_vinyl == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_vinyl: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyVinyl">Vinyl</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyWood" checked={form.exterior_wood == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_wood: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyWood">Wood</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyComposite" checked={form.exterior_composite == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_composite: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyComposite">Composite</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyFiberCement" checked={form.exterior_fiber_cement == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    exterior_fiber_cement: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyFiberCement">Fiber Cement</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-12 col-xl-12">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <div className="form-check form-check-inline">
                                                                            <label htmlFor="floor_material">High Ceiling:</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox" id="propertyHighCelling" checked={form.high_ceiling == '1'} onChange={(event) => {
                                                                                this.setForm({
                                                                                    ...form,
                                                                                    high_ceiling: event.target.checked ? '1' : '0',
                                                                                })
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor="propertyHighCelling">High Ceiling</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                                {/*
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="yearBuild">
                                                            Year Built
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="yearBuild"
                                                        />
                                                    </div>
                                                </div>
                                                */}
                                                {/*
                                                <div className="col-xl-12">
                                                    <h4>Amenities</h4>
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                    <ul className="ui_kit_checkbox selectable-list">
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck1"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck1"
                                                                >
                                                                    Air Conditioning
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck2"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck2"
                                                                >
                                                                    Lawn
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck3"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck3"
                                                                >
                                                                    Swimming Pool
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck4"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck4"
                                                                >
                                                                    Barbeque
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                    <ul className="ui_kit_checkbox selectable-list">
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck6"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck6"
                                                                >
                                                                    TV Cable
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck7"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck7"
                                                                >
                                                                    Dryer
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck9"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck9"
                                                                >
                                                                    Washer
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                    <ul className="ui_kit_checkbox selectable-list">
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck11"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck11"
                                                                >
                                                                    Refrigerator
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck13"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck13"
                                                                >
                                                                    Laundry
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customCheck14"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck14"
                                                                >
                                                                    Sauna
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                if (typeof form.id !== 'undefined') {
                                                    return (
                                                        <div className="my_dashboard_review mt30">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="mb30">Property media</h4>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <ul className="mb0">
                                                                        {
                                                                            images ?.map(item => {
                                                                                return (
                                                                                    <li className="list-inline-item" key={item.id}>
                                                                                        <div className="portfolio_item">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={item.src}
                                                                                                alt="404"
                                                                                            />
                                                                                            <div
                                                                                                className="edu_stats_list"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Delete"
                                                                                                data-original-title="Delete"
                                                                                            >
                                                                                                <a onClick={() => {

                                                                                                }}>
                                                                                                    <span className="flaticon-garbage" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="portfolio_upload" onClick={() => {
                                                                        document.getElementById('myfile').click()
                                                                    }}>
                                                                        <input type="file" id="myfile" multiple="multiple" onChange={async (e) => {
                                                                            for (const file of e.currentTarget.files) {
                                                                                await this.upload(file)
                                                                            }
                                                                        }} />
                                                                        <div className="icon">
                                                                            <span className="flaticon-download" />
                                                                        </div>
                                                                        <p>Drag and drop images here</p>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-xl-6">*/}
                                                                {/*<div className="resume_uploader mb30">*/}
                                                                {/*<h4>Attachments</h4>*/}
                                                                {/*<form className="form-inline">*/}
                                                                {/*<input className="upload-path" />*/}
                                                                {/*<label className="upload">*/}
                                                                {/*<input type="file" />*/}
                                                                {/*Select Attachment*/}
                                                                {/*</label>*/}
                                                                {/*</form>*/}
                                                                {/*</div>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-xl-12">*/}
                                                                {/*<div className="my_profile_setting_input">*/}
                                                                {/*<button className="btn btn2 float-right" onClick={() => {*/}
                                                                {/*this.submit()*/}
                                                                {/*}}>*/}
                                                                {/*Submit*/}
                                                                {/*</button>*/}
                                                                {/*</div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2023 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageAddNewProperty;
