import { produce } from 'immer';
import { ActionTypes } from './actions';
import { Action, UserInfo } from './types';

export const initialState: UserInfo = Object.freeze({});

export default (state: UserInfo = initialState, action: Action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.SETUSERINFO: {
                draft = action.payload;
                return;
            }
        }
    });
