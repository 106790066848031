import React from 'react';
import Request from 'components/Utils/FormRequest';
import { message } from 'antd';
import { Props, ProfileState } from './interface';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Profile extends React.Component {
    state: ProfileState = {
        first_name: '',
        last_name: '',
        phone_no: '',
        state: '',
        wechat_id: ''
    };
    constructor(props) {
        super(props);
        this.props
        console.log("Prop has " + this.props.showHideQuestionnaire)

    }


    handleSubmit(e) {
        e.preventDefault();
        console.log('form ==> ', this.state, process.env);
        Request({
            url: '/user/'+this.props.user_id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'PUT',
            data: this.state
        })
            .then(async (res) => {
                if (res && res.data.status == "200") {
                    this.state = res.data.data
                    toast("Your profile is updated, please continue to finish TopSky Club Questionnaire!");
                    // message.success({
                    //     content: "Your profile is updated, please continue to finish TopSky Club Questionnaire!",
                    //     duration: 3,
                    //     className: 'success-notice-green',
                    // });
                    this.props.handler("showHideQuestionnaire")
                    this.props.handler("showHideProfileInfo")
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    render() {
        return (

            <div className="form_grid mt-3" >
                <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    method="post"
                >
                    <div className="form1 form-container container">
                        <h4 className="mb5">Your Profile Info </h4>
                        <p><small>Please fill in the infomation below:</small></p>
                        <div className="row">
                            <div className="form-group col-6">
                                <label
                                    htmlFor="first_name"
                                    className="label-text"
                                >
                                    Last Name
                                                                </label>
                                <input
                                    id="first_name"
                                    name="first_name"
                                    className="form-control"
                                    required={true}
                                    type="text"
                                    value={this.state.first_name}
                                    onChange={(e) =>
                                        this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="form-group col-6">
                                <label
                                    htmlFor="last_name"
                                    className="label-text"
                                >
                                    Last Name
                                                                </label>
                                <input
                                    id="last_name"
                                    name="last_name"
                                    className="form-control"
                                    required={true}
                                    type="text"
                                    value={this.state.last_name}
                                    onChange={(e) =>
                                        this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="form-group col-6">
                                <label
                                    htmlFor="phone_no"
                                    className="label-text"
                                >
                                    Phone No
                                                                </label>
                                <input
                                    id="phone_no"
                                    name="phone_no"
                                    className="form-control"
                                    required={true}
                                    type="text"
                                    value={this.state.phone_no}
                                    onChange={(e) =>
                                        this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="form-group col-6">
                                <label
                                    htmlFor="state"
                                    className="label-text"
                                >
                                    State
                                                                </label>
                                <input
                                    id="state"
                                    name="state"
                                    className="form-control"
                                    required={true}
                                    type="text"
                                    value={this.state.state}
                                    onChange={(e) =>
                                        this.handleChange(e)
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label
                                    htmlFor="wechat_id"
                                    className="label-text"
                                >
                                    Wechat Id
                                                                </label>
                                <input
                                    id="wechat_id"
                                    name="wechat_id"
                                    className="form-control"
                                    required={true}
                                    type="text"
                                    value={this.state.wechat_id}
                                    onChange={(e) =>
                                        this.handleChange(e)
                                    }
                                />
                            </div>

                            <div className="form-group col-12">
                                <button
                                    type="submit"
                                    className="btn btn2 btn-block w-100"
                                >
                                    Next
                                                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        );
    }
}

export default Profile;
