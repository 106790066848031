import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import { WithUtils } from 'components/Utils/Utils';
import SideBar from 'components/SideBar/SideBar';
import HeaderBar from 'components/HeaderBar/HeaderBar';
import Pager from 'components/Pager/Pager';
import Request from 'components/Utils/Request';
import { SubmitModal } from 'components/SubmitModal/SubmitModal';
import { ModalState } from 'components/SubmitModal/Interface';
import { Props, State } from './interface';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class Page extends React.Component<Props, State> {
    state: State = {
        allData: [],
        data: [],
        all: [],
        pager: 0,
        searchKey: '',
        modal: {
            show: false,
            syncShow: false,
        },
        messageText: '',
        email: '',
        id: '',
        userInfo: this.props.userInfo,
        
        new_home_marketing: false,
        research_report: false,
        tosha_events: false,
        isTokenSetting: false,
        user_id: '',
    };

    constructor(props) {
        super(props);
        setTimeout(() => {
            this.setState({
                userInfo: props.userInfo,
                new_home_marketing: props.userInfo.new_home_marketing !='false',
                research_report: props.userInfo.research_report !='false',
                tosha_events: props.userInfo.tosha_events !='false',
            });
            // console.log(this.state)
        }, 30);
        // this.onSearch = this.onSearch.bind(this);
        // this.onChangeSearch = this.onChangeSearch.bind(this);
    }

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
        }
    }

    updateInfo = async () => {
        const { userInfo, new_home_marketing, research_report, tosha_events, isTokenSetting, user_id  } = this.state;
        let formData = {
            id: isTokenSetting ? user_id : userInfo.id,
            new_home_marketing:  String(new_home_marketing),
            research_report: String(research_report),
            tosha_events: String(tosha_events)
        }
        Request({
            url: '/user/subscribe',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then(({ data: res }) => {
            // console.log('rrr=', res)
            if(res.status === 200) {
                toast(res.message || 'Subscription updated successfully!')
            }
        });
        // window.location.reload();
    };
    getStatusFromSider(params) {
        if(params) {
            this.setState(params)
        }
    }
    render(): React.ReactNode {
        const { all, pager, modal, messageText, email, new_home_marketing, research_report, tosha_events, isTokenSetting } = this.state;
        const that = this;

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar sendStatusToSubscribe={this.getStatusFromSider.bind(this)}/>
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className={'col-lg-3 col-xl-2 dn-992 pl0' + (isTokenSetting ? 'd-none' : '')} />
                            <div className={'col-sm-12 col-lg-8 col-xl-10 maxw100flex-992' + (isTokenSetting ? 'col-lg-12 col-xl-12' : '')}>
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12 mb10">
                                        <div className="breadcrumb_content">
                                            <h2 className="breadcrumb_title">Subscribe / Unsubscribe Emails</h2>
                                        </div>
                                    </div>                                    
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" checked={new_home_marketing} onChange={(event) => {
                                                                    this.setState({
                                                                        new_home_marketing: event.target.checked,
                                                                    });
                                                                }} className="form-check-input" name="new_home_marketing" id="new_home_marketing" />
                                                            <label className="form-check-label" htmlFor="new_home_marketing">Market Research/Data Analysis Report</label>
                                                        </div>
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" checked={research_report} onChange={(event) => {
                                                                    this.setState({
                                                                        research_report: event.target.checked,
                                                                    });
                                                                }} className="form-check-input" name="research_report" id="research_report" />
                                                            <label className="form-check-label" htmlFor="research_report">New Home Marketing Email</label>
                                                        </div>
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" checked={tosha_events} onChange={(event) => {
                                                                    this.setState({
                                                                        tosha_events: event.target.checked,
                                                                    });
                                                                }} className="form-check-input" name="tosha_events" id="tosha_events" />
                                                            <label className="form-check-label" htmlFor="tosha_events">TopSky Events</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 text-right">
                                                    <div className="my_profile_setting_input">
                                                        <button
                                                            className="btn btn2"
                                                            onClick={() => {
                                                                this.updateInfo().then();
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
