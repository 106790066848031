import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";
import "./index.less";

class PageCommunityIndex extends React.Component<Props, State> {
    state: State = {
        list: []
    };

    getCommunityList = async () => {
        Request({
            url: '/community/themes?status=APPROVED',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET'
        })
        .then(async (res: any) => {
            const rs = res.data;
            if (res && rs && rs.data) {
                this.setState({
                    list: rs.data
                })
            }
        })
        .catch((err) => {
            console.log('error ==> ', err)
        });
    }
    formatEventTime = (event_time) => {
        const WeekArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let d = new Date(event_time)
        let week = WeekArr[d.getDay()]
        let month = d.toDateString().split(' ')[1]
        let date = d.getDate()
        let year = d.getFullYear()
        let hours = d.getHours()
        let hour = (hours < 2 ? '0' : '') + hours
        let minutes = d.getMinutes()
        let minute = (minutes < 2 ? '0' : '') + minutes
        let seconds = d.getSeconds()
        let second = (seconds < 2 ? '0' : '') + seconds
        return {
            dd: date,
            mm: month,
            yy: year,
            date: week + ', ' + month + ' ' + date + ', ' + year,
            time: hour + ':' + minute + ':' + second + ' Pacific Daylight Time'
        }
    }
    componentDidMount(): void {
        this.getCommunityList()
    }
    

    render() {
        let { list=[] } = this.state;
        
        const routeToLanding = (item) => {
            // this.props.history.push({
            //     pathname: '/PageMWebinarLanding?id=' + item.id
            // });
            window.location.href = '/PageCommunitySingle?id=' + item.id;
        };
        const renderList = () => {
            return list.map((item, index) => {
                // return tampaVideos.map((item, index) => {
                // if (index < count)
                let clsName = 'comm-box';
                if(index%2==1) {
                    clsName += ' even';
                }
                let imgs = item.images && item.images[0]? item.images[0]:"https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png";
                return (
                    <div className="col-lg-4 col-xl-4" data-id={index} key={index}>
                        <div className={clsName} style={{backgroundImage: 'url(' + imgs + ')'}}>
                            <div className="comm-box-inner" onClick={() => {
                                routeToLanding(item);
                            }}>
                                <div className="inner-content">
                                    <div className="s-title" title={item.name}>{item.name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        };
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="community-title pt20 pb20">ALL COMMUNITIES</div>
                        <div className="row">
                            {renderList()}
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default PageCommunityIndex;
