import { combineReducers } from 'redux';
import app from './app/reducer';
import userInfo from './userInfo/reducer';
import compare from './compare/reducer';

const createRootReducer = () =>
    combineReducers({
        app,
        userInfo,
        compare,
    });

export default createRootReducer;
