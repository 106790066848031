import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import { WithUtils } from 'components/Utils/Utils';
import SideBar from 'components/SideBar/SideBar';
import HeaderBar from 'components/HeaderBar/HeaderBar';
import Pager from 'components/Pager/Pager';
import Request from 'components/Utils/Request';
import { SubmitModal } from 'components/SubmitModal/SubmitModal';
import { ModalState } from 'components/SubmitModal/Interface';
import { Props, State } from './interface';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class Page extends React.Component<Props, State> {
    state: State = {
        allData: [],
        data: [],
        all: [],
        pager: 0,
        searchKey: '',
        modal: {
            show: false,
            syncShow: false,
        },
        messageText: '',
        email: '',
        id: '',
    };

    constructor(props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
            this.getData().then();
        }
    }

    getData = async () => {
        Request({
            url: `/contact`,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            let sortedData = _.orderBy(res.data, 'created_at', 'desc');
            const data = _.chunk(sortedData, 5);
            this.setState({
                allData: sortedData,
                data: sortedData,
                all: data,
            });
        });
    };

    changePage = (pager) => {
        this.setState({
            pager,
        });
    };

    onChangeSearch = (e) => {
        e.persist();
        let { value } = e.target;
        this.setState({
            searchKey: value,
        });
        if (!Boolean(value)) {
            let { allData } = this.state;
            this.setState({
                data: allData,
                all: _.chunk(allData, 5),
            });
        }
    };

    onSearch = () => {
        let { allData, searchKey } = this.state;
        const newList = allData.filter(
            (o) =>
                o.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.phone?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.subject?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.email?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.id?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.replied_at?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.updated_at?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.created_at?.toLowerCase().includes(searchKey.toLowerCase()) ||
                o.message?.toLowerCase().includes(searchKey.toLowerCase())
        );
        this.setState({
            data: newList,
            all: _.chunk(newList, 5),
        });
    };

    setModal = (modal: ModalState) => {
        this.setState({ modal });
    };

    setModalShow = (item) => {
        this.setModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setModal({
                show: true,
                syncShow: true,
            });
        }, 100);
        this.setState({
            id: item.id,
            email: item.email,
            messageText: '',
        });
    };

    onCloseModal = () => {
        this.setModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setModal({
                show: false,
                syncShow: false,
            });
        }, 300);
    };

    sendMessage = () => {
        let { messageText, id } = this.state;
        Request({
            url: `/contact/reply`,
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                id,
                form_type: 'CONTACT',
                message: messageText,
            },
        }).then(async (res) => {
            const data = res.data;
            if (data.status === 200) {
                await this.getData();
                this.onCloseModal();
                toast(data.message ? data.message : 'success');
                // message.success({
                //     content: data.message ? data.message : 'success',
                //     duration: 0.5,
                //     className: 'success-notice-green',
                // });
            }
        });
    };

    render(): React.ReactNode {
        const { all, pager, modal, messageText, email } = this.state;
        const resultData = all[pager];
        const that = this;

        function renderList() {
            return resultData?.map((item) => {
                return (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.wechat_id}</td>
                        <td>{item.intention}</td>
                        <td>{item.subject}</td>
                        <td className="lg-col">{item.message}</td>
                        <td>
                            {item.replied_at ? moment(item.replied_at).format('YYYY-MM-DD') : ''}
                        </td>
                        <td>
                            {item.created_at ? moment(item.created_at).format('YYYY-MM-DD') : ''}
                        </td>
                        {/*<td>{item.state}</td>*/}
                        <td>
                            <div className="form-container">
                                <button
                                    type="button"
                                    className="btn btn2 btn-block w-100"
                                    onClick={() => that.setModalShow(item)}
                                >
                                    Send
                                </button>
                            </div>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 mb10">
                                        <div className="breadcrumb_content style2 mb30-991">
                                            <h2 className="breadcrumb_title">Contact</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8">
                                        <div className="candidate_revew_select style2 text-right mb30-991">
                                            <ul className="mb0">
                                                <li className="list-inline-item">
                                                    <div className="candidate_revew_search_box course fn-520">
                                                        <div className="form-inline my-2">
                                                            <input
                                                                className="form-control mr-sm-2"
                                                                type="search"
                                                                placeholder="Search Keyword"
                                                                aria-label="Search"
                                                                value={this.state.searchKey}
                                                                onChange={this.onChangeSearch}
                                                            />
                                                            <button
                                                                className="btn my-2 my-sm-0"
                                                                type="submit"
                                                                onClick={this.onSearch}
                                                            >
                                                                <span className="flaticon-magnifying-glass" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mb40">
                                            <div className="property_table">
                                                <div className="table-responsive mt0">
                                                    <table className="table table-sm table-striped">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Id</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Phone</th>
                                                                <th scope="col">Wechat Id</th>
                                                                <th scope="col">Intention</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col" >Message</th>
                                                                <th scope="col">Replied At</th>
                                                                <th scope="col">Create At</th>
                                                                <th scope="col">Send</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderList()}</tbody>
                                                    </table>
                                                </div>
                                                <div className="mbp_pagination">
                                                    <Pager
                                                        pager={pager}
                                                        all={all}
                                                        changePage={(e) => {
                                                            this.changePage(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>

                <SubmitModal modal={modal} onClose={this.onCloseModal}>
                    <div className="login_form">
                        {/*<div className="heading">
                            <h4>Title</h4>
                        </div>*/}
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder={email}
                                required={true}
                                disabled={true}
                            />
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="flaticon-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group form-group">
                            <textarea
                                className="form-control required message"
                                id="message"
                                required={true}
                                placeholder="Your Message"
                                rows={8}
                                value={messageText}
                                onChange={(event) => {
                                    this.setState({
                                        messageText: event.target.value,
                                    });
                                }}
                            />
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="flaticon-reply" />
                                </div>
                            </div>
                        </div>
                        <button
                            className="btn btn-log btn-block btn-thm"
                            onClick={this.sendMessage}
                        >
                            Send
                        </button>
                    </div>
                </SubmitModal>
            </div>
        );
    }
}

export default Page;
