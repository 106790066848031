import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import { connect } from 'react-redux';
import Request from '../../components/Utils/Request';
import Select from '../../components/Select/Select';
import { setUserInfo } from '../../store/userInfo/actions';
import { message } from 'antd';

@connect(({ userInfo }) => {
    return { userInfo };
})
class Page extends React.Component<Props, State> {
    state: State = {
        userInfo: this.props.userInfo,
        passForm: {},
    };

    constructor(props) {
        super(props);
        setTimeout(() => {
            this.setState({
                userInfo: props.userInfo,
            });
        }, 30);
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        this.setState({
            userInfo: nextProps.userInfo,
        });
    }

    componentDidMount(): void {
        console.log('user info ==> ', this.props.userInfo);
        if (typeof document !== 'undefined') {
        }
    }

    changePassword = async () => {
        const {
            userInfo,
            passForm: { password, new_password, new_password_conf },
        } = this.state;
        const { data } = await Request({
            url: '/user/change_password',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
                'token': window.localStorage.getItem('token'),
            },
            data: {
                id: userInfo.id,
                password: password,
                new_password: new_password,
                new_password_conf: new_password_conf,
            },
        });
    };

    upload = async (file) => {
        const { userInfo } = this.props;
        const formData = new FormData();
        formData.append('parent_id', userInfo.id);
        formData.append('image_type', 'PROFILE');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data;
        });
        this.getUser(userInfo.id);
    };

    getUser = (id) => {
        const { dispatch, userInfo } = this.props;
        Request({
            url: '/user/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            const data = res.data;
            if (data.status === 200) {
                dispatch(setUserInfo(data.data));
                this.setState({
                    userInfo: data.data,
                });
                // window.location.reload();
            }
        });
    };

    updateUserInfo = async () => {
        const { dispatch } = this.props;
        const { userInfo } = this.state;
        const { data } = await Request({
            url: '/user/' + userInfo.id,
            method: 'PUT',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: userInfo,
        });
        dispatch(setUserInfo(data.data));
        // window.location.reload();
    };

    render(): React.ReactNode {
        const { userInfo } = this.state;
        return (
            <div className="wrapper">
                <HeaderBar animate={false} />

                <SideBar />
                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">My Profile</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-xl-2">
                                                    <h4>Profile Information</h4>
                                                </div>
                                                <div className="col-xl-10">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="wrap-custom-file">
                                                                <input
                                                                    type="file"
                                                                    name="image1"
                                                                    id="image1"
                                                                    accept=".gif, .jpg, .png"
                                                                    onChange={async (e) => {
                                                                        for (const file of e
                                                                            .currentTarget.files) {
                                                                            await this.upload(file);
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    htmlFor="image1"
                                                                    style={{
                                                                        backgroundImage: `url(${this.props.userInfo?.profile_image_src})`,
                                                                        backgroundSize: 'cover',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <i className="flaticon-download" />{' '}
                                                                        Upload Photo{' '}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <p>*minimum 260px x 260px</p>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleEmail">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="formGroupExampleEmail"
                                                                    value={userInfo?.email}
                                                                    placeholder="creativelayers@gmail.com"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                email:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput4">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.name
                                                                            ? userInfo.name
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput4"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                name:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput8">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.phone_no
                                                                            ? userInfo.phone_no
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput8"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                phone_no:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput12">
                                                                    Occupation
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.occupation
                                                                            ? userInfo.occupation
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput12"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                occupation:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput13">
                                                                    Address
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.address
                                                                            ? userInfo.address
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput13"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                address:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput12">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.city
                                                                            ? userInfo.city
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput12"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                city:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput12">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.state
                                                                            ? userInfo.state
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput12"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                state:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                                {/*<div className="list-inline-item">*/}
                                                                {/*<Select/>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleInput12">
                                                                    Zip
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={
                                                                        userInfo?.zip
                                                                            ? userInfo.zip
                                                                            : ''
                                                                    }
                                                                    id="formGroupExampleInput12"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                zip: e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <div className="my_profile_setting_textarea">
                                                                <label htmlFor="exampleFormControlTextarea1">
                                                                    About me
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    rows={7}
                                                                    value={
                                                                        userInfo?.self_description
                                                                            ? userInfo.self_description
                                                                            : ''
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            userInfo: {
                                                                                ...(userInfo
                                                                                    ? userInfo
                                                                                    : {}),
                                                                                self_description:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 text-right">
                                                            <div className="my_profile_setting_input">
                                                                {/*<button className="btn btn1">*/}
                                                                {/*View Public Profile*/}
                                                                {/*</button>*/}
                                                                <button
                                                                    className="btn btn2"
                                                                    onClick={() => {
                                                                        this.updateUserInfo().then();
                                                                    }}
                                                                >
                                                                    Update Profile
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-xl-2">
                                                    <h4>Change password</h4>
                                                </div>
                                                <div className="col-xl-10">
                                                    <div className="row">
                                                        <div className="col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleOldPass">
                                                                    Current Password
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Current Password"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            passForm: {
                                                                                ...this.state
                                                                                    .passForm,
                                                                                password:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleNewPass">
                                                                    New Password
                                                                </label>
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            passForm: {
                                                                                ...this.state
                                                                                    .passForm,
                                                                                new_password:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-6">
                                                            <div className="my_profile_setting_input form-group">
                                                                <label htmlFor="formGroupExampleConfPass">
                                                                    Confirm New Password
                                                                </label>
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            passForm: {
                                                                                ...this.state
                                                                                    .passForm,
                                                                                new_password_conf:
                                                                                    e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <div className="my_profile_setting_input float-right fn-520">
                                                                <button
                                                                    className="btn btn2"
                                                                    onClick={() => {
                                                                        this.changePassword().then();
                                                                    }}
                                                                >
                                                                    Update Password
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt10">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
