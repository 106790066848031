import * as React from 'react';
import { Props, State } from './Interface';
import { initPopClass } from './Popup';
import './popup.less';

class GoogleMap extends React.Component<Props, State> {
    static defaultProps = {
        centerMark: false,
        center: { lat: 38.913611, lng: -77.013222 },
        placeId: 'ChIJk3rbege9w4kRlgIvJmOuQvU',
        zoom: 11,
        markers: [],
        onEvent: () => {},
    };
    state: State = {
        show: false,
        markers: [],
    };
    private map;
    private featureLayer;

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        // console.log(this.props, this.map);
        if (this.map) {
            // if(this.props.center && this.props.center.lat != 0 && this.props.center.lng != 0) {
            //     this.map.setCenter(this.props.center);
            // }
            let center_format = {
                lat: this.props.center && this.props.center.lat || 38.913611,
                lng: this.props.center && this.props.center.lng || -77.013222
            }
            // console.log('center', center, center_format )
            this.map.setCenter(center_format);
            this.map.setZoom(this.props.zoom);
            const map = this.map;
            const Popup = initPopClass(google);

            this.props.markers.map(({position, label, id}) => {
                const div = document.createElement('div');
                div.innerText = `$${label}`;
                div.addEventListener('click', ()=>{                    
                    this.props.onEvent({
                        event: 'clickMarker',
                        res: {
                            selectId: id
                        },
                    });
                })
                const popup = new Popup(
                    new google.maps.LatLng(position.lat, position.lng),
                    div
                );
                popup.setMap(map);
            });
        }

    }

    init = () => {
        try {
            const { center, placeId, zoom, markers } = this.props;
            // if(!center || center.lat == 0 && center.lng == 0) {
            //     // center = { lat: 38.913611, lng: -77.013222 }; 
            // }
            let center_format = {
                lat: center && center.lat || 38.913611,
                lng: center && center.lng || -77.013222
            }
            // console.log('center', center, center_format )
            this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
                center_format,
                zoom,
                mapId: "711c2561b991099c"
            });
            this.map.addListener('zoom_changed', () => {
                this.props.onEvent({
                    event: 'zoom',
                    res: {
                        zoom: this.map.getZoom()
                    },
                });
            });
            this.map.addListener('dragend', () => {
                const latLng = this.map.getCenter();
                const [lat, lng] = [latLng.lat(), latLng.lng()];
                // console.log(e, lat, lng);
                const geo = new google.maps.Geocoder();
                geo.geocode(
                    {
                        latLng: new google.maps.LatLng(lat, lng),
                    },
                    (e) => {
                        this.props.onEvent({
                            event: 'click',
                            res: {
                                lat,
                                lng,
                                e,
                            },
                        });
                    }
                );
            });
            //@ts-ignore
			this.featureLayer = this.map.getFeatureLayer("POSTAL_CODE");
			
			// Define a style with purple fill and border.
			//@ts-ignore
			const featureStyleOptions = {
				strokeColor: "#810FCB",
				strokeOpacity: 1.0,
				strokeWeight: 3.0,
				fillColor: "#810FCB",
				fillOpacity: 0.5,
			};

			// Apply the style to a single boundary.
			//@ts-ignore
			this.featureLayer.style = (options) => {
				if (options.feature.placeId == placeId) {
					// Hana, HI
					return featureStyleOptions;
				}
            };
            
            this.setState({
                markers: markers,
            });
        } catch(e) {
            console.log(e)
        }
    };

    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            let interval = setInterval(() => {
                if (window.google && google.maps && google.maps.Map) {
                    this.init();
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    render(): React.ReactNode {
        const { markers } = this.state;
        const {centerMark, center} = this.props;
        const map = this.map;
        if (centerMark && typeof google !== 'undefined') {
            new google.maps.Marker({
                position: center,
                map,
            });
        }
        if (typeof google !== 'undefined') {
            const Popup = initPopClass(google);

            markers.map(({position, label}) => {
                const div = document.createElement('div');
                div.innerText = `$${label}`;
                const popup = new Popup(
                    new google.maps.LatLng(position.lat, position.lng),
                    div
                );
                popup.setMap(map);
            });

        }

        return (
            <div
                id={'google-map'}
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        );
    }
}

export default GoogleMap;
