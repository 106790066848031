import _ from 'lodash';

const values = [
    'I\'m interested in...',
    'Buying',
    'Selling',
    'Leasing',
    'Property Management',
    'Others'
];

export default values;

export const intentions = values.map((item) => {
    return {
        name: formatType(item),
        value: item,
    };
});

export function formatType(type) {
    if (typeof type == 'string') {
        const types = type.split('_').map((t) => {
            const arr = t.split('');
            arr[0] = arr[0].toString().toUpperCase();
            return arr.join('');
        });
        return types.join(' ');
    }
}