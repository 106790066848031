import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import { connect } from 'react-redux';
import { CompareDel, CompareInit } from '../../store/compare/actions';
import Agent from 'shared/components/Agent/Agent';
import {formatType} from "../../components/Utils/TypesData";
import Site from "../../components/Site/Site";
import Footer from "../../components/Footer/Footer";

@connect(({ compare }) => {
    return { compare };
})
class Page extends React.Component<Props, State> {
    state: State = {
        headerBar: false,
    };
    headerBar;
    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.props.dispatch(CompareInit());
        }
    }

    render(): React.ReactNode {
        const { properties } = this.props.compare;
        console.log(properties);
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {

                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                let dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (let i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {

                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        return (
            <div className="wrapper">
                <HeaderBar
                    animate={true}
                    onRef={(e) => {
                        this.headerBar = e;
                        if (!this.state.headerBar)
                            this.setState({
                                headerBar: true,
                            });
                    }}
                />

                <section className="inner_page_breadcrumb" style={{ marginTop: '-94px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="breadcrumb_content">
                                    <h4 className="breadcrumb_title">Compare</h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Compare
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="our-pricing bgc-fa">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>Compare Listings</h2>
                                    <p>We provide full service at every step</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="membership_container">
                                    <ul className="mc_parent_list">
                                        <li className="list-inline-item">
                                            <ul className="mc_child_list one">
                                                <li>
                                                    <div className="membership_header dn" />
                                                </li>
                                                <li>City</li>
                                                <li>Beds</li>
                                                <li>Baths</li>
                                                <li>Garage</li>
                                                {/*<li>Laundry Room</li>*/}
                                                <li>Status</li>
                                            </ul>
                                        </li>
                                        {properties.map((item, index) => {
                                            return (
                                                <li className="list-inline-item" key={item.id}>
                                                    <ul className="mc_child_list two text-center">
                                                        <li>
                                                            <div className="membership_header">
                                                                <div
                                                                    className="thumb"
                                                                    style={{
                                                                        margin: '0 auto',
                                                                    }}
                                                                >
                                                                    <a
                                                                        onClick={() => {
                                                                            this.props.dispatch(CompareDel(item))
                                                                        }}
                                                                    >
                                                                        <span className="flaticon-close" />
                                                                    </a>
                                                                    <img
                                                                        className="img-fluid w100"
                                                                        src={item.images&&item.images.length ? item.images[0] : ''}
                                                                        alt=""
                                                                    />
                                                                    <div className="price">
                                                                        $
                                                                        {toThousands(
                                                                            item.listing_price
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="details">
                                                                    <h4>{item.title}</h4>
                                                                    <p>
                                                                        {item.type
                                                                            ? formatType(item.type)
                                                                            : 'unknown'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                {item.city ? item.city : 'unknown'}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                {item.bedrooms
                                                                    ? item.bedrooms
                                                                    : 'unknown'}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                {item.bathrooms
                                                                    ? item.bathrooms
                                                                    : 'unknown'}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                {item.parking
                                                                    ? item.parking
                                                                    : 'unknown'}
                                                            </a>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*<a href="#">Yes</a>*/}
                                                        {/*</li>*/}
                                                        <li>
                                                            <a className="btn pricing_btn">
                                                                {item.status
                                                                    ? item.status
                                                                    : 'unknown'}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Agent headerBar={this.headerBar} />

                <Site />

                <Footer/>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
