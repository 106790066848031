import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import ComponentCalculator from 'shared/components/ComponentCalculator/ComponentCalculator';
import Request from '../../components/Utils/Request';
import logo from '../../assets/home/header-logo.png';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import { CompareAdd, CompareDel, CompareInit } from '../../store/compare/actions';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import {withRouter} from "react-router";
import {formatType} from "../../components/Utils/TypesData";
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import './index.less';
import { Tabs, Slider, Radio, Collapse, Drawer, Space, Button, Skeleton } from 'antd';
import { setJSAPI } from 'components/Utils/Utils';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const { Panel } = Collapse;

@connect(({ compare, userInfo }) => {
    return { compare, userInfo };
})
@withRouter
class Page extends React.Component<Props, State> {
    state: State = {
        details: {
            floorplan: {
                community: {}
            }
        },
        community: {
            // "elem_schools": [
            //     {
            //         "name": "All Hope Christian Academy",
            //         "rating": null,
            //         "rating_description": "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process."
            //     }
            // ],
            // "mid_schools": [
            //     {
            //         "name": "Jere L. Stambaugh Middle School",
            //         "rating": "2",
            //         "rating_description": "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process."
            //     }
            // ],
            // "hig_schools": [
            //     {
            //         "name": "Polk Pre-Collegiate Academy",
            //         "rating": "8",
            //         "rating_description": "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process."
            //     },
            //     {
            //         "name": "Auburndale Senior High School",
            //         "rating": "2",
            //         "rating_description": "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process."
            //     }
            // ]
        },
        owner: {},
        properties: [],
        gallery: null,
        purchasePrice: 0,
        sliderPrice: 0,
        downpayment: 25,
        estimatedRent: 0,
        sliderEstimatedRent: 0,
        calcYear: '1',

        openDrawer: false,
        showAll: false,
        // purchasePrice: 0,
        propertyTaxRate: 25,
        mortgageRes: 0,
        // downpayment: 25,
        downpaymentRes: 0,
        termYear: 30,
        interest: 7.5,
        mortgagePaymentRes: 0,
        closingCost: 0,
        cashInvestedRes: 0,
        rentMonthly: 0,
        rentMonthlyRes: 0,
        vacancy: 0,
        vacancyRes: 0,
        insurance: 0,
        insuranceRes: 0,
        hoa: 0,
        hoaRes: 0,
        pmFee: 6,
        pmFeeRes: 0,
        utility: 0,
        utilityRes: 0,
        repair: 0,
        repairRes: 0,
        housingAppreciation: 3,
        housingAppreciationRes: 0,
        rentIncresePercent: 3,

        rHousingPrice: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rMortgageBalance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rRentalIncome: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rRentalIncomeMonthly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rPropertyTax: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rVacancy: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rInsurance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rHOA: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rPMFee: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rUtility: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rRepair: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rNetOpIncome: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rCapRate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rInterestExpense: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rNetIncome: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rPrincipalPayment: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rCashFlow: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rCashOnCash: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rNetIncomeMonthly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rCashFlowMonthly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rGrainFromAppreciation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rTotalGain: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rTotalReturn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

        dataLoading: true
    };
    
    headerBar;

    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
    }

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        if (typeof document !== 'undefined') {
            const search = nextProps.location.search.replace('?', '');
            const params = search.split('&')[0].split('=')[1];
            this.getDetails(params).then();
            this.search();
        }
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            const search = this.props.location.search.replace('?', '');
            const params = search.split('&')[0].split('=')[1];
            this.getDetails(params).then();
            this.search();
        }
        if (typeof window !== 'undefined') {
            this.props.dispatch(CompareInit());
        }
    }
    globalFormatFixed(value) {
        let formatValue = Math.abs(Number(value)).toFixed(2);
        return Number(formatValue);
    };
    calc() {
        const { sliderPrice, propertyTaxRate, downpayment, termYear, interest, mortgagePaymentRes, closingCost, cashInvestedRes, rentMonthly, vacancy, insurance, hoa, pmFee, utility, repair, housingAppreciation, rentIncresePercent } = this.state;
        let mortgagePaymentR = Math.ceil(-this.pmt(interest / 1200, termYear * 12, sliderPrice * (100 - downpayment) / 100, 0, 0));
        let cashInvestedR = sliderPrice * downpayment / 100 + Number(closingCost);
        this.setState({
            mortgagePaymentRes: mortgagePaymentR,
            cashInvestedRes: cashInvestedR
        })

        let key1 = 1 + housingAppreciation / 100;
        // 等另外一个参数设置 rPrincipalPayment
        let key2 = 1 - downpayment / 100;

        let key3 = 1 + rentIncresePercent / 100;
        let rHousingPrice_arr = [sliderPrice];//, purchasePrice*key1, purchasePrice*key1*key1, purchasePrice*key1*key1*key1, purchasePrice*key1*key1*key1*key1, purchasePrice*key1*key1*key1*key1*key1];
        let rent = rentMonthly * 12;
        let rRentalIncome_arr = [rent]; //, rent*key3, rent*key3*key3, rent*key3*key3*key3, rent*key3*key3*key3*key3, 0];
        let rRentalIncomeMonthly_arr = [rentMonthly];
        for (let i = 1; i < 21; i++) {
            rHousingPrice_arr.push(rHousingPrice_arr[i - 1] * key1);
            rRentalIncome_arr.push(rRentalIncome_arr[i - 1] * key3);
            rRentalIncomeMonthly_arr.push(rRentalIncomeMonthly_arr[i - 1] * key3);
        }

        let rPropertyTax_arr = rHousingPrice_arr.map(item => { return item * propertyTaxRate / 100; });
        let rVacancy_arr = rRentalIncome_arr.map(item => { return item * vacancy / 100; });
        let rInsurance_arr = rRentalIncome_arr.map(item => { return item * insurance / 100; });
        let rHOA_arr = rRentalIncome_arr.map(item => { return item * hoa / 100; });
        let rPMFee_arr = rRentalIncome_arr.map(item => { return item * pmFee / 100; });
        let rUtility_arr = rRentalIncome_arr.map(item => { return item * utility / 100; });
        let rRepair_arr = rRentalIncome_arr.map(item => { return item * repair / 100; });
        let rTotal_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];// Operating Expense
        let rNetOpIncome_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rCapRate_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let i = 0; i < 21; i++) {
            rTotal_arr[i] = rPropertyTax_arr[i] + rVacancy_arr[i] + rInsurance_arr[i] + rHOA_arr[i] + rPMFee_arr[i] + rUtility_arr[i] + rRepair_arr[i];
            rNetOpIncome_arr[i] = rRentalIncome_arr[i] - rTotal_arr[i];
            rCapRate_arr[i] = sliderPrice != 0 ? rNetOpIncome_arr[i] / sliderPrice * 100 : 0;
        }
        /**
         * 手工计算的部分，找一下规律
         */
        let rMortgageBalance_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // 需放在后面计算，TODO
        let rInterestExpense_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rNetIncome_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rPrincipalPayment_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rCashFlow_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rCashOnCash_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rNetIncomeMonthly_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rCashFlowMonthly_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rGrainFromAppreciation_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rTotalGain_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let rTotalReturn_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        rMortgageBalance_arr[0] = rHousingPrice_arr[0] * key2;
        for (let i = 0; i < 21; i++) {
            if (i > 0) {
                rMortgageBalance_arr[i] = rMortgageBalance_arr[i - 1] - rPrincipalPayment_arr[i - 1];
            }
            rInterestExpense_arr[i] = rMortgageBalance_arr[i] * interest / 100;
            rPrincipalPayment_arr[i] = mortgagePaymentR * 12 - rInterestExpense_arr[i];

            rNetIncome_arr[i] = rNetOpIncome_arr[i] - rInterestExpense_arr[i];
            rCashFlow_arr[i] = rNetIncome_arr[i] - rPrincipalPayment_arr[i];
            rCashOnCash_arr[i] = cashInvestedR != 0 ? rCashFlow_arr[i] / cashInvestedR * 100 : 0;
            rNetIncomeMonthly_arr[i] = rNetIncome_arr[i] / 12;
            rCashFlowMonthly_arr[i] = rCashFlow_arr[i] / 12;
        }
        for (let i = 0; i < 20; i++) {
            rGrainFromAppreciation_arr[i] = rHousingPrice_arr[i + 1] - rHousingPrice_arr[i];
            rTotalGain_arr[i] = rGrainFromAppreciation_arr[i] + rNetIncome_arr[i];
            rTotalReturn_arr[i] = cashInvestedR != 0 ? rTotalGain_arr[i] / cashInvestedR * 100 : 0;
        }

        // rInterestExpense_arr[0] = rMortgageBalance_arr[0] * interest / 100;
        // rNetIncome_arr[0] = rNetOpIncome_arr[0] - rInterestExpense_arr[0];
        // rPrincipalPayment_arr[0] = rInterestExpense_arr[0] * 12 - mortgagePaymentRes;

        this.setState({
            rHousingPrice: rHousingPrice_arr,
            rMortgageBalance: rMortgageBalance_arr,

            rRentalIncome: rRentalIncome_arr,
            rRentalIncomeMonthly: rRentalIncomeMonthly_arr,//[rentMonthly, rentMonthly*key3, rentMonthly*key3*key3, rentMonthly*key3*key3*key3, rentMonthly*key3*key3*key3*key3, 0],
            rPropertyTax: rPropertyTax_arr,
            rVacancy: rVacancy_arr,
            rInsurance: rInsurance_arr,
            rHOA: rHOA_arr,
            rPMFee: rPMFee_arr,
            rUtility: rUtility_arr,
            rRepair: rRepair_arr,
            rTotal: rTotal_arr,
            rNetOpIncome: rNetOpIncome_arr,
            rCapRate: rCapRate_arr,
            rInterestExpense: rInterestExpense_arr,
            rNetIncome: rNetIncome_arr,
            rPrincipalPayment: rPrincipalPayment_arr,
            rCashFlow: rCashFlow_arr,
            rCashOnCash: rCashOnCash_arr,
            rNetIncomeMonthly: rNetIncomeMonthly_arr,
            rCashFlowMonthly: rCashFlowMonthly_arr,
            rGrainFromAppreciation: rGrainFromAppreciation_arr,
            rTotalGain: rTotalGain_arr,
            rTotalReturn: rTotalReturn_arr
        });
    }
    pmt(rate_per_period, number_of_payments, present_value, future_value, type) {
        if (rate_per_period != 0.0) {
            // Interest rate exists
            var q = Math.pow(1 + rate_per_period, number_of_payments);
            return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

        } else if (number_of_payments != 0.0) {
            // No interest rate, but number of payments exists
            return -(future_value + present_value) / number_of_payments;
        }

        return 0;
    };
    pmt3(i, n, p) {
        return i * p * Math.pow((1 + i), n) / (1 - Math.pow((1 + i), n));
    }
    pmt1(ir, np, pv, fv, type) {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        var pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv) / np;

        pvif = Math.pow(1 + ir, np);
        pmt = - ir * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + ir);

        return pmt;
    }
    setValue(e): void {
        let self = this;
        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [name]: value
        });
        switch (name) {
            case 'insurance':
                this.setState({
                    insuranceRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'insuranceRes':
                this.setState({
                    insurance: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;

            case 'hoa':
                this.setState({
                    hoaRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'hoaRes':
                this.setState({
                    hoa: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;

            case 'pmFee':
                this.setState({
                    pmFeeRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'pmFeeRes':
                this.setState({
                    pmFee: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;

            case 'utility':
                this.setState({
                    utilityRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'utilityRes':
                this.setState({
                    utility: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;

            case 'repair':
                this.setState({
                    repairRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'repairRes':
                this.setState({
                    repair: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
        }

        setTimeout(function () {
            self.calc();
        }, 10)
    };

    addCompare = (data) => {
        this.props.dispatch(CompareAdd(data));
    };
    search = () => {
        const data = window.localStorage.getItem('properties');
        if (data) {
            const cache = JSON.parse(data);
            if (new Date().getTime() - cache.time < 1000 * 60 * 5) {
                this.setState({
                    properties: cache?.data,
                });
                return;
            }
        }
        Request({
            // url: '/property?is_featured=Y&approval_status=APPROVED',
            // url: '/property?approval_status=APPROVED&limit=5',
            url: '/property?limit=5',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            const data = res.data;
            window.localStorage.setItem('properties', JSON.stringify({
                time: new Date().getTime(),
                data: data?.properties
            }));
            this.setState({
                properties: data.properties,
            });
        });
    };

    setViewer = () => {
        this.setState({
            dataLoading: false
        })
        setTimeout(() => {
            this.gallery = new Viewer(document.getElementById('popimages'));
        }, 1000)
    };
    setJS = (details) => {
        /* 20231230 allon wx share */
        setJSAPI({
            title: details.title || 'TopSky Home',
            desc: details.description || 'Helping you find your property!',
            link: window.location.href,
            imgUrl: 'https://topskyhome.s3.us-west-2.amazonaws.com/property/128/property.png#/' || details.images && details.images.length && details.images[0] || 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/33/webinar_1701829442_387f658b458a230ae504.jpeg'
        });
    }
    showViewer() {
        this.gallery.show();
    }
    getDetails = async (id) => {
        const data = window.localStorage.getItem(id);
        if (data) {
            const cache = JSON.parse(data);
            if (new Date().getTime() - cache.time < 1000 * 60 * 5) {
                this.setState(cache?.data);
                this.setJS(cache?.data.details);
                this.setViewer();
                const cache_details = cache?.data.details;
                document.querySelector('meta[property="og:title"]').setAttribute('content', cache_details.title);
                cache_details.description && document.querySelector('meta[property="og:description"]').setAttribute('content', cache_details.description);
                cache_details.images && cache_details.images.length && document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://topskyhome.s3.us-west-2.amazonaws.com/property/128/property.png#/' || cache_details.images[0]);
                setTimeout(()=>{this.calc();}, 100);
                return;
            }
        }
        const res = await Request({
            url: '/property/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        });
        const details = res?.data?.data;        
        document.querySelector('meta[property="og:title"]').setAttribute('content', details.title);
        details.description && document.querySelector('meta[property="og:description"]').setAttribute('content', details.description);
        details.images && details.images.length && document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://topskyhome.s3.us-west-2.amazonaws.com/property/128/property.png#/' || details.images[0]);
        // console.log('details=>', details)
        
        this.setJS(details);
        if (details) {
            const {
                data: { data: owner },
            } = await Request({
                url: '/user/' + details?.user_entered,
                method: 'GET',
                headers: {
                    'x-api-key': process.env.X_API_KEY,
                },
            });
            
            // if(details.floorplan && JSON.stringify(details.floorplan) !== '{}') {
            //     const {
            //         data: {data: floorpan},
            //     } = await Request({
            //         url: '/community/' + details.floorplan.community_id,
            //         method: 'GET',
            //         headers: {
            //             'x-api-key': process.env.X_API_KEY,
            //         },
            //     })
            // }
            window.localStorage.setItem(
                id,
                JSON.stringify({
                    time: new Date().getTime(),
                    data: { details, owner, purchasePrice: details.listing_price, sliderPrice: details.listing_price, estimatedRent: details.estimated_rent, sliderEstimatedRent: details.estimated_rent },
                })
            );
            this.setState({ details, owner, purchasePrice: details.listing_price, sliderPrice: details.listing_price, estimatedRent: details.estimated_rent, sliderEstimatedRent: details.estimated_rent });
            
            setTimeout(()=>{this.calc();}, 100);
        }
        this.setViewer();
    };

    
    onChange = (newValue: number) => {
        this.setState({sliderPrice: newValue});
        this.calc();
    };
    onDownpaymentChange = (newValue: number) => {
        this.setState({downpayment: newValue});
    };
    onSliderEstimatedRentChange = (newValue: number) => {
        this.setState({sliderEstimatedRent: newValue});
    };
    
    onClose = () => {
        this.setState({
            openDrawer: false
        })
    }

    render(): React.ReactNode {
        const { compare } = this.props;
        const { details, owner, properties, purchasePrice, sliderPrice, downpayment, estimatedRent, sliderEstimatedRent, calcYear, openDrawer, showAll, propertyTaxRate, termYear, interest, mortgagePaymentRes, closingCost, cashInvestedRes, rentMonthly, vacancy, insurance, insuranceRes, hoa, hoaRes, pmFee, pmFeeRes, utility, utilityRes, repair, repairRes, housingAppreciation, rentIncresePercent, rHousingPrice, rMortgageBalance, rRentalIncome, rRentalIncomeMonthly, rPropertyTax, rVacancy, rInsurance, rHOA, rPMFee, rUtility, rRepair, rTotal, rNetOpIncome, rCapRate, rInterestExpense, rNetIncome, rPrincipalPayment, rCashFlow, rCashOnCash, rNetIncomeMonthly, rCashFlowMonthly, rGrainFromAppreciation, rTotalGain, rTotalReturn, dataLoading } = this.state;
        const { floorplan } = details;
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {

                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                let dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (let i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {

                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        const formatFixed = value => {
            let formatValue = Math.abs(Number(value)).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
            if (value < 0) {
                formatValue = "(" + formatValue.replace('-', '') + ")";
            }
            return formatValue;
        }
        const formatFixedRate = value => {
            let formatValue = Number(value).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
            return formatValue;
        }

        const marks = {};
        const rentMarks = {};
        marks[purchasePrice] = '';
        rentMarks[estimatedRent] = '';
        const purchaseMin = Math.round(purchasePrice*0.95/100) * 100;
        const purchaseMax = Math.round(purchasePrice*1.2/100) * 100;
        const rentMin = Math.round(estimatedRent*0.75/10) * 10;
        const rentMax = Math.round(estimatedRent*1.25/10) * 10;
        
        const calcYearIndex = parseInt(calcYear) - 1;
        // const rechartsData = [];
        // for(let i = 0; i < 20; i++) {
        //     rechartsData.push({
        //             name: (i + 1) + (i > 0 ? 'Yrs' : ' Yr'),
        //             cashFlow: Math.abs(Number(Number(rCashFlowMonthly[i]).toFixed(2))),
        //             totalReturn: Math.abs(Number(Number(rTotalReturn[i]).toFixed(2))),
        //     })
        // }
        
        const isShowFlooring = details.flooring_tile == '1' || details.flooring_hardwood == '1' || details.flooring_vinyl == '1' || details.flooring_carpet == '1';

        const isShowKitchen = details.kitchen_granite == '1' || details.kitchen_marble == '1' || details.kitchen_quartz == '1' || details.kitchen_laminate == '1';

        const isShowAppliances = details.appliances_refrigerator == '1' || details.appliances_electric_stove == '1' || details.appliances_gas_stove == '1' || details.appliances_microwave == '1';

        const isShowAppliancesOther = details.other_washer == '1' || details.other_dryer == '1';
        
        const isShowHeating = details.heat_furnace == '1' || details.heat_heat_pump == '1';
        
        const isShowCooling = details.heat_heat_pump == '1' || details.ac_central_ac == '1';
        
        const isShowExteriorMaterial = details.exterior_brick == '1' || details.exterior_stone == '1' || details.exterior_stucco == '1' || details.exterior_vinyl == '1' || details.exterior_wood == '1' || details.exterior_composite == '1' || details.exterior_fiber_cement == '1';
        
        const isShowHighCeiling = details.high_ceiling == '1';

        return (
            <div
                className="wrapper page-listing-single"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} onRef={(e) => {
                    this.headerBar = e;
                }}/>
                <Drawer
                    title="Edit Assumptions"
                    placement="right"
                    width={600}
                    onClose={this.onClose}
                    open={openDrawer}
                    visible={openDrawer}
                    zIndex={3001}
                    extra={
                        <Space>
                            <Button onClick={this.onClose}>Cancel</Button>
                            <Button type="primary" onClick={this.onClose}>
                            OK
                            </Button>
                        </Space>
                    }
                >
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Base" key="1">
                            <table className="table table-bordered calc-table">
                                <tbody>
                                    <tr>
                                        <td>Purchase Price</td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="purchasePrice" value={sliderPrice} onChange={(e) => { this.setValue(e) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Property Tax Rate</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="propertyTaxRate" value={propertyTaxRate} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                        <Panel header="Mortgage" key="2">
                            <table className="table table-bordered calc-table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Mortgage</th>
                                        <td></td>
                                        <th>${formatFixed(purchasePrice - purchasePrice * downpayment / 100)}</th>
                                    </tr>
                                    <tr>
                                        <td>Downpayment</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="downpayment" value={downpayment} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>${formatFixed(purchasePrice * downpayment / 100)}</td>
                                    </tr>
                                    <tr>
                                        <td>Term(Year)</td>
                                        <td>
                                            <input type="text" className="form-control" name="termYear" value={termYear} onChange={(e) => { this.setValue(e) }} />
                                        </td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td>Interest(%)</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="interest" value={interest} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Mortgage Payment</td>
                                        <td></td>
                                        <td>
                                            ${formatFixed(mortgagePaymentRes)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Closing Cost</td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="closingCost" value={closingCost} onChange={(e) => { this.setValue(e) }} />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                        <Panel header="Monthly Rent(% of Rent)" key="3">
                            <table className="table table-bordered calc-table">
                                <tbody>
                                    <tr>
                                        <td>Rent(Monthly)</td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="rentMonthly" value={rentMonthly} onChange={(e) => { this.setValue(e) }} />
                                            </div>
                                        </td>
                                        <td>${formatFixed(rentMonthly)}</td>
                                    </tr>
                                    <tr>
                                        <td>Vacancy</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="vacancy" value={vacancy} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>${formatFixed(rentMonthly * vacancy / 100)}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="insurance" value={insurance} onInput={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="insuranceRes" value={insuranceRes} onInput={(e) => { this.setValue(e) }} />
                                            </div>
                                            {/* ${ formatFixed(rentMonthly * insurance / 100) } */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>HOA</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="hoa" value={hoa} onInput={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="hoaRes" value={hoaRes} onInput={(e) => { this.setValue(e) }} />
                                            </div>
                                            {/* ${ formatFixed(rentMonthly * hoa / 100) } */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>PM Fee</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="pmFee" value={pmFee} onInput={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="pmFeeRes" value={pmFeeRes} onInput={(e) => { this.setValue(e) }} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Repair and Maintanance</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="repair" value={repair} onInput={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" name="repairRes" value={repairRes} onInput={(e) => { this.setValue(e) }} />
                                            </div>
                                            {/* ${ formatFixed(rentMonthly * repair / 100) } */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                        <Panel header="Assumption" key="4">
                            <table className="table table-bordered calc-table">
                                <tbody>
                                    <tr>
                                        <td>Housing Appreciation</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="housingAppreciation" value={housingAppreciation} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Rent Increse Percent</td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="rentIncresePercent" value={rentIncresePercent} onChange={(e) => { this.setValue(e) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                    </Collapse>
                </Drawer>
                <section className="listing-title-area pd30">
                    <div className="container">
                        <div className="row mb30">
                            <div className="col-lg-7 col-xl-8">
                                <Skeleton loading={dataLoading} active="active" size="large">
                                    <div className="single_property_title mt30-767">
                                        <h2>{details.title}</h2>
                                        {details.hide_full_address == 'Y' ? '' : <p>{details.address && details.address.replaceAll('undefined', '').replaceAll(' ', '')}</p>}
                                        {/* {details.images_from ? <p>Images provide from {details.images_from}</p> : ''} */}
                                    </div>
                                </Skeleton>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div className="single_property_social_share">
                                    <div className="price float-left fn-400">
                                        <Skeleton loading={dataLoading} active="active" size="large">
                                            <h2>${toThousands(details.listing_price)}</h2>
                                        </Skeleton>
                                    </div>
                                    {/* <div className="spss style2 mt20 text-right tal-400">
                                        <ul className="mb0">
                                            <li className="list-inline-item">
                                                <a
                                                    onClick={() => {
                                                        this.addCompare(details);
                                                    }}
                                                >
                                                    <span className="flaticon-transfer-1" />
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#">
                                                    <span className="flaticon-heart" />
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#">
                                                    <span className="flaticon-share" />
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#">
                                                    <span className="flaticon-printer" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                            <div className="row" id="popimages">
                                <div className="col-sm-7 col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Skeleton loading={dataLoading} active="active" size="large">
                                            <div className="spls_style_two mb30-520">
                                                <div className="popup-img">
                                                    <img
                                                        className="img-fluid w100"
                                                        src={details.images ? details.images[0] : ''}
                                                        alt={details.images ? details.images[0] : ''}
                                                    />
                                                    {details.exclusive == 'Y' ? <div className="list-line-exclusive">EXCLUSIVE</div> : ''}
                                                    {details.status == 'Sold' ? <div className="list-line-sold">SOLD</div> : ''}
                                                </div>
                                            </div>
                                            </Skeleton>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5 col-lg-4">
                                    <div>
                                        <Skeleton loading={dataLoading} active="active" size="large">
                                        </Skeleton>
                                        <ul className="row img-section">
                                            {details.images
                                                ? details.images.map((src, index) => {
                                                    if(index > 0 ) {
                                                        return (
                                                            <li className={'col-sm-6 col-lg-6 img-item' + (index > 6 ? '': '')} key={index}>
                                                                <div className="spls_style_two">
                                                                    <div className="popup-img">
                                                                        <img
                                                                            className="img-fluid w100"
                                                                            src={src}
                                                                            alt={src}
                                                                        />
                                                                    </div>
                                                                    {/* {index == 6 ? <div className="popup-img overlay" onClick={() => {
                                                                        this.showViewer();
                                                                    }}><h3 class="title">More</h3></div> : ''} */}
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                })
                                                : ''}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        {details && details.images_from ? <div className='mt10'>The pictures come from {details.images_from}. </div> : ''}
                    </div>
                </section>
                {/* <div className="tabs">
                    <div className="container">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="SUMMARY" size="middle" key="1">
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="CALCULATOR" size="middle" key="2">
                            
                                
                                <ComponentCalculator price={details.listing_price}></ComponentCalculator>

                            </Tabs.TabPane>
                            <Tabs.TabPane tab="FINANCIALS" size="middle" key="3">
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div> */}
                <section className="our-agent-single bgc-f7 pb30-991 pt10">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-8">
                                <div className="row">
                                    {/* <div className="col-lg-12">
                                        <div className="listing_single_description">
                                            <div className="lsd_list">
                                                <ul className="mb0">
                                                    <li className="list-inline-item">
                                                        <a href="#">{formatType(details.type)}</a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#">Beds: {details.bedrooms}</a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#">Baths: {details.bathrooms}</a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#">Sq Ft: {details.size}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h4 className="mb30">Description</h4>
                                            <p className="mb25">{details.description}</p>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="additional_details">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb15">Property Details</h4>
                                                </div>
                                                <div className="detail-desc col-lg-12">
                                                    {details.description}
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-6">
                                                    <ul className="list-inline-item">
                                                        <li><p>Type :</p></li>
                                                        <li><p>Status :</p></li>
                                                        <li><p>Price :</p></li>
                                                        <li><p>Incentive :</p></li>
                                                        <li><p>Tax Rate  :</p></li>
                                                        <li><p>Estimated Rent :</p></li>
                                                        <li><p>HOA :</p></li>
                                                        <li><p>CDD :</p></li>
                                                        {floorplan && JSON.stringify(floorplan) !== '{}' ? <li><p>FloorPlan:</p></li> : ''}
                                                    </ul>
                                                    <ul className="list-inline-item">
                                                        <li><p><span>{formatType(details.type || '-')}</span></p></li>
                                                        <li><p><span>{details.status || '-'}</span></p></li>
                                                        <li><p><span>${details.listing_price || 0}</span></p></li>
                                                        <li><p><span>{details.incentive || '-'}</span></p></li>
                                                        <li><p><span>{details.tax_rate || 0}%</span></p></li>
                                                        <li><p><span>${details.estimated_rent || 0} / m</span></p></li>
                                                        <li><p><span>${details.hoa || 0} / m</span></p></li>
                                                        <li><p><span>${details.cdd || 0} / m</span></p></li>
                                                        {floorplan && JSON.stringify(floorplan) !== '{}' ? <li><p><span>{floorplan.name}</span></p></li> : ''}
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-6">
                                                    <ul className="list-inline-item">
                                                        <li><p>Area Size :</p></li>
                                                        <li><p>Bedrooms :</p></li>
                                                        <li><p>Bathrooms :</p></li>
                                                        <li><p>Garages :</p></li>
                                                        <li><p>Year Built :</p></li>
                                                        <li><p>Stories :</p></li>
                                                        <li><p>Sold price :</p></li>
                                                        <li><p>County :</p></li>
                                                    </ul>
                                                    <ul className="list-inline-item">
                                                        <li><p><span>{details.size || 0} Sq Ft</span></p></li>
                                                        <li><p><span>{details.bedrooms || 0}</span></p></li>
                                                        <li><p><span>{details.bathrooms || 0}</span></p></li>
                                                        <li><p><span>{details.garage || 0}</span></p></li>
                                                        <li><p><span>{details.year_built || '-'}</span></p></li>
                                                        <li><p><span>{details.stories || 0}</span></p></li>
                                                        <li><p><span>${details.sold_price || '-'}</span></p></li>
                                                        <li><p><span>{details.county || '-'}</span></p></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                    <div className="row" style={{ "display": isShowFlooring == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10">Flooring</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.flooring_tile == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Title</a>
                                                                </li>
                                                                <li style={{ "display": details.flooring_hardwood == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Hardwood</a>
                                                                </li>
                                                                <li style={{ "display": details.flooring_vinyl == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Vinyl</a>
                                                                </li>
                                                                <li style={{ "display": details.flooring_carpet == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Carpet</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowKitchen == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10">Kitchen Countertop material</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.kitchen_granite == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Granite</a>
                                                                </li>
                                                                <li style={{ "display": details.kitchen_marble == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Marble</a>
                                                                </li>
                                                                <li style={{ "display": details.kitchen_quartz == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Quartz</a>
                                                                </li>
                                                                <li style={{ "display": details.kitchen_laminate == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Laminate</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowAppliances == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10">Appliances</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.appliances_refrigerator == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Refrigerator</a>
                                                                </li>
                                                                <li style={{ "display": details.appliances_electric_stove == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Electric Stove</a>
                                                                </li>
                                                                <li style={{ "display": details.appliances_gas_stove == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Gas Stove</a>
                                                                </li>
                                                                <li style={{ "display": details.appliances_microwave == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Microwave</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowAppliancesOther == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10">Other Appliances</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.other_washer == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Washer</a>
                                                                </li>
                                                                <li style={{ "display": details.other_dryer == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Dryer</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowHeating == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10">Heating </h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.heat_furnace == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Furnace</a>
                                                                </li>
                                                                <li style={{ "display": details.heat_heat_pump == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Heat Pump</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowCooling == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10"> Cooling</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.heat_heat_pump == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Air Condition</a>
                                                                </li>
                                                                <li style={{ "display": details.ac_central_ac == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Central</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowExteriorMaterial == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10"> Exterior Material</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.exterior_brick == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Brick</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_stone == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Stone</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_stucco == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Stucco</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_vinyl == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Vinyl</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_wood == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Wood</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_composite == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Composite</a>
                                                                </li>
                                                                <li style={{ "display": details.exterior_fiber_cement == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />Fiber Cement</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ "display": isShowHighCeiling == '1' ? '' : 'none'}}>
                                                        <div className="col-lg-12 mt30">
                                                            <h5 className="mb10"> High Ceiling</h5>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <ul className="order_list list-inline-item">
                                                                <li style={{ "display": details.high_ceiling == '1' ? '' : 'none'}}>
                                                                    <a href="javascript:void(0)"><span className="flaticon-tick" />High Ceiling</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(()=> {
                                        if(floorplan && JSON.stringify(floorplan) !== '{}' && floorplan.community && JSON.stringify(floorplan.community) != '{}' && (floorplan.community.elementary_schools || floorplan.community.middle_schools || floorplan.community.high_schools)){
                                            return (
                                                <div className="col-lg-12">
                                                    <div className="application_statics mt30">
                                                        <h4 className="mb30">Nearby Schools</h4>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                {floorplan.community.elementary_schools.map((item, index) => {
                                                                    return (
                                                                        <div className="nearby-sch-box">
                                                                            <div className="nearby-sch-rating">
                                                                                <b>{item.rating || 'NA'}</b>/10
                                                                            </div>
                                                                            <div className="nearby-sch-detail">
                                                                                <div className="nearby-sch-name">Elementary School 0{index+1} {item.type=='public' ? '' : '(' + item.type + ')'}</div>
                                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                                            </div>
                                                                        </div>
                                                                    )})
                                                                }
                                                                {floorplan.community.middle_schools.map((item, index) => {
                                                                    return (
                                                                        <div className="nearby-sch-box mt10">
                                                                            <div className="nearby-sch-rating">
                                                                                <b>{item.rating || 'NA'}</b>/10
                                                                            </div>
                                                                            <div className="nearby-sch-detail">
                                                                                <div className="nearby-sch-name">Middle School 0{index+1} {item.type=='public' ? '' : '(' + item.type + ')'}</div>
                                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                                            </div>
                                                                        </div>
                                                                    )})
                                                                }
                                                                {floorplan.community.high_schools.map((item, index) => {
                                                                    return (
                                                                        <div className="nearby-sch-box mt10">
                                                                            <div className="nearby-sch-rating">
                                                                                <b>{item.rating || 'NA'}</b>/10
                                                                            </div>
                                                                            <div className="nearby-sch-detail">
                                                                                <div className="nearby-sch-name">High School 0{index+1} {item.type=='public' ? '' : '(' + item.type + ')'}</div>
                                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                                            </div>
                                                                        </div>
                                                                    )})
                                                                }
                                                                <div className="nearby-sch-by"> * Data sourced from Great school</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        })()
                                    }
                                    {/* <div className="col-lg-12">
                                        <div className="property_attachment_area">
                                            <h4 className="mb30">Property Attachments</h4>
                                            <div className="iba_container style2">
                                                <div className="icon_box_area style2">
                                                    <div className="score">
                                                        <span className="flaticon-document text-thm fz30" />
                                                    </div>
                                                    <div className="details">
                                                        <h5>
                                                            <span className="flaticon-download text-thm pr10" />{' '}
                                                            Demo Word Document
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="icon_box_area style2">
                                                    <div className="score">
                                                        <span className="flaticon-pdf text-thm fz30" />
                                                    </div>
                                                    <div className="details">
                                                        <h5>
                                                            <span className="flaticon-download text-thm pr10" />{' '}
                                                            Demo pdf Document
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                   
                                    
                                    {/* <div className="col-lg-12">
                                        <div className="application_statics">
                                            
                                        </div>
                                    </div> */}
                                   
                                    {(() => {
                                        if (details.latitude) {
                                            return (
                                                <div className="col-lg-12">
                                                    <div className="application_statics mt30">
                                                        <h4 className="mb30">
                                                            Location{' '}
                                                            {/* <small className="float-right">
                                                                {details.address}
                                                            </small> */}
                                                        </h4>
                                                        <div className="property_video p0">
                                                            <div className="thumb">
                                                                <div
                                                                    className="h400"
                                                                    id="map-canvas"
                                                                >
                                                                    <GoogleMap
                                                                        zoom={12}
                                                                        placeId={details.place_id}
                                                                        center={
                                                                            details.latitude
                                                                                ? {
                                                                                      lat: +details.latitude,
                                                                                      lng: +details.longitude,
                                                                                  }
                                                                                : undefined
                                                                        }
                                                                        onEvent={() => {}}
                                                                    />
                                                                </div>
                                                                {/* <div className="overlay_icon">
                                                                    <a>
                                                                        <img
                                                                            className="map_img_icon"
                                                                            src={logo}
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                                <div className="cal-block mb30">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-4">
                                            <div className="row" style={{fontSize: '16px', padding: '0 10px'}}>
                                                <div className="col-lg-6">
                                                    <span className="calc-title">Purchase Price</span>
                                                </div>
                                                <div className="col-lg-6" style={{textAlign: 'right'}}>
                                                    <span className="calc-title">${toThousands(sliderPrice)}</span>
                                                </div>
                                                <div className="col-lg-12" style={{margin: '0 -6px'}}>
                                                    <Slider min={purchaseMin} max={purchaseMax} defaultValue={purchasePrice} value={sliderPrice} step={700} onChange={this.onChange} marks={marks}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{fontSize: '16px', padding: '0 10px'}}>
                                                <div className="col-lg-6">
                                                    <span className="calc-title">Down Payment</span>
                                                </div>
                                                <div className="col-lg-6" style={{textAlign: 'right'}}>
                                                    <span className="calc-title">{downpayment}%</span>
                                                </div>
                                                <div className="col-lg-12" style={{margin: '0 -6px'}}>
                                                    <Slider min={20} max={100} defaultValue={25} onChange={this.onDownpaymentChange} marks={{25:''}}/>
                                                </div>
                                            </div>
                                            <div className="row" style={{fontSize: '16px', padding: '0 10px'}}>
                                                <div className="col-lg-6">
                                                    <span className="calc-title">Rent</span>
                                                </div>
                                                <div className="col-lg-6" style={{textAlign: 'right'}}>
                                                    <span className="calc-title">${toThousands(sliderEstimatedRent)}</span>
                                                </div>
                                                <div className="col-lg-12" style={{margin: '0 -6px'}}>
                                                    <Slider min={rentMin} max={rentMax} defaultValue={estimatedRent} value={sliderEstimatedRent} step={10} onChange={this.onSliderEstimatedRentChange} marks={rentMarks}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-8">
                                            {/* <div className="ml10 mb20">
                                                <span className="calc-title">Total Return</span>
                                            </div> */}
                                            <div className="mb20" >
                                                <Radio.Group defaultValue={calcYear} value={calcYear} size="large" onChange={(e) => {
                                                    this.setState({
                                                        calcYear: e.target.value
                                                    })
                                                }}>
                                                    <Radio.Button value="1">1 Yr</Radio.Button>
                                                    <Radio.Button value="2">2 Yrs</Radio.Button>
                                                    <Radio.Button value="3">3 Yrs</Radio.Button>
                                                    <Radio.Button value="4">4 Yrs</Radio.Button>
                                                    <Radio.Button value="5">5 Yrs</Radio.Button>
                                                    <Radio.Button value="10">10 Yrs</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            <div className="m10 mb20">
                                                <span className="calc-title">Cap Rate = NOI / Price</span>
                                                <span className="calc-value">{formatFixed(rCapRate[calcYearIndex])}%</span>
                                            </div>
                                            <div className="m10 mb20">
                                                <span className="calc-title">Cash Flow (Annual)</span>
                                                <span className="calc-value">${formatFixed(rCashFlow[calcYearIndex])}</span>
                                            </div>
                                            <div className="m10 mb20">
                                                <span className="calc-title">Cash on Cash Return</span>
                                                <span className="calc-value">{formatFixedRate(rCashOnCash[calcYearIndex])}%</span>
                                            </div>
                                            <div className="m10 mb20">
                                                <span className="calc-title">Cash Flow (Monthly)</span>
                                                <span className="calc-value">${formatFixed(rCashFlowMonthly[calcYearIndex])}</span>
                                            </div>
                                            <div className="m10 mb20">
                                                <span className="calc-title">Total Return = Total Gain / Cash Invested</span>
                                                <span className="calc-value">{formatFixed(rTotalReturn[calcYearIndex])}%</span>
                                            </div>

                                            <div className="m10 mb20">
                                                <span className="calc-value"><Button type="link" onClick={()=>{
                                                    this.setState({openDrawer: true})
                                                }}>Edit Assumptions</Button></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {owner && (
                            <div className="col-lg-4 col-xl-4">
                                <div className="sidebar_listing_list">
                                    <div className="sidebar_advanced_search_widget">
                                        <div className="sl_creator mb0">
                                            <h4 className="mb25">Listed By</h4>
                                            <div className="media">
                                                <img
                                                    className="mr-3"
                                                    src={owner.profile_image_src}
                                                    onError="this.src = '../../assets/home/header-logo.png'"                                                    
                                                    alt={owner.name}
                                                    width={'40px'}
                                                />
                                                <div className="media-body">
                                                    <h5 className="mt-0 mb0">{owner.name}</h5>
                                                    <p className="mb0">{owner.phone_no}</p>
                                                    <p className="mb0">{owner.email}</p>
                                                    <a className="text-thm" onClick={() => {
                                                        if (this.props.userInfo?.id) {
                                                            this.props.history.push({
                                                                pathname: '/PageMessage',
                                                                state: {
                                                                    owner,
                                                                    subject: details.title,
                                                                    id: details.id,
                                                                },
                                                            });
                                                        } else
                                                        this.headerBar.setLoginShow();
                                                    }}>
                                                        Contact Me
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="contact-qrcode">
                                                <img width="100%" src="https://topskyhome.s3.us-west-2.amazonaws.com/profile/topsky_qrcode.png#/" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="terms_condition_widget" style={{"display": properties && properties.length ? "" : "none"}}>
                                    <h4 className="title">Featured Properties</h4>
                                    <div className="sidebar_feature_property_slider">
                                        {properties.length > 0 && properties.slice(0, 5).map((item) => {
                                            return (
                                                <div
                                                    className="item"
                                                    key={item.id}
                                                    onClick={() => {
                                                        window.location.href = `/PageListingSingle?id=${item.id}`;
                                                        // this.props.history.push({
                                                        //     pathname: '/PageListingSingle',
                                                        //     search: `id=${item.id}`,
                                                        // });
                                                    }}
                                                >
                                                    <div className="feat_property home7 agent">
                                                        <div className="thumb">
                                                            <img
                                                                className="img-whp"
                                                                src={item.images&&item.images.length ? item.images[0] : ''}
                                                                alt={item.images&&item.images.length ? item.images[0] : ''}
                                                            />
                                                            <div className="thmb_cntnt">
                                                                <ul className="tag mb0">
                                                                    <li className="list-inline-item">
                                                                        <a>Featured</a>
                                                                    </li>
                                                                </ul>
                                                                <a className="fp_price" style={{
                                                                    bottom: '0px',
                                                                    left: 'unset',
                                                                }}>
                                                                    $
                                                                    {toThousands(
                                                                        item.listing_price
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                
                </section>
                <Site />
                <Footer />
                {(() => {
                    if (compare.properties.length > 0) {
                        return (
                            <div
                                style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    width: '100vw',
                                    backgroundColor: 'rgba(33,33,33,0.7)',
                                    height: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexFlow: 'row',
                                }}
                            >
                                <button
                                    className={'btn btn-primary mb-2'}
                                    style={{
                                        position: 'fixed',
                                        right: '10px',
                                        bottom: '140px',
                                        height: '40px',
                                    }}
                                    onClick={() => {
                                        this.props.history.push('/PageCompare');
                                    }}
                                >
                                    Compare
                                </button>
                                {compare.properties.map((item) => {
                                    return (
                                        <div
                                            style={{
                                                padding: '20px',
                                                position: 'relative',
                                            }}
                                            key={item.id}
                                        >
                                            <img src={item.images&&item.images.length ? item.images[0] : ''} height={160} alt={''} />
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: '20px',
                                                    bottom: '45px',
                                                    height: '40px',
                                                    padding: '10px',
                                                    color: '#FFFFFF',
                                                    fontSize: '20px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                ${toThousands(item.listing_price)}
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: '20px',
                                                    bottom: '25px',
                                                    height: '30px',
                                                    padding: '10px',
                                                    color: '#FFFFFF',
                                                    fontSize: '15px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Bedroom: {item.bedrooms}
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: '20px',
                                                    bottom: '25px',
                                                    height: '30px',
                                                    padding: '10px',
                                                    color: '#FFFFFF',
                                                    fontSize: '15px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Bathroom: {item.bathrooms}
                                            </div>
                                            <div
                                                className={'flaticon-close'}
                                                style={{
                                                    position: 'absolute',
                                                    right: '20px',
                                                    top: '20px',
                                                    width: '40px',
                                                    height: '40px',
                                                    backgroundColor: '#FFF',
                                                    padding: '10px',
                                                    color: 'red',
                                                }}
                                                onClick={() => {
                                                    this.props.dispatch(CompareDel(item));
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                })()}

                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
