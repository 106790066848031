import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";
import "./index.less";

class PageCommunitySingle extends React.Component<Props, State> {
    state: State = {
        details: {},
        pageLoading: true
    };

    getDetails = async (id) => {
        const res = await Request({
            url: '/community/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        });
        const details = res?.data?.data;
        
        this.setState({ details });
        this.setState({
            pageLoading: false
        })
        // if (details) {
        //     const {
        //         data: { data: owner },
        //     } = await Request({
        //         url: '/user/' + details?.user_entered,
        //         method: 'GET',
        //         headers: {
        //             'x-api-key': process.env.X_API_KEY,
        //         },
        //     });
        // }
    }
    // getCommunityList = async () => {
    //     Request({
    //         url: '/community/themes',
    //         headers: {
    //             'x-api-key': process.env.X_API_KEY,
    //         },
    //         method: 'GET'
    //     })
    //     .then(async (res: any) => {
    //         const rs = res.data;
    //         if (res && rs && rs.data) {
    //             this.setState({
    //                 list: rs.data
    //             })
    //         }
    //     })
    //     .catch((err) => {
    //         console.log('error ==> ', err)
    //     });
    // }
    formatEventTime = (event_time) => {
        const WeekArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let d = new Date(event_time)
        let week = WeekArr[d.getDay()]
        let month = d.toDateString().split(' ')[1]
        let date = d.getDate()
        let year = d.getFullYear()
        let hours = d.getHours()
        let hour = (hours < 2 ? '0' : '') + hours
        let minutes = d.getMinutes()
        let minute = (minutes < 2 ? '0' : '') + minutes
        let seconds = d.getSeconds()
        let second = (seconds < 2 ? '0' : '') + seconds
        return {
            dd: date,
            mm: month,
            yy: year,
            date: week + ', ' + month + ' ' + date + ', ' + year,
            time: hour + ':' + minute + ':' + second + ' Pacific Daylight Time'
        }
    }
    componentDidMount(): void {
        // this.getCommunityList()
        if (typeof document !== 'undefined') {
            const search = this.props.location.search.replace('?', '');
            const params = search.split('&')[0].split('=')[1];
            this.getDetails(params).then();
        }
    }
    

    render() {
        let { details, pageLoading } = this.state;
        
        const routeToLanding = (item) => {
            // this.props.history.push({
            //     pathname: '/PageMWebinarLanding?id=' + item.id
            // });
            window.location.href = '/PageListingSingle?id=' + item.id;
        };
        
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {
                // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
                var dotCnt = String(num).substring(dot + 1, num.length);

                // 获取小数点前面的数字
                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {
                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        const renderList = () => {
            // return list.map((item, index) => {
            //     // return tampaVideos.map((item, index) => {
            //     // if (index < count)
            //     let clsName = 'comm-box';
            //     if(index%2==1) {
            //         clsName += ' even';
            //     }
            //     let imgs = item.images && item.images[0]? item.images[0]:"https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png";
            //     return (
            //         <div className="col-lg-4 col-xl-4" data-id={index} key={index}>
            //             <div className={clsName} style={{backgroundImage: 'url(' + imgs + ')'}}>
            //                 <div className="comm-box-inner" onClick={() => {
            //                     routeToLanding(item);
            //                 }}>
            //                     <div className="inner-content">
            //                         <div className="s-title" title={item.name}>{item.name}</div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     );
            // });
        };
        
        let imgs = pageLoading ? '' : (details.images && details.images[0]? details.images[0] : "https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png");
        let title = details.name ? 'LEARN MORE ABOUT ' + details.name + ' COMMUNITY!' : '';
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="wrapper-banner mb50">
                        <div className="thumbnail">
                            <div className="banner-bg-img" style={{backgroundImage: 'url(' + imgs+ ')'}}></div>
                            <div className = "banner-bg-title">
                                <strong>{title}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        {/* <img
                            className="img-fluid w100"
                            src={details.images ? details.images[0] : 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png'}
                            alt={details.images ? details.images[0] : 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/default_image.png'}
                        /> */}
                        
                        <div className="desc-title mb30">{details.name}</div>
                        {details.additional_infomation ? (<div className="desc-highlight mb20" dangerouslySetInnerHTML={{ __html: details.additional_infomation }}></div>): ''}
                        {details.notes ? (<div className="desc-notes mb20" dangerouslySetInnerHTML={{ __html: details.notes }}></div>): ''}
                        {(() => {
                            return details.video_url ? (<div className="commnity-video">
                                {/* <div dangerouslySetInnerHTML={{ __html: details.video_url }}></div> */}
                                <div>
                                    {/* "https://www.youtube.com/embed/dJn6A6V1-Ss?si=CVXwxZU5-qaqC9xl" */}
                                    <iframe width="560" height="315" src={details.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>) : ''
                        })()}
                        {(()=> {
                            if(details && JSON.stringify(details) != '{}' && (details.elementary_schools || details.middle_schools || details.high_schools)){
                                return (
                                    <div className="application_statics mt30">
                                        <h4 className="mb30">Nearby Schools</h4>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {details.elementary_schools && details.elementary_schools.length && details.elementary_schools.map((item, index) => {
                                                    return (
                                                        <div className="nearby-sch-box mb10" title={item.school_summary}>
                                                            <div className="nearby-sch-rating">
                                                                <b>{item.rating || 'NA'}</b>/10
                                                            </div>
                                                            <div className="nearby-sch-detail">
                                                                <div className="nearby-sch-name">{item.name} ({item.type})</div>
                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                            </div>
                                                        </div>
                                                    )})
                                                }
                                                {details.middle_schools && details.middle_schools.length && details.middle_schools.map((item, index) => {
                                                    return (
                                                        <div className="nearby-sch-box mb10">
                                                            <div className="nearby-sch-rating">
                                                                <b>{item.rating || 'NA'}</b>/10
                                                            </div>
                                                            <div className="nearby-sch-detail">
                                                                <div className="nearby-sch-name">{item.name} ({item.type})</div>
                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                            </div>
                                                        </div>
                                                    )})
                                                }
                                                {details.high_schools && details.high_schools.length && details.high_schools.map((item, index) => {
                                                    return (
                                                        <div className="nearby-sch-box mb10">
                                                            <div className="nearby-sch-rating">
                                                                <b>{item.rating || 'NA'}</b>/10
                                                            </div>
                                                            <div className="nearby-sch-detail">
                                                                <div className="nearby-sch-name">{item.name} ({item.type})</div>
                                                                <div className="nearby-sch-level">Grade: {item.level}</div>
                                                            </div>
                                                        </div>
                                                    )})
                                                }
                                                <div className="nearby-sch-by"> * Data sourced from Great school</div>
                                            </div>                                                
                                        </div>
                                    </div>
                                )
                            }
                            })()
                        }
                        <div className="application_statics mt30">
                            <div className="row">
                                <div className="info-root col-lg-6" style={{ "display": details.community_size ? '' : 'none'}}>
                                    <div className="info-label">Community Size</div>
                                    <div className="info-value">{details.community_size}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.purchase_restriction ? '' : 'none'}}>
                                    <div className="info-label">Purchase restriction</div>
                                    <div className="info-value">{details.purchase_restriction}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.deposit_requirement ? '' : 'none'}}>
                                    <div className="info-label">Deposit Requirement</div>
                                    <div className="info-value">{details.deposit_requirement}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.development_phase ? '' : 'none'}}>
                                    <div className="info-label">Development Phase</div>
                                    <div className="info-value">{details.development_phase}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.typical_price_range ? '' : 'none'}}>
                                    <div className="info-label">Typic Price Range</div>
                                    <div className="info-value">{details.typical_price_range}(k$)</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.typical_home_size ? '' : 'none'}}>
                                    <div className="info-label">Typical Home Size </div>
                                    <div className="info-value">{details.typical_home_size}(Sqft)</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.typical_lot_size ? '' : 'none'}}>
                                    <div className="info-label">Typical Lot Size</div>
                                    <div className="info-value">{details.typical_lot_size}(Acre)</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.rental_restrictions ? '' : 'none'}}>
                                    <div className="info-label">Rental Restrictions</div>
                                    <div className="info-value">{details.rental_restrictions}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.year_built ? '' : 'none'}}>
                                    <div className="info-label">Year Built</div>
                                    <div className="info-value">{details.year_built}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.POIs ? '' : 'none'}}>
                                    <div className="info-label">POIs</div>
                                    <div className="info-value">{details.POIs}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.crime_level ? '' : 'none'}}>
                                    <div className="info-label">Crime Level</div>
                                    <div className="info-value">{details.crime_level}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.hoa ? '' : 'none'}}>
                                    <div className="info-label">HOA</div>
                                    <div className="info-value">{details.hoa}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.home_type ? '' : 'none'}}>
                                    <div className="info-label">Home Type</div>
                                    <div className="info-value">{details.home_type}</div>
                                </div>
                                <div className="info-root col-lg-6" style={{ "display": details.metro ? '' : 'none'}}>
                                    <div className="info-label">Metro</div>
                                    <div className="info-value">{details.metro}</div>
                                </div>
                                <div className="info-root col-lg-12">
                                    <div className="info-label">Other</div>
                                    <div className="info-value">
                                        <ul className="order_list list-inline-item">
                                            <li style={{ "display": details.swimming_pool == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Swimming Pool</a>
                                            </li>
                                            <li style={{ "display": details.club_house == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Club House</a>
                                            </li>
                                            <li style={{ "display": details.fitness_center == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Fitness Center</a>
                                            </li>
                                            <li style={{ "display": details.playground == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Playground</a>
                                            </li>
                                            <li style={{ "display": details.dock == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Dock</a>
                                            </li>
                                            <li style={{ "display": details.tennis_court == '1' ? '' : 'none'}}>
                                                <a href="javascript:void(0)"><span className="flaticon-tick" />Tennis Court</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="sqs-block-horizontalrule"><hr/></div> */}
                        <div className="desc-title mt30 mb30">MOST RECENT PROPERTY FOR SALE</div>
                        <div className="row">
                            {(() => {
                                let _arr = [];
                                if(details.properties) _arr = details.properties;
                                return _arr.map((item) => (
                                        <div className="col-md-6 col-lg-4" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/PageListingSingle',
                                                search: `id=${item.id}`,
                                            });
                                        }} key={item.id}>
                                            <div className="feat_property">
                                                <div className="thumb">
                                                    <img className="img-whp" src={item.images&&item.images.length ? item.images[0] : ''} alt="fp1.jpg" />
                                                    {item.exclusive == 'Y' ? <div className="list-line-exclusive">EXCLUSIVE</div> : ''}
                                                    <div className="thmb_cntnt">
                                                        <ul className="tag mb0">
                                                            <li className="list-inline-item"><a href="#">{item.type.replace('_', ' ')}</a></li>
                                                            <li className="list-inline-item"><a href="#" className={item.status}>{item.status}</a></li>
                                                        </ul>
                                                        {/* <ul className="icon mb0">
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-transfer-1"></span></a></li>
                                                            <li className="list-inline-item"><a href="#"><span className="flaticon-heart"></span></a></li>
                                                        </ul> */}
                                                        <a className="fp_price" href="javascrpt:void(0);">${toThousands(item.listing_price || 0)}<small></small></a>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <div className="tc_content">
                                                        <p className="text-thm">{item.type.replace('_', ' ')}</p>
                                                        <h4 className="prop-title" title={item.title}>{item.title}</h4>
                                                        {/* <p><span className="flaticon-placeholder"></span> {item.address}, {item.city}, {item.state}</p> */}
                                                        <ul className="prop_details mb0">
                                                            <li className="list-inline-item"><a href="#">Beds: {item.bedrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Baths: {item.bathrooms}</a></li>
                                                            <li className="list-inline-item"><a href="#">Sq Ft: {item.size}</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="fp_footer">
                                                        {/* <ul className="fp_meta float-left mb0">
                                                            <li className="list-inline-item"><a href="#"><img src="images/property/pposter1.png" alt="pposter1.png" /></a></li>
                                                            <li className="list-inline-item"><a href="#">Ali Tufan</a></li>
                                                        </ul> */}
                                                        <div className="fp_pdate float-right">built {item.year_built}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))
                            })()}
                        </div>
                        <div className="row">
                            {renderList()}
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default PageCommunitySingle;
