export const ActionTypes = {
    COMPARE_ADD: 'compare/add',
    COMPARE_DEL: 'compare/del',
    COMPARE_INIT: 'compare/init',
};

export const CompareAdd = (property) => ({
    type: ActionTypes.COMPARE_ADD,
    payload: {
        data: property,
    },
});

export const CompareDel = (property) => ({
    type: ActionTypes.COMPARE_DEL,
    payload: {
        data: property,
    },
});

export const CompareInit = () => ({
    type: ActionTypes.COMPARE_INIT,
    payload: {
        data: JSON.parse(window.localStorage.getItem("COMPARE")),
    },
});
