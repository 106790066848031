import React from 'react';
import { message } from 'antd';

// require('dotenv').config();
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import StepItem from 'components/StepItem/StepItem';
import { State, Props } from './interface';
import Request from 'components/Utils/FormRequest';
import licenseImg from '../../assets/images/license/buy.jpg';

class buy extends React.Component<Props, State> {
    state: State = {
        address: '',
        city: '',
        state: '',
        zip: '',
        buy_county: '',
        name: '',
        phone: '',
        email: '',
    };

    handleSubmit(e) {
        e.preventDefault();
        const form = this.state;
        Request({
            url: '/buy/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    message.success({
                        content: data.message,
                        duration: 3,
                        className: 'success',
                    });
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="ml-5 mr-5">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                {/*<div className="container">*/}
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="terms_condition_grid">
                                            <div className="grids mb30 text-left pl-5 pr-5 ml-5 mr-5">
                                                <div className="container">
                                                    <h5 className="color-primary">
                                                        Full service, carefree investment from anywhere in the world
                                                    </h5>
                                                    <h5 className="color-primary">
                                                        Data driven approach to maximize your
                                                        return:
                                                    </h5>
                                                    <div className="stepper">
                                                        <StepItem
                                                            {...this.props}
                                                            title="Research"
                                                            bgColor="#BD2431"
                                                            marginLeft={0}
                                                        />
                                                        <StepItem
                                                            {...this.props}
                                                            title="Analyze"
                                                            bgColor="#F58B00"
                                                            marginLeft={-10}
                                                        />
                                                        <StepItem
                                                            {...this.props}
                                                            title="Buy"
                                                            bgColor="#828081"
                                                            marginLeft={-10}
                                                        />
                                                        <StepItem
                                                            {...this.props}
                                                            title="Manage"
                                                            bgColor="#267B8C"
                                                            marginLeft={-10}
                                                        />
                                                        <StepItem
                                                            {...this.props}
                                                            title="Sell"
                                                            bgColor="#3ABA2D"
                                                            marginLeft={-10}
                                                        />
                                                    </div>
                                                    <h6 className="color-golden">
                                                        Schedule a free, no obligation consultation.
                                                    </h6>
                                                </div>
                                                <form
                                                    onSubmit={(e) => this.handleSubmit(e)}
                                                    method="post"
                                                >
                                                    <div className="form1 form-container container">
                                                        <div className="row">
                                                            <div className="form-group col-12">
                                                                <label
                                                                    htmlFor="address"
                                                                    className="label-text"
                                                                >
                                                                    Address
                                                                </label>
                                                                <input
                                                                    id="address"
                                                                    name="address"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.address}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-4">
                                                                <label
                                                                    htmlFor="address"
                                                                    className="label-text"
                                                                >
                                                                    City
                                                                </label>
                                                                <input
                                                                    id="city"
                                                                    name="city"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.city}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-4">
                                                                <label
                                                                    htmlFor="address"
                                                                    className="label-text"
                                                                >
                                                                    State
                                                                </label>
                                                                <input
                                                                    id="state"
                                                                    name="state"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.state}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-4">
                                                                <label
                                                                    htmlFor="address"
                                                                    className="label-text"
                                                                >
                                                                    Zip
                                                                </label>
                                                                <input
                                                                    id="zip"
                                                                    name="zip"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.zip}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <label
                                                                    htmlFor="buy_county"
                                                                    className="label-text"
                                                                >
                                                                    Where do you want to buy
                                                                </label>
                                                                <input
                                                                    id="buy_county"
                                                                    name="buy_county"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.buy_county}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <label
                                                                    htmlFor="name"
                                                                    className="label-text"
                                                                >
                                                                    Name
                                                                </label>
                                                                <input
                                                                    id="name"
                                                                    name="name"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.name}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <label
                                                                    htmlFor="phone"
                                                                    className="label-text"
                                                                >
                                                                    Telephone number
                                                                </label>
                                                                <input
                                                                    id="phone"
                                                                    name="phone"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="text"
                                                                    value={this.state.phone}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <label
                                                                    htmlFor="email"
                                                                    className="label-text"
                                                                >
                                                                    Email
                                                                </label>
                                                                <input
                                                                    id="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    required={true}
                                                                    type="email"
                                                                    value={this.state.email}
                                                                    onChange={(e) =>
                                                                        this.handleChange(e)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn2 btn-block w-100"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="grids mb30"></div>
                                        </div>
                                    </div>
                                </div>
                                {/*</div>*/}
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div
                                    style={{
                                        backgroundImage: `url(${licenseImg})`,
                                        backgroundSize: 'cover',
                                    }}
                                    className="w-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default buy;
