import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Profile from '../../components/Profile/Profile';
import Question from '../../components/Question/Question';
import Request from 'components/Utils/FormRequest';
import { message } from 'antd';
import { connect } from 'react-redux';
import { Props, ModelState } from './interface';
import {toast} from "react-toastify";
import './index.less';

@connect(({ userInfo }) => {
    return { userInfo };
})
class ToshaClub extends React.Component<Props, ModelState> {
    headerBar;
    PASS_CODE = 'GETRICH';
    code;
    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
        this.code = React.createRef();
        console.log("current_user_props==>", this.props);
        this.headerBar;
        this.state = {
            isModal: false,
            showHideHaveCodeInfo: false,
            showHideProfileInfo: false,
            showHideQuestionnaire: true //false,
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(name) {
        console.log(name);
        console.log("showHide====>", this.state.showHideProfileInfo);
        switch (name) {
            case 'showHideProfileInfo':
                this.setState({ showHideProfileInfo: !this.state.showHideProfileInfo });
                break;
            case 'showHideQuestionnaire':
                this.setState({ showHideQuestionnaire: !this.state.showHideQuestionnaire });
                break;
            default:
                null;
        }
    }

    private checkUser = (props) => {
        const user = props.userInfo;
        // console.log(user);
        if (!(user && user.id)) {
            this.headerBar.setLoginShow();
        } else {
            if (user.role == 'MEMBER') {
                toast('Thank you for being a valueble member of TopSky Club!');
                // message.success({
                //     content: 'Thank you for being a valueble member of Topsky Club!',
                //     duration: 3,
                //     className: 'success-notice-green',
                // });
                setTimeout(function () {
                    window.location = '/';
                }, 5000);
            }
        }
    };
    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        this.checkUser(nextProps);
    }
    componentDidMount(): void {
        // if (typeof document !== 'undefined' && this.props.location.search) {
        //     const search = this.props.location.search.slice(1, this.props.location.search.length);
        //     const params = {};
        //     search
        //         .split('&')
        //         .map((item) => item.split('='))
        //         .forEach(([key, value]) => {
        //             params[key] = value;
        //         });
        //         console.log('props2=>', params)
        //     if(params['isFromRegister']) {
        //         this.setState({
        //             showHideHaveCodeInfo: false, 
        //             showHideProfileInfo: true
        //         });
        //     }
        //     else {
        //         this.setState({
        //             showHideHaveCodeInfo: true
        //         });
        //     }
        // }
        // else {
        //     this.setState({
        //         showHideHaveCodeInfo: true
        //     });
        // }
        this.checkUser(this.props);
    }
    private handleUpdateUser = (props, code) => {
        const user = props.userInfo;
        Request({
            url: '/user/' + user.id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'PUT',
            data: {
                role: 'MEMBER',
                member_code: code,
            },
        })
            .then(async (res) => {
                if (res && res.data) {
                    const memberRole = res.data.data.role;
                    if (memberRole == 'MEMBER') {
                        toast('Your account status is updated, thank you for being a valueble member of TopSky Club!');
                        // message.success({
                        //     content:
                        //         'Your account status is updated, thank you for being a valueble member of Topsky Club!',
                        //     duration: 3,
                        //     className: 'success-notice-green',
                        // });
                        setTimeout(function () {
                            window.location = '/';
                        }, 5000);
                    }
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleAnswer1(event) {
        console.log("answer1===>");
    }
    // Has code, verify code value and update backend for user's role and code used.
    handleHasCode() {
        const myCode = this.code.current.value;
        if (myCode) {
            console.log('Click has Code:' + myCode);
            if (myCode == this.PASS_CODE) {
                this.handleUpdateUser(this.props, myCode);
            } else {
                toast('Invalid code.');
                // message.error({
                //     content: 'Invalid code.',
                //     duration: 3,
                //     className: 'error-notice-red',
                // });
            }
        } else {
            toast('Please input the code first.');
            // message.error({
            //     content: 'Please input the code first.',
            //     duration: 3,
            //     className: 'error-notice-red',
            // });
        }
    }
    // No code, display the questions model
    handleNoCode(e) {
        console.log('Click no Code');
        this.hideComponent('showHideProfileInfo');
    }
    setModalStatus(val, isLogin) {
        console.log('fromLogin', val, isLogin)
        this.setState({
            isModal: val.syncShow == true
        });
    }
    render() {
        const { showHideHaveCodeInfo, showHideProfileInfo, showHideQuestionnaire } = this.state;
        return (
            <div
                className={[
                    'wrapper',
                    this.state.isModal ? 'page-modal':''
                ].join(' ')}
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e) => {
                        this.headerBar = e;
                    }}
                    setModalStatus={this.setModalStatus.bind(this)}
                />
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                {showHideHaveCodeInfo && (
                                    <div className="form_grid">
                                        <h4 className="mb5">Do you have a code?</h4>
                                        <p>
                                            <small>
                                                Code is provided in wechat group for existing TopSky club
                                                members
                                            </small>
                                        </p>
                                        <form onSubmit={() => this.handleHasCode()}>
                                            <div className="form1 form-container container">
                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                id="code"
                                                                name="code"
                                                                className="form-control"
                                                                required={true}
                                                                type="text"
                                                                ref={this.code}
                                                                placeholder="Pass Code"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className="btn btn-lg btn-thm"
                                                                onClick={() => this.handleHasCode()}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className="btn dbxshad btn-lg btn-thm white"
                                                                onClick={(e) => this.handleNoCode(e)}
                                                            >
                                                                I Don't Have Code
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                {showHideProfileInfo && (
                                    <Profile
                                        user_id={this.props.userInfo.id}
                                        handler={this.hideComponent}
                                    />
                                )}
                                {showHideQuestionnaire && (
                                    <Question user_id={this.props.userInfo && this.props.userInfo.id}  headerBar={this.headerBar}/>
                                )}
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div className="contact_localtion"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <Site />
                <Footer />
            </div>
        );
    }
}

export default ToshaClub;
