import React, { Component } from 'react';
import Request from './Request';
import UUID from './UUID';
import $ from 'jquery';

const readyComponents = { Request, UUID };
let usingComponents = {};

function Utils(WrappedComponent) {
    return class extends Component {
        render(): React.ReactNode {
            const props = { ...this.props, ...usingComponents };
            return <WrappedComponent {...props} />;
        }
    };
}

export function WithUtils() {
    const args =
        arguments.length > 1 ? arguments : arguments.length <= 0 ? undefined : arguments[0];
    if (typeof args === 'string') {
        if (~Object.keys(readyComponents).indexOf(args)) {
            usingComponents[args] = readyComponents[args];
        }
    }
    if (typeof args === 'object') {
        if (Array.isArray(args)) {
            for (const arg of args) {
                if (~Object.keys(readyComponents).indexOf(arg)) {
                    usingComponents[arg] = readyComponents[arg];
                }
            }
        } else {
            /**
             * @TODO 做对象处理
             * */
        }
    }
    if (typeof args === 'undefined') {
        usingComponents = readyComponents;
    }
    return Utils;
}

function Tools(WrappedComponent) {
    return class extends Component {
        render(): React.ReactNode {
            const props = { ...this.props, ...usingComponents };
            return <WrappedComponent {...props} />;
        }
    };
}

export function WithTools(func) {
    return function (WrappedComponent) {
        WrappedComponent.prototype[func] = readyComponents[func];
        return class extends Component {
            render(): React.ReactNode {
                return <WrappedComponent />;
            }
        };
    };
}

export function getUrlParams(name, str) {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return null;
}

export function getGooglePlaceId(zipCode) {    
    const headers = {
        "X-Goog-Api-Key": "AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk",
        "Content-Type": "application/json"
    };
    const reqData = {
        identifiers: [
            {
                place: zipCode,
                place_type: "postal_code",
                region_code: "us",
                language_code: "en"
            },
        ],
    };
    let result = '';
    let res = '';
    $.ajax({
        url: 'https://regionlookup.googleapis.com/v1alpha:lookupRegion',
        type: 'POST',
        datatype: 'jsonp',
        headers,
        async: false,
        data: JSON.stringify(reqData),
        success: function(data) {
            res = data;
        }
    })

    if(res && res.matches && res.matches.length > 0) {
        result = res.matches[0].matchedPlaceId;
    }
    // const ret = await Request({
    //     baseURL: '',
    //     url: 'https://regionlookup.googleapis.com/v1alpha:lookupRegion',
    //     method: 'POST',
    //     headers,
    //     data: reqData
    // }).then((res) => {
    //     if(res) {
    //         return res.data
    //     }
    //     return false
    // });
    // if(ret && ret.matches && ret.matches.length > 0) {
    //     result = ret.matches[0].matchedPlaceId;
    // }
    return result;
}

export function setJSAPI(option = {
    title: 'TopSky Home',
    desc: 'Helping you find your property!',
    link: window.location.href,
    imgUrl: 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/33/webinar_1701829442_387f658b458a230ae504.jpeg'
}) {
    // if(window.location.href.indexOf('detail.html') > -1) {
    //     option.link = window.location.href + '&flag=' + global_params.user_info.flag;
    //     if($('#name').length > 0 && $('#name').html()) {
    //         option.desc = $('#name').html();
    //     }
    //     if($('img')[0]) {
    //         option.imgUrl = $($('img')[0]).attr('src');
    //     }
    // }
    let domain = window.location.host.slice(window.location.host.indexOf('.') + 1);
    let request_domain = 'topskyrealty.com';
    const siteHost = 'https://v1.' + request_domain + '/';
    const headers = {
        "X-Goog-Api-Key": "AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk",
        "Content-Type": "application/json"
    };
    
    $.ajax({
        url: siteHost + 'api/wechat/sdk/config?url=' + window.location.href,
        type: 'GET',
        datatype: 'jsonp',
        headers,
        async: false,
        // data: JSON.stringify({}),
        // data: {
        //     url: window.location.href
        // },
        success: function(res) {
            // $.getJSON( siteHost + 'customer/sign', function (res) {
            
            const e = res.data;
            wx.config({
                //beta: false,
                debug: false,
                appId: e.appId,
                timestamp: e.timestamp,
                nonceStr: e.nonceStr,
                signature: e.signature,
                jsApiList: [
                    'updateTimelineShareData',
                    'updateAppMessageShareData',
                    'onMenuShareAppMessage',
                    'onMenuShareTimeline'
                    //'onMenuShareQQ',
                    //'onMenuShareWeibo',
                    //'onMenuShareQZone',
                    // 'setNavigationBarColor',
                    //'setBounceBackground'
                ]
            });
            
            wx.ready(function () {
                console.log('jssdk调用成功，不影响使用')
                //$.ys.func.message('jssdk调用成功，不影响使用' + $('#name').html());
                //wx.invoke('setNavigationBarColor', {
                //    color: '#F8F8F8'
                //});
                //
                //wx.invoke('setBounceBackground', {
                //    'backgroundColor': '#F8F8F8',
                //    'footerBounceColor': '#F8F8F8'
                //});
                wx.updateTimelineShareData(option);
                wx.updateAppMessageShareData(option);
                try{
                    wx.onMenuShareAppMessage(option)
                    wx.onMenuShareTimeline(option)
                }
                catch(e){}
                //wx.onMenuShareQQ(option);
            });
            
            wx.error(function(res){
                console.log(res);
                //alert(res);
                //$.ys.func.message('jssdk调用失败，不影响使用');
            });
        }
    });
}