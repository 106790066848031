import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import licenseImg from '../../assets/images/license/sell.jpg';
import {toast} from "react-toastify";

class sell extends React.Component<Props, State> {
    state: State = {
        address: '',
        city: '',
        state: '',
        zip: '',
        name: '',
        phone: '',
        email: '',
    };

    handleSubmit(e) {
        e.preventDefault();
        const form = this.state;
        Request({
            url: '/sell/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    toast(data.message);
                    // message.success({
                    //     content: data.message,
                    //     duration: 3,
                    //     className: 'success',
                    // });
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="ml-5 mr-5">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="terms_condition_grid">
                                                <div className="grids mb30 text-left pl-5 pr-5 ml-5 mr-5">
                                                    <div className="container">
                                                        <h5 className="color-primary">
                                                            List your property with TopSky via MLS or as an off-market deal, we have the listing experts and ready buyers.
                                                            Various listing packages, one with the best rate of 1%-2% commission to TopSky Broker
                                                        </h5>

                                                        <h6 className="color-golden">
                                                            Enter your address. See how much your
                                                            house is worth,
                                                        </h6>
                                                        <h6 className="color-golden">
                                                            receive a free in-depth report.
                                                        </h6>
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => this.handleSubmit(e)}
                                                        method="post"
                                                    >
                                                        <div className="form1 form-container container">
                                                            <div className="row">
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="address"
                                                                        className="label-text"
                                                                    >
                                                                        Address
                                                                    </label>
                                                                    <input
                                                                        id="address"
                                                                        name="address"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.address}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12 col-md-4">
                                                                    <label
                                                                        htmlFor="city"
                                                                        className="label-text"
                                                                    >
                                                                        City
                                                                    </label>
                                                                    <input
                                                                        id="city"
                                                                        name="city"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.city}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12 col-md-4">
                                                                    <label
                                                                        htmlFor="state"
                                                                        className="label-text"
                                                                    >
                                                                        State
                                                                    </label>
                                                                    <input
                                                                        id="state"
                                                                        name="state"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.state}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12 col-md-4">
                                                                    <label
                                                                        htmlFor="zip"
                                                                        className="label-text"
                                                                    >
                                                                        Zip
                                                                    </label>
                                                                    <input
                                                                        id="zip"
                                                                        name="zip"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.zip}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="name"
                                                                        className="label-text"
                                                                    >
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        id="name"
                                                                        name="name"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.name}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="phone"
                                                                        className="label-text"
                                                                    >
                                                                        Telephone number
                                                                    </label>
                                                                    <input
                                                                        id="phone"
                                                                        name="phone"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        value={this.state.phone}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="email"
                                                                        className="label-text"
                                                                    >
                                                                        Email
                                                                    </label>
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        type="email"
                                                                        className="form-control"
                                                                        required={true}
                                                                        value={this.state.email}
                                                                        onChange={(e) =>
                                                                            this.handleChange(e)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn2 btn-block w-100"
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="grids mb30"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div
                                    style={{
                                        backgroundImage: `url(${licenseImg})`,
                                        backgroundSize: 'cover',
                                    }}
                                    className="w-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default sell;
