import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import GoogleMap from 'shared/components/GoogleMap/GoogleMap';
import { connect } from 'react-redux';
import Select from '../../components/Select/Select';
import Request from '../../components/Utils/Request';
import AutoComplete from 'react-google-autocomplete';
import { Collapse } from "antd";
import {types} from 'shared/components/Utils/TypesData'
import {toast} from "react-toastify";

const { Panel } = Collapse;

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageMFloorplanAdd extends React.Component<Props, State> {
    state: State = {
        form: {
            user_entered: this.props.userInfo.id,
            name: '',
            community_id: 0,
            home_type: '',
            price: 0,
            stories: 0,
            bedroom_num: 0,
            bathroom_num: 0,
            garage: 0,
            size: 0,
            lot_size: 0,
            current_rental: 0,
            earnest_money: 0,
            notes: '',
            description: ''
        },
        images: [],
        communities: []
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };

    queryImages = () => {
        if (this.state.form.id)
        Request({
            url: '/image',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                parent_id: this.state.form.id,
                image_type: 'FLOORPLAN',
            },
        }).then((res) => {
            this.setState({
                images: res.data.data
            })
        });
    };

    upload = async (file) => {
        const formData = new FormData();
        formData.append('parent_id', this.state.form.id);
        formData.append('image_type', 'FLOORPLAN');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data
        });
        this.queryImages();
    };

    getCommunities = async () => {
        Request({
            url: '/community',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 5);
            this.setState({
                communities: res.data.map(item => {
                    return {
                        ...item,
                        value: item.id
                    }
                })
            });
        });
    };
    
    submit = () => {
        const form = this.state.form;
        Request({
            url: '/floorplan',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then((res) => {            
            if (res.data.status == 200) {
                // 200的状态值就进行跳转
                toast(res.message || 'Add the floorplan successfully!')
                this.props.history.push({
                    pathname: '/PageMFloorplans'
                })
            }

        });
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.getCommunities().then()
            this.queryImages()
        }
    }

    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form, images, communities } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Add New Floorplan</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Create Listing</h4>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Floorplan Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={form.name}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        name: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Community</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="form-control"
                                                            title="Select a community"
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        community_id: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={communities}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Home Type
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.home_type}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        home_type: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb30">
                                                    <Collapse defaultActiveKey={['0']} ghost>
                                                        <Panel header="More" key="1">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Price
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.price}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        price: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Purchase restriction
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={form.listing_price}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        listing_price: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Stories
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.stories}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        stories: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Bedrooms
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.bedroom_num}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        bedroom_num: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Bathrooms
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.bathroom_num}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        bathroom_num: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Garage
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.garage}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        garage: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Size(sq)
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.size}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        size: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Lot(sq)
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.lot_size}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        lot_size: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Current rental
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.current_rental}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        current_rental: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Earnest Money
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.earnest_money}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        earnest_money: parseFloat(e.target.value),
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="my_profile_setting_textarea">
                                                                        <label htmlFor="propertyDescription">
                                                                            Notes
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="propertyDescription"
                                                                            rows="7"
                                                                            value={form.notes}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        notes: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="my_profile_setting_textarea">
                                                                        <label htmlFor="propertyDescription">
                                                                            Additional information
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="propertyDescription"
                                                                            rows="7"
                                                                            value={form.description}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        description: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-12">
                                                                    <div className="my_profile_setting_textarea">
                                                                        <label htmlFor="propertyDescription">
                                                                            Description
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="propertyDescription"
                                                                            rows="7"
                                                                            value={form.description}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        description: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                */}
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                if (typeof form.id !== 'undefined') {
                                                    return (
                                                        <div className="my_dashboard_review mt30">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="mb30">Property media</h4>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <ul className="mb0">
                                                                        {
                                                                            images?.map(item => {
                                                                                return (
                                                                                    <li className="list-inline-item" key={item.id}>
                                                                                        <div className="portfolio_item">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={item.src}
                                                                                                alt="404"
                                                                                            />
                                                                                            <div
                                                                                                className="edu_stats_list"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Delete"
                                                                                                data-original-title="Delete"
                                                                                            >
                                                                                                <a onClick={() => {

                                                                                                }}>
                                                                                                    <span className="flaticon-garbage" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="portfolio_upload" onClick={() => {
                                                                        document.getElementById('myfile').click()
                                                                    }}>
                                                                        <input type="file" id="myfile" multiple="multiple" onChange={async (e) => {
                                                                            for (const file of e.currentTarget.files) {
                                                                                await this.upload(file)
                                                                            }
                                                                        }}/>
                                                                        <div className="icon">
                                                                            <span className="flaticon-download" />
                                                                        </div>
                                                                        <p>Drag and drop images here</p>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-xl-6">*/}
                                                                {/*<div className="resume_uploader mb30">*/}
                                                                {/*<h4>Attachments</h4>*/}
                                                                {/*<form className="form-inline">*/}
                                                                {/*<input className="upload-path" />*/}
                                                                {/*<label className="upload">*/}
                                                                {/*<input type="file" />*/}
                                                                {/*Select Attachment*/}
                                                                {/*</label>*/}
                                                                {/*</form>*/}
                                                                {/*</div>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-xl-12">*/}
                                                                    {/*<div className="my_profile_setting_input">*/}
                                                                        {/*<button className="btn btn2 float-right" onClick={() => {*/}
                                                                            {/*this.submit()*/}
                                                                        {/*}}>*/}
                                                                            {/*Submit*/}
                                                                        {/*</button>*/}
                                                                    {/*</div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMFloorplanAdd;
