import React from "react";
import { Props, State } from './interface';
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Footer from "../../components/Footer/Footer";
import Site from "../../components/Site/Site";
import Agent from "../../components/Agent/Agent";
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import './calculator.less';

@connect(({ compare, userInfo }) => {
    return { compare, userInfo };
})
class Calculator extends React.Component<Props, State> {
    state: State = {
        purchasePrice: 0,
        propertyTaxRate: 0,
        mortgageRes: 0,
        downpayment: 0,
        downpaymentRes: 0,
        termYear: 0,
        interest: 0,
        mortgagePaymentRes: 0,
        closingCost: 0,
        cashInvestedRes: 0,
        rentMonthly: 0,
        rentMonthlyRes: 0,
        vacancy: 0,
        vacancyRes: 0,
        insurance: 0,
        insuranceRes: 0,
        hoa: 0,
        hoaRes: 0,
        pmFee: 0,
        pmFeeRes: 0,
        utility: 0,
        utilityRes: 0,
        repair: 0,
        repairRes: 0,
        housingAppreciation: 0,
        housingAppreciationRes: 0,
        rentIncresePercent: 0,
        
        rHousingPrice: [0,0,0,0,0,0],
        rMortgageBalance: [0,0,0,0,0,0],
        rRentalIncome: [0,0,0,0,0,0],
        rRentalIncomeMonthly: [0,0,0,0,0,0],
        rPropertyTax: [0,0,0,0,0,0],
        rVacancy: [0,0,0,0,0,0],
        rInsurance: [0,0,0,0,0,0],
        rHOA: [0,0,0,0,0,0],
        rPMFee: [0,0,0,0,0,0],
        rUtility: [0,0,0,0,0,0],
        rRepair: [0,0,0,0,0,0],
        rTotal: [0,0,0,0,0,0],
        rNetOpIncome: [0,0,0,0,0,0],
        rCapRate: [0,0,0,0,0,0],
        rInterestExpense: [0,0,0,0,0,0],
        rNetIncome: [0,0,0,0,0,0],
        rPrincipalPayment: [0,0,0,0,0,0],
        rCashFlow: [0,0,0,0,0,0],
        rCashOnCash: [0,0,0,0,0,0],
        rNetIncomeMonthly: [0,0,0,0,0,0],
        rCashFlowMonthly: [0,0,0,0,0,0],
        rGrainFromAppreciation: [0,0,0,0,0,0],
        rTotalGain: [0,0,0,0,0,0],
        rTotalReturn: [0,0,0,0,0,0]
    };
    setValue(e): void {
        let self = this;
        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [name]: value
        });
        switch(name) {
            case 'insurance':
                this.setState({
                    insuranceRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'insuranceRes':
                this.setState({
                    insurance: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
            
            case 'hoa':
                this.setState({
                    hoaRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'hoaRes':
                this.setState({
                    hoa: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
        
            case 'pmFee':
                this.setState({
                    pmFeeRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'pmFeeRes':
                this.setState({
                    pmFee: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
            
            case 'utility':
                this.setState({
                    utilityRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'utilityRes':
                this.setState({
                    utility: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
            
                case 'repair':
                this.setState({
                    repairRes: this.globalFormatFixed(this.state.rentMonthly * value / 100)
                })
                break;
            case 'repairRes':
                this.setState({
                    repair: this.globalFormatFixed(100 * value / this.state.rentMonthly)
                })
                break;
        }

        setTimeout(function() {
            self.calc();
        }, 10)
    };
    globalFormatFixed(value) {
        let formatValue = Math.abs(Number(value)).toFixed(2);
        return Number(formatValue);
    };
    calc() {
        const {purchasePrice, propertyTaxRate, downpayment, termYear, interest, mortgagePaymentRes, closingCost, cashInvestedRes, rentMonthly, vacancy, insurance, hoa, pmFee, utility, repair, housingAppreciation, rentIncresePercent} = this.state;
        let mortgagePaymentR = Math.ceil(-this.pmt(interest/1200, termYear*12, purchasePrice*(100-downpayment)/100, 0, 0));
        let cashInvestedR = purchasePrice*downpayment/100 + Number(closingCost);
        this.setState({
            mortgagePaymentRes: mortgagePaymentR,
            cashInvestedRes: cashInvestedR
        })

        let key1 = 1 + housingAppreciation / 100;
        // 等另外一个参数设置 rPrincipalPayment
        let key2 = 1 - downpayment / 100;
        
        let key3 = 1 + rentIncresePercent / 100;

        let rHousingPrice_arr = [purchasePrice];//, purchasePrice*key1, purchasePrice*key1*key1, purchasePrice*key1*key1*key1, purchasePrice*key1*key1*key1*key1, purchasePrice*key1*key1*key1*key1*key1];
        let rent = rentMonthly * 12;
        let rRentalIncome_arr = [rent]; //, rent*key3, rent*key3*key3, rent*key3*key3*key3, rent*key3*key3*key3*key3, 0];
        let rRentalIncomeMonthly_arr = [rentMonthly];
        for(let i = 1; i < 11; i++) {
            rHousingPrice_arr.push(rHousingPrice_arr[i-1] * key1);
            rRentalIncome_arr.push(rRentalIncome_arr[i-1] * key3);
            rRentalIncomeMonthly_arr.push(rRentalIncomeMonthly_arr[i-1] * key3);
        }

        let rPropertyTax_arr = rHousingPrice_arr.map(item => { return item * propertyTaxRate / 100; });
        let rVacancy_arr = rRentalIncome_arr.map(item => { return item * vacancy / 100; });
        let rInsurance_arr = rRentalIncome_arr.map(item => { return item * insurance / 100; });
        let rHOA_arr = rRentalIncome_arr.map(item => { return item * hoa / 100; });
        let rPMFee_arr = rRentalIncome_arr.map(item => { return item * pmFee / 100; });
        let rUtility_arr = rRentalIncome_arr.map(item => { return item * utility / 100; });
        let rRepair_arr = rRentalIncome_arr.map(item => { return item * repair / 100; });
        let rTotal_arr = [0,0,0,0,0,0,0,0,0,0,0];// Operating Expense
        let rNetOpIncome_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rCapRate_arr = [0,0,0,0,0,0,0,0,0,0,0];

        for(let i = 0; i < 11; i++) {
            rTotal_arr[i] = rPropertyTax_arr[i] + rVacancy_arr[i] + rInsurance_arr[i] + rHOA_arr[i] + rPMFee_arr[i] + rUtility_arr[i] + rRepair_arr[i];
            rNetOpIncome_arr[i] = rRentalIncome_arr[i] - rTotal_arr[i];
            rCapRate_arr[i] = purchasePrice != 0 ? rNetOpIncome_arr[i] / purchasePrice * 100 : 0;
        }
        /**
         * 手工计算的部分，找一下规律
         */
        let rMortgageBalance_arr = [0,0,0,0,0,0,0,0,0,0,0]; // 需放在后面计算，TODO
        let rInterestExpense_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rNetIncome_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rPrincipalPayment_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rCashFlow_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rCashOnCash_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rNetIncomeMonthly_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rCashFlowMonthly_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rGrainFromAppreciation_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rTotalGain_arr = [0,0,0,0,0,0,0,0,0,0,0];
        let rTotalReturn_arr = [0,0,0,0,0,0,0,0,0,0,0];
        rMortgageBalance_arr[0] = rHousingPrice_arr[0] * key2;
        for(let i = 0; i < 11; i++) {
            if(i > 0) {
                rMortgageBalance_arr[i] = rMortgageBalance_arr[i-1] - rPrincipalPayment_arr[i-1];
            }
            rInterestExpense_arr[i] = rMortgageBalance_arr[i] * interest / 100;
            rPrincipalPayment_arr[i] = mortgagePaymentR * 12 - rInterestExpense_arr[i];
            
            rNetIncome_arr[i] = rNetOpIncome_arr[i] - rInterestExpense_arr[i];
            rCashFlow_arr[i] = rNetIncome_arr[i] - rPrincipalPayment_arr[i];
            rCashOnCash_arr[i] = cashInvestedR != 0 ? rCashFlow_arr[i] / cashInvestedR * 100 : 0;
            rNetIncomeMonthly_arr[i] = rNetIncome_arr[i] / 12;
            rCashFlowMonthly_arr[i] = rCashFlow_arr[i] / 12;
        }
        for(let i = 0; i < 10; i++) {
            rGrainFromAppreciation_arr[i] = rHousingPrice_arr[i+1] - rHousingPrice_arr[i];
            rTotalGain_arr[i] = rGrainFromAppreciation_arr[i] + rNetIncome_arr[i];
            rTotalReturn_arr[i] = cashInvestedR !=0 ? rTotalGain_arr[i] / cashInvestedR * 100 : 0;
        }
        
        // rInterestExpense_arr[0] = rMortgageBalance_arr[0] * interest / 100;
        // rNetIncome_arr[0] = rNetOpIncome_arr[0] - rInterestExpense_arr[0];
        // rPrincipalPayment_arr[0] = rInterestExpense_arr[0] * 12 - mortgagePaymentRes;

        this.setState({
            rHousingPrice: rHousingPrice_arr,
            rMortgageBalance: rMortgageBalance_arr,

            rRentalIncome: rRentalIncome_arr,
            rRentalIncomeMonthly: rRentalIncomeMonthly_arr,//[rentMonthly, rentMonthly*key3, rentMonthly*key3*key3, rentMonthly*key3*key3*key3, rentMonthly*key3*key3*key3*key3, 0],
            rPropertyTax: rPropertyTax_arr,
            rVacancy: rVacancy_arr,
            rInsurance: rInsurance_arr,
            rHOA: rHOA_arr,
            rPMFee: rPMFee_arr,
            rUtility: rUtility_arr,
            rRepair: rRepair_arr,
            rTotal: rTotal_arr,
            rNetOpIncome: rNetOpIncome_arr,
            rCapRate: rCapRate_arr,
            rInterestExpense: rInterestExpense_arr,
            rNetIncome: rNetIncome_arr,
            rPrincipalPayment: rPrincipalPayment_arr,
            rCashFlow: rCashFlow_arr,
            rCashOnCash: rCashOnCash_arr,
            rNetIncomeMonthly: rNetIncomeMonthly_arr,
            rCashFlowMonthly: rCashFlowMonthly_arr,
            rGrainFromAppreciation: rGrainFromAppreciation_arr,
            rTotalGain: rTotalGain_arr,
            rTotalReturn: rTotalReturn_arr
        });
    }
    pmt(rate_per_period, number_of_payments, present_value, future_value, type) {
        if(rate_per_period != 0.0){
            // Interest rate exists
            var q = Math.pow(1 + rate_per_period, number_of_payments);
            return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));
    
        } else if(number_of_payments != 0.0){
            // No interest rate, but number of payments exists
            return -(future_value + present_value) / number_of_payments;
        }
    
        return 0;
    };
    pmt3(i, n, p) {
        return i * p * Math.pow((1 + i), n) / (1 - Math.pow((1 + i), n));
    }
    pmt1(ir, np, pv, fv, type) {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        var pmt, pvif;
    
        fv || (fv = 0);
        type || (type = 0);
    
        if (ir === 0)
            return -(pv + fv)/np;
    
        pvif = Math.pow(1 + ir, np);
        pmt = - ir * (pv * pvif + fv) / (pvif - 1);
    
        if (type === 1)
            pmt /= (1 + ir);
    
        return pmt;
    }
    
    render() {
        const {purchasePrice, propertyTaxRate, downpayment, termYear, interest, mortgagePaymentRes, closingCost, cashInvestedRes, rentMonthly, vacancy, insurance, insuranceRes, hoa, hoaRes, pmFee, pmFeeRes, utility, utilityRes, repair, repairRes, housingAppreciation, rentIncresePercent, rHousingPrice, rMortgageBalance, rRentalIncome, rRentalIncomeMonthly, rPropertyTax, rVacancy, rInsurance, rHOA, rPMFee, rUtility, rRepair, rTotal, rNetOpIncome, rCapRate, rInterestExpense, rNetIncome, rPrincipalPayment, rCashFlow, rCashOnCash, rNetIncomeMonthly, rCashFlowMonthly, rGrainFromAppreciation, rTotalGain, rTotalReturn} = this.state;
        const formatFixed = value => {
            let formatValue = Math.abs(Number(value)).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
            if (value < 0) {
                formatValue = "(" + formatValue.replace('-', '') + ")";
            }
            return formatValue;
        }
        const formatFixedRate = value => {
            let formatValue = Number(value).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
            return formatValue;
        }

        return (
            <div className="wrapper" style={{
                paddingTop: '60px'
            }}>
                <HeaderBar animate={false}/>
                <section className="our-terms bgc-f7">
                    <div className="calc-container">
                        <div className="row" style={{
                            width: '100%',
                            overflowX: 'hidden',
                            margin: '0'
                        }}>
                            <div className="col-lg-12 col-xl-12 mb30" style={{
                                padding: '0'
                            }}>
                                <div className="terms_condition_grid">
                                    <div className="grids mb30">
                                        <h4>Calculator</h4> 
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-xl-4 col-sm-12 table-responsive-sm">
                                            <table className="table table-bordered calc-table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="col">House</th>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Purchase Price</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="purchasePrice" value={purchasePrice} onChange={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Property Tax Rate</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="propertyTaxRate" value={propertyTaxRate} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="table table-bordered calc-table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="col">Mortgage</th>
                                                        <td></td>
                                                        <th>${ formatFixed(purchasePrice - purchasePrice*downpayment/100) }</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Downpayment</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="downpayment" value={downpayment} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>${ formatFixed(purchasePrice*downpayment/100) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Term(Year)</td>
                                                        <td>
                                                            <input type="text" className="form-control" name="termYear" value={termYear} onChange={(e)=> {this.setValue(e)}} />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>Interest(%)</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="interest" value={interest} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mortgage Payment</td>
                                                        <td></td>
                                                        <td>
                                                            {/* <input type="text" className="form-control" name="mortgagePaymentRes" value={mortgagePaymentRes} onChange={(e)=> {this.setValue(e)}} /> */}
                                                            ${formatFixed(mortgagePaymentRes)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Closing Cost</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="closingCost" value={closingCost} onChange={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cash Invested</td>
                                                        <td>
                                                            {/* <input type="text" className="form-control" name="cashInvestedRes" value={cashInvestedRes} onChange={(e)=> {this.setValue(e)}} /> */}
                                                        </td>
                                                        <td>${ formatFixed(cashInvestedRes) }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-4 col-xl-4 col-sm-12">
                                            <table className="table table-bordered calc-table">
                                                <tbody>
                                                    <tr>
                                                        <th>Rent(% of Rent)</th>
                                                        <td></td>
                                                        <td>Monthly</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rent(Monthly)</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="rentMonthly" value={rentMonthly} onChange={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                        </td>
                                                        <td>${ formatFixed(rentMonthly) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vacancy</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="vacancy" value={vacancy} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>${ formatFixed(rentMonthly * vacancy / 100) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Insurance</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="insurance" value={insurance} onInput={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="insuranceRes" value={insuranceRes} onInput={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                        {/* ${ formatFixed(rentMonthly * insurance / 100) } */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>HOA</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="hoa" value={hoa} onInput={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="hoaRes" value={hoaRes} onInput={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                            {/* ${ formatFixed(rentMonthly * hoa / 100) } */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>PM Fee</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="pmFee" value={pmFee} onInput={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="pmFeeRes" value={pmFeeRes} onInput={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                            {/* ${ formatFixed(rentMonthly * pmFee / 100) } */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Utility</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="utility" value={utility} onInput={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="utilityRes" value={utilityRes} onInput={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                            {/* ${ formatFixed(rentMonthly * utility / 100) } */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Repair and Maintanance</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="repair" value={repair} onInput={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control" name="repairRes" value={repairRes} onInput={(e)=> {this.setValue(e)}} />
                                                            </div>
                                                            {/* ${ formatFixed(rentMonthly * repair / 100) } */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-4 col-xl-4 col-sm-12">
                                            <table className="table table-bordered calc-table">
                                                <tbody>
                                                    <tr>
                                                        <th>Assumption</th>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Housing Appreciation</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="housingAppreciation" value={housingAppreciation} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rent Increse Percent</td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" name="rentIncresePercent" value={rentIncresePercent} onChange={(e)=> {this.setValue(e)}} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-bordered calc-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Rent/Purchase Ratio</td>
                                                        <td></td>
                                                        <td>{formatFixed(purchasePrice ? rentMonthly/purchasePrice*100 : 0)}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                            <a type="primary" htmlType="submit" href="#tipResult">
                                                Submit
                                            </a>
                                        </Form.Item>
                                    </div> */}
                
                                    <div className="grids mb30"> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-12" id="tipResult">
                                <div className="terms_condition_grid">
                                    <div className="grids mb30">
                                        <h4>Result</h4> 
                                    </div>
                                    <table className="table table-bordered res-table">
                                        <tbody>
                                            <tr>
                                                <td colspan="2">Housing Price</td>
                                                <td>${formatFixed(rHousingPrice[0])}</td>
                                                <td>${formatFixed(rHousingPrice[1])}</td>
                                                <td>${formatFixed(rHousingPrice[2])}</td>
                                                <td>${formatFixed(rHousingPrice[3])}</td>
                                                <td>${formatFixed(rHousingPrice[4])}</td>
                                                <td>${formatFixed(rHousingPrice[5])}</td>
                                                <td>${formatFixed(rHousingPrice[10])}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Mortgage Balance</td>
                                                <td>${formatFixed(rMortgageBalance[0])}</td>
                                                <td>${formatFixed(rMortgageBalance[1])}</td>
                                                <td>${formatFixed(rMortgageBalance[2])}</td>
                                                <td>${formatFixed(rMortgageBalance[3])}</td>
                                                <td>${formatFixed(rMortgageBalance[4])}</td>
                                                <td>${formatFixed(rMortgageBalance[5])}</td>
                                                <td>${formatFixed(rMortgageBalance[10])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Income Statement</th>
                                                <td>Year 0</td>
                                                <td>Year 1 End</td>
                                                <td>Year 2 End</td>
                                                <td>Year 3 End</td>
                                                <td>Year 4 End</td>
                                                <td>Year 5 End</td>
                                                <td>Year 10 End</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Operating Income</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Rental Income</th>
                                                <td></td>
                                                <td>${formatFixed(rRentalIncome[0])}</td>
                                                <td>${formatFixed(rRentalIncome[1])}</td>
                                                <td>${formatFixed(rRentalIncome[2])}</td>
                                                <td>${formatFixed(rRentalIncome[3])}</td>
                                                <td>${formatFixed(rRentalIncome[4])}</td>
                                                <td>${formatFixed(rRentalIncome[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Rental Income(Monthly)</th>
                                                <td></td>
                                                <td>${formatFixed(rRentalIncomeMonthly[0])}</td>
                                                <td>${formatFixed(rRentalIncomeMonthly[1])}</td>
                                                <td>${formatFixed(rRentalIncomeMonthly[2])}</td>
                                                <td>${formatFixed(rRentalIncomeMonthly[3])}</td>
                                                <td>${formatFixed(rRentalIncomeMonthly[4])}</td>
                                                <td>${formatFixed(rRentalIncomeMonthly[9])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Operating Expenses</th>
                                                <td></td><td></td><td></td><td></td><td></td><td></td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Property Tax</th>
                                                <td></td>
                                                <td>${formatFixed(rPropertyTax[0])}</td>
                                                <td>${formatFixed(rPropertyTax[1])}</td>
                                                <td>${formatFixed(rPropertyTax[2])}</td>
                                                <td>${formatFixed(rPropertyTax[3])}</td>
                                                <td>${formatFixed(rPropertyTax[4])}</td>
                                                <td>${formatFixed(rPropertyTax[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Vacancy</th>
                                                <td></td>
                                                <td>${formatFixed(rVacancy[0])}</td>
                                                <td>${formatFixed(rVacancy[1])}</td>
                                                <td>${formatFixed(rVacancy[2])}</td>
                                                <td>${formatFixed(rVacancy[3])}</td>
                                                <td>${formatFixed(rVacancy[4])}</td>
                                                <td>${formatFixed(rVacancy[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Insurance</th>
                                                <td></td>
                                                <td>${formatFixed(rInsurance[0])}</td>
                                                <td>${formatFixed(rInsurance[1])}</td>
                                                <td>${formatFixed(rInsurance[2])}</td>
                                                <td>${formatFixed(rInsurance[3])}</td>
                                                <td>${formatFixed(rInsurance[4])}</td>
                                                <td>${formatFixed(rInsurance[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>HOA</th>
                                                <td></td>
                                                <td>${formatFixed(rHOA[0])}</td>
                                                <td>${formatFixed(rHOA[1])}</td>
                                                <td>${formatFixed(rHOA[2])}</td>
                                                <td>${formatFixed(rHOA[3])}</td>
                                                <td>${formatFixed(rHOA[4])}</td>
                                                <td>${formatFixed(rHOA[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>PM Fee</th>
                                                <td></td>
                                                <td>${formatFixed(rPMFee[0])}</td>
                                                <td>${formatFixed(rPMFee[1])}</td>
                                                <td>${formatFixed(rPMFee[2])}</td>
                                                <td>${formatFixed(rPMFee[3])}</td>
                                                <td>${formatFixed(rPMFee[4])}</td>
                                                <td>${formatFixed(rPMFee[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Utility</th>
                                                <td></td>
                                                <td>${formatFixed(rUtility[0])}</td>
                                                <td>${formatFixed(rUtility[1])}</td>
                                                <td>${formatFixed(rUtility[2])}</td>
                                                <td>${formatFixed(rUtility[3])}</td>
                                                <td>${formatFixed(rUtility[4])}</td>
                                                <td>${formatFixed(rUtility[9])}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Repair and Maintanance</th>
                                                <td></td>
                                                <td>${formatFixed(rRepair[0])}</td>
                                                <td>${formatFixed(rRepair[1])}</td>
                                                <td>${formatFixed(rRepair[2])}</td>
                                                <td>${formatFixed(rRepair[3])}</td>
                                                <td>${formatFixed(rRepair[4])}</td>
                                                <td>${formatFixed(rRepair[9])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Total Operating Expense</th>
                                                <td></td>
                                                <td>${formatFixed(rTotal[0])}</td>
                                                <td>${formatFixed(rTotal[1])}</td>
                                                <td>${formatFixed(rTotal[2])}</td>
                                                <td>${formatFixed(rTotal[3])}</td>
                                                <td>${formatFixed(rTotal[4])}</td>
                                                <td>${formatFixed(rTotal[9])}</td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <th colspan="2">Net Operating Income</th>
                                                <td></td>
                                                <td>${formatFixed(rNetOpIncome[0])}</td>
                                                <td>${formatFixed(rNetOpIncome[1])}</td>
                                                <td>${formatFixed(rNetOpIncome[2])}</td>
                                                <td>${formatFixed(rNetOpIncome[3])}</td>
                                                <td>${formatFixed(rNetOpIncome[4])}</td>
                                                <td>${formatFixed(rNetOpIncome[9])}</td>
                                            </tr>
                                            <tr className="special">
                                                <th colspan="2">Cap Rate = NOI / Price</th>
                                                <td></td>
                                                <td>{formatFixed(rCapRate[0])}%</td>
                                                <td>{formatFixed(rCapRate[1])}%</td>
                                                <td>{formatFixed(rCapRate[2])}%</td>
                                                <td>{formatFixed(rCapRate[3])}%</td>
                                                <td>{formatFixed(rCapRate[4])}%</td>
                                                <td>{formatFixed(rCapRate[9])}%</td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <th colspan="2">Interest Expense (Simplified)</th>
                                                <td></td>
                                                <td>${formatFixed(rInterestExpense[0])}</td>
                                                <td>${formatFixed(rInterestExpense[1])}</td>
                                                <td>${formatFixed(rInterestExpense[2])}</td>
                                                <td>${formatFixed(rInterestExpense[3])}</td>
                                                <td>${formatFixed(rInterestExpense[4])}</td>
                                                <td>${formatFixed(rInterestExpense[9])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Net Income (Annual)</th>
                                                <td></td>
                                                <td>${formatFixed(rNetIncome[0])}</td>
                                                <td>${formatFixed(rNetIncome[1])}</td>
                                                <td>${formatFixed(rNetIncome[2])}</td>
                                                <td>${formatFixed(rNetIncome[3])}</td>
                                                <td>${formatFixed(rNetIncome[4])}</td>
                                                <td>${formatFixed(rNetIncome[9])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Principal Payment (Annual)</th>
                                                <td></td>
                                                <td>${formatFixed(rPrincipalPayment[0])}</td>
                                                <td>${formatFixed(rPrincipalPayment[1])}</td>
                                                <td>${formatFixed(rPrincipalPayment[2])}</td>
                                                <td>${formatFixed(rPrincipalPayment[3])}</td>
                                                <td>${formatFixed(rPrincipalPayment[4])}</td>
                                                <td>${formatFixed(rPrincipalPayment[9])}</td>
                                            </tr>
                                            <tr className="special">
                                                <th colspan="2">Cash Flow (Annual)</th>
                                                <td></td>
                                                <td>${formatFixed(rCashFlow[0])}</td>
                                                <td>${formatFixed(rCashFlow[1])}</td>
                                                <td>${formatFixed(rCashFlow[2])}</td>
                                                <td>${formatFixed(rCashFlow[3])}</td>
                                                <td>${formatFixed(rCashFlow[4])}</td>
                                                <td>${formatFixed(rCashFlow[9])}</td>
                                            </tr>
                                            <tr className="special">
                                                <th colspan="2">Cash on Cash Return</th>
                                                <td></td>
                                                <td>{formatFixedRate(rCashOnCash[0])}%</td>
                                                <td>{formatFixedRate(rCashOnCash[1])}%</td>
                                                <td>{formatFixedRate(rCashOnCash[2])}%</td>
                                                <td>{formatFixedRate(rCashOnCash[3])}%</td>
                                                <td>{formatFixedRate(rCashOnCash[4])}%</td>
                                                <td>{formatFixedRate(rCashOnCash[9])}%</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Net Income (Monthly)</td>
                                                <td></td>
                                                <td>${formatFixed(rNetIncomeMonthly[0])}</td>
                                                <td>${formatFixed(rNetIncomeMonthly[1])}</td>
                                                <td>${formatFixed(rNetIncomeMonthly[2])}</td>
                                                <td>${formatFixed(rNetIncomeMonthly[3])}</td>
                                                <td>${formatFixed(rNetIncomeMonthly[4])}</td>
                                                <td>${formatFixed(rNetIncomeMonthly[9])}</td>
                                            </tr>
                                            <tr className="special">
                                                <td colspan="2">Cash Flow (Monthly)</td>
                                                <td></td>
                                                <td>${formatFixed(rCashFlowMonthly[0])}</td>
                                                <td>${formatFixed(rCashFlowMonthly[1])}</td>
                                                <td>${formatFixed(rCashFlowMonthly[2])}</td>
                                                <td>${formatFixed(rCashFlowMonthly[3])}</td>
                                                <td>${formatFixed(rCashFlowMonthly[4])}</td>
                                                <td>${formatFixed(rCashFlowMonthly[9])}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Gain From Appreciation</td>
                                                <td></td>
                                                <td>${formatFixed(rGrainFromAppreciation[0])}</td>
                                                <td>${formatFixed(rGrainFromAppreciation[1])}</td>
                                                <td>${formatFixed(rGrainFromAppreciation[2])}</td>
                                                <td>${formatFixed(rGrainFromAppreciation[3])}</td>
                                                <td>${formatFixed(rGrainFromAppreciation[4])}</td>
                                                <td>${formatFixed(rGrainFromAppreciation[9])}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Total Gain/Loss</th>
                                                <td></td>
                                                <td>${formatFixed(rTotalGain[0])}</td>
                                                <td>${formatFixed(rTotalGain[1])}</td>
                                                <td>${formatFixed(rTotalGain[2])}</td>
                                                <td>${formatFixed(rTotalGain[3])}</td>
                                                <td>${formatFixed(rTotalGain[4])}</td>
                                                <td>${formatFixed(rTotalGain[9])}</td>
                                            </tr>
                                            <tr className="bold">
                                                <th colspan="2">Total Return = Total Gain / Cash Invested</th>
                                                <td></td>
                                                <td>{formatFixed(rTotalReturn[0])}%</td>
                                                <td>{formatFixed(rTotalReturn[1])}%</td>
                                                <td>{formatFixed(rTotalReturn[2])}%</td>
                                                <td>{formatFixed(rTotalReturn[3])}%</td>
                                                <td>{formatFixed(rTotalReturn[4])}%</td>
                                                <td>{formatFixed(rTotalReturn[9])}%</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Agent/>
                <Site />
                <Footer/>
            </div>
        );
    }
}

export default Calculator;
