import axios from 'axios';
import { message } from 'antd';
import {toast} from "react-toastify";

const service = axios.create({
    baseURL: process.env.API_BASE_URL || '/dev',
    timeout: 60 * 1000,
    headers: {
        'x-api-key': process.env.X_API_KEY
    }
})
let LOAD_STATUS = 0;
let loading = () => {};
async function request(option) {
    // loading = LOAD_STATUS == 0 ? message.loading({
    //     content: 'Requesting...',
    //     duration: 0
    // }) : loading;
    LOAD_STATUS++;
    try {
        const res = await service(option);
        //console.log("request_result", res);
        if (res.data) {
            if (res.data.status == 200) {
                // if (LOAD_STATUS == 1)
                /*message.success({
                    content: res.data.message ? res.data.message : 'success',
                    duration: 0.5,
                    className: 'success-notice-green',
                });*/
            } else {
                const msg = res.data.messages || res.data.message;
                if (msg) {
                    if (typeof msg === 'object') {
                        const text = Object.entries(msg)[0];
                        if(!option.isToast) {
                            toast.error(text[0] + ':' + text[1])
                            // message.error({
                            //     content: text[0] + ':' + text[1],
                            //     duration: 3,
                            //     className: 'error-notice-red',
                            // });
                        }
                    } else {
                        if(!option.isToast) {
                            toast.error(msg)
                            // message.error({
                            //     content: msg,
                            //     duration: 3,
                            //     className: 'error-notice-red',
                            // });
                        }
                    }
                }
            }
        }
        return res;
    } catch (e) {
        message.error({
            content: e,
            duration: 1,
            className: 'error-notice-red',
        });
    } finally {
        LOAD_STATUS--;
        if (LOAD_STATUS == 0) loading();
    }
}

export default request;
