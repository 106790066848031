import React, { useEffect }  from 'react';
import logo from '../../assets/home/header-logo.png';
import logo1 from '../../assets/home/header-logo2.png';
import login from '../../assets/images/resource/login.jpg';
import regstr from '../../assets/images/resource/regstr.jpg';

import Request from 'shared/components/Utils/Request';
import SubMenu from '../SubMenu/SubMenu';

import data from './HeaderMenuData';
import leftData from './HeaderLMenuData';
import {ChangeForm, HeaderBarProps, LoginForm, LoginModal, State} from './HeaderInterface';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux';
import {setUserInfo} from '../../store/userInfo/actions';
import {withRouter} from 'react-router';
import {message} from 'antd';
import 'shared/assets/css/notice.less';
import {SubmitModal} from 'components/SubmitModal/SubmitModal';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import './HeaderBar.less';
import moment from 'moment';
import * as Utils from 'components/Utils/Utils';

@withRouter
@connect(({userInfo}) => {
    return {userInfo};
})
@withTranslation()
class HeaderBar extends React.Component<HeaderBarProps, State> {
    state: State = {
        pageScrollTop: 0,
        menuList: data,
        leftMenuList: leftData,
        loginModal: {
            show: false,
            syncShow: false,
        },
        modalIsLogin: true,
        resetModal: {
            show: false,
            syncShow: false,
            modalFor: 'reset_password',
        },
        email: '',
        code: '',
        changeForm: {
            email: '',
            new_password: '',
            new_password_conf: '',
        },
        form: {
            email: '',
            password: '',
            passConfirm: '',
            role: 'USER',
        },
        userInfo: this.props.userInfo ? this.props.userInfo : {},
        isCheck: true,
        isJoinCheck: false,
        errors: {},
        live_stream_info: {
            id: '',
            parent_id: '',
            video_type: '',
            description: '',
            title: '',
            file_name:'',
            url: '',
            source: '',
            file_type: '',
            city: '',
            state: '',
            created_at: '',
            user_created: '',
            date_recorded: '',
            updated_at: ''
        },
        time_info: {
            day: '',
            hour: '',
            minutes: '',
            seconds: ''
        },
        is_streaming_event: false,
        
        codeModal: {
            show: false,
            syncShow: false
        },
        codeModalMessage: '',
        urlParams: {
            event: ''
        }
    };

    setModalIsLogin = (modalIsLogin: boolean) => {
        this.setState({modalIsLogin});
    };

    setForm = (form: LoginForm) => {
        this.setState({form});
    };
    setChangeForm = (changeForm: ChangeForm) => {
        this.setState({changeForm});
    };

    /**
     * @param props 输入参数
     * */
    constructor(props) {
        super(props);
        // console.log("currentPath==>", this.props.location.pathname);
        this.handleScroll.bind(this);
        let event = Utils.getUrlParams('event', props.location.search) || '';
        this.state.urlParams = {
            event
        };
    }

    setLoginModal = (loginModal: LoginModal) => {
        this.setState({loginModal});
        this.props.setModalStatus && this.props.setModalStatus(loginModal, this.state.modalIsLogin);
    };

    setLoginShow() {
        this.setLoginModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setLoginModal({
                show: true,
                syncShow: true,
            });
        }, 100);
    }

    closeLoginModal = (type): void => {
        if((this.props.location.pathname=="/PageMarket" ||
            this.props.location.pathname=="/PageTopSkyClub" ||
            this.props.location.pathname=="/PageDataByZip" ||
            this.props.location.pathname=="/PageDataByMetro"
        ) && type != 'reset'){
            console.log("PageMarket page not logined", this.state.userInfo);
            if (!this.props.userInfo) {
                console.log("PageMarket page not logined");
                window.location.href="/";
            }
        }
        this.setLoginModal({
            show: true,
            syncShow: false,
        });
        this.setForm({
            ...this.state.form,
            role: 'USER',
        });
        setTimeout(() => {
            this.setLoginModal({
                show: false,
                syncShow: false,
            });
        }, 300);
    };

    setResetModal = (resetModal: any) => {
        this.setState((prevState: State) => ({
            ...prevState,
            resetModal: {
                ...prevState.resetModal,
                ...resetModal,
            },
        }));
    };

    showResetModal(modalFor: string) {
        // alert('asdfasdfwe')
        this.closeLoginModal('reset');
        this.setResetModal({
            show: true,
            syncShow: false,
            modalFor,
        });
        setTimeout(() => {
            this.setResetModal({
                show: true,
                syncShow: true,
            });
        }, 100);
    }

    onCloseResetModal = (): void => {
        if(this.props.location.pathname=="/PageMarket" ||
            this.props.location.pathname=="/PageTopSkyClub" ||
            this.props.location.pathname=="/PageDataByZip" ||
            this.props.location.pathname=="/PageDataByMetro"
        ){
            if (!this.props.userInfo) {
                window.location.href="/";
            }
        }
        this.setResetModal({
            show: true,
            syncShow: false,
        });
        setTimeout(() => {
            this.setResetModal({
                show: false,
                syncShow: false,
            });
        }, 300);
    };

    resetPassword = (): void => {
        const {email} = this.state;
        const formData = new FormData();
        formData.append('email', email);
        Request({
            url: `/user/reset_password`,
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {email},
            // data: formData
        }).then(async (res) => {
            const data = res.data;
            toast(data.message);
            if (data.status === 200) {
                this.setResetModal({modalFor: 'verify_code'});
            } else {

            }
        });
    };

    verifyCode = (): void => {
        const {code, email} = this.state;
        Request({
            url: `/user/verify_code`,
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                "code": code,
                "email": email
            }
        }).then(async (res) => {
            const data = res.data;
            if (data.status === 200) {
                toast(data.message);
                // this.onCloseResetModal();
                // this.setLoginShow();
                // this.setModalIsLogin(false);
                this.setResetModal({modalFor: 'change_password'});
            } else {
                toast("Empty or Invalid verification code");
            }
        });
    };

    changePassword = (): void => {
        const {changeForm} = this.state;
        if (changeForm.new_password != changeForm.new_password_conf) {
            toast('Your confirmed password and password do not match.');
            return;
        }
        let errors = {}
        const formData = new FormData();
        formData.append('email', changeForm.email);
        formData.append('new_password', changeForm.new_password);
        formData.append('new_password_conf', changeForm.new_password_conf);
        Request({
            url: `/user/change_password`,
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            // data: {code}
            data: changeForm,
        }).then(async (res) => {
            const data = res.data;
            errors["change_email"] = data.message["email"];
            errors["change_new_password"] = data.message["new_password"];
            errors["change_new_password_conf"] = data.message["new_password_conf"];
            this.setState({errors: errors});
            if (data.status === 200) {
                this.setLoginShow();
                toast(data.message);
                this.setResetModal({
                    show: false,
                    syncShow: false,
                });
                // message.success({
                //     content: data.message || 'success',
                //     // duration: 0.5,
                //     className: 'success-notice-green',
                // });
            }
        });
    };

    menuLiHover = (i) => {
        const l: any = this.state.menuList;
        if (l[i]?.active) return;
        l[i].active = true;
        this.setState({
            menuList: l,
        });
    };

    subMenuLiHover = (i) => {
        const l = this.state.menuList;
        if (l[i]?.subHover) return;
        l[i].subHover = true;
        this.setState({
            menuList: l,
        });
    };
    subMenuLiLeave = (i) => {
        const l = this.state.menuList;
        // if (l[i].active) return;
        l[i].subHover = false;
        l[i].active = false;
        this.setState({
            menuList: l,
        });
    };
    menuLiLeave = (i) => {
        setTimeout(() => {
            const l = this.state.menuList;
            l[i].active = l[i].subHover;
            this.setState({
                menuList: l,
            });
        }, 50);
    };

    handleScroll = () => {
        if (typeof document !== 'undefined') {
            const scrollTop: Number = document ? document.documentElement.scrollTop : 0;
            const state = {
                pageScrollTop: scrollTop,
            };
            this.setState(state);
        }
    };

    login(isFromRegister: boolean = false) {
        const {form} = this.state;
        let errors = {};
        Request({
            url: '/user/signin',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                email: form.email,
                password: form.password,
            },
        }).then(async (res) => {
            const data = res.data;

            if (data.status === 200) {
                window.localStorage.setItem('token', data.data.token);
                this.setLoginModal({
                    show: false,
                    syncShow: false,
                });
                console.log("user", data);
                await this.getUser(data.data.user_id);
                if(isFromRegister) {
                    window.location.href="/PageTopSkyClub?isFromRegister=1";
                } else {
                    window.location.reload(false);
                }
            }else {
                errors["email"] = data.message["email"];
                errors["password"] = data.message["password"];
                this.setState({errors: errors});
                toast(data.message);
            }
        });
    }

    getUser = async (id) => {
        // console.log("user_id==>", id);
        // console.log("props==>", this.props);
        const {dispatch, userInfo} = this.props;
        await Request({
            url: '/user/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            const data = res.data;
            if (data.status === 200) {
                dispatch(setUserInfo(data.data));
                this.setState({
                    userInfo: data.data,
                });
            }
        });
    };

    signup() {
        const {form, isCheck, isJoinCheck} = this.state;
        if (!isCheck) {
            toast('Please check Terms and Privacy.');
            // message.warn('Please check Terms and Privacy.').then();
            return;
        }
        if (form.password != form.passConfirm) {
            toast('Your confirmed password and password do not match.');
            return;
        }
        let errors = {};
        Request({
            url: '/user/signup',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                email: form.email,
                pass_confirm: form.passConfirm,
                password: form.password,
                role: form.role,
            },
        }).then((res: any) => {
            const data = res.data;
            console.log("signup result==>", data);
            if (data.status === 200) {
                this.setLoginModal({
                    show: false,
                    syncShow: false,
                });
                this.login(true);
            } else {
                console.log("response data==>", res);
                errors["reg_email"] = data.message.email;
                errors["reg_password"] = data.message.password;
                errors["reg_repassword"] = data.message.pass_confirm;
                this.setState({errors: errors});
                toast(data.message);
            }
        });
    }

    componentDidMount = async()=> {
        this.handleScroll();
        if (this.state.userInfo && this.state.userInfo.id) {
            this.getUser(this.state.userInfo.id);
            // console.log("userinfo==>", this.state.userInfo);
        }
        if (typeof document !== 'undefined')
            window.addEventListener('scroll', this.handleScroll.bind(this));
        const headers={
            'x-api-key': process.env.X_API_KEY,
            'Content-Type': 'application/json',
        };
        let validtime;
        if(localStorage.getItem("valid_time")){
            let valid_str=localStorage.getItem("valid_time")
            let moment_variable=moment(valid_str, "YYYY-MM-DD HH:mm:ss")
            validtime=new Date(moment_variable.format("YYYY-MM-DD HH:mm:ss"))
            // console.log("valid_date==>", validtime)
        } else {

            validtime=new Date();
            // console.log("valid_date==>", validtime);
        }
        let now=new Date();
        if(+now >= +validtime){
            // console.log("valid_time", validtime);
            let video_data=null;
            await Request({
                url: `/webinar/event`,
                method: 'GET',
                //data: {email},
                // data: formData
                isToast: true
            }).then(async (res) => {
                // video_data=res.data;
                video_data=res;
                // video_data.data = {
                //     "id": "44",
                //     "title": "12月21日北卡投资价值洼地Graham城市及房源介绍",
                //     "sub_title": "Topysky听我说新盘系列讲座",
                //     "event_time": "2023-12-21 18:00:00",
                //     "duration": "1",
                //     "speaker_name": "地产学堂远程投资老师Dennis、Topsky北卡团队常琳",
                //     "speaker_description": "",
                //     "profile_image_url": "https://topskyhome.s3.us-west-2.amazonaws.com/webinar/44/webinar_1703139409_8b6ed87b591ca0dd4a70.jpg",
                //     "summary_title": "",
                //     "summary_content": "Graham城市介绍；\nGraham代表房源分享",
                //     "streaming_url": "https://us02web.zoom.us/j/86749854158?pwd=cXVjOGZzbDFHRjRzYjNhNUJ5aGNidz09",
                //     "meeting_id": "867 4985 4158",
                //     "passcode": "123",
                //     "playback_url": "",
                //     "annouce_at_home_page": "1",
                //     "created_at": "2023-12-21 00:16:46",
                //     "deleted_at": null,
                //     "updated_at": "2023-12-21 01:25:31"
                // }
                if(video_data && video_data.data !== undefined){
                    this.setState({is_streaming_event:true});
                    let banner_time=new Date(video_data.data.event_time + ' PST'); // 后台存储为UTC时间
                    let now=new Date();
                    let difference_time=banner_time-now
                    if(difference_time>0){
                        document.getElementById('video_banner').style.display="";
                        // document.getElementById('video_banner1').style.display="";
                        const mobile_banner=document.getElementsByClassName("header stylehome1")[0];
                        if(mobile_banner !== undefined) {
                            mobile_banner.style.position="relative";
                        }
                        //mobile_banner.style.top="90px";
                    }else {
                        if (this.state.is_streaming_event){
                            document.getElementById('video_banner').style.display="none";
                            // document.getElementById('video_banner1').style.display="none";
                        }
                        const mobile_banner=document.getElementsByClassName("header stylehome1")[0];
                        if(mobile_banner !== undefined) {
                            mobile_banner.style.position="absolute";
                            mobile_banner.style.top="0px";
                        }

                    }
                }

            }).catch((err) => {
                console.log('error ==> ', err);
            });
            this.setState({live_stream_info: video_data.data});
            setInterval(()=>{
                if(video_data!== null && video_data.data !== undefined  ){
                    //console.log("res_data", video_data.data.title);
                    let banner_time=new Date(video_data.data.event_time + ' PST');
                    let now=new Date();
                    let difference_time=banner_time-now
                    //console.log("difference_time", difference_time);
                    let diff_day, diff_hour_sec , diff_hour , diff_min_sec ,diff_min ,diff_sec_sec , diff_sec ;
                    diff_day = diff_hour_sec = diff_hour = diff_min_sec = diff_min = diff_sec_sec = diff_sec = 0;
                    if (difference_time>0) {
                        diff_day=parseInt(difference_time/(1000*60*60*24), 10)
                        diff_hour_sec=difference_time-1000*60*60*24*diff_day
                        diff_hour=parseInt(diff_hour_sec/(1000*60*60),10)
                        diff_min_sec=diff_hour_sec-1000*60*60*diff_hour
                        diff_min=parseInt(diff_min_sec/(1000*60),10)
                        diff_sec_sec=diff_min_sec-1000*60*diff_min
                        diff_sec=parseInt(diff_sec_sec/1000,10)
                    }else {
                    }
                    //console.log("days", diff_day, diff_hour, diff_min, diff_sec)
                    let day=diff_day>=10 ? diff_day.toString() : "0" + diff_day.toString();
                    let hour=diff_hour>=10 ? diff_hour.toString() : "0" + diff_hour.toString();
                    let minutes=diff_min>=10 ? diff_min.toString() : "0" + diff_min.toString();
                    let seconds=diff_sec>=10 ? diff_sec.toString() : "0" + diff_sec.toString();
                    this.setState({time_info: {
                            day : day,
                            hour : hour,
                            minutes: minutes,
                            seconds: seconds
                        }})
                }else {
                    //console.log("there is no incoming data");
                    if (this.state.is_streaming_event){
                        document.getElementById('video_banner').style.display="none";
                        // document.getElementById('video_banner1').style.display="none";
                    }
                    const mobile_banner=document.getElementsByClassName("header stylehome1")[0];
                    if(mobile_banner !== undefined) {
                        mobile_banner.style.position="absolute";
                        mobile_banner.style.top="0px";
                    }

                }
                //console.log("this is timer console");

            }, 1000);

        }else {
            //console.log("times===>", now, validtime);
            // document.getElementById('video_banner').style.display="none";
            // document.getElementById('video_banner1').style.display="none";
            const mobile_banner=document.getElementsByClassName("header stylehome1")[0];
            mobile_banner.style.position="absolute";
            mobile_banner.style.top="0px";
        }
        const { is_streaming_event, urlParams } = this.state;
        if(is_streaming_event && urlParams.event) {
            this.handleStreamingEvent();
        }
    }

    componentWillUnmount(): void {
        if (window) window.removeEventListener('scroll', this.handleScroll.bind(this));
        this.setState = () => {
        };
    }
    closeBanner(): void {
        //let cur_timestr=moment(new Date()).format("'YYYY-MM-DD HH:mm:ss'");
        let tomorrow = new Date(); // Or Date.today()
        tomorrow.setDate(tomorrow.getDate()+1);
        let valid_time=moment(tomorrow).format("'YYYY-MM-DD HH:mm:ss'");
        console.log("next day time==>", valid_time);
        localStorage.removeItem("valid_time");
        localStorage.setItem("valid_time", valid_time);
        document.getElementById("video_banner").style.display="none";
        document.getElementById("video_banner1").style.display="none";
        const mobile_banner=document.getElementsByClassName("header stylehome1")[0];
        mobile_banner.style.position="absolute";
        mobile_banner.style.top="0px";
        console.log("mobile_banner_element", mobile_banner);


    }
    /**
     * 直播跳转相关 
     */
    async handleStreamingEvent() {
        const {live_stream_info, userInfo} = this.state;
        let params = {
            event_id:  live_stream_info.id,
            user_id: userInfo.id
        }
        await Request({
            url: '/market/streaming_event',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: params
        }).then((res) => {
            const data = res.data;

            if (data.status === 200) {
                // 弹窗显示后端返回结果
                this.setState({
                    codeModal: {
                        show: true,
                        syncShow: true
                    },
                    codeModalMessage: data.message
                });
            }
            else {
                toast(data.message || 'Please check your email, thank you for the reversation!')
            }
        });
    }
    closeCodeModal = (): void => {
        this.setState({
            codeModal: {
                show: false,
                syncShow: false
            }
        });
    }
    handleHasCode() {
        
    }
    render(): React.ReactNode {
        const {t, i18n, onRef} = this.props;
        if (onRef) onRef(this);
        const {loginModal, modalIsLogin, form, changeForm, userInfo, live_stream_info, time_info, is_streaming_event, codeModal, codeModalMessage} = this.state;
        const renderLi = () => {
            if (!userInfo || (userInfo.role != 'AGENT' && userInfo.role != 'ADMIN')) {
                this.state.menuList = this.state.menuList.filter((item) => {
                    return item.name != 'AgentTool';
                })
            }
            return this.state.menuList.map((item, index) => {
                return (
                    <li className={item.active ? 'menu-active' : ''} key={index}>
                        <a
                            href={item.href}
                            onMouseMove={() => {
                                this.menuLiHover(index);
                            }}
                            onMouseLeave={() => {
                                this.menuLiLeave(index);
                            }}
                        >
                            <span className="title">{t('nav.' + item.name)}</span>
                            {item.children.length > 0 ? <span className="arrow "/> : ''}
                        </a>
                        {item.children.length > 0 ? (
                            <SubMenu
                                display={item.active}
                                children={item.children}
                                userRole={userInfo.role}
                                onHover={() => {
                                    this.subMenuLiHover(index);
                                }}
                                onLeave={() => {
                                    this.subMenuLiLeave(index);
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </li>
                );
            });
        };

        const renderLeftMenu = () => {
            return this.state.leftMenuList.map((item, index) => {
                let leftIndex = index + 100;
                return (
                    <li className={item.active ? 'menu-active' : ''} key={leftIndex}>
                        <a href={item.href}>
                            <span className="title">{t('nav.' + item.name)}</span>
                            {item.children.length > 0 ? <span className="arrow "/> : ''}
                        </a>
                        {item.children.length > 0 ? (
                            <SubMenu display={item.active} children={item.children}/>
                        ) : (
                            ''
                        )}
                    </li>
                );
            });
        };

        const closeLeftMenu = () => {
            if(userInfo?.role == 'ADMIN'){
                console.log("Admin_menu==>", document.getElementById("admin_menu").classList);
                let admin_opened = document.getElementById("admin_menu").classList.contains("mm-menu_opened");
                if (!admin_opened) {
                    document.getElementById("admin_menu").classList.add("mm-menu_opened");
                    document.getElementById("app").style.transform = "translateX(300px)";
                    document.getElementById("admin_menu").style.transform = "translateX(-300px)";
                } else {
                    document.getElementById("admin_menu").classList.remove("mm-menu_opened");
                    document.getElementById("app").style.transform = "";
                }
            }
            else {
                let user_opened = document.getElementById("user_menu").classList.contains("mm-menu_opened");
                if (!user_opened) {
                    document.getElementById("user_menu").classList.add("mm-menu_opened");
                    document.getElementById("app").style.transform = "translateX(300px)";
                    document.getElementById("user_menu").style.transform = "translateX(-300px)";
                } else {
                    document.getElementById("user_menu").classList.remove("mm-menu_opened");
                    document.getElementById("app").style.transform = "";
                }
            }
        }

        return (
            <div>
                <ToastContainer/>
                <div className={
                    'sign_up_modal modal fade bd-example-modal-lg ' +
                    (codeModal.syncShow ? 'show' : '')
                    } tabIndex="-1" role="dialog" style={
                        codeModal.show ? {display: 'block',  paddingRight: '17px'}: {}
                    }
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div
                                className="modal-header"
                                style={{
                                    top: '-25px',
                                    right: '-25px',
                                }}
                            >
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.closeCodeModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body container pb20">
                                {/* <div className="row"> */}
                                    <div className="form_grid">
                                        <h4 className="mb5">{codeModalMessage}</h4>
                                        <form onSubmit={() => this.handleHasCode()}>
                                            <div className="form1 form-container container">
                                                
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className="btn dbxshad btn-lg btn-thm white"
                                                                onClick={(e) => this.closeCodeModal()}
                                                            >
                                                                Ok
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <header
                    className={[
                        'header-nav',
                        'menu_style_home_one',
                        'navbar-scrolltofixed',
                        'stricky',
                        'main-menu',
                        this.state.pageScrollTop <= 10 && this.props.animate
                            ? 'slideIn'
                            : 'stricky-fixed slideInDown',
                        // 'animated',
                        'scroll-to-fixed-fixed',
                    ].join(' ')}
                    style={{
                        zIndex: 1000,
                        position: 'fixed',
                        top: '0px',
                        marginLeft: '0px',
                        width: '100vw',
                        left: '0px',
                        paddingLeft: '0px',
                    }}
                >
                    <div className="container-fluid p0">
                        <nav>
                            <div
                                className="menu-toggle"
                                style={{
                                    display: 'none',
                                }}
                            >
                                <img
                                    className="nav_logo_img img-fluid"
                                    src={logo}
                                    style={{
                                        height: '65px',
                                        visibility: 'visible',
                                    }}
                                    alt="header-logo.png"
                                />
                                <button type="button" id="menu-btn">
                                    <span className="icon-bar"/>
                                    <span className="icon-bar"/>
                                    <span className="icon-bar"/>
                                </button>
                            </div>
                            <a
                                href="/"
                                className="navbar_brand float-left dn-smd"
                                style={{
                                    marginTop: '15px',
                                    marginLeft: '15px'
                                }}
                            >
                                <img
                                    className="logo1 img-fluid"
                                    src={logo}
                                    alt="header-logo.png"
                                    style={{
                                        height:  '65px',
                                        visibility:  'visible',
                                    }}
                                />
                                <img
                                    className="logo2 img-fluid"
                                    src={logo1}
                                    style={{
                                        height: '65px',
                                        visibility: 'visible',
                                    }}
                                    alt="header-logo2.png"
                                />
                                {/* <span>TopskyHome</span> */}
                            </a>
                            <div className="d-flex main-nav">
                                {this.props.location.pathname !== '/PageMyProfile' && (
                                    <ul
                                        id="respMenuLeft"
                                        className="ace-responsive-menu text-left"
                                        data-menu-style="horizontal"
                                        style={{
                                            width: 'auto'
                                        }}
                                    >
                                        {renderLeftMenu()}
                                    </ul>
                                )}
                                <ul
                                    id="respMenu"
                                    className="ace-responsive-menu text-right"
                                    data-menu-style="horizontal"
                                    style={{
                                        flex: '1'
                                    }}
                                >
                                    {renderLi()}
                                    <li></li>
                                    {/* <li className="list-inline-item list_s">
                                        <a
                                            className="btn flaticon-lang"
                                            onClick={(e) => {
                                                e.persist();
                                                i18n.changeLanguage(
                                                    i18n.language.includes('en') ? 'zh_CN' : 'en_US'
                                                );
                                            }}
                                        >
                                            {' '}
                                            <span className="dn-lg">{t('nav.lang')}</span>
                                        </a>
                                    </li> */}
                                    <li className="list-inline-item list_s">
                                        <a
                                            className="btn flaticon-user"
                                            onClick={(e) => {
                                                e.persist();
                                                if (!userInfo || !userInfo.id) {
                                                    this.setLoginShow();
                                                } else {
                                                    this.getUser(userInfo?.id);
                                                    window.location.href = '/PageMyProfile';
                                                }
                                            }}
                                        >
                                            {' '}
                                            <span className="dn-lg">
                                                {userInfo?.name ||
                                                userInfo?.email ||
                                                t('login') + '/' + t('register')}
                                            </span>
                                        </a>
                                    </li>
                                    {userInfo?.role == 'ADMIN' ? (
                                        <li className="list-inline-item add_listing">
                                            <Link to={'/PageAddNewProperty'}>
                                                <span className="flaticon-plus"/>
                                                <span className="dn-lg"> Create Listing</span>
                                            </Link>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </ul>
                            </div>
                        </nav>
                        {/* { is_streaming_event &&
                            <div className="row row-event" id="video_banner">
                                <div className="col-md-4 banner_time">
                                    <div className="row time_banner">
                                        <div className=""><span>{ !isNaN(time_info.day) && time_info.day}</span></div>
                                        <div className=""><span>{ !isNaN(time_info.hour) && time_info.hour}</span></div>
                                        <div className=""><span>{!isNaN(time_info.minutes) && time_info.minutes}</span></div>
                                        <div className=""><span>{ !isNaN(time_info.seconds) &&time_info.seconds}</span></div>
                                    </div>
                                    <div className="row title_banner">
                                        <div className=""><span className="under_font">Days</span></div>
                                        <div className=""><span className="under_font">Hours</span></div>
                                        <div className=""><span className="under_font">Minutes</span></div>
                                        <div className=""><span className="under_font">Seconds</span></div>
                                    </div>
                                </div>
                                <div className="col-md-8 banner_title">
                                    <div>{live_stream_info.title ? live_stream_info.title : "No incoming living Stream"}</div>
                                    <div>
                                        <a className="stream-event-btn mr-4"  onClick={(e) =>  {
                                                e.persist();
                                                window.location.href = '/PageMWebinarLanding?id=' + live_stream_info.id;
                                                // if (!userInfo || !userInfo.id) {
                                                //     this.setLoginShow();
                                                //     toast('Please login to get the live streaming link');
                                                // } else {
                                                //     this.handleStreamingEvent();
                                                // }
                                            }
                                        } >
                                            {(Number(time_info.day) + Number(time_info.hour) + Number(time_info.minutes) + Number(time_info.seconds)) > 0 ? 'Reserve The Event' : 'Watch Live Streaming Now'}
                                        </a>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="close banner_close_btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={
                                        this.closeBanner
                                    }
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        } */}
                    </div>
                </header>

                <div
                    className={
                        'sign_up_modal modal fade bd-example-modal-lg ' +
                        (loginModal.syncShow ? 'show' : '')
                    }
                    tabIndex="-1"
                    role="dialog"
                    style={
                        loginModal.show
                            ? {
                                display: 'block',
                                paddingRight: '0px',
                            }
                            : {}
                    }
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div
                                className="modal-header"
                            >
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.closeLoginModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body container pb20">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul
                                            className="sign_up_tab nav nav-tabs"
                                            id="myTab"
                                            role="tablist"
                                        >
                                            <li className="nav-item">
                                                <a
                                                    className={
                                                        'nav-link ' + (modalIsLogin ? 'active' : '')
                                                    }
                                                    id="home-tab"
                                                    data-toggle="tab"
                                                    role="tab"
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        this.setModalIsLogin(true);
                                                    }}
                                                >
                                                    Login
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={
                                                        'nav-link ' +
                                                        (!modalIsLogin ? 'active' : '')
                                                    }
                                                    id="profile-tab"
                                                    onClick={() => {
                                                        this.setModalIsLogin(false);
                                                    }}
                                                >
                                                    Register
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content container" id="myTabContent">
                                    <div
                                        className={
                                            'row mt25 tab-pane fade ' +
                                            (modalIsLogin ? 'show active' : '')
                                        }
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <div className="col-lg-6 col-xl-6">
                                            <div className="login_thumb">
                                                <img
                                                    className="img-fluid w100"
                                                    src={login}
                                                    alt="login.jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                            <div className="login_form">
                                                <div className="heading">
                                                    <h4>Login</h4>
                                                </div>
                                                <div className="input-group mb-2 mr-sm-2">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="inlineFormInputGroupUsername2"
                                                        placeholder="Email"
                                                        value={form.email}
                                                        onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                email: event.target.value,
                                                            });
                                                            this.state.errors["email"] = "";
                                                        }} required
                                                    />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="flaticon-user"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors["email"]}</span>
                                                <div className="input-group form-group">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="exampleInputPassword1"
                                                        placeholder="Password"
                                                        value={form.password}
                                                        onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                password: event.target.value,
                                                            });
                                                            this.state.errors["password"] = "";
                                                        }}
                                                        required/>
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="flaticon-password"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors["password"]}</span>

                                                <div
                                                    onClick={() =>
                                                        this.showResetModal('reset_password')
                                                    }
                                                    className="form-group custom-control custom-checkbox"
                                                >
                                                    <a className="btn-fpswd float-right" href="#!">
                                                        Lost your password?
                                                    </a>
                                                </div>

                                                <button
                                                    className="btn btn-log btn-block btn-thm"
                                                    onClick={() => {
                                                        this.login();
                                                    }}
                                                >
                                                    Log In
                                                </button>
                                                <p className="text-center">
                                                    Don't have an account?{' '}
                                                    <a
                                                        className="text-thm"
                                                        onClick={() => {
                                                            this.setModalIsLogin(false);
                                                        }}
                                                    >
                                                        Register
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'row mt25 tab-pane fade ' +
                                            (!modalIsLogin ? 'show active' : '')
                                        }
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <div className="col-lg-6 col-xl-6">
                                            <div className="regstr_thumb">
                                                <img
                                                    className="img-fluid w100"
                                                    src={regstr}
                                                    alt="regstr.jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                            <div className="sign_up_form">
                                                <div className="heading">
                                                    <h4>Register</h4>
                                                </div>
                                                <div className="form-group input-group">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="exampleInputEmail2"
                                                        placeholder="Email"
                                                        value={form.email}
                                                        onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                email: event.target.value,
                                                            });
                                                            this.state.errors["reg_email"] = "";
                                                        }}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="fa fa-envelope-o"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors["reg_email"]}</span>
                                                <div className="form-group input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="exampleInputPassword2"
                                                        placeholder="Password"
                                                        value={form.password}
                                                        onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                password: event.target.value,
                                                            });
                                                            this.state.errors["reg_password"] = "";
                                                        }}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="flaticon-password"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors["reg_password"]}</span>
                                                <div className="form-group input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="exampleInputPassword3"
                                                        placeholder="Re-enter password"
                                                        value={form.passConfirm}
                                                        onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                passConfirm: event.target.value,
                                                            });
                                                            this.state.errors["reg_repassword"] = "";
                                                        }}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i className="flaticon-password"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{color: "red"}}>{this.state.errors["reg_repassword"]}</span>
                                                <div
                                                    className="form-group custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="exampleCheck2"
                                                        checked={this.state.isJoinCheck}
                                                        onChange={() => {
                                                            this.setState({
                                                                isJoinCheck: !this.state.isJoinCheck,
                                                            });
                                                        }}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="exampleCheck2"
                                                    >
                                                        I want to Join TopSkyClub for free
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-group custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="exampleCheck3"
                                                        checked={this.state.isCheck}
                                                        onChange={() => {
                                                            this.setState({
                                                                isCheck: !this.state.isCheck,
                                                            });
                                                        }}
                                                    />

                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="exampleCheck3"
                                                    >
                                                        I have read and accept the
                                                    </label>
                                                    <a
                                                        href="/PageTerms"
                                                        style={{
                                                            paddingTop: '2px',
                                                            paddingLeft: '2px',
                                                            paddingRight: '2px',
                                                            position: 'relative',
                                                            marginBottom: 0,
                                                            verticalAlign: 'top',
                                                            display: 'inline-block',
                                                        }}
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: '/PageTerms',
                                                            });
                                                        }}
                                                    >
                                                        Terms and Privacy{' '}
                                                    </a>
                                                    <span
                                                        style={{
                                                            paddingTop: '2px',
                                                            position: 'relative',
                                                            marginBottom: 0,
                                                            verticalAlign: 'top',
                                                            display: 'inline-block',
                                                        }}
                                                    >
                                                        Policy?
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn btn-log btn-block btn-thm"
                                                    onClick={() => {
                                                        this.signup();
                                                    }}
                                                >
                                                    Sign Up
                                                </button>
                                                <p className="text-center">
                                                    Already have an account?{' '}
                                                    <a
                                                        className="text-thm"
                                                        onClick={() => {
                                                            this.setModalIsLogin(true);
                                                        }}
                                                    >
                                                        Log In
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SubmitModal modal={this.state.resetModal} onClose={this.onCloseResetModal}>
                    {this.state.resetModal.modalFor === 'reset_password' && (
                        <div className="login_form" id="reset_password">
                            <div className="heading">
                                <h4>Submit your email</h4>
                            </div>
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    required={true}
                                    value={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value})}
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="flaticon-envelope"/>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn btn-log btn-block btn-thm"
                                onClick={this.resetPassword}
                            >
                                Reset
                            </button>
                        </div>
                    )}
                    {this.state.resetModal.modalFor === 'verify_code' && (
                        <div className="login_form" id="reset_password">
                            <div className="heading">
                                <h4>Verify Code</h4>
                            </div>
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="verify_code"
                                    placeholder="code"
                                    value={this.state.code}
                                    required={true}
                                    onChange={(e) => this.setState({code: e.target.value})}
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="flaticon-pin"/>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn btn-log btn-block btn-thm"
                                onClick={this.verifyCode}
                            >
                                Verify Code
                            </button>
                        </div>
                    )}
                    {this.state.resetModal.modalFor === 'change_password' && (
                        <div className="login_form" id="reset_password">
                            <div className="heading">
                                <h4>Change your password</h4>
                            </div>
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="change_email"
                                    placeholder="Email"
                                    value={changeForm.email}
                                    onChange={(event) => {
                                        this.setChangeForm({
                                            ...changeForm,
                                            email: event.target.value,
                                        });
                                        this.state.errors["email"] = "";
                                    }}
                                    required={true}
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="flaticon-envelope"/>
                                    </div>
                                </div>
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["change_email"]}</span>
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="new_password"
                                    value={changeForm.new_password}
                                    placeholder="Password"
                                    onChange={(event) => {
                                        this.setChangeForm({
                                            ...changeForm,
                                            new_password: event.target.value,
                                        });
                                        this.state.errors["change_new_password"] = "";
                                    }}
                                    required={true}
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="flaticon-password"/>
                                    </div>
                                </div>
                            </div>
                            <span
                                style={{color: "red"}}>{this.state.errors["change_new_password"]}</span>
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="new_password_conf"
                                    value={changeForm.new_password_conf}
                                    placeholder="Confirm Password"
                                    onChange={(event) => {
                                        this.setChangeForm({
                                            ...changeForm,
                                            new_password_conf: event.target.value,
                                        });
                                        this.state.errors["change_new_password_conf"] = "";
                                    }}
                                    required={true}
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="flaticon-password"/>
                                    </div>
                                </div>
                            </div>
                            <span
                                style={{color: "red"}}>{this.state.errors["change_new_password_conf"]}</span>
                            <button
                                className="btn btn-log btn-block btn-thm"
                                onClick={this.changePassword}
                            >
                                Change Password
                            </button>
                        </div>
                    )}
                </SubmitModal>

                <div id="page" className="stylehome1 h0">
                    <div className="mobile-menu">
                        { is_streaming_event ?
                            <div className="header stylehome1">
                                <div className="main_logo_home2 text-center">
                                    <img className="nav_logo_img img-fluid mt20" src={logo1}
                                        onClick={() => {
                                            window.location.href = '/';
                                        }} alt="TopSky Home"
                                        style={{width: '250px', height: '50px'}}/>
                                    {/*<span className="mt20"></span>*/}
                                </div>
                                <ul className="menu_bar_home2">
                                    <li className="list-inline-item list_s" onClick={(e) => {
                                        e.persist();
                                        if (!userInfo || !userInfo.id) {
                                            this.setLoginShow();

                                        } else {
                                            this.getUser(userInfo?.id);
                                            window.location.href = '/PageMyProfile';
                                        }
                                    }}><a><span
                                        className="flaticon-user"></span></a></li>
                                    <li className="list-inline-item" onClick={() => {
                                        if(userInfo?.role == 'ADMIN'){
                                            console.log("Admin_menu==>", document.getElementById("admin_menu").classList);
                                            let admin_opened = document.getElementById("admin_menu").classList.contains("mm-menu_opened");
                                            if (!admin_opened) {
                                                document.getElementById("admin_menu").classList.add("mm-menu_opened");
                                                document.getElementById("app").style.transform = "translateX(300px)";
                                                document.getElementById("admin_menu").style.transform = "translateX(-300px)";
                                            } else {
                                                document.getElementById("admin_menu").classList.remove("mm-menu_opened");
                                                document.getElementById("app").style.transform = "";
                                            }
                                        }
                                        else {
                                            let user_opened = document.getElementById("user_menu").classList.contains("mm-menu_opened");
                                            if (!user_opened) {
                                                document.getElementById("user_menu").classList.add("mm-menu_opened");
                                                document.getElementById("app").style.transform = "translateX(300px)";
                                                document.getElementById("user_menu").style.transform = "translateX(-300px)";
                                            } else {
                                                document.getElementById("user_menu").classList.remove("mm-menu_opened");
                                                document.getElementById("app").style.transform = "";
                                            }
                                        }

                                    }}><a><span></span></a>
                                    </li>
                                </ul>
                            </div>    :
                            <div className="header stylehome1" style={{position: 'absolute', top:'0px'}}>
                                <div className="main_logo_home2 text-center">
                                    <img className="nav_logo_img img-fluid mt20" src={logo1}
                                        onClick={() => {
                                            window.location.href = '/';
                                        }} alt="TopSky Home"
                                        style={{width: '250px', height: '50px'}}/>
                                    {/*<span className="mt20"></span>*/}
                                </div>
                                <ul className="menu_bar_home2">
                                    <li className="list-inline-item list_s" onClick={(e) => {
                                        e.persist();
                                        if (!userInfo || !userInfo.id) {
                                            this.setLoginShow();

                                        } else {
                                            this.getUser(userInfo?.id);
                                            window.location.href = '/PageMyProfile';
                                        }
                                    }}><a><span
                                        className="flaticon-user"></span></a></li>
                                    <li className="list-inline-item" onClick={() => {
                                        closeLeftMenu();
                                    }}><a><span></span></a>
                                    </li>
                                </ul>
                            </div>
                        }
                        {/* { is_streaming_event &&
                            <div className="row row-event" id="video_banner1">
                                <div className="col-md-4 banner_time">
                                    <div className="row time_banner">
                                        <div className=""><span>{ !isNaN(time_info.day) && time_info.day}</span></div>
                                        <div className=""><span>{ !isNaN(time_info.hour) && time_info.hour}</span></div>
                                        <div className=""><span>{!isNaN(time_info.minutes) && time_info.minutes}</span></div>
                                        <div className=""><span>{ !isNaN(time_info.seconds) &&time_info.seconds}</span></div>
                                    </div>
                                    <div className="row title_banner">
                                        <div className=""><span className="under_font">Days</span></div>
                                        <div className=""><span className="under_font">Hours</span></div>
                                        <div className=""><span className="under_font">Minutes</span></div>
                                        <div className=""><span className="under_font">Seconds</span></div>
                                    </div>
                                </div>
                                <div className="col-md-8 banner_title">
                                    <div>{live_stream_info.title ? live_stream_info.title : "No incoming living Stream"}</div>
                                    <div>
                                        <a className="stream-event-btn mr-4"  onClick={(e) =>  {
                                                e.persist();
                                                window.location.href = '/PageMWebinarLanding?id=' + live_stream_info.id;
                                                // if (!userInfo || !userInfo.id) {
                                                //     this.setLoginShow();
                                                //     toast('Please login to get the live streaming link');
                                                // } else {
                                                //     this.handleStreamingEvent();
                                                // }
                                            }
                                        } >
                                            {(Number(time_info.day) + Number(time_info.hour) + Number(time_info.minutes) + Number(time_info.seconds)) > 0 ? 'Reserve The Event' : 'Watch Live Streaming Now'}
                                        </a>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="close banner_close_btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={
                                        this.closeBanner
                                    }
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        } */}
                    </div>
                       <nav id="admin_menu" className="stylehome1 mm-menu mm-menu_offcanvas">
                            <div className="mm-panels">
                                <div id="mm-1"
                                     className="mm-panel mm-panel_has-navbar mm-panel_opened">
                                    <div className="mm-navbar"><a
                                        className="mm-navbar__title">Menu</a></div>
                                    <ul className="mm-listview">
                                        <li className="mm-listitem"><a href="/"
                                                                       className=" mm-listitem__text">Home</a>
                                        </li>
                                        <li className="mm-listitem"><a href="/PageMessage"
                                                                       className=" mm-listitem__text">Message</a>
                                        </li>
                                        <li className="mm-listitem"><a
                                            href="/PageMyProperties"
                                            className=" mm-listitem__text">My
                                            Properties</a>
                                        </li>
                                        {(userInfo?.role === 'ADMIN') &&
                                        (<>
                                            <li className="mm-listitem"><a
                                                href="/PageMWebinarCalendar"
                                                className=" mm-listitem__text">Webinar</a>
                                            </li>
                                            <li className="mm-listitem"><a
                                                href="/MarketingVideos"
                                                className=" mm-listitem__text">Marketing
                                                Videos</a>
                                            </li>
                                            {/* <li className="mm-listitem"><a href="/BuyAdmin"
                                                                           className=" mm-listitem__text">Buy Forms</a>
                                            </li>
                                            <li className="mm-listitem"><a href="/SellAdmin"
                                                                           className="mm-listitem__text">Sell Forms</a>
                                            </li> */}
                                            <li className="mm-listitem"><a
                                                href="/ContactAdmin"
                                                className="mm-listitem__text">Contact Forms</a>
                                            </li>
                                            <li className="mm-listitem"><a
                                                href="/UsersAdmin"
                                                className="mm-listitem__text">Users</a>
                                            </li>                                                                                    
                                            <li className="mm-listitem"><a href="/QuestionnaireReport"
                                                className="mm-listitem__text">Questionnaire Report</a>
                                            </li>
                                        </>)

                                        }

                                        <li className="mm-listitem"><a href="/PageMyProfile"
                                                                       className="mm-listitem__text">MyProfile</a>
                                        </li>

                                        {/* <li className="mm-listitem"><a href="/PageBuy"
                                                                       className=" mm-listitem__text">Buy</a>
                                         </li> */}
                                        {/* <li className="mm-listitem"><a href="/PageSell"
                                                                       className=" mm-listitem__text">Sell</a>
                                        </li> */}
                                        {/* <li className="mm-listitem"><a href="/PageAgent"
                                                                       className=" mm-listitem__text">Agent</a>
                                        </li> */}

                                        <li className="mm-listitem" onClick={() => {
                                            document.getElementById("mm-3").classList.remove("mm-hidden");
                                            document.getElementById("mm-3").style.transform = "translateX(0px)";
                                        }}><a className="mm-listitem__text">Tool<span
                                            className="mm-sronly">Open submenu</span></a>
                                        </li>
                                        {/* <li className="mm-listitem"><a href="/PageLearn"
                                                                       className="mm-listitem__text">Learn</a>
                                        </li> */}
                                        <li className="mm-listitem">
                                            <a href="/PageAbout" className="mm-listitem__text">About Us</a>
                                        </li>
                                        {/* <li className="mm-listitem" onClick={(e) => {
                                            e.persist();
                                            i18n.changeLanguage(
                                                i18n.language.includes('en') ? 'zh_CN' : 'en_US'
                                            );
                                        }}><a className="mm-listitem__text">中文简体</a></li> */}
                                        <li className="mm-listitem" onClick={(e) => {
                                            e.persist();
                                            if (!userInfo || !userInfo.id) {
                                                this.setLoginShow();
                                                const signup_modal = document.getElementsByClassName("sign_up_modal")[0];
                                                signup_modal.style.transform = "translateX(-300px)";
                                                closeLeftMenu();
                                            } else {
                                                this.getUser(userInfo?.id);
                                                window.location.href = '/PageMyProfile';
                                            }
                                        }}><a className="mm-listitem__text"><span
                                            className="flaticon-user"></span> {userInfo?.name ||
                                        userInfo?.email ||
                                        t('login') + '/' + t('register')}</a></li>
                                        <li className="mm-listitem"><a onClick={() => {
                                            window.localStorage.removeItem('token');
                                            window.localStorage.removeItem('app/set-user-info');
                                            setTimeout(() => {
                                                window.location.href = '/';
                                            }, 100);
                                        }}
                                                                       className="mm-listitem__text">Logout</a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="mm-3"
                                     className="mm-panel mm-panel_has-navbar mm-hidden"
                                     aria-hidden="true">
                                    <div className="mm-navbar"><a
                                        className="mm-btn mm-btn_prev mm-navbar__btn"
                                        onClick={() => {
                                            document.getElementById("mm-3").classList.add("mm-hidden");
                                        }} aria-owns="mm-1"
                                        aria-haspopup="true"><span className="mm-sronly">Close submenu</span></a><a
                                        className="mm-navbar__title" href="#mm-1">Tool</a></div>
                                    <ul className="mm-listview">
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageDataByZip" aria-owns="mm-4"
                                            aria-haspopup="true">Housing Data By Zip Code<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageDataByMetro" aria-owns="mm-5"
                                            aria-haspopup="true">Housing Data By
                                            Metropolitan<span
                                                className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageMarket" aria-owns="mm-6"
                                            aria-haspopup="true">Market<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageCalculator" aria-owns="mm-6"
                                            aria-haspopup="true">Calculator<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <nav id="user_menu" className="stylehome1 mm-menu mm-menu_offcanvas">
                            <div className="mm-panels">
                                <div id="mm-1"
                                     className="mm-panel mm-panel_has-navbar mm-panel_opened">
                                    <div className="mm-navbar"><a
                                        className="mm-navbar__title">Menu</a></div>
                                    <ul className="mm-listview">
                                        <li className="mm-listitem"><a href="/"
                                                                       className=" mm-listitem__text">Home</a>
                                        </li>
                                        {/* <li className="mm-listitem"><a href="/PageBuy"
                                                                       className=" mm-listitem__text">Buy</a>
                                        </li>
                                        <li className="mm-listitem"><a href="/PageSell"
                                                                       className=" mm-listitem__text">Sell</a>
                                        </li>
                                        <li className="mm-listitem"><a href="/PageAgent"
                                                                       className=" mm-listitem__text">Agent</a>
                                        </li> */}
                                        <li className="mm-listitem"><a href="/PageMWebinarCalendar"
                                                                       className=" mm-listitem__text">Webinar</a>
                                        </li>
                                        <li className="mm-listitem" onClick={() => {
                                            document.getElementById("user-mm-3").classList.remove("mm-hidden");
                                            document.getElementById("user-mm-3").style.transform = "translateX(0px)";
                                        }}><a className="mm-listitem__text">Tool<span
                                            className="mm-sronly">Open submenu</span></a>
                                        </li>
                                        {/* <li className="mm-listitem"><a href="/PageLearn"
                                                                       className="mm-listitem__text">Learn</a>
                                        </li> */}
                                        <li className="mm-listitem"><a href="/PageAbout"
                                                                       className="mm-listitem__text">About
                                            Us</a></li>
                                        <li className="mm-listitem" onClick={(e) => {
                                            e.persist();
                                            i18n.changeLanguage(
                                                i18n.language.includes('en') ? 'zh_CN' : 'en_US'
                                            );
                                        }}><a className="mm-listitem__text">中文简体</a></li>
                                        <li className="mm-listitem" onClick={(e) => {
                                            e.persist();
                                            if (!userInfo || !userInfo.id) {
                                                this.setLoginShow();
                                                const signup_modal = document.getElementsByClassName("sign_up_modal")[0];
                                                signup_modal.style.transform = "translateX(-300px)";
                                                closeLeftMenu();
                                            } else {
                                                this.getUser(userInfo?.id);
                                                window.location.href = '/PageMyProfile';
                                            }
                                        }}><a className="mm-listitem__text"><span
                                            className="flaticon-user"></span> {userInfo?.name ||
                                        userInfo?.email ||
                                        t('login') + '/' + t('register')}</a></li>
                                    </ul>
                                </div>
                                <div id="user-mm-3"
                                     className="mm-panel mm-panel_has-navbar mm-hidden"
                                     aria-hidden="true">
                                    <div className="mm-navbar"><a
                                        className="mm-btn mm-btn_prev mm-navbar__btn"
                                        onClick={() => {
                                            document.getElementById("user-mm-3").classList.add("mm-hidden");
                                        }} aria-owns="mm-1"
                                        aria-haspopup="true"><span className="mm-sronly">Close submenu</span></a><a
                                        className="mm-navbar__title" href="#mm-1">Tool</a></div>
                                    <ul className="mm-listview">
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageDataByZip" aria-owns="mm-4"
                                            aria-haspopup="true">Housing Data By Zip Code<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageDataByMetro" aria-owns="mm-5"
                                            aria-haspopup="true">Housing Data By
                                            Metropolitan<span
                                                className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageMarket" aria-owns="mm-6"
                                            aria-haspopup="true">Market<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                        <li className="mm-listitem"><a
                                            className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text"
                                            href="/PageCalculator" aria-owns="mm-6"
                                            aria-haspopup="true">Calculator<span
                                            className="mm-sronly">Open submenu</span></a>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                </div>
                { (is_streaming_event) && (
                        <div id="video_banner">
                            <div className="popup-mask"></div>
                            <div className="popup-ads">
                                <div onClick={(e) =>  {
                                        e.persist();
                                        window.location.href = '/PageMWebinarLanding?id=' + live_stream_info.id;
                                    }
                                }>
                                    <img className="popup-ads-img" src={live_stream_info.profile_image_url}/>
                                    <div>
                                        {live_stream_info.title}
                                    </div>
                                </div>
                                <div className="popup-ads-line"></div>
                                <div className="popup-ads-close" onClick={() => {
                                    this.closeBanner();
                                }}>
                                    <i className="flaticon-close"/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default HeaderBar;
