import React from 'react';
import { message } from 'antd';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";
import { intentions } from 'shared/components/Utils/IntentionTypes'
import { Select } from 'antd';
const { Option } = Select;

class ContactUs extends React.Component<Props, State> {
    state: State = {
        name: '',
        phone: '',
        email: '',
        subject: '',
        intention: '',
        message: '',
        isCheck: false
    };

    handleSubmit(e) {
        e.preventDefault();
        console.log('form ==> ', this.state, process.env);
        const form = this.state;
        if(form.intention == '') {
            toast('Please choose an option of interest');
            return false;
        }
        Request({
            url: '/contact/form',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'POST',
            data: form,
        })
            .then(async (res) => {
                const data = res.data;
                if (data.status === 200) {
                    toast(data.message);
                    message.success({
                        content: data.message,
                        duration: 3,
                        className: 'success',
                    });
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8">
                                <div className="form_grid">
                                    <h4 className="mb5">Free Consultation on Real Estate Investment</h4>
                                    
                                    <form
                                        className="contact_form"
                                        id="contact_form"
                                        name="contact_form"
                                        // noValidate={true}
                                        onSubmit={(e) => this.handleSubmit(e)}
                                        method="post"
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        required={true}
                                                        type="text"
                                                        placeholder="Full Name"
                                                        value={this.state.name}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        className="form-control required email"
                                                        required={true}
                                                        type="email"
                                                        placeholder="Email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="phone"
                                                        name="phone"
                                                        className="form-control required phone"
                                                        // required={true}
                                                        type="phone"
                                                        placeholder="Phone Number"
                                                        value={this.state.phone}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        id="subject"
                                                        name="subject"
                                                        className="form-control subject"
                                                        // required={true}
                                                        type="text"
                                                        placeholder="Wechat ID"
                                                        value={this.state.wechat_id}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-12">
                                                <div className="form-group">
                                                    <select className="form-control subject" onChange={(e) => {
                                                        // this.handleChange(e)
                                                        console.log(e )
                                                        this.setState({
                                                            intention: e.value
                                                        })
                                                    }}>
                                                        {intentions.map((item, index) => {
                                                            return (
                                                                <option value={item.name}>
                                                                    { item.name }
                                                                </option>
                                                            );
                                                        })}}
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <Select size="large" defaultValue="" bordered={false} className="form-control subject" style={{ width: '100%' }} onChange={(e) => {
                                                        this.setState({
                                                            intention: e
                                                        })
                                                    }}>
                                                        {intentions.map((item, index) => {
                                                            return (
                                                                <option value={index == 0 ? '' : item.name} disabled={index == 0 ? true: false}>
                                                                    { item.name }
                                                                </option>
                                                            );
                                                        })}}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <textarea
                                                        id="message"
                                                        name="message"
                                                        className="form-control required"
                                                        rows={8}
                                                        required={true}
                                                        placeholder="How can we help you?"
                                                        value={this.state.message}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customCheck1"
                                                            required={true}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="customCheck1"
                                                        >
                                                            When you join our list, you agree to be "in the know" via email – as well as calls & texts if you share your phone number. Messages and data rates may apply.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group mb0">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-thm"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <div className="contact_localtion">
                                    <h4>Contact Us</h4>
                                    <p>
                                        We are reimaging how you buy, sell and invest in real
                                        estate. It’s now easier to get everything done worry-free,
                                        with TopSky Home.
                                    </p>
                                    <div className="content_list">
                                        <h5>Address</h5>
                                        <p>
                                            20370 Town Center Lane, #245 Cupertino <br />
                                            California 92612
                                        </p>
                                    </div>
                                    <div className="content_list">
                                        <h5>Phone</h5>
                                        <p>
                                            <a href="tel:866-998-6742">866-998-6742</a>
                                        </p>
                                    </div>
                                    <div className="content_list">
                                        <h5>Mail</h5>
                                        <p>
                                            <a href="mailto: info@topskyhome.com">
                                                info@topskyhome.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p0 mt50">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="h600" id="map-canvas"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default ContactUs;
