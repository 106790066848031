import { MenuLi } from './HeaderInterface';
const data: Array<MenuLi> = [
    
    {
        name: 'Webinar',
        active: false,
        subHover: false,
        children: [],
        type: 1,
        href: '/PageMWebinarCalendar',
    },
    {
        name: 'Tool',
        active: false,
        subHover: true,
        children: [
            {
                name: 'Housing Data By Zip Code',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageDataByZip'
            },
            {
                name: 'Housing Data By Metropolitan',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageDataByMetro'
            },
            {
                name: 'Market',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageMarket'
            },
            {
                name: 'Calculator',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageCalculator'
            },
        ],
        type: 1,
        href: '/',
    },
    
    {
        name: 'AgentTool',
        active: false,
        subHover: true,
        children: [
            {
                name: 'Housing Data By Power BI',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageDataByPowerbi'
            },
            {
                name: 'Agent Documents',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageMAgentDoc'
            },
        ],
        type: 1,
        href: '/',
    },
    // {
    //     name: 'Learn',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageLearn',
    // },
    {
        name: 'About',
        active: false,
        subHover: true,
        children: [{
                name: 'About Us',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageAbout',
            }, {
                name: 'Contact',
                active: false,
                subHover: false,
                children: [],
                type: 1,
                href: '/PageContactUs',
            }
        ],
        type: 1,
        href: '/',
    },
    // {
    //     name: 'blog',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageCompare',
    // },
    // {
    //     name: 'contact',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageMessage',
    // },
];

export default data;
