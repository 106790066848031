import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import usmap from 'shared/assets/images/background/us-map.jpg';
class about extends React.Component {
    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar animate={false} />
                <section className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2 className="mt0">About Us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-xl-12">
                                <div
                                    className="about_content"
                                >
                                    <p style={{
                                        fontSize: '16pt',
                                    }}>
                                        TopSky Home is a proptech company combining big data and AI
                                        in real estate with top notch one-stop real-estate services
                                        serving home buyers, sellers and investors. Our mission is
                                        to make real estate investment and transactions worry-free,
                                        cost-effective and simple.
                                        <br />
                                        <br />
                                        Our platform provides a complete set of data and reports,
                                        easy-to-use tools, and real estate analysts, realtors and
                                        property managers to facilitate real estate investment
                                        decisions with confidence from anywhere in the world.
                                        <br />
                                        <br />
                                        No matter what your investing goals and needs are, TopSky
                                        Home is here to help.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Who we are*/}
                <section id="why-chose" className="whychose_us pb20 pt0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="main-title text-center">
                                    <h2>Who we are</h2>
                                    <p>Fast Growing Company</p>
                                </div>
                            </div>
                            
                            <div className="col-lg-12">
                                <img
                                        // className="img-whp"
                                        className="img-map"
                                        src={usmap}
                                        alt="us-map.jpg"
                                    />
                            </div>
                        </div>
                    </div>
                </section>
                {/* From home page - who we are*/}
                {/*
                <section id="why-chose" className="whychose_us pb70 pt120 who-we-are">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className="why_chose_us home-who">
                                    <div className="icon">
                                        <span className="flaticon-money-bag"></span>
                                    </div>
                                    <div className="desc">
                                        <h4>$150M<label className="label">+</label></h4>
                                        <p>Total transaction volume<br/>(8 months)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className="why_chose_us home-who">
                                    <div className="icon">
                                        <span className="flaticon-house"></span>
                                    </div>
                                    <div className="desc">
                                        <h4>400<label className="label">+</label></h4>
                                        <p>house sold<br/>(8 months)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className="why_chose_us home-who">
                                    <div className="icon">
                                        <span className="flaticon-house-1"></span>
                                    </div>
                                    <div className="desc">
                                        <h4>4<label className="label">+</label></h4>
                                        <p>home brokerages</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className="why_chose_us home-who">
                                    <div className="icon">
                                        <span className="flaticon-user-1"></span>
                                    </div>
                                    <div className="desc">
                                        <h4>2000<label className="label">+</label></h4>
                                        <p>members in network</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                */}
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default about;
