import React from "react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Footer from "../../components/Footer/Footer";
import Site from "../../components/Site/Site";
import Agent from "../../components/Agent/Agent";

class comingsoon extends React.Component {
    render() {
        return (
            <div className="wrapper" style={{
                paddingTop: '60px'
            }}>
                <HeaderBar animate={false}/>
                <section className="our-terms bgc-f7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="terms_condition_grid">                                    
                                    <div className="grids mb30">
                                        <h4>Coming Soon...</h4> 
                                    </div>
                                    <div className="grids mb30"> 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </section>
                <Agent/>
                <Site />
                <Footer/>
            </div>
        );
    }
}

export default comingsoon;
