import React from "react";
import Request from "components/Utils/FormRequest";
import { message } from "antd";
import { QuestionState } from "./interface";
import QuestionItem from "./QuestionItem";
import axios from "axios";
import FormData from "form-data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setUserInfo } from '../../store/userInfo/actions';
import $ from 'jquery';
class Question extends React.Component {
    state: QuestionState = {
        answer1: null,
        answer2: null,
        answer3: null,
        answer4: null,
        answer5: null,
        answer6: null,
        answer7: null,
        answer8: null,
        answer9: null,
        answer10: null,
        answer11: null,
        isCheck: false,
        answers3_detail: [
            { checked: false, value: "Getting financing" },
            { checked: false, value: "Finding a good contractor" },
            { checked: false, value: "Finding a really good deal and evaluate return" },
            { checked: false, value: "Analyzing deals and risk-control" },
            { checked: false, value: "" }
        ],
        answers4_detail: [
            { checked: false, value: "Appreciation potential" },
            { checked: false, value: "Positive cash flow/Cash on cash return" },
            { checked: false, value: "Portfolio diversification" },
            { checked: false, value: "" }
        ],
        answers8_detail: [
            { checked: false, value: "Realtor" },
            { checked: false, value: "Mortgage Loan Officer" },
            { checked: false, value: "Contractor" },
            { checked: false, value: "CPA" },
            { checked: false, value: "" }
        ],
        user_id: null,
        is_success: false,
        stepper: 1
    };
    headerBar;
    questions;
    code;

    constructor(props) {
        super(props);
        console.log('question_pops', this.props.headerBar)
        this.headerBar = React.createRef();
        this.code = React.createRef();
        this.props;
        this.state.user_id = this.props.user_id;
        console.log("question constructor==>", this.state.user_id);
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("form ==> ", this.state, process.env);
        Request({
            url: "/question/" + this.props.user_id,
            headers: {
                "x-api-key": process.env.X_API_KEY
            },
            method: "POST",
            data: this.state
        })
            .then(async (res) => {
                if (res != null && res.data.status === 200) {
                    this.state = res.data.data;
                }
            })
            .catch((err) => {
                console.log("error ==> ", err);
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    configureAnswer3(event) {
        if (!isNaN(event.target.value)) {
            let id = Number(event.target.value);
            this.state.answers3_detail[id].checked = !this.state.answers3_detail[id].checked;
        }
        let tmp_string = "";
        for (let i = 0; i < 5; i++) {
            if (this.state.answers3_detail[i].checked == true) {
                tmp_string += this.state.answers3_detail[i].value;
                tmp_string += ";";
            }
        }
        this.state.answer3 = tmp_string;
        console.log("array3", this.state.answer3);
    }

    configureAnswer4(event) {
        if (!isNaN(event.target.value)) {
            let id = Number(event.target.value);
            this.state.answers4_detail[id].checked = !this.state.answers4_detail[id].checked;
        }
        let tmp_string = "";
        for (let i = 0; i < 4; i++) {
            if (this.state.answers4_detail[i].checked == true) {
                tmp_string += this.state.answers4_detail[i].value;
                tmp_string += ";";
            }
        }
        this.state.answer4 = tmp_string;
        console.log("array4", this.state.answer4);
    }

    configureAnswer8(event) {
        if (!isNaN(event.target.value)) {
            let id = Number(event.target.value);
            this.state.answers8_detail[id].checked = !this.state.answers8_detail[id].checked;
        }
        let tmp_string = "";
        for (let i = 0; i < 5; i++) {
            if (this.state.answers8_detail[i].checked == true) {
                tmp_string += this.state.answers8_detail[i].value;
                tmp_string += ";";
            }
        }
        this.state.answer8 = tmp_string;
        console.log("array8", this.state.answer8);
    }
    
    getUser = (id) => {
        const { dispatch } = this.props;
        Request({
            url: '/user/' + id,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            const data = res.data;
            if (data.status === 200) {
                dispatch(setUserInfo(data.data));
            }
        });
    };
    submitAnswers = async (e) => {
        e.preventDefault();
        console.log(
            this.props
        )
        if (!this.state.user_id) {
            this.props.headerBar.setLoginShow();
            return false;
        }
        let user_id = this.state.user_id;
        // console.log("this is answer form ==> ", this.state.user_id);\
        const user_number = Number(user_id);
        const headers = {
            "x-api-key": process.env.X_API_KEY,
            "Content-Type": "application/json"
        };
        if (!this.state.isCheck) {
            toast("Agreement is required");
            this.setStepper(11);
            return;
        }
        if (this.state.answer1 == null) {
            toast("Question1 is required");
            this.setStepper(1);
            return;
        }
        if (this.state.answer2 == null) {
            toast("Question2 is required");
            this.setStepper(2);
            return;
        }
        if (this.state.answer3 == null) {
            toast("Question3 is required");
            this.setStepper(3);
            return;
        }
        if (this.state.answer4 == null) {
            toast("Question4 is required");
            this.setStepper(4);
            return;
        }
        if (this.state.answer5 == null) {
            toast("Question5 is required");
            this.setStepper(5);
            return;
        }
        if (this.state.answer6 == null) {
            toast("Question6 is required");
            this.setStepper(6);
            return;
        }
        if (this.state.answer7 == null) {
            toast("Question7 is required");
            this.setStepper(7);
            return;
        }
        if (this.state.answer8 == null) {
            toast("Question8 is required");
            this.setStepper(8);
            return;
        }
        if (this.state.answer9 == null) {
            toast("Question9 is required");
            this.setStepper(9);
            return;
        }
        if (this.state.answer10 == null) {
            toast("Question10 is required");
            this.setStepper(10);
            return;
        }
        if (this.state.answer11 == null) {
            toast("Question11 is required");
            this.setStepper(11);
            return;
        }
        const data = [
            { question_id: 1, user_id: user_number, answer: this.state.answer1 },
            { question_id: 2, user_id: user_number, answer: this.state.answer2 },
            { question_id: 3, user_id: user_number, answer: this.state.answer3 },
            { question_id: 4, user_id: user_number, answer: this.state.answer4 },
            { question_id: 5, user_id: user_number, answer: this.state.answer5 },
            { question_id: 6, user_id: user_number, answer: this.state.answer6 },
            { question_id: 7, user_id: user_number, answer: this.state.answer7 },
            { question_id: 8, user_id: user_number, answer: this.state.answer8 },
            { question_id: 9, user_id: user_number, answer: this.state.answer9 },
            { question_id: 10, user_id: user_number, answer: this.state.answer10 },
            { question_id: 11, user_id: user_number, answer: this.state.answer11 }
        ];
        await Request({
            url: `/answer/`,
            method: "POST",
            headers: {
                "x-api-key": process.env.X_API_KEY,
                "Content-Type": "application/json"
            },
            data: {
                "answers": JSON.stringify(data)
            }

        })
            .then(async (res) => {
                if (res.data !== undefined &&  res.data.status == 200) {
                    this.getUser(this.state.user_id)
                    this.setState({is_success:true})
                    console.log("success response", this.state.is_success);
                }
            })
            .catch((err) => {
                console.log("error ==> ", err);
            });
    };
    next = () => {
        const {stepper} = this.state;
        if(this.state['answer' + stepper] == null) {
            toast("Question" + stepper + " is required")
            return false;
        }
        this.setStepper(stepper + 1);
    };
    previous = () => {
        const {stepper} = this.state
        this.setStepper(stepper - 1);
    };
    setStepper = (item) => {
        this.setState({
            stepper: item
        })
    };
    render() {
        const {is_success, stepper}=this.state
        return (
            <div>
                <div className="form_grid">
                    <ToastContainer />
                    <div className="form1 form-container container questionform">
                        <h4 className="mb5">Property Investment Plan</h4>
                        <div className="row" style={{'display': stepper == 1 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">1.How did you hear about us? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio11" name="customRadio1"
                                           className="custom-control-input" value="Youtube"
                                           onClick={(e) => {
                                               this.state.answer1 = e.target.value;
                                               console.log(this.state.answer1);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio11">Youtube
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio12" name="customRadio1"
                                           className="custom-control-input" value="Friends"
                                           onClick={(e) => {
                                               this.state.answer1 = e.target.value;
                                               console.log(this.state.answer1);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio12">Friends
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio13" name="customRadio1"
                                           className="custom-control-input" value="WeChat groups"
                                           onClick={(e) => {
                                               this.state.answer1 = e.target.value;
                                               console.log(this.state.answer1);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio13">WeChat groups
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio15" name="customRadio1"
                                           className="custom-control-input" value="North America Real Estate"
                                           onClick={(e) => {
                                               this.state.answer1 = e.target.value;
                                               console.log(this.state.answer1);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio15">North America Real Estate
                                    </label>
                                </div>
                                <div className="form-row   align-items-center">
                                    <div className="custom-control custom-radio pr-2">
                                        <input type="radio" id="customRadio14" name="customRadio1"
                                               className="custom-control-input" value=""
                                               onClick={(e) => {
                                                   this.state.answer1 = e.target.value;
                                                   console.log(this.state.answer1);
                                               }} />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio14">Other:
                                        </label>
                                    </div>
                                    <input type="text" className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answer1 = e.target.value;
                                               console.log(this.state.answer1);
                                           }} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row" style={{'display': stepper == 2 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">2.What’s your preferred property type? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio21" name="customRadio2"
                                           className="custom-control-input"
                                           value="Townhome"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio21">Townhome</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio22" name="customRadio2"
                                           className="custom-control-input"
                                           value="Single Family Home"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio22">Single Family Home</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio23" name="customRadio2"
                                           className="custom-control-input"
                                           value="Multi-family"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio23">Multi-family</label>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="form-group col-12">
                                <label className="question_title">2.How much real estate investing
                                    experience do you
                                    have? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio21" name="customRadio"
                                           className="custom-control-input"
                                           value="Rookie: Eager to learn and a new investor"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio21">Rookie: Eager to learn and a new
                                        investor
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio22" name="customRadio"
                                           className="custom-control-input"
                                           value="Beginner: I’ve read a lot and know the basics, but this is my first real estate investment purchase"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio22">Beginner: I’ve read a lot and
                                        know
                                        the basics, but this is my first real estate investment
                                        purchase

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio23" name="customRadio"
                                           className="custom-control-input"
                                           value="Intermediate: I have purchased 1-3 investment properties and know what I’m doing"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio23">Intermediate: I have purchased
                                        1-3
                                        investment properties and know what I’m doing

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio24" name="customRadio"
                                           className="custom-control-input"
                                           value="Expert: I have purchased 3+ investment properties"
                                           onClick={(e) => {
                                               this.state.answer2 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio24">Expert: I have purchased 3+
                                        investment properties
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="form-group col-12">
                                <label className="question_title">3.What are your largest challenges
                                    on an
                                    investment
                                    property (multiple choices)? *
                                </label>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio31" name="customRadio"
                                           value="0"
                                           className="custom-control-input" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio31">Getting financing

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio32" name="customRadio"
                                           value="1" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio32">Finding a good contractor

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio33" name="customRadio"
                                           value="2" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio33">Finding a really good deal and
                                        evaluate return

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio34" name="customRadio"
                                           value="3" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio34">Analyzing deals and risk-control

                                    </label>
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="custom-control  custom-checkbox">
                                        <input type="checkbox" id="customRadio35" name="customRadio"
                                               value="4" onClick={(e) => {
                                            this.configureAnswer3(e);
                                        }}
                                               className="custom-control-input" />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio35">Other:

                                        </label>
                                    </div>
                                    <input type="text" className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answers3_detail[4].value = e.target.value;
                                               this.configureAnswer3(e);
                                           }} />
                                </div>
                            </div>
                        </div> */}
                        {/* Question 3 */}
                        <div className="row" style={{'display': stepper == 3 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">3.Please identify which buyer’s type you are (multiple choices)? *
                                </label>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio31" name="customRadio3"
                                           value="0"
                                           className="custom-control-input" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio31">Loan
                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio32" name="customRadio3"
                                           value="1" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio32">Cash

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio33" name="customRadio3"
                                           value="2" onClick={(e) => {
                                        this.configureAnswer3(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio33">1031 exchang
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        {/* Question 4 */}
                        <div className="row" style={{'display': stepper == 4 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">4.What are your investment goals
                                    (multiple choices)? *
                                </label>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio41" name="customRadio4"
                                           value="0" onClick={(e) => {
                                        this.configureAnswer4(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio41">Appreciation potential

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio42" name="customRadio4"
                                           value="1" onClick={(e) => {
                                        this.configureAnswer4(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio42">Positive cash flow/Cash on cash
                                        return

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio43" name="customRadio4"
                                           value="2" onClick={(e) => {
                                        this.configureAnswer4(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio43">Portfolio diversification

                                    </label>
                                </div>
                                {/* <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio44" name="customRadio"
                                           value="3" onClick={(e) => {
                                        this.configureAnswer4(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio44">Flipping
                                    </label>
                                </div> */}
                                <div className="form-row align-items-center">
                                    <div className="custom-control  custom-checkbox">
                                        <input type="checkbox" id="customRadio45" name="customRadio4"
                                               value="3" onClick={(e) => {
                                            this.configureAnswer4(e);
                                        }}
                                               className="custom-control-input" />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio45">Other:
                                        </label>
                                    </div>
                                    <input className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answers4_detail[4].value = e.target.value;
                                               this.configureAnswer4(e);
                                           }} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="form-group col-12">
                                <label className="question_title">5.What is your risk tolerance? *

                                </label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio51" name="customRadio5"
                                           className="custom-control-input"
                                           value="Very Conservative"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio51">Very Conservative

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio52" name="customRadio5"
                                           className="custom-control-input" value="Conservative"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio52">Conservative

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio53" name="customRadio5"
                                           className="custom-control-input" value="Moderate"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio53">Moderate

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio54" name="customRadio5"
                                           className="custom-control-input"
                                           value="Somewhat aggressive"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer2);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio54">Somewhat aggressive
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        <div className="row" style={{'display': stepper == 5 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">5.When do you plan to invest? *
                                </label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio51" name="customRadio5"
                                           className="custom-control-input"
                                           value="Anytime, ready to pull the trigger"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer5);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio51">Anytime, ready to pull the
                                        trigger

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio52" name="customRadio5"
                                           className="custom-control-input" value="Within 3 months"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer5);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio52">Within 3 months

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio53" name="customRadio5"
                                           className="custom-control-input" value="Within 6 months"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer5);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio53">Within 6 months

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio54" name="customRadio5"
                                           className="custom-control-input" value="Within a year"
                                           onClick={(e) => {
                                               this.state.answer5 = e.target.value;
                                               console.log(this.state.answer5);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio54">Within a year
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 6 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">6.What’s your comfortable investment amount? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio61" name="customRadio6"
                                           className="custom-control-input" value={"<100K"}
                                           onClick={(e) => {
                                               this.state.answer6 = e.target.value;
                                               console.log(this.state.answer6);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio61"> {"<100K"}
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio62" name="customRadio6"
                                           className="custom-control-input" value=" 100k-300K"
                                           onClick={(e) => {
                                               this.state.answer6 = e.target.value;
                                               console.log(this.state.answer6);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio62"> 100k-300K

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio63" name="customRadio6"
                                           className="custom-control-input" value="300k-500K"
                                           onClick={(e) => {
                                               this.state.answer6 = e.target.value;
                                               console.log(this.state.answer6);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio63">300k-500K

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio64" name="customRadio6"
                                           className="custom-control-input" value={">500K"}
                                           onClick={(e) => {
                                               this.state.answer6 = e.target.value;
                                               console.log(this.state.answer6);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio64">{">500K"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 7 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">7.Which area(s) would you like to invest in? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio71" name="customRadio7"
                                           className="custom-control-input" value="Bay Area"
                                           onClick={(e) => {
                                               this.state.answer7 = e.target.value;
                                               console.log(this.state.answer7);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio71">Bay Area

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio72" name="customRadio7"
                                           className="custom-control-input" value="Angels"
                                           onClick={(e) => {
                                               this.state.answer7 = e.target.value;
                                               console.log(this.state.answer7);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio72">Los Angeles

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio73" name="customRadio7"
                                           className="custom-control-input"
                                           value="Texas: Dallas and Austin" onClick={(e) => {
                                        this.state.answer7 = e.target.value;
                                        console.log(this.state.answer7);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio73">Texas: Dallas and Austin

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio74" name="customRadio7"
                                           className="custom-control-input"
                                           value="Florida: Tampa and Orlando" onClick={(e) => {
                                        this.state.answer7 = e.target.value;
                                        console.log(this.state.answer7);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio74">Florida: Tampa and Orlando
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio75" name="customRadio7"
                                           className="custom-control-input"
                                           value="North Carolina: Raleigh and Charlotte"
                                           onClick={(e) => {
                                               this.state.answer7 = e.target.value;
                                               console.log(this.state.answer7);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio75">North Carolina: Raleigh and
                                        Charlotte
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio76" name="customRadio7"
                                           className="custom-control-input"
                                           value="Georgia: Atlanta"
                                           onClick={(e) => {
                                               this.state.answer7 = e.target.value;
                                               console.log(this.state.answer7);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio76">Georgia: Atlanta
                                        Charlotte
                                    </label>
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="customRadio77" name="customRadio7"
                                               className="custom-control-input" value=""
                                               onClick={(e) => {
                                                   this.state.answer7 = e.target.value;
                                                   console.log(this.state.answer7);
                                               }} />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio77">Other:
                                        </label>
                                    </div>
                                    <input className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answer7 = e.target.value;
                                               console.log(this.state.answer7);
                                           }} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 8 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">8.For better networking, please select your occupation(s) *
                                </label>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio81" name="customRadio"
                                           value="0" onClick={(e) => {
                                        this.configureAnswer8(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio81">Realtor

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio82" name="customRadio"
                                           value="1" onClick={(e) => {
                                        this.configureAnswer8(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio82">Mortgage Loan Officer

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio83" name="customRadio"
                                           value="2" onClick={(e) => {
                                        this.configureAnswer8(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio83">Contractor

                                    </label>
                                </div>
                                <div className="custom-control  custom-checkbox">
                                    <input type="checkbox" id="customRadio84" name="customRadio"
                                           value="3" onClick={(e) => {
                                        this.configureAnswer8(e);
                                    }}
                                           className="custom-control-input" />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio84">CPA
                                    </label>
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="custom-control  custom-checkbox">
                                        <input type="checkbox" id="customRadio85" name="customRadio"
                                               value="4" onClick={(e) => {
                                            this.configureAnswer8(e);
                                        }}
                                               className="custom-control-input" />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio85">Other:
                                        </label>
                                    </div>
                                    <input className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answers8_detail[4].value = e.target.value;
                                               this.configureAnswer8(e);
                                           }} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 9 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">9.What property are you looking for to purchase? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio91" name="customRadio9"
                                           className="custom-control-input" value="Primary home"
                                           onClick={(e) => {
                                               this.state.answer9 = e.target.value;
                                               console.log(this.state.answer9);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio91">Primary home

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio92" name="customRadio9"
                                           className="custom-control-input" value="Long-term rental investment"
                                           onClick={(e) => {
                                               this.state.answer9 = e.target.value;
                                               console.log(this.state.answer9);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio92">Long-term rental investment

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio93" name="customRadio9"
                                           className="custom-control-input"
                                           value="Short-term rental investment" onClick={(e) => {
                                        this.state.answer9 = e.target.value;
                                        console.log(this.state.answer9);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio93">Short-term rental investment

                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio94" name="customRadio9"
                                           className="custom-control-input"
                                           value="Flipping opportunity" onClick={(e) => {
                                        this.state.answer9 = e.target.value;
                                        console.log(this.state.answer9);
                                    }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio94">Flipping opportunity
                                    </label>
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="customRadio95" name="customRadio9"
                                               className="custom-control-input" value=""
                                               onClick={(e) => {
                                                   this.state.answer9 = e.target.value;
                                                   console.log(this.state.answer9);
                                               }} />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio95">Other:
                                        </label>
                                    </div>
                                    <input className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answer9 = e.target.value;
                                               console.log(this.state.answer9);
                                           }} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 10 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">10.Who referred you to us? *</label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customRadio101" name="customRadio10"
                                           className="custom-control-input" value="Nobody"
                                           onClick={(e) => {
                                               this.state.answer10 = e.target.value;
                                               console.log(this.state.answer10);
                                           }} />
                                    <label className="custom-control-label"
                                           htmlFor="customRadio101">Nobody

                                    </label>
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="customRadio102" name="customRadio10"
                                               className="custom-control-input" value=""
                                               onClick={(e) => {
                                                   this.state.answer10 = e.target.value;
                                                   console.log(this.state.answer10);
                                               }} />
                                        <label className="custom-control-label"
                                               htmlFor="customRadio102">Name:
                                        </label>
                                    </div>
                                    <input className="form-control col-8 radio-input"
                                           onChange={(e) => {
                                               this.state.answer10 = e.target.value;
                                               console.log(this.state.answer10);
                                           }} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 11 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <label className="question_title">11.Tell us about yourself, what resources you are looking for and what resources you can offer. *</label>
                                
                                <div className="form-row align-items-center">
                                    <textarea className="form-control col-12" rows="5"
                                           onChange={(e) => {
                                               this.state.answer11 = e.target.value;
                                               console.log(this.state.answer10);
                                           }} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row mt20" style={{'display': stepper == 11 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck1"
                                        required={true}
                                        onClick={(e) => {
                                            this.state.isCheck = e.target.value;
                                            console.log(this.state.isCheck);
                                        }}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customCheck1"
                                    >
                                        When you join our list, you agree to be "in the know" via email – as well as calls & texts if you share your phone number. Messages and data rates may apply.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper == 11 ? '' : 'none'}}>
                            <div className="form-group col-12">
                                <a target="_blank">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-transparent w-100"
                                        onClick={(e) => {
                                            this.submitAnswers(e);
                                        }}
                                    >
                                        Submit
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="row" style={{'display': stepper < 11 ? '' : 'none'}}>
                            <div className="form-group col-6">
                                <a target="_blank">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-transparent w-100"
                                        onClick={(e) => {
                                            this.next();
                                        }}
                                    >
                                        Next
                                    </button>
                                </a>
                            </div>
                            <div className="form-group col-6" style={{'display': stepper > 1 ? '' : 'none'}}>
                                <a target="_blank">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-transparent w-100 default"
                                        onClick={(e) => {
                                            this.previous();
                                        }}
                                    >
                                        Previous
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    is_success && <div className="modal" tabindex="-1" role="dialog" style={{ display: 'block', position:'fixed', top:'100px'}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Welcome</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={(e) => {
                                            this.setState({ is_success: false });
                                        }} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        Thanks for Joining TopSky Home Club, your Pass Code is <b>GETRICH</b>. <br/>
                                        {/* <a href="https://calendly.com/toshaclubsv/20-mins-meeting-with-toshaclub" target="_blank">Schedule a meeting</a> with Topsky Club now to on board! */}
                                        Schedule a meeting with TopSky Club now to on board!
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={(e) => {
                                            this.setState({ is_success: false });
                                        }}>No Thanks</button>
                                    <button type="button" className="btn btn-primary" onClick={(e)=>{ window.location.href="/IframCalendly"; }}> Schedule Free Consultation
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        ); 
    }
}

export default Question;
