import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import Agent from '../../components/Agent/Agent';
import { connect } from 'react-redux';
import { Props, State } from './interface';
import Select from 'components/Select/Select';
import {
    citiesByState,
    extractAllStates,
    formatCities,
    formatStates,
} from 'components/Utils/TypesData';
import './page-market.less';
import Request from 'components/Utils/FormRequest';
import * as Utils from 'components/Utils/Utils';

import Atlanta from 'shared/assets/images/home/atlanta.jpg';
import Tampa from 'shared/assets/images/home/tampa.jpg';
import Orlando from 'shared/assets/images/home/orlando.jpg';
import SanAntonio from 'shared/assets/images/home/SanAntonio.jpeg';
import NewYork from 'shared/assets/images/home/ny.jpg';
import Dallas from 'shared/assets/images/home/dallas.jpg';

import {toast} from "react-toastify";
import { setUserInfo } from '../../store/userInfo/actions';

const cityImages = {
    'New York': NewYork,
    Atlanta,
    Orlando,
    Tampa,
    'San Antonio': SanAntonio,
    Dallas
};
const SixCities = [
    {
        city: 'New York',
        state_id: 'Ny',
        state_name: 'New York',
    },
    {
        city: 'San Antonio',
        state_id: 'TX',
        state_name: 'Texas',
    },
    {
        city: 'Atlanta',
        state_id: 'GA',
        state_name: 'Georgia',
    },
    {
        city: 'Tampa',
        state_id: 'FL',
        state_name: 'Florida',
    },
    {
        city: 'Orlando',
        state_id: 'KY',
        state_name: 'Kentucky',
    },
    {
        city: 'Dallas',
        state_id: 'TX',
        state_name: 'Texas',
    },
];

@connect(({ userInfo }) => {
    return { userInfo };
})
class Market extends React.Component<Props, State> {
    headerBar;
    PASS_CODE = 'GETRICH';
    code;
    state: State = {
        title: 'Market Analysis',
        allCities: {},
        allStates: [],
        city: null,
        state: null,
        videoList: [],
        liveVideoList: [],
        clientHeight: 0,
        currentScrollTop: 0,
        count: 4,
        urlParams: {},
        isLoadUrlParams: false,
        isShowRound: false,
        isgetRound: false, 
        codeModal: {
            show: false,
            syncShow: false,
        },
        topVideoList: [],
        playVideo: false,
        playSrc: ''
    };

    constructor(props) {
        super(props);
        this.state.urlParams = {
            state: Utils.getUrlParams('state', props.location.search),
            city: Utils.getUrlParams('city', props.location.search),
            id: Utils.getUrlParams('id', props.location.search)
        }
        
        this.headerBar = React.createRef();
        this.code = React.createRef();
        this.headerBar;
    }

    private checkUser = (props) => {
        const user = props.userInfo;
        
        // 获取url中的refer
        const refer = Utils.getUrlParams('refer', window.location.href);
        if (!(user && user.id)) {
            this.headerBar.setLoginShow();
        } else if (refer != 'webinar' && user.role != 'MEMBER' && user.role != 'ADMIN' && user.role != 'AGENT') {
            // if(confirm('Do you have a member code？')) {
            //     console.log(11)
            // }
            this.setState({
                codeModal: {
                    show: true,
                    syncShow: true
                }
            });
            return false;
            // toast( 'This page is for TopSky Club members only, please join and visit again, thank you!');
            // message.warning({
            //     content:
            //         'This page is for TopSky Club members only, please join and visit again, thank you!',
            //     duration: 5,
            //     className: 'ant-message-warning',
            // });
            // setTimeout(function () {
            //     window.location = '/PageTopSkyClub';
            // }, 5000);
        }
    };

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        // this.checkUser(nextProps);
    }

    componentDidMount(): void {
        this.checkUser(this.props);
        //console.log(this.props.location.hash)
        //window.addEventListener("hashchange", this.reRender(this.props.location.hash), false);
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
        }
        this.getState();
        /* 先取消圆桌论坛的视频显示 23-12-28 allon */
        // this.getRoundVideos();

        /* 增加TOP6 的视频显示 */
        this.getTopSixVideos();
    }
    
    getTopSixVideos = () => {
        const that = this;
        Request({
            // url: '/property?is_featured=Y&approval_status=APPROVED&limit=12',
            url: '/market/streaming_top?limit=6',
            method: 'GET'
        }).then((res) => {
            if (res.data.status === 200)
                that.setState({
                    topVideoList: res.data.data,
                });
        });
    };
    getLiveVideos = (city) => {
        const that = this;
        Request({
            url: `/market/streaming/${encodeURIComponent(city)}`,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET',
        }).then((res) => {
            if (res.data.status === 200)
                that.setState({
                    liveVideoList: res.data.data,
                });
        });
    };
    getCityItem = (allCities, params) => {
        let cityItem = {};
        const filterItems = allCities.filter(city => {
            return city.city == params.city;
        });
        if(filterItems.length > 0) {
            cityItem = filterItems[0];
        }
    
        return cityItem;
    };
    getState = async () => {
        const self = this;
        Request({
            url: '/market/states',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET',
        }).then(async (res) => {
                let states = extractAllStates(res.data.data);
                let groupByState = citiesByState(res.data.data);
                let allStates = formatStates(states);
                let allCities = groupByState;
                this.setState({
                    allStates,
                    allCities,
                });
                // 获取url中的城市参数并进行设置
                const urlParams = self.state.urlParams;
                // console.log('allCity', allCities)
                const cityItem = self.getCityItem(res.data.data, urlParams);
                console.log('cityItem', cityItem)
                if(JSON.stringify(cityItem) != '{}') {
                    this.onClickCity(cityItem);
                }
                this.setState({
                    isLoadUrlParams: true
                })
                // console.log(allStates, allCities, self.state.urlParams)
                // console.log("formatted ==> ", formattedCities, formattedStates);
                // const data = res.data;
                // if (data.status === 200) {
                //     toast(data.message);
                // }

            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };

    getVideos = (city) => {
        const self = this;
        // 获取url中的id参数并进行设置
        const id = Utils.getUrlParams('id', window.location.href);//self.state.urlParams;
        let url = `/market/video/${encodeURIComponent(city)}`;
        if(Boolean(id)) {
            url += `/${encodeURIComponent(id)}`;
        }
        this.setState({
            title: 'Market Analysis'
        })
        Request({
            url,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET',
        }).then((res) => {
            if (res.data.status === 200)
                self.setState({
                    videoList: res.data.data,
                });
        });
    };
    
    /* 先取消圆桌论坛的视频显示 23-12-28 allon */
    // getRoundVideos = () => {
    //     const that = this;
    //     this.setState({
    //         title: 'Round Table Forum'
    //     })
    //     Request({
    //         url: `/market/roundtable`,
    //         headers: {
    //             'x-api-key': process.env.X_API_KEY,
    //         },
    //         method: 'GET',
    //     }).then((res) => {
    //         const data = res.data
    //         console.log(data.data && (data.data.length > 0))
    //         if (data.status === 200)
    //             that.setState({
    //                 videoList: data.data,
    //                 isShowRound: data.data && (data.data.length > 0)
    //             });
    //     });
    // };

    changeBroswerUrl = (itemCity)  => {
        let self = this;
        setTimeout(() => {
            let {state, city} = self.state;
            if(self.state.isLoadUrlParams) {
                window.history.pushState({status:0}, '', '?city=' + city.city + '&state=' + state.name)
            }
            self.getVideos(itemCity);
            self.getLiveVideos(itemCity);
        }, 100)
    }

    onChangeCity = (item) => {
        this.setState({
            city: item,
        });
        this.changeBroswerUrl(item.city);
    };

    onChangeState = (e) => {
        let { allCities } = this.state;
        this.setState({
            state: e,
            city: e,
            // city: allCities[e.value] ? allCities[e.value][0] : {}
        });
        this.changeBroswerUrl(e.city);
    };

    onClickCity = (item) => {
        const formatItem = {
            name: item.state_name,
            value: item.state_id,
            city: item.city,
        };
        this.setState({
            city: formatItem,
            state: formatItem,
        });
        this.changeBroswerUrl(item.city);
    };
    /* 先取消圆桌论坛的视频显示 23-12-28 allon */
    // onChangeRound = () => {
    //     this.getRoundVideos();
    //     this.setState({
    //         isgetRound: true
    //     });
    // }

    closeLoginModal = (): void => {}
    handleHasCode() {
        const myCode = this.code.current.value;
        if (myCode) {
            console.log('Click has Code:' + myCode);
            if (myCode == this.PASS_CODE) {
                this.handleUpdateUser(this.props, myCode);
            } else {
                toast('Invalid code.');
            }
        } else {
            toast('Please input the code first.');
        }
    }
    handleNoCode(e) {
        console.log('Click no Code');
        window.location = '/PageTopSkyClub';
    }
    private handleUpdateUser = (props, code) => {
        const {userInfo, dispatch } = props;
        Request({
            url: '/user/' + userInfo.id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'PUT',
            data: {
                role: 'MEMBER',
                member_code: code,
            },
        })
            .then(async (res) => {
                if (res && res.data) {
                    const memberRole = res.data.data.role;
                    if (memberRole == 'MEMBER') {
                        toast('Your account status is updated, thank you for being a valueble member of TopSky Club!');
                        // message.success({
                        //     content:
                        //         'Your account status is updated, thank you for being a valueble member of TopSky Club!',
                        //     duration: 3,
                        //     className: 'success-notice-green',
                        // });
                        dispatch(setUserInfo(res.data.data))
                        setTimeout(function () {
                            window.location = '/PageMarket';
                        }, 1000);
                    }
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };
    handleScroll = (e) => {
        let { currentScrollTop, clientHeight, count } = this.state;
        let element = e.target;
        this.setState({
            clientHeight: element.clientHeight,
        });
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // do something at end of scroll
            this.setState({
                count: count + 4,
            });
        } else if (element.scrollTop === 0) {
            this.setState({
                count: 4,
            });
        }
    };

    render() {
        let { allStates, allCities, videoList, liveVideoList, count, codeModal, topVideoList, playSrc } = this.state;
        let cities = []
        if(allCities[this.state.state?.value]) {
            cities = allCities[this.state.state?.value].map(item => {
                return {
                    ...item,
                    name: item.city,
                    value: item.city
                }
            })
        }
        const renderVideos = () => {
            return videoList.map((item, index) => {
                // return tampaVideos.map((item, index) => {
                if (index < count)
                    return (
                        <div className="col-lg-4 col-md-6 col-sm-6 tx-center" data-id={index} key={index}>
                            {/* <div className="justify-content-between pt-3 pb-1">
                                <h5>{item.title}<br/><br/><p>{item.date_recorded}</p></h5>
                            </div> */}
                            <div className="video-image-background" style={{backgroundImage: item.theme_image_url ? `url(${item.theme_image_url})` : ''}}>
                                <div className="video-image-content">
                                    <h1>{item.title}</h1>
                                </div>
                                <a
                                    className="popup_video_btn popup-iframe popup-youtube"
                                    onClick={(e) => {
                                        this.setState({
                                            playVideo: true,
                                            playSrc: item.url
                                        });
                                    }}
                                >
                                    <i className="flaticon-play"/>
                                </a>
                            </div>
                            <div className="justify-content-between pt10">
                                <h5 className="video-title">{item.title}</h5>
                                <p>Starting at {item.date_recorded}</p>
                            </div>
                            <hr></hr>
                            {/*<iframe src={item.url} title={item.title} width="900" height="600" />*/}
                            {/* <video
                                key={item.url}
                                width="1068"
                                height="600"
                                controls
                                controlsList="nodownload"
                            >
                                <source src={item.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <hr></hr> */}
                        </div>
                    );
            });
        };

        const renderLiveVideos = () => {
            return liveVideoList.map((item, index) => {
                // return tampaVideos.map((item, index) => {
                if (index < count)
                    return (
                        <div className="col-lg-4 col-md-6 col-sm-6 tx-center" data-id={index} key={index}>
                            {/* <div className="justify-content-between pt-3 pb-1">
                                <h5>{item.title}<br/><br/><p>{item.date_recorded}</p></h5>
                            </div> */}
                            <div className="video-image-background" style={{backgroundImage: item.theme_image_url ? `url(${item.theme_image_url})` : ''}}>
                                <div className="video-image-content">
                                    <h1>{item.title}</h1>
                                </div>
                                <a
                                    className="popup_video_btn popup-iframe popup-youtube"
                                    onClick={(e) => {
                                        this.setState({
                                            playVideo: true,
                                            playSrc: item.url
                                        });
                                    }}
                                >
                                    <i className="flaticon-play"/>
                                </a>
                            </div>
                            <div className="justify-content-between pt10">
                                <h5 className="video-title">{item.title}</h5>
                                <p>Starting at {item.date_recorded}</p>
                            </div>
                            <hr></hr>
                            {/* <iframe width="1080" height="900" src={item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <hr></hr> */}
                        </div>
                    );
            });
        };
        
        const renderTopVideos = () => {
            return topVideoList.map((item, index) => {
                // return tampaVideos.map((item, index) => {
                if (index < 6)
                    return (
                        <div className="col-lg-4 col-md-6 col-sm-6" data-id={index} key={index}>
                            <div className="video-image-background" style={{backgroundImage: item.theme_image_url ? `url(${item.theme_image_url})` : ''}}>
                                <div className="video-image-content">
                                    <h1>{item.title}</h1>
                                </div>
                                <a
                                    className="popup_video_btn popup-iframe popup-youtube"
                                    onClick={(e) => {
                                        this.setState({
                                            playVideo: true,
                                            playSrc: item.url
                                        });
                                    }}
                                >
                                    <i className="flaticon-play"/>
                                </a>
                            </div>
                            {/* <img src={item.theme_image_url}/> */}
                            {/* allow: autoplay; */}
                            {/* <iframe width="360" height="300" src={item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <div className="justify-content-between pt10">
                                <h5 className="video-title">{item.title}</h5>
                                <p>Starting at {item.date_recorded}</p>
                            </div>
                            <hr></hr>
                        </div>
                    );
            });
        };

        const renderStates = () => {
            return allStates.map((item, index) => {
                return (
                    <div className="col-sm-3 col-md-3 col-xl-2 all-item" data-id={index} key={index}>
                        <label className={`cursor-hover ${item.name === this.state.state?.name ? 'active' : ''}`}  onClick={() => {this.onChangeState(item)}}>{item.name}</label>
                    </div>
                );
            });
        }
        const renderCities = () => {
            if(!Boolean(this.state.state?.value) || !Boolean(allCities[this.state.state?.value])) {
                return '';
            }
            return allCities[this.state.state?.value].map((item, index) => {
                return (
                    <div className="col-sm-3 col-md-3 col-xl-2 all-item" data-id={index} key={index}>
                        <label className={`cursor-hover ${item.city === this.state.city?.city ? 'active' : ''}`}  onClick={() => {this.onChangeCity(item)}}>{item.city}</label>
                    </div>
                );
            });
        }
        
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '90px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e: any) => {
                        this.headerBar = e;
                    }}
                />
                <div
                    style={{
                        display: this.state.playVideo ? 'block' : 'none',
                        width: '100vw',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        overflow: 'hidden',
                        background: 'rgba(0,0,0,0.2)'
                    }}
                    onClick={() => {
                        this.setState({
                            playVideo: false,
                            playSrc: ''
                        });
                    }}
                >
                    <div className={'mfp-container mfp-iframe-holder video-mfp'}>
                        <div className={'mfp-content'}>
                            <div className="mfp-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        this.setState({
                                            playVideo: false,
                                            playSrc: ''
                                        });
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="line"></div>
                            </div>
                            <div className={'mfp-iframe-scaler'}>
                                <iframe
                                    className="mfp-iframe"
                                    src={playSrc}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={
                        'sign_up_modal modal fade bd-example-modal-lg ' +
                        (codeModal.syncShow ? 'show' : '')
                    } tabIndex="-1" role="dialog" style={
                        codeModal.show ? {display: 'block',  paddingRight: '17px'}: {}
                    }
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            {/*  先取消圆桌论坛的视频显示 23-12-28 allon 
                            <div
                                className="modal-header"
                                style={{
                                    top: '-25px',
                                    right: '-25px',
                                }}
                            >
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.closeLoginModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-body container pb20">
                                {/* <div className="row"> */}
                                    <div className="form_grid">
                                        <h4 className="mb5">Do you have a code?</h4>
                                        <p>
                                            <small>
                                                Code is provided in wechat group for existing TopSky club
                                                members
                                            </small>
                                        </p>
                                        <form onSubmit={() => this.handleHasCode()}>
                                            <div className="form1 form-container container">
                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                id="code"
                                                                name="code"
                                                                className="form-control"
                                                                required={true}
                                                                type="text"
                                                                ref={this.code}
                                                                placeholder="Pass Code"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className="btn btn-lg btn-thm"
                                                                onClick={() => this.handleHasCode()}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className="btn dbxshad btn-lg btn-thm white"
                                                                onClick={(e) => this.handleNoCode(e)}
                                                            >
                                                                I Don't Have Code
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                

                <section className="our-terms bgc-f7">
                    <div className="main-container" onScroll={this.handleScroll}>
                        <div>
                            <h1 className="d-flex justify-content-center">{this.state.title}</h1>

                            <div className="row">
                                <div className="col-12">
                                    {Boolean(this.state.city) || Boolean(this.state.isgetRound) ? 
                                        '' : (
                                        <div className="row">
                                            {renderTopVideos()}
                                        </div>)
                                    }
                                    {/* {Boolean(this.state.isShowRound) ? (
                                        <div className="btn-roundtable"
                                            onClick={() =>
                                                this.onChangeRound()
                                            }>
                                            Roundtable Videos
                                        </div>
                                    ): ''} */}
                                    <div className="select-pane select-market row">
                                        <div className="col-xl-2 col-lg-0"></div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                            <div className="ui_kit_select_search form-group group-market">
                                                <label className="title title-market">Select State</label>
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    buttonClass="select-btn"
                                                    title={this.state.state?.name || 'State'}
                                                    onSelect={this.onChangeState}
                                                    options={allStates}
                                                />
                                            </div>
                                        </div>
                                        {/* {Boolean(this.state.state?.value) && Boolean(allCities[this.state.state?.value]) && ( */}
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                                <div className="ui_kit_select_search form-group group-market">
                                                    <label className="title title-market">
                                                        Select Cities
                                                    </label>
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        buttonClass="select-btn"
                                                        title={this.state.city?.city || 'City'}
                                                        onSelect={this.onChangeCity}
                                                        options={cities}
                                                    />
                                                    {/* {allCities[this.state.state?.value].map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                className={`city-list-item ${
                                                                    item.city ===
                                                                    this.state.city?.city
                                                                        ? 'active-city-item'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    this.onChangeCity(item)
                                                                }
                                                            >
                                                                {item.city}
                                                            </li>
                                                        )
                                                    )} */}
                                                </div>
                                            </div>
                                        {/* )} */}
                                    </div>
                                </div>
                                {Boolean( this.state.isLoadUrlParams) || 1 ?
                                    <div className="col-12">
                                        {Boolean(this.state.city) || Boolean(this.state.isgetRound) ? (
                                            <div
                                                id="searched-result"
                                                className="searched-result"
                                                // onScroll={this.handleScroll}
                                            >   
                                                {/* <div className="select-container">
                                                    <div className="state-box" style={{display : !Boolean(allStates) || allStates.length < 1 ? 'none' : ''}}>
                                                        <div className="state-box-title">SELECT STATE:</div>
                                                        <div className="row">
                                                            {renderStates()}
                                                        </div>
                                                    </div>
                                                    <div className="state-box" style={{display : !Boolean(this.state.state?.value) || !Boolean(allCities[this.state.state?.value]) ? 'none' : ''}}>
                                                        <hr />
                                                        <div className="state-box-title">SELECT CITY:</div>
                                                        <div className="row">
                                                            {renderCities()}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {Boolean(this.state.liveVideoList.length > 0) ? (<h3>Live Streaming</h3>) : (<div/>)}
                                                <div className="row">{renderLiveVideos()}</div>
                                                <div className="row">{renderVideos()}</div>

                                                {/* <div className="border-top my-5"></div> */}
                                            </div>
                                        ) : (
                                            <div id="original">
                                                {/* <div className="state-box" style={{display : !Boolean(allStates) || allStates.length < 1 ? 'none' : ''}}>
                                                    <div className="state-box-title">SELECT STATE:</div>
                                                    <div className="row">
                                                        {renderStates()}
                                                    </div>
                                                </div>
                                                <div className="state-box" style={{display : !Boolean(this.state.state?.value) || !Boolean(allCities[this.state.state?.value]) ? 'none' : ''}}>
                                                    <div className="state-box-title">SELECT CITY:</div>
                                                    <div className="row">
                                                        {renderCities()}
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    {SixCities.map((item, index) => (
                                                        <div className=" col-lg-4 col-md-6 col-sm-6">
                                                            <div
                                                                className="properti_city"
                                                                onClick={() => this.onClickCity(item)}
                                                            >
                                                                <div className="thumb">
                                                                    <img
                                                                        className="img-fluid w100"
                                                                        src={cityImages[item.city]}
                                                                        alt="alt"
                                                                        style={{
                                                                            height: '330px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="overlay">
                                                                    <div className="details">
                                                                        <h4>{item.city}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {renderLiveVideos()}
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </div>
                </section>
                <Agent />
                <Site />
                <Footer />
            </div>
        );
    }
}

export default Market;
