import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Agent.less';
const Agent = (props) => {
    const { t } = useTranslation();
    return (
        <section className="start-partners bgc-thm pt50 pb50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="start_partner tac-smd">
                            {/* <h2>Join Topsky Home</h2> */}

                            <ul>
                                <li>
                                    <p>Full service, carefree investment from anywhere</p>
                                </li>
                                <li>
                                    <p>Data-driven approach to maximize your return</p>
                                </li>
                                <li>
                                    <p>
                                        Exclusive access to locked zoom recordings, market trend
                                        reports, research data to zip-code level
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="parner_reg_btn text-right tac-smd">
                            <a className="btn btn-thm2" href="/PageTopSkyClub">
                                Get Free Consultation
                                {/*Join Topsky Home*/}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Agent;
