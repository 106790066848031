import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Props, State } from './interface';
import SideBar from '../../components/SideBar/SideBar';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Request from '../../components/Utils/Request';
import { WithUtils } from 'components/Utils/Utils';
import { message } from 'antd';
import Select from 'components/Select/Select';
import {
    citiesByState,
    extractAllStates,
    formatCities,
    formatStates,
} from 'components/Utils/TypesData';
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
@withRouter
@WithUtils('UUID')
class Page extends React.Component<Props, State> {
    state: State = {
        title: '',
        date_recorded: '',
        video_url: '',
        allCities: {},
        allStates: [],
        city: null,
        state: null,
        allTypes: [],
        video_type: null,
        validState: true,
        validCity: true,
        theme_image_url: '',
        image: null,
        video_id: ''
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        if (typeof document !== 'undefined') {
            require('shared/assets/js/wow.min.js');
        }
        this.setState({
            allTypes: [{
                name: 'Zoom Recording Videos',
                value: 'ZOOM_RECORDING'
            }, {
                name: 'RoundTable Videos',
                value: 'ROUND_TABLE'
            }, {
                name: 'Stream Event',
                value: 'ZOOM_STREAMING_EVENT'
            }],
            video_type: {
                name: 'Zoom Recording Videos',
                value: 'ZOOM_RECORDING'
            }
        })
        this.getState();
    }

    getState = async () => {
        Request({
            url: '/market/states',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET',
        }).then(async (res) => {
                let states = extractAllStates(res.data.data);
                let groupByState = citiesByState(res.data.data);
                let allStates = formatStates(states);
                let allCities = groupByState;
                this.setState({
                    allStates,
                    allCities,
                });
                // console.log("formatted ==> ", formattedCities, formattedStates);
                const data = res.data;
                // toast(data.message);
                /*if (data.status === 200) {
                    message.success({
                        content: data.message,
                        duration: 3,
                        className: "success"
                    });
                }*/
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };
    //获取input file的文件地址
    getObjectURL = (file) => {
    　　var url = null;
    　　if (window.createObjectURL != undefined) {//basic
    　　    url = window.createObjectURL(file);
    　　} else if (window.URL != undefined) {//mozilla(firefox)兼容火狐
    　　    url = window.URL.createObjectURL(file);
    　　} else if (window.webkitURL != undefined) {//webkit or chrome
    　　    url = window.webkitURL.createObjectURL(file);
    　　}
    　　return url;
　　}
    upload = async () => {
        const { video_id, image } = this.state
        const formData = new FormData();
        if(!image) {
            return false;
        }
        formData.append('parent_id', video_id);
        formData.append('image_type', 'MARKETVIDEO');
        formData.append('file', image);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data;
        });
        // this.getUser(userInfo.id);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let { title, video_url, state, city, validCity, validState, date_recorded, video_type, theme_image_url } = this.state;
        if (!city) {
            this.setState({ validCity: false });
        }
        if (!state) {
            this.setState({ validState: false });
        }
        /*转换时间*/
        let date = new Date(date_recorded);
        let dateString = date.toISOString();
        if (validState && validCity) {
            let reqData = {
                url: video_url,
                title: title,
                city: city.name,
                state: state.value,
                date_recorded: dateString,
                video_type: video_type.value,
                theme_image_url: ''
            };

            Request({
                url: `/market/load_video`,
                headers: {
                    'x-api-key': process.env.X_API_KEY,
                },
                method: 'POST',
                data: reqData,
            }) .then(async ({data: res})  => {
                    // const data = res.data;
                    if(res.status === 200) {
                        /**
                         * 添加成功的话，需要进行一个回显的动作
                         */
                        // this.showWebinarEvent();                
                        this.setState({
                            video_id: res.id,
                            title: '',
                            date_recorded: '',
                            video_url: '',
                            city: null,
                            state: null,
                            validState: true,
                            validCity: true,
                            theme_image_url: ''
                        })
                        this.upload();
                        toast(res.message || 'Add video successfully!');
                    }
                    else {
                        let errors = [];
                        for(let i in res.message ) {
                            errors.push(res.message[i]);
                        }
                        toast(errors.join(',') || 'Add failed')
                    }
                    // if (data.status === 200) {
                    //     toast(data.message ? data.message : 'success');
                    //     // message.success({
                    //     //     content: data.message ? data.message : 'success',
                    //     //     duration: 0.5,
                    //     //     className: 'success-notice-green',
                    //     // });
                    // }
                })
                .catch((err) => console.log('err ==> ', err));
        }
    };

    onChangeState = (item) => {
        const city = {
            name: item.city,
            value: item.value,
            state: item.name,
        };
        this.setState({
            state: item,
            city,
            validState: true,
            validCity: true,
        });
    };

    onChangeCity = (item) => {
        this.setState({
            city: item,
            validCity: true,
        });
    };
    onChangeType = (item) => {
        this.setState({
            video_type: item
        })
    };

    render(): React.ReactNode {
        let { title, video_url, allStates, allCities, city, state, allTypes, video_type, theme_image_url } = this.state;

        return (
            <div className="wrapper">
                <HeaderBar animate={false} />
                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-sm-12 col-lg-8 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 mb10">
                                        <div className="breadcrumb_content style2 mb30-991">
                                            <h2 className="breadcrumb_title">Marketing Videos</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review mb40">
                                            <div className="property_table">
                                                <div className="table-responsive mt0">
                                                    <form
                                                        onSubmit={this.handleSubmit}
                                                        // method="post"
                                                    >
                                                        <div className="form1 form-container container">
                                                            <div className="row">
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="address"
                                                                        className="label-text"
                                                                    >
                                                                        Title
                                                                    </label>
                                                                    <input
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        maxLength={200}
                                                                        value={title}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                title:
                                                                                    e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <label
                                                                        htmlFor="name"
                                                                        className="label-text"
                                                                    >
                                                                        Video URL
                                                                    </label>
                                                                    <input
                                                                        id="video_url"
                                                                        name="video_url"
                                                                        className="form-control"
                                                                        required={true}
                                                                        type="text"
                                                                        maxLength={200}
                                                                        value={video_url}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                video_url:
                                                                                    e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 col-xl-6">
                                                                    <div className="form-group ui_kit_select_search form-group">
                                                                        <label
                                                                            htmlFor="address"
                                                                            className="label-text"
                                                                        >
                                                                            Select State
                                                                        </label>
                                                                        <Select
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            title={
                                                                                this.state.state
                                                                                    ?.name || 'State'
                                                                            }
                                                                            onSelect={
                                                                                this.onChangeState
                                                                            }
                                                                            options={allStates}
                                                                        />
                                                                        {!this.state.validState && (
                                                                            <div className="color-red">
                                                                                Please select state
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="form-group ui_kit_select_search form-group">
                                                                        <label
                                                                            htmlFor="address"
                                                                            className="label-text"
                                                                        >
                                                                            Select City
                                                                        </label>
                                                                        <Select
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            title={city?.name || 'City'}
                                                                            onSelect={this.onChangeCity}
                                                                            options={formatCities(
                                                                                allCities[
                                                                                    state?.value
                                                                                ] || []
                                                                            )}
                                                                        />
                                                                        {!this.state.validCity && (
                                                                            <div className="color-red">
                                                                                Please select city
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="form-group ui_kit_select_search form-group">
                                                                        <label
                                                                            htmlFor="address"
                                                                            className="label-text"
                                                                        >
                                                                            Select Type
                                                                        </label>
                                                                        <Select
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            title={video_type?.name || 'Zoom Recording Videos'}
                                                                            onSelect={this.onChangeType}
                                                                            options={allTypes}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="address"
                                                                            className="label-text"
                                                                        >
                                                                            Date Recorded
                                                                        </label>
                                                                        <input
                                                                            id="title"
                                                                            name="title"
                                                                            className="form-control"
                                                                            required={true}
                                                                            type="datetime-local"
                                                                            maxLength={200}
                                                                            value={
                                                                                this.state.date_recorded
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    date_recorded: moment(
                                                                                        e.target.value.toString()
                                                                                    ).format(
                                                                                        'YYYY-MM-DD HH:mm'
                                                                                    ),
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-xl-6">
                                                                    <div className="my_profile_setting_input form-group" style={{
                                                                        marginBottom: '0'
                                                                    }}>
                                                                        <label htmlFor="propertyLastname">
                                                                            Theme Image
                                                                        </label>
                                                                    </div>
                                                                    <div className="wrap-custom-file" style={{
                                                                        border: '1px solid #f1f1f1',
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        <input
                                                                            type="file"
                                                                            name="image1"
                                                                            id="image1"
                                                                            accept=".gif, .jpg, .png"
                                                                            onChange={async (e) => {
                                                                                for (const file of e.currentTarget.files) {
                                                                                    this.setState({
                                                                                        theme_image_url: this.getObjectURL(file) || ''
                                                                                    })
                                                                                    this.setState({
                                                                                        image: file
                                                                                    })
                                                                                    // await this.upload(file);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label
                                                                            htmlFor="image1"
                                                                            style={{
                                                                                backgroundImage: `url(${theme_image_url})`,
                                                                                backgroundSize: 'cover',
                                                                            }}
                                                                        >
                                                                            <span style={{
                                                                                width: '80%',
                                                                                padding: '0 10px',
                                                                                left: '50%',
                                                                                transform: 'translateX(-50%)'
                                                                            }}>
                                                                                <i className="flaticon-download" />{' '}
                                                                                Upload Theme Image{' '}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <p>*minimum 260px x 260px</p>
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn2 btn-block w-100"
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default Page;
