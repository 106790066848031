import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import GoogleMap from 'shared/components/GoogleMap/GoogleMap';
import { connect } from 'react-redux';
import Select from '../../components/Select/Select';
import Request from '../../components/Utils/Request';
import AutoComplete from 'react-google-autocomplete';
import {message} from "antd";
import {types} from 'shared/components/Utils/TypesData'
import {toast} from "react-toastify";

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageMSpeakerAdd extends React.Component<Props, State> {
    state: State = {
        form: {
            name: '',
            description: '',
            title: '',
            name_en: '',
            description_en: '',
            pfp: ''
        }
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };

    queryImages = () => {
        if (this.state.form.id)
        Request({
            url: '/image',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                parent_id: this.state.form.id,
                image_type: 'BUILDER',
            },
        }).then((res) => {
            this.setState({
                images: res.data.data
            })
        });
    };

    upload = async (file) => {
        const formData = new FormData();
        formData.append('parent_id', this.state.form.id);
        formData.append('image_type', 'BUILDER');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data
        });
        this.queryImages();
    };

    submit = () => {
        const form = this.state.form;
        Request({
            url: '/speaker',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then((res) => {
            console.log(res, res.data, res.data.status)
            if (res.data.status == 200) {
                // 200的状态值就进行跳转
                toast(res.message || 'Add the speaker successfully!')
                this.props.history.push({
                    pathname: '/PageMSpeakers'
                })
            }
        });
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            this.queryImages()
        }
    }

    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Add New Speaker</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Name
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.name}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        name: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyUrl">
                                                            <span className="text-danger">*</span> Description
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyUrl"
                                                            required
                                                            value={form.description}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    description: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyUrl">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyUrl"
                                                            required
                                                            value={form.title}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    title: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* {
                                            (() => {
                                                if (typeof form.id !== 'undefined') {
                                                    return (
                                                        <div className="my_dashboard_review mt30">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="mb30">Property media</h4>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <ul className="mb0">
                                                                        {
                                                                            images?.map(item => {
                                                                                return (
                                                                                    <li className="list-inline-item" key={item.id}>
                                                                                        <div className="portfolio_item">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={item.src}
                                                                                                alt="404"
                                                                                            />
                                                                                            <div
                                                                                                className="edu_stats_list"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Delete"
                                                                                                data-original-title="Delete"
                                                                                            >
                                                                                                <a onClick={() => {

                                                                                                }}>
                                                                                                    <span className="flaticon-garbage" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="portfolio_upload" onClick={() => {
                                                                        document.getElementById('myfile').click()
                                                                    }}>
                                                                        <input type="file" id="myfile" multiple="multiple" onChange={async (e) => {
                                                                            for (const file of e.currentTarget.files) {
                                                                                await this.upload(file)
                                                                            }
                                                                        }}/>
                                                                        <div className="icon">
                                                                            <span className="flaticon-download" />
                                                                        </div>
                                                                        <p>Drag and drop images here</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        } */}
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMSpeakerAdd;
