import React from 'react';
import './index.less'
import { Props, State } from './interface';
import Request from 'components/Utils/FormRequest';
import {toast} from "react-toastify";
import { Result, Button, message } from 'antd';
import * as Utils from 'components/Utils/Utils';
import {connect} from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import { setJSAPI } from 'components/Utils/Utils';
import { setUserInfo } from '../../store/userInfo/actions';

@connect(({userInfo}) => {
    return {userInfo};
})
class PageMWebinarLanding extends React.Component<Props, State> {
    headerBar;
    PASS_CODE = 'GETRICH';
    code;
    state: State = {
        title: '',
        sub_title: '',
        summary_title: '',
        summary_content: '',
        event_time: '',
        duration: '',
        speaker_name: '',
        speaker_description: '',
        profile_image_url: '',
        form: {
            email: '',
            first_name : '',
            last_name: '',
            wechat_id: '',
            phone: ''
        },
        webinar_id: 0,
        playback_url: '',
        video_id: 0,
        userInfo: this.props.userInfo ? this.props.userInfo : {},
        isRegisterSuccess: false,
        loadingRegister: false,
        messageTitle: '',
        codeModal: {
            show: false,
            syncShow: false,
        }
    }
    constructor(props) {
        super(props)
        this.headerBar = React.createRef();
        this.code = React.createRef();
        this.headerBar;
    }
    setRegister() {
        this.setState({
            form: {
                email: this.props.userInfo ? this.props.userInfo.email : '',
                first_name: this.props.userInfo ? this.props.userInfo.first_name : '',
                last_name: this.props.userInfo ? this.props.userInfo.last_name : '',
                wechat_id: this.props.userInfo ? this.props.userInfo.last_name : '',
                phone: this.props.userInfo ? this.props.userInfo.phone_no : ''
            }
        })
    }
    getWebniar = async () => {
        const {webinar_id} = this.state;
        Request({
            url: '/webinar/' + webinar_id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'GET'
        })
        .then(async (res: any) => {
            const rs = res.data
            if (res && res.data) {
                this.setState({
                    title: rs.title,
                    sub_title: rs.sub_title,
                    summary_title: rs.summary_title,
                    summary_content: rs.summary_content,
                    event_time: rs.event_time,
                    duration: rs.duration,
                    speaker_name: rs.speaker_name,
                    speaker_description: rs.speaker_description,
                    profile_image_url: rs.profile_image_url,
                    playback_url: rs.playback_url,
                    video_id: rs.video_id
                })
            }
            setJSAPI({
                title: res && res.data && rs.title || 'TopSky Home',
                desc: res && res.data && rs.summary_content || 'Helping you find your property!',
                link: window.location.href,
                imgUrl: res && res.data && rs.profile_image_url || 'https://topskyhome.s3.us-west-2.amazonaws.com/webinar/33/webinar_1701829442_387f658b458a230ae504.jpeg'
            });
        })
        .catch((err) => {
            console.log('error ==> ', err)
        });
    }
    formatEventTime = (event_time) => {
        const WeekArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let d = new Date(event_time)
        let week = WeekArr[d.getDay()]
        let month = d.toDateString().split(' ')[1]
        let date = d.getDate()
        let year = d.getFullYear()
        let hours = d.getHours()
        let hour = (hours < 2 ? '0' : '') + hours
        let minutes = d.getMinutes()
        let minute = (minutes < 2 ? '0' : '') + minutes
        let seconds = d.getSeconds()
        let second = (seconds < 2 ? '0' : '') + seconds
        return {
            date: week + ', ' + month + ' ' + date + ', ' + year,
            // time: hour + ':' + minute + ':' + second + ' Pacific Daylight Time',
            time: hour + ':' + minute + ' Pacific Daylight Time'
        }
    }
    setForm = (form) => {
        this.setState({
            form
        })
    };
    submit = () => {        
        const {webinar_id, form, loadingRegister} = this.state;
        if(loadingRegister) {
            message.warn({
                content: '提交中，请稍等片刻',
                className: 'custom-class',
                style: {
                  marginTop: '20vh',
                },
            });
            return false;
        }
        this.setState({loadingRegister: true});
        Request({
            url: '/webinar/register',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: {
                ...form,
                webinar_id: webinar_id
            },
        }).then(({data: res}) => {
            this.setState({loadingRegister: false});
            if(res.status === 200) {
                // toast(res.message || 'Add crm user successfully!');
                // setTimeout(() => {
                //     window.location.href = '/';
                // }, 1000)
                // this.setState({
                //     form: {
                //         email: '',
                //         first_name : '',
                //         last_name: '',
                //         wechat_id: '',
                //         role : 'USER'
                //     }
                // })
                this.setState({
                    messageTitle: res.message || 'Please check your email to get the Zoom Link. And thank you for the registration.',
                    isRegisterSuccess: true
                })
            }
            else {
                let errors = [];
                for(let i in res.message ) {
                    errors.push(res.message[i]);
                }
                // alert(errors.join(',') || 'Add failed')
            }
        });
    };
    componentDidMount(): void {
        let wid = parseInt(Utils.getUrlParams('id', this.props.location.search) || '0')
        this.setState({
            webinar_id: wid
        })
        if(wid == 0) {
            toast('Landing error!')
            window.location.href = '/';
        }
        else {
            setTimeout(() => {
                this.getWebniar()
            }, 0)
        }
        this.setRegister()
    }
    /* 确认用户是否登录，如未登录要弹窗 */
    private checkUser = (props) => {
        const user = props.userInfo;
        if (!(user && user.id)) {
            this.headerBar.setLoginShow();
            return false;
        } 
        // else if (user.role != 'MEMBER' && user.role != 'ADMIN' && user.role != 'AGENT') {
        //     // if(confirm('Do you have a member code？')) {
        //     //     console.log(11)
        //     // }
        //     this.setState({
        //         codeModal: {
        //             show: true,
        //             syncShow: true
        //         }
        //     });
        //     return false;
        //     // toast( 'This page is for TopSky Club members only, please join and visit again, thank you!');
        //     // message.warning({
        //     //     content:
        //     //         'This page is for TopSky Club members only, please join and visit again, thank you!',
        //     //     duration: 5,
        //     //     className: 'ant-message-warning',
        //     // });
        //     // setTimeout(function () {
        //     //     window.location = '/PageTopSkyClub';
        //     // }, 5000);
        // }
        return true;
    };
    
    handleHasCode() {
        const myCode = this.code.current.value;
        if (myCode) {
            console.log('Click has Code:' + myCode);
            if (myCode == this.PASS_CODE) {
                this.handleUpdateUser(this.props, myCode);
            } else {
                toast('Invalid code.');
            }
        } else {
            toast('Please input the code first.');
        }
    }
    handleNoCode(e) {
        console.log('Click no Code');
        window.location.href = '/PageTopSkyClub';
    }
    private handleUpdateUser = (props, code) => {
        const {userInfo, dispatch } = props;
        Request({
            url: '/user/' + userInfo.id,
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            method: 'PUT',
            data: {
                role: 'MEMBER',
                member_code: code,
            },
        })
            .then(async (res) => {
                if (res && res.data) {
                    const memberRole = res.data.data.role;
                    if (memberRole == 'MEMBER') {
                        toast('Your account status is updated, thank you for being a valueble member of TopSky Club!');
                        // message.success({
                        //     content:
                        //         'Your account status is updated, thank you for being a valueble member of TopSky Club!',
                        //     duration: 3,
                        //     className: 'success-notice-green',
                        // });
                        dispatch(setUserInfo(res.data.data))
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 1000);
                    }
                }
            })
            .catch((err) => {
                console.log('error ==> ', err);
            });
    };
    render() {
        const { form, title, sub_title, summary_title, summary_content, event_time, duration, speaker_name, speaker_description, playback_url, video_id, profile_image_url, userInfo, isRegisterSuccess, loadingRegister, messageTitle } = this.state
        let date = this.formatEventTime(event_time.replace(/-/g, '/'));
        let now = new Date();
        let banner_time = new Date(event_time.replace(/-/g, '/') + ' PST'); // 后台存储为UTC时间
        let difference_time = banner_time.getTime() - now.getTime() + 3 * 60 * 60 * 1000;
        return (
            <div className="wrapper pt100">
                <HeaderBar
                    animate={false}
                    onRef={(e: any) => {
                        this.headerBar = e;
                    }}
                />
                <div className="webinar-container">
                    {/* <div className="banner-container row">
                        <img alt="Company Banner" src="/event/11/53/65/9/rt/1/logo/event/buildingwebinar.png?t=830534760000" />
                    </div> */}
                    <div className="banner-container">
                        <div className="logo-title">TopSky Webinar Series</div>
                        <div className="nav-title">{title}</div>
                        <div className="subtitle js-header">{sub_title}</div>
                    </div>
                    <div id="sections-layout-container" className="row sections-layout-container">
	                    <div id="right-col" className="col-xs-12 col-sm-6 col-sm-push-6 right-col-border">
	                        <div className="overview-section section">
                                <h1 className="section-header js-header">Overview</h1>
                                <p>
                                    <strong>Title: </strong>
                                    <span>{title}</span>
                                </p>
                                <p>
                                    <strong>Date: </strong>
                                    <span>{date.date}</span>
                                </p>
                                <p>
                                    <strong>Time: </strong>
                                    <span>{date.time}</span>
                                </p>
                                <p>
                                    <strong>Duration: </strong>
                                    <span>{duration} hours</span>
                                </p>
                            </div>
                            <div className="registration-section section">
	                            <div className="header-container">
                                    <div className="section-header js-header data-section-label">{difference_time > 0 ? 'Register Now' : '' }</div>
                                    <div className="login-link-container">
                                        <a className="already-registered" href="/">
                                            <div className="js-login-link" onClick={(e)=>{
                                                window.location.href = "/";
                                            }}>
                                                <strong>Back to Homepage</strong>
                                            </div>
                                        </a>
                                    </div>
	                            </div>
                                {/* <div className="s-time" style={{display: playback_url && difference_time < 0 && (this.props.userInfo && (this.props.userInfo.role != 'USER')) ? '' : 'none'}} onClick={(e) => {
                                        window.open(playback_url);
                                        e.stopPropagation();
                                        e.cancelBubble = true;
                                        return false;
                                    }}><i className="flaticon-play" /> Click to Play back video.</div> */}
                                    
                                {/* <div className="s-992-landing" style={{display: playback_url && difference_time < 0 && (this.props.userInfo && (this.props.userInfo.role != 'USER')) ? '' : 'none'}}> */}
                                <div className="s-992-landing" style={{display: playback_url && difference_time < 0 ? '' : 'none'}}>
                                    <button onClick={(e) => {
                                        if(this.checkUser(this.props)) {
                                            window.open(playback_url + (video_id ? '&id=' + video_id  + '&refer=webinar': ''));
                                        }
                                        e.stopPropagation();
                                        e.cancelBubble = true;
                                        return false;
                                    }}>Watch the Video</button>
                                </div>
                                <div >
                                    <Result
                                        status="success" style={{display: isRegisterSuccess ? 'block' : 'none'}}
                                        title="Register Successfully!"
                                        subTitle={messageTitle}
                                        extra={[
                                            <button className="btn btn-default submit-btn js-submit" onClick={() => {
                                                this.props.history.push({
                                                    pathname: '/'
                                                });
                                            }}>
                                                <strong>Back to Homepage</strong>
                                            </button>,
                                            <button className="btn btn-default submit-btn " onClick={() => {
                                                this.props.history.push({
                                                    pathname: '/PageMWebinarCalendar'
                                                });
                                            }}>
                                                <strong>Back to Webinar</strong>
                                            </button>
                                        ]}
                                    />
                                </div>
	                            <div className="js-form-container" style={{display: !isRegisterSuccess && difference_time > 0 ? '' : 'none'}}>
		                            {/* <form className="form-horizontal reg-form" novalidate="true" method="POST" action="/eventRegistration/eventRegistrationServlet"> */}
                                    <div className="form-horizontal reg-form">
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="first_name" placeholder="First Name *" value={form.first_name}
                                            onChange={(e) => {
                                                this.setForm({
                                                    ...form,
                                                    first_name: e.target.value,
                                                })
                                            }}/>
                                        </div>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="last_name" placeholder="Last Name *" value={form.last_name}
                                            onChange={(e) => {
                                                this.setForm({
                                                    ...form,
                                                    last_name: e.target.value,
                                                })
                                            }}/>
                                        </div>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="email" placeholder="Email *" value={form.email}
                                            onChange={(e) => {
                                                this.setForm({
                                                    ...form,
                                                    email: e.target.value,
                                                })
                                            }}/>
                                        </div>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="wechat_id" placeholder="Wechat ID *" value={form.wechat_id}
                                            onChange={(e) => {
                                                this.setForm({
                                                    ...form,
                                                    wechat_id: e.target.value,
                                                })
                                            }}/>
                                        </div>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="phone" placeholder="Phone Number *" value={form.phone}
                                            onChange={(e) => {
                                                this.setForm({
                                                    ...form,
                                                    phone: e.target.value,
                                                })
                                            }}/>
                                        </div>

                                        <div className="submit-container">
                                            <div id="reg-recaptcha" className="g-recaptcha"></div>
                                            <p>
                                                <span className="required">*</span> Denotes required.
                                            </p>
                                            <button className="btn btn-default submit-btn js-submit" onClick={() => {
                                                this.submit()
                                            }}>
                                                <LoadingOutlined style={{display: loadingRegister ? '' : 'none'}}/> <strong>REGISTER</strong>
                                            </button>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
	                    <div id="left-col" className="col-xs-12 col-sm-6 col-sm-pull-6">
                            <div className="summary-section section">
                                <div className="section-header js-header">Summary</div>
	                            <span className="summary">
                                    <h4><span className="txt-black">{summary_title}</span></h4>

                                    <p>
                                        <span className="txt-summary-desc" dangerouslySetInnerHTML={{ __html: summary_content }}></span>
                                    </p>

                                    <h4><span className="txt-black">Speakers:</span></h4>
                                    <p>
                                        <span className="txt-black"><strong>{speaker_name}</strong></span>
                                    </p>
                                    <p>
                                        <span className="txt-summary-desc" dangerouslySetInnerHTML={{__html: speaker_description}}></span>
                                    </p>
                                    {profile_image_url && (<p>
                                        <span className="txt-black">
                                            <strong>
                                                <img alt="" src={profile_image_url} className="image" />
                                            </strong>
                                        </span>
                                    </p>)}
                                    <div className="clearFix"></div>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div id="footer" className="row">
                        <a href="https://www.topskyhome.com/" target="_blank">
                            <img src="/view/eventregistration/images/poweredby.gif" alt="Website Powered by Topsky" />
                        </a>
                    </div> */}
                    <div className="webinar-footer">
                        © 2021 TopSky Home.
                    </div>
                </div>
            </div>
        );
    }
}

export default PageMWebinarLanding;
