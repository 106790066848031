import { MenuLi } from './HeaderInterface';
const leftData: Array<MenuLi> = [
    // {
    //     name: 'home',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/',
    // },
    // {
    //     name: 'Buy',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageBuy',
    // },
    // {
    //     name: 'Sell',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageSell',
    // },
    // {
    //     name: 'Agent',
    //     active: false,
    //     subHover: false,
    //     children: [],
    //     type: 1,
    //     href: '/PageAgent',
    // },
];
export default leftData;
