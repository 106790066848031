import { produce } from 'immer';
import { ActionTypes } from './actions';
import { Action, AppState } from './types';
const state: AppState = {
    locale: 'en_US',
};
export const initialState = Object.freeze(state);

export default (state: AppState = initialState, action: Action): AppState => {
    return produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.SETLOCALE: {
                draft.locale = action.payload;
                return;
            }
        }
    });
};
