import * as React from 'react';
import { MenuLi } from '../HeaderBar/HeaderInterface';

interface SubMenuProps {
    display: boolean;
    children: Array<MenuLi>;
    onHover: Function;
    onLeave: Function;
    userRole: String;
}
interface State extends MenuLi {}
class SubMenu extends React.Component<SubMenuProps, State> {
    state: State = {
        name: '',
        active: false,
        subHover: false,
        type: 0,
        href: '',
        children: [],
    };
    constructor(props) {
        super(props);
    }
    componentDidMount(): void {
        this.setState({
            children: this.props.children,
        });
    }

    render(): React.ReactNode {
        let { display, onHover, onLeave, userRole } = this.props;
        let { children } = this.state;
        const renderChild = () => {
            return children.map((item, index) => {

                // Only display Market page for ADMIN and MEMBER users
                
                // if (item.name == "Market" && userRole != 'ADMIN' && userRole != 'MEMBER'){
                //     return (
                //         ''
                //     )
                // }

                return (
                    <li
                        className={item.active ? 'menu-active' : ''}
                        key={index}
                        onMouseOver={() => {
                            const c = [...children];
                            c[index].active = true;
                            this.setState({
                                children,
                            });
                        }}
                        onMouseLeave={() => {
                            children[index].active = false;
                            this.setState({
                                children,
                            });
                        }}
                    >
                        <a href={item.href}>{item.name}</a>
                    </li>
                );
            });
        };
        return (
            <ul
                className={'sub-menu ' + (display ? 'slide' : '')}
                style={{ display: display ? 'block' : 'none' }}
                onMouseMove={() => {
                    onHover();
                }}
                onMouseLeave={() => {
                    onLeave();
                }}
            >
                {renderChild()}
            </ul>
        );
    }
}

export default SubMenu;
