import * as React from 'react';
import { withRouter } from 'react-router';
import HeaderBar from 'components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import { Props, State } from './interface';

@withRouter
class Page extends React.Component<Props, State> {
    state: State = {
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
    }

    render(): React.ReactNode {

        return (
            <div className="wrapper">
                 <HeaderBar animate={false} />
                <div style={{
                    width: '100%', 
                    height: '100%',
                    position: 'absolute',
                    top: '60px',
                    bottom: '0',
                    left: '0',
                    right: '0'
                }}>
                    <iframe width="100%" height="100%" src='https://calendly.com/info-35499/topsky-home-vip-customer-registration' title="Calendly" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                
                    {/*Our Footer*/}
                    <Site/>

                    {/*Our Footer Bottom Area*/}
                    <Footer/>
                </div>

            </div>
        );
    }
}

export default Page;
