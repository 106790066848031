import {UserInfo} from "./types";

export const ActionTypes = {
    SETUSERINFO: 'app/set-user-info',
};

export const setUserInfo = (userInfo: UserInfo) => {
    window.localStorage.setItem(ActionTypes.SETUSERINFO, JSON.stringify(userInfo));
    return {
        type: ActionTypes.SETUSERINFO,
        payload: userInfo,
    }
};
