import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
// import Select from '../../components/Select/Select';
import Pager from '../../components/Pager/Pager';
import Request from '../../components/Utils/Request';
import _ from 'lodash';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import { withRouter } from 'react-router';
import AutoComplete from 'react-google-autocomplete';
import {formatType, types} from 'shared/components/Utils/TypesData'
import { Slider, Select, Drawer, Button } from 'antd';
import './index.less'

const { Option } = Select;

@withRouter
class Page extends React.Component<Props, State> {
    state: State = {
        all: [],
        list: [],
        pager: 0,
        filter: {
            address: ''
        },
        showFilter: false,
        typography: true,
        choose: {
            lat: 0,
            lng: 0,
            id: 0,
        },
        openDrawer: false,
        currentId: '0',
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }

    componentDidMount(): void {
        const params = {};
        if (typeof document !== 'undefined' && this.props.location.search) {
            const search = this.props.location.search.slice(1, this.props.location.search.length);
            search
                .split('&')
                .map((item) => item.split('='))
                .forEach(([key, value]) => {
                    params[key] = decodeURIComponent(value);
                });
            this.setState({
                filter: params,
            });
        }
        this.getData(params).then();
    }

    getData = async (params = {}) => {
        let url = '';
        if(params.isFeatured) {
            // url = '/property?is_featured=Y&approval_status=APPROVED';
            // url = '/property?approval_status=APPROVED';
            url = '/property';
        }
        else {
            // params['approval_status'] = 'APPROVED';
            url = `/property${
                params.latitude && params.longitude && params.distance
                    ? '/point_radius_search'
                    : ''
            }?` +
            Object.entries(params)
                .map((p) => `${p[0]}=${p[1]}`)
                .join('&');
        }
        Request({
            url: url,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const properties =
                params.latitude && params.longitude && params.distance ? res.data : res.properties;
            const data = _.chunk(properties, 10);
            this.setState({
                all: data,
                list: data[this.state.pager],
            });
        });
    };

    changePage = (pager) => {
        this.setState({
            list: this.state.all[pager],
            pager,
        });
    };
    
    onCloseDrawer = () => {
        this.setState({
            openDrawer: false
        })
    }

    render(): React.ReactNode {
        const { list = [], all, pager, showFilter, filter, typography, choose, openDrawer, currentId } = this.state;
        const params = filter;
        const setList = (list) =>
            this.setState({
                list,
            });
        const setChoose = (choose) => {
            this.setState({
                choose,
                filter: {
                    ...filter,
                    latitude: choose.lat,
                    longitude: choose.lng,
                }
            });
        };
        const routeToDetails = (item) => {
            this.props.history.push({
                pathname: '/PageListingSingle',
                search: `id=${item.id}`,
            });
        };
        const $this = this;
        function toThousands(num) {
            let result = '',
                counter = 0;
            let dot = String(num).indexOf('.');
            if (dot != -1) {
                var dotCnt = String(num).substring(dot + 1, num.length);

                num = String(num).split('.')[0];
                num = (num || 0).toString();
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result = num.charAt(i) + result;
                    if (!(counter % 3) && i != 0) {
                        result = ',' + result;
                    }
                }
                result = result + '.' + dotCnt;
                return result;
            } else {
                return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
        }
        function renderList() {
            if (list.length == 0)
                return (
                    <div
                        className={'col-lg-12'}
                        style={{
                            textAlign: 'center',
                            height: '25vh',
                            lineHeight: '25vh',
                        }}
                    >
                        Sorry, no new homes listed with current searching criterias, please checkout
                        later
                    </div>
                );
            return list.map((item) => {
                const img =
                    item.images && item.images.length > 0 ? item.images[item.ii ? item.ii : 0] : '';
                return (
                    <div className={'col-lg-' + (typography ? '6' : '12')} key={item.id} id={item.id}>
                        <div
                            className={'feat_property' + (typography ? '' : ' list') + (item.id == currentId ? ' active': '')}
                            onClick={() => {
                                // 改为直接跳转，不再地图上定位位置
                                routeToDetails(item);
                                // if (item.id === choose.id) {
                                //     routeToDetails(item);
                                // }
                                // setChoose({
                                //     lat: item.latitude,
                                //     lng: item.longitude,
                                //     id: item.id,
                                // });
                            }}
                            style={
                                item.id === choose.id
                                    ? {
                                          border: '2px solid #ff5a5f',
                                      }
                                    : {}
                            }
                        >
                            <div className="thumb">
                                <img className="img-whp" src={img} alt="404" />
                                {item.exclusive == 'Y' ? <div className="list-line-exclusive">EXCLUSIVE</div> : ''}
                                <div className="thmb_cntnt">
                                    <ul className="tag mb0">
                                        {item.is_featured == 'Y' ? (
                                            <li className="list-inline-item">
                                                <a>Featured</a>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {item.status ? (
                                            <li className="list-inline-item">
                                                <a className={item.status}>{item.status}</a>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                    </ul>
                                    <ul className="icon mb0">
                                        <li className="list-inline-item">
                                            <a
                                                onClick={(e) => {
                                                    item.ii =
                                                        ((item.ii ? item.ii : 0) + 1) %
                                                        item.images.length;
                                                    setList(list);
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    return false;
                                                }}
                                            >
                                                <span className="flaticon-transfer-1" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="details">
                                <div className="tc_content">
                                    <p className="text-thm">{item.type.replace('_', ' ')}</p>
                                    
                                    <h4 className="listing-price">${toThousands(item.listing_price)}</h4>
                                    <h4 className="prop-title" title={item.title}>{item.title}</h4>
                                    {/* <p><span className="flaticon-placeholder"></span> {item.address}, {item.city}, {item.state}</p> */}
                                    <ul className="prop_details mb0">
                                        <li className="list-inline-item"><a href="#">Beds: {item.bedrooms}</a></li>
                                        <li className="list-inline-item"><a href="#">Baths: {item.bathrooms}</a></li>
                                        <li className="list-inline-item"><a href="#">Sq Ft: {item.size}</a></li>
                                    </ul>
                                </div>
                                <div className="fp_footer">
                                    {/* <ul className="fp_meta float-left mb0">
                                        <li className="list-inline-item"><a href="#"><img src="images/property/pposter1.png" alt="pposter1.png" /></a></li>
                                        <li className="list-inline-item"><a href="#">Ali Tufan</a></li>
                                    </ul> */}
                                    <div className="fp_pdate float-right">built {item.year_built}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return (
            <div
                className={'wrapper' + (showFilter ? ' mm-page mm-slideout' : '')}
                style={{ paddingTop: '96px' }}
            >
                <HeaderBar animate={false} />
                
                <div className="sasw_list google-input">
                    <div className="search_area">
                        <div className="form-group">
                            <AutoComplete
                                className="form-control"
                                // apiKey={
                                //     'AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk'
                                // }
                                types={[]}
                                defaultValue={filter.address}
                                onPlaceSelected={(e) => {
                                    if (!e.geometry) return;
                                    const location =
                                        e.geometry.location;
                                    const addr = {};
                                        e.address_components.forEach(
                                            (item) => {
                                                addr[item.types[0]] = item;
                                            }
                                        );
                                    this.setState({
                                        filter: {
                                            ...this.state
                                                .filter,
                                            latitude: location.lat(),
                                            longitude: location.lng(),
                                            distance: 20,
                                            address:
                                                (addr.street_number && addr.route) ?
                                                addr.street_number
                                                    ?.long_name +
                                                ' ' +
                                                addr.route?.long_name : e.formatted_address,
                                        },
                                    });
                                    this.getData(
                                        this.state.filter
                                    ).then();
                                }}
                                onChange={(e)=> {}}
                            />
                            <label htmlFor="exampleInputEmail">
                                <span className="flaticon-maps-and-flags" />
                            </label>
                        </div>
                    </div>
                </div> 
                <div className="map-right-container">
                <Drawer title="" placement="right" className="topsky-drawer" getContainer={false} width={"375"} onClose={this.onCloseDrawer} open={openDrawer} visible={openDrawer}>
                    <div>
                        <ul className="sasw_list mb0">
                            {/*
                            <li className="search_area">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="keyword"
                                        value={this.state.filter.key}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    key: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor="exampleInputEmail">
                                        <span className="flaticon-magnifying-glass"/>
                                    </label>
                                </div>
                            </li>
                            */}
                            {/* <li className="search_area">
                                <div className="form-group">
                                    <AutoComplete
                                        className="form-control"
                                        // apiKey={
                                        //     'AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk'
                                        // }
                                        types={[]}
                                        defaultValue={filter.address}
                                        onPlaceSelected={(e) => {
                                            if (!e.geometry) return;
                                            const location =
                                                e.geometry.location;
                                            this.setState({
                                                filter: {
                                                    ...this.state
                                                        .filter,
                                                    latitude: location.lat(),
                                                    longitude: location.lng(),
                                                    distance: 20,
                                                },
                                            });
                                        }}
                                        onChange={(e)=> {}}
                                    />
                                    <label htmlFor="exampleInputEmail">
                                        <span className="flaticon-maps-and-flags" />
                                    </label>
                                </div>
                            </li> */}
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'Distance'}
                                        style={{ width: '100%' }}
                                        value={filter.distance}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...this.state
                                                        .filter,
                                                    distance: e,
                                                },
                                            });
                                        }}
                                    >
                                        {[
                                            {
                                                name:
                                                    '5Mile',
                                                value:
                                                    '5',
                                            },
                                            {
                                                name: '10Mile',
                                                value: '10',
                                            },
                                            {
                                                name: '20Mile',
                                                value: '20',
                                            },
                                            {
                                                name:
                                                    '50Mile',
                                                value:
                                                    '50',
                                            },
                                            {
                                                name: '100Mile',
                                                value: '100',
                                            },
                                        ].map((province) => (
                                            <Option
                                                key={province.value}
                                            >
                                                {province.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </li>
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'Property Type'}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...this.state
                                                        .filter,
                                                    type: e,
                                                },
                                            });
                                        }}
                                    >
                                        {types.map((province) => (
                                            <Option
                                                key={province.value}
                                            >
                                                {province.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </li>
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'SqFt Min'}
                                        style={{ width: '50%' }}
                                        value={filter.size_min}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    size_min: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 200; i <= 5000; i += 100) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                    <div style={{
                                        width: '20px',
                                        textAlign: 'center',
                                        lineHeight: '32px'
                                    }}>to</div>
                                    <Select
                                        defaultValue={'SqFt Max'}
                                        style={{ width: '50%' }}
                                        value={filter.size_max}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    size_max: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 200; i <= 5000; i += 100) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                </div>
                            </li>
                            {/*
                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                'space-between',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <span>SqFt</span>
                                        <span>
                                            {filter?.size_min
                                                ? filter?.size_min
                                                : 200}
                                            -
                                            {filter?.size_max
                                                ? filter?.size_max
                                                : 5000}
                                        </span>
                                    </div>
                                    <Slider
                                        range={true}
                                        defaultValue={[
                                            filter?.size_min
                                                ? filter?.size_min
                                                : 200,
                                            filter?.size_max
                                                ? filter?.size_max
                                                : 5000,
                                        ]}
                                        min={200}
                                        max={5000}
                                        step={50}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    size_min: e[0],
                                                    size_max: e[1],
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </li>
                            */}
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'Bedroom Min'}
                                        style={{ width: '50%' }}
                                        value={filter.bedrooms_min}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bedrooms_min: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 1; i <= 6; i ++) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                    <div style={{
                                        width: '20px',
                                        textAlign: 'center',
                                        lineHeight: '32px'
                                    }}>to</div>
                                    <Select
                                        defaultValue={'Bedroom Max'}
                                        style={{ width: '50%' }}
                                        value={filter.bedrooms_max}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bedrooms_max: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 1; i <= 6; i ++) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                </div>
                            </li>
                            {/*
                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                'space-between',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <span>Bedroom</span>
                                        <span>
                                            {filter?.bedrooms_min
                                                ? filter?.bedrooms_min
                                                : 1}
                                            -
                                            {filter?.bedrooms_max
                                                ? filter?.bedrooms_max
                                                : 6}
                                        </span>
                                    </div>
                                    <Slider
                                        range={true}
                                        defaultValue={[1, 6]}
                                        min={1}
                                        max={6}
                                        step={1}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bedrooms_min: e[0],
                                                    bedrooms_max: e[1],
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </li>
                            */}
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'Bathroom Min'}
                                        style={{ width: '50%' }}
                                        value={filter.bathrooms_min}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bathrooms_min: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 1; i <= 6; i ++) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                    <div style={{
                                        width: '20px',
                                        textAlign: 'center',
                                        lineHeight: '32px'
                                    }}>to</div>
                                    <Select
                                        defaultValue={'Bathroom Max'}
                                        style={{ width: '50%' }}
                                        value={filter.bathrooms_max}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bathrooms_max: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 1; i <= 6; i ++) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                </div>
                            </li>
                            {/*
                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                'space-between',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <span>Bathroom</span>
                                        <span>
                                            {filter?.bathrooms_min
                                                ? filter?.bathrooms_min
                                                : 1}
                                            -
                                            {filter?.bathrooms_max
                                                ? filter?.bathrooms_max
                                                : 6}
                                        </span>
                                    </div>
                                    <Slider
                                        range={true}
                                        defaultValue={[1, 6]}
                                        min={1}
                                        max={6}
                                        step={1}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    bathrooms_min: e[0],
                                                    bathrooms_max: e[1],
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </li>
                            */}
                            <li className="search_area">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '10px 0'
                                    }}
                                >
                                    <Select
                                        defaultValue={'Price Min'}
                                        style={{ width: '50%' }}
                                        value={filter.listing_price_min}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    listing_price_min: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 0; i <= 1000 * 1000 * 10; i < 1000 * 1000 ? i += 50 * 1000 : i += 500 * 1000) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province < 1000 * 1000 ? +(province / 1000).toFixed(2) + 'K' : +(province / 1000 / 1000).toFixed(2) + 'M'}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                    <div style={{
                                        width: '20px',
                                        textAlign: 'center',
                                        lineHeight: '32px'
                                    }}>to</div>
                                    <Select
                                        defaultValue={'Price Max'}
                                        value={filter.listing_price_max}
                                        style={{ width: '50%' }}
                                        onChange={(e) => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    listing_price_max: e,
                                                },
                                            });
                                        }}
                                    >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 50 * 1000; i <= 1000 * 1000 * 10; i < 1000 * 1000 ? i += 50 * 1000 : i += 500 * 1000) {
                                                arr.push(i)
                                            }
                                            return arr.map((province) => (
                                                <Option
                                                    key={province}
                                                >
                                                    {province < 1000 * 1000 ? +(province / 1000).toFixed(2) + 'K' : +(province / 1000 / 1000).toFixed(2) + 'M'}
                                                </Option>
                                            ))
                                        })()}
                                    </Select>
                                </div>
                            </li>
                            {/*
                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                'space-between',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <span>Price</span>
                                        <span>
                                            $
                                            {filter?.listing_price_min
                                                ? filter?.listing_price_min <
                                                    1000000
                                                    ? (
                                                            Math.floor(
                                                                filter?.listing_price_min /
                                                                    1000 /
                                                                    50
                                                            ) * 50
                                                        ).toFixed(2) + 'k'
                                                    : (
                                                            (Math.floor(
                                                                filter?.listing_price_min /
                                                                    500
                                                            ) *
                                                                500) /
                                                            1000 /
                                                            1000
                                                        ).toFixed(2) + 'm'
                                                : 0}
                                            -
                                            {filter?.listing_price_max
                                                ? filter?.listing_price_max <
                                                    1000000
                                                    ? (
                                                            Math.floor(
                                                                filter?.listing_price_max /
                                                                    1000 /
                                                                    50
                                                            ) * 50
                                                        ).toFixed(2) + 'k'
                                                    : (
                                                            (Math.floor(
                                                                filter?.listing_price_max /
                                                                    500
                                                            ) *
                                                                500) /
                                                            1000 /
                                                            1000
                                                        ).toFixed(2) + 'm'
                                                : '10m'}
                                        </span>
                                    </div>
                                    <Slider
                                        range={true}
                                        defaultValue={[0, 10000000]}
                                        min={0}
                                        max={10000000}
                                        step={500}
                                        onChange={function (e) {
                                            $this.setState({
                                                filter: {
                                                    ...filter,
                                                    listing_price_min:
                                                        e[0],
                                                    listing_price_max:
                                                        e[1],
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </li>
                            */}
                            <li>
                                <div className="search_option_button">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-thm"
                                        style={{
                                            border: '2px solid #ff5a5f',
                                        }}
                                        onClick={() => {
                                            this.getData(
                                                this.state.filter
                                            ).then();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Drawer>
                    
                    <div className="map-filter">
                        Advanced Search {filter.address}
                    </div>
                    <div className="grid_list_search_result mt10 topsky-result">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="left_area tac-xsd">
                                <p>
                                    <span>{all.flat(1).length} Search results</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 pl0 pr0">
                            <div className="half_map_advsrch_navg style2 text-right tac-xsd">
                                <ul>
                                    <li className="list-inline-item" style={{
                                        width: '180px',
                                        marginTop: '14px',
                                    }}>
                                        <span className="stts" style={{
                                            marginRight: '10px'
                                        }}>
                                            Sort by:
                                        </span>

                                        <Select
                                            defaultValue={'Default'}
                                            style={{ width: '60%' }}
                                            onChange={(e) => {
                                                let tag = e.value || e;
                                                const properties = this.state.all
                                                    .flat(1)
                                                    .sort((a, b) => {
                                                        if(a[tag] < b[tag]){
                                                            return -1;
                                                        } else if(a[tag] == b[tag]){
                                                            return 0;
                                                        }else if(a[tag] > b[tag]){
                                                            return 1;
                                                        }
                                                        return (a[tag] - b[tag]);
                                                    });
                                                const data = _.chunk(properties, 10);
                                                this.setState({
                                                    all: data,
                                                    list: data[this.state.pager],
                                                });
                                                // this.setState({
                                                //     filter: {
                                                //         ...this.state.filter,
                                                //         type: e,
                                                //     },
                                                // });
                                            }}
                                        >
                                            {[
                                                {
                                                    name: 'sqft',
                                                    value: 'size',
                                                },
                                                {
                                                    name: 'price',
                                                    value: 'listing_price',
                                                },
                                                {
                                                    name: 'date listed',
                                                    value: 'created_at',
                                                },
                                            ].map((province) => (
                                                <Option
                                                    key={province.value}
                                                >
                                                    {province.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="hvr-text-thm"
                                            onClick={() => {
                                                this.setState({
                                                    typography: true,
                                                });
                                            }}
                                        >
                                            <span className="fa fa-th-large" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="hvr-text-thm"
                                            onClick={() => {
                                                this.setState({
                                                    typography: false,
                                                });
                                            }}
                                        >
                                            <span className="fa fa-th-list" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a onClick={()=>{
                                                this.setState({openDrawer: true})
                                            }}>Other filters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search-result-list">
                        {renderList()}
                        <div className="col-lg-12 mb30">
                            <div className="mbp_pagination">
                                <Pager
                                    pager={pager}
                                    all={all}
                                    changePage={(e) => {
                                        this.changePage(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="map-left-container">
                    <div className="map">
                        <GoogleMap
                            center={
                                params?.latitude
                                    ? {
                                            lat: +params.latitude,
                                            lng: +params.longitude,
                                        }
                                    : choose.id && choose.lat && choose.lng
                                    ? {
                                            lat: +choose.lat,
                                            lng: +choose.lng,
                                        }
                                    : list[0]
                                    ? {
                                            lat: +this.state.list[0].latitude,
                                            lng: +this.state.list[0]
                                                .longitude,
                                        }
                                    : undefined
                            }
                            markers={this.state.all.flat(1).map((item) => ({
                                position: {
                                    lat: +item.latitude,
                                    lng: +item.longitude,
                                },
                                label: (() => {
                                    if (+item.listing_price < 1000 * 1000) {
                                        return (
                                            Math.round(
                                                +item.listing_price / 1000
                                            ) + 'K'
                                        );
                                    }
                                    if (
                                        +item.listing_price <
                                        1000 * 1000 * 10
                                    ) {
                                        return (
                                            Math.round(
                                                +item.listing_price /
                                                    1000 /
                                                    100
                                            ) /
                                                10 +
                                            'M'
                                        );
                                    }
                                    if (
                                        +item.listing_price >
                                        1000 * 1000 * 10
                                    ) {
                                        return (
                                            Math.round(
                                                +item.listing_price /
                                                    1000 /
                                                    1000
                                            ) + 'M'
                                        );
                                    }
                                })(),
                                id: item.id
                            }))}
                            zoom={(() => {
                                const dis = filter.distance;
                                if (dis == 100) {
                                    return 9
                                }
                                if (dis == 50) {
                                    return 10
                                }
                                if (dis == 20) {
                                    return 11
                                }
                                if (dis == 10) {
                                    return 12
                                }
                                if (dis == 5) {
                                    return 13
                                }
                                return 12
                            })()}
                            onEvent={(e) => {
                                if (e.event == 'click') {
                                    this.setState({
                                        filter: {
                                            ...filter,
                                            latitude: e.res.lat,
                                            longitude: e.res.lng
                                        }
                                    });
                                    this.getData(filter).then()
                                }
                                if (e.event == 'zoom') {
                                    const {zoom} = e.res;
                                    let dis = filter.distance;
                                    if (zoom == 9) {
                                        dis = 100
                                    }
                                    if (zoom == 10) {
                                        dis = 50
                                    }
                                    if (zoom == 11) {
                                        dis = 20
                                    }
                                    if (zoom == 12) {
                                        dis = 10
                                    }
                                    if (zoom == 13) {
                                        dis = 5
                                    }
                                    // console.log(dis);
                                    if (dis !== filter.distance && filter.longitude && filter.latitude) {
                                        this.setState({
                                            filter: {
                                                ...filter,
                                                distance: dis,
                                            }
                                        });
                                        this.getData(filter).then()
                                    }
                                }
                                if(e.event == 'clickMarker') {
                                    // Get and change page, and then match style
                                    let page = 0;
                                    this.state.all.map((arr, index) => {
                                        if(arr.findIndex(item => {
                                            return item.id == e.res.selectId
                                        }) > -1) {
                                            page = index;
                                            return false;
                                        }
                                    })
                                    this.changePage(page);
                                    this.setState({
                                        currentId: e.res.selectId
                                    })
                                    document.documentElement.scrollTop = document.getElementById(e.res.selectId).offsetTop - 94;
                                }
                            }}
                        >
                        </GoogleMap>
                    </div>
                </div>

                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows"></i>
                </a>
            </div>
        );
    }
}

export default Page;
