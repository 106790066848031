import React from 'react';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import Footer from '../../components/Footer/Footer';
import Site from '../../components/Site/Site';
import { connect } from 'react-redux';
import moment from 'moment';

@connect(({ userInfo }) => {
    return { userInfo };
})
class DataByZip extends React.Component {
    headerBar;
    constructor(props) {
        super(props);
        this.headerBar = React.createRef();
        this.headerBar;
    }

    private checkUser = (props) => {
        const user = props.userInfo;
        // const currentDate = moment().format('YYYY-MM-DD');
        let isInScope = false;
        if(user && user.scope && Array.isArray(user.scope) && user.scope.length > 0) {
            isInScope = user.scope.some(item=> { return item=='zipcode_level'}); //&& item.due_date > currentDate});
        }
        if (!user || (user.role != 'AGENT' && user.role != 'ADMIN' && !isInScope)) {
            alert('You don\'t have permissio)n to access this page. Please email to info@topskyhome.com ');
            window.location.href = '/';
            return false;
        }
        if (user && user.id) {
            if (typeof window !== 'undefined') {
                var divList = ['viz1701891298001']; //['viz1611719030603', 'viz1696562767517', 'viz1696563345302', 'viz1696563369020', 'viz1696563382084']
                for(var key in divList) {
                    var divElement = document.getElementById(divList[key]);
                    var vizElement = divElement.getElementsByTagName('object')[0];
                    if (divElement.offsetWidth > 800) {
                        vizElement.style.width = '1000px';
                        vizElement.style.height = '880px';
                    } else if (divElement.offsetWidth > 500) {
                        vizElement.style.width = '1000px';
                        vizElement.style.height = '880px';
                    } else {
                        vizElement.style.width = '100%';
                        vizElement.style.height = '1750px';
                    }
                    if(parseInt(key)+1 == divList.length) {
                        var scriptElement = document.createElement('script');
                        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
                        vizElement.parentNode.insertBefore(scriptElement, vizElement);
                    }
                }
            }
        } else {
            this.headerBar.setLoginShow();
        }
    };

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        // this.checkUser(nextProps);
    }

    componentDidMount(): void {
        this.checkUser(this.props);
    }

    render() {
        return (
            <div
                className="wrapper"
                style={{
                    paddingTop: '60px',
                }}
            >
                <HeaderBar
                    animate={false}
                    onRef={(e) => {
                        this.headerBar = e;
                    }}
                />
                <section className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-12">
                                <div className="about_content">
                                    <h3> Housing Data By Zipcode </h3>
                                    <p>
                                        After you finalize your targeted metropolitan, dig into the
                                        region by comparing data for different zip code
                                    </p>
                                    <div className='tableauPlaceholder' id='viz1701891298001' style={{ position: 'relative' }}>
                                        <noscript>
                                            <img alt=' ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics&#47;1_rss.png' style={{ border: 'none' }} />
                                        </noscript>
                                        <object className='tableauViz'style={{ display: 'none' }}>
                                            <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                                            <param name='embed_code_version' value='3' />
                                            <param name='site_root' value='' />
                                            <param name='name' value='TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics' />
                                            <param name='tabs' value='yes' />
                                            <param name='toolbar' value='yes' />
                                            <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics&#47;1.png' />
                                            <param name='animate_transition' value='yes' />
                                            <param name='display_static_image' value='yes' />
                                            <param name='display_spinner' value='yes' />
                                            <param name='display_overlay' value='yes' />
                                            <param name='display_count' value='yes' />
                                            <param name='language' value='en-US' />
                                        </object>
                                    </div> 
                                    {/* <div className="tableauPlaceholder" id="viz1611719030603" style={{ position: 'relative' }}>
                                        <noscript>
                                            <a href=" ">
                                            <img alt=" " src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics&#47;1_rss.png" style={{ border: 'none' }} />
                                            </a>
                                        </noscript>
                                        <object className="tableauViz" style={{ display: 'none' }}>
                                            <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
                                            <param name="embed_code_version" value="3" />
                                            <param name="site_root" value="" />
                                            <param name="name" value="TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics" />
                                            <param name="tabs" value="yes" />
                                            <param name="toolbar" value="yes" />
                                            <param name="static_image" value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Metrics-Zipcode-2023&#47;Metrics&#47;1_rss.png" />
                                            <param name="animate_transition" value="yes" />
                                            <param name="display_static_image" value="yes" />
                                            <param name="display_spinner" value="yes" />
                                            <param name="display_overlay" value="yes" />
                                            <param name="display_count" value="yes" />
                                            <param name="language" value="en" />
                                            <param name="filter" value="publish=yes" />
                                        </object>
                                    </div> */}
                                    <script type="text/javascript"> </script>
                                </div>
                                {/* <div className="tableauPlaceholder" id="viz1696562767517" style={{ position: 'relative' }}>
                                    <noscript>
                                        <a href="#"><img
                                            alt="Home Value Gain "
                                            src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-HomeValueGain-Zipcode-2023&#47;HomeValueGain&#47;1_rss.png"
                                            style={{ border: 'none' }} /></a>
                                    </noscript>
                                    <object className="tableauViz" style={{ display: 'none' }}>
                                        <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
                                        <param name="embed_code_version" value="3" />
                                        <param name="site_root" value="" />
                                        <param name="name" value="TopSkyHomeAnalysis-HomeValueGain-Zipcode-2023&#47;HomeValueGain" />
                                        <param name="tabs" value="no" />
                                        <param name="toolbar" value="yes" />
                                        <param name="static_image"
                                            value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-HomeValueGain-Zipcode-2023&#47;HomeValueGain&#47;1.png" />
                                        <param name="animate_transition" value="yes" />
                                        <param name="display_static_image" value="yes" />
                                        <param name="display_spinner" value="yes" />
                                        <param name="display_overlay" value="yes" />
                                        <param name="display_count" value="yes" />
                                        <param name="language" value="en" />
                                    </object>
                                </div>
                                <div className='tableauPlaceholder' id='viz1696563345302' style={{ position: 'relative' }}>
                                    <noscript>
                                        <a href='#'>
                                        <img alt='Rent to Home Value ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-RentToHomeValue-Zipcode-2023&#47;RenttoHomeValue&#47;1_rss.png' style={{ border: 'none' }} />
                                        </a>
                                    </noscript>
                                    <object className='tableauViz' style={{ display: 'none' }}>
                                        <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                                        <param name='embed_code_version' value='3' />
                                        <param name='site_root' value='' />
                                        <param name='name' value='TopSkyHomeAnalysis-RentToHomeValue-Zipcode-2023&#47;RenttoHomeValue' />
                                        <param name='tabs' value='no' />
                                        <param name='toolbar' value='yes' />
                                        <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-RentToHomeValue-Zipcode-2023&#47;RenttoHomeValue&#47;1.png' />
                                        <param name='animate_transition' value='yes' />
                                        <param name='display_static_image' value='yes' />
                                        <param name='display_spinner' value='yes' />
                                        <param name='display_overlay' value='yes' />
                                        <param name='display_count' value='yes' />
                                        <param name='language' value='en' />
                                    </object>
                                </div>
                                <div className='tableauPlaceholder' id='viz1696563369020' style={{ position: 'relative' }}>
                                    <noscript>
                                        <a href='#'>
                                        <img alt='Home Value ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-HomeValue-Zipcode-2023&#47;HomeValue&#47;1_rss.png' style={{ border: 'none' }} />
                                        </a>
                                    </noscript>
                                    <object className='tableauViz' style={{ display: 'none' }}>
                                        <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                                        <param name='embed_code_version' value='3' />
                                        <param name='site_root' value='' />
                                        <param name='name' value='TopSkyHomeAnalysis-HomeValue-Zipcode-2023&#47;HomeValue' />
                                        <param name='tabs' value='no' />
                                        <param name='toolbar' value='yes' />
                                        <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-HomeValue-Zipcode-2023&#47;HomeValue&#47;1.png' />
                                        <param name='animate_transition' value='yes' />
                                        <param name='display_static_image' value='yes' />
                                        <param name='display_spinner' value='yes' />
                                        <param name='display_overlay' value='yes' />
                                        <param name='display_count' value='yes' />
                                        <param name='language' value='en' />
                                    </object>
                                </div>
                                <div className='tableauPlaceholder' id='viz1696563382084' style={{ position: 'relative' }}>
                                    <noscript>
                                        <a href='#'>
                                        <img alt='Rent ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Rent-Zipcode-2023&#47;Rent&#47;1_rss.png' style={{ border: 'none' }} />
                                        </a>
                                    </noscript>
                                    <object className='tableauViz' style={{ display: 'none' }}>
                                        <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                                        <param name='embed_code_version' value='3' />
                                        <param name='site_root' value='' />
                                        <param name='name' value='TopSkyHomeAnalysis-Rent-Zipcode-2023&#47;Rent' />
                                        <param name='tabs' value='no' />
                                        <param name='toolbar' value='yes' />
                                        <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;To&#47;TopSkyHomeAnalysis-Rent-Zipcode-2023&#47;Rent&#47;1.png' />
                                        <param name='animate_transition' value='yes' />
                                        <param name='display_static_image' value='yes' />
                                        <param name='display_spinner' value='yes' />
                                        <param name='display_overlay' value='yes' />
                                        <param name='display_count' value='yes' />
                                        <param name='language' value='en' />
                                    </object>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Site />
                <Footer />
            </div>
        );
    }
}

export default DataByZip;
