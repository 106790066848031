import * as React from 'react';
import { Props, State } from './interface';
import HeaderBar from 'shared/components/HeaderBar/HeaderBar';
import SideBar from 'shared/components/SideBar/SideBar';
import GoogleMap from 'shared/components/GoogleMap/GoogleMap';
import { connect } from 'react-redux';
import Select from '../../components/Select/Select';
import Request from '../../components/Utils/Request';
import AutoComplete from 'react-google-autocomplete';
import {Collapse, Select as SelectAnt, Input, Button} from "antd";
import {commSize, typePurchase, typeDeposit, developmentPhase, typicPriceRange, typicHomeSize, typicLotSize, rentalRestrictions, crimeLevel} from 'shared/components/Utils/TypesData'
import {toast} from "react-toastify";
const { Panel } = Collapse;

@connect(({ userInfo }) => {
    return { userInfo };
})
class PageMCommunityUpdate extends React.Component<Props, State> {
    state: State = {
        form: {
            name: '',
            size_min: 0,
            size_max: 0,
            series_or_collection: '',
            purchase_restriction: '',
            deposit_requirement: '',
            builder: '',
            development_phase: '',
            home_type: '',
            typical_price_range_min: 0,
            typical_price_range_max: 0,
            typical_home_size_min: 0,
            typical_home_size_max: 0,
            typical_lot_size_min: 0,
            typical_lot_size_max: 0,
            tax_rate: 0,
            metro: '',
            hoa: 0,
            rental_restrictions: '',
            crime_level: 0,
            website: '',
            
            swimming_pool: '0',
            club_house: '0',
            fitness_center: '0',
            playground: '0',
            dock: '0',
            tennis_court: '0',

            notes: '',
            elementary_school_id: 0,
            middle_school_id: 0,
            high_school_id: 0,
            elementary_schools: '',
            middle_schools: '',
            high_schools: '',
            
            major_employers: '',
            POIs: '',
            additional_infomation: '',
            address: '',
            zip: 0,
            country: '',
            state: '',
            city: '',

            latitude: 0,
            longitude: 0,
            hide_full_address: 'Y',
            community_size: '',
            typical_price_range: '',
            typical_home_size: '',
            typical_lot_size: '',
            year_built: '',
            video_url: '',
            attachment_url: ''
        },
        images: [],
        builders: [],
        schools_e: [],
        schools_m: [],
        schools_h: [],
        default_e: [],
        default_m: [],
        default_h: [],
        filterZipcode: ''
        
    };

    setForm = (form) => {
        this.setState({
            form
        })
    };
    
    deleteImage = (id) => {
        Request({
            url: '/image/' + id,
            method: 'DELETE',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            this.queryImages();
        });
    };
    queryImages = () => {
        if (this.state.form.id)
        Request({
            url: '/image',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            params: {
                parent_id: this.state.form.id,
                image_type: 'COMMUNITY',
            },
        }).then((res) => {
            this.setState({
                images: res.data.data
            })
        });
    };

    getBuilders = async () => {
        Request({
            url: '/builder',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 5);
            this.setState({
                builders: res.data.map(item => {
                    return {
                        ...item,
                        value: item.id
                    }
                })
            });
        });
    };
    getSchoolsFromGreat = async () => {
        const {filterZipcode} = this.state;
        const res = await Request({
            url: '/api/schools?zipcode=' + filterZipcode,
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then((res) => {
            return res.data
        });
        const schoolOptions = res.data.map((item) => {
            return {
                ...item,
                label: item.name,
                value: item['universal-id']
            }
        })
        this.setState({
            schools_e: schoolOptions.filter(item => { return item['level-codes'].indexOf('e') > -1}),
            schools_m: schoolOptions.filter(item => { return item['level-codes'].indexOf('m') > -1}),
            schools_h: schoolOptions.filter(item => { return item['level-codes'].indexOf('h') > -1})
        });
    }
    getSchools = async () => {
        Request({
            url: '/school',
            method: 'GET',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
        }).then(({ data: res }) => {
            const data = _.chunk(res.data, 5);
            const _data = res.data.map(item => {
                return {
                    ...item,
                    name: item.full_name,
                    value: item.id
                }
            })
            
            this.setState({
                schools_e: _data.filter(item => { return item.type == 'E'}),
                schools_m: _data.filter(item => { return item.type == 'M'}),
                schools_h: _data.filter(item => { return item.type == 'H'})
            });
        });
    };
    upload = async (file) => {
        const formData = new FormData();
        formData.append('parent_id', this.state.form.id);
        formData.append('image_type', 'COMMUNITY');
        formData.append('file', file);
        const res = await Request({
            url: '/image',
            method: 'POST',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: formData,
        }).then((res) => {
            return res.data
        });
        this.queryImages();
    }

    submit = () => {
        const form = this.state.form;
        Request({
            url: '/community/' + form.id,
            method: 'PUT',
            headers: {
                'x-api-key': process.env.X_API_KEY,
            },
            data: form
        }).then((res) => {
            if (res.data.status == 200) {
                // 200的状态值就进行跳转
                toast(res.message || 'Update the community successfully!')
                this.props.history.push({
                    pathname: '/PageMCommunities'
                })
            }
        });
    };

    componentWillUnmount(): void {
        this.setState = () => {};
    }
    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            if (this.props.location.state) {
                const { name, size_min, size_max, series_or_collection, purchase_restriction, deposit_requirement, builder, development_phase, home_type, typical_price_range_min, typical_price_range_max, typical_home_size_min, typical_home_size_max, typical_lot_size_min, typical_lot_size_max, tax_rate, metro, hoa, rental_restrictions, crime_level, website, swimming_pool, club_house, fitness_center, playground, dock, tennis_court, notes, elementary_school_id, middle_school_id, high_school_id, elem_schools, mid_schools, hig_schools, major_employers, POIs, additional_infomation, address, zip, country, state,  latitude, longitude, hide_full_address, community_size, typical_price_range, typical_home_size, typical_lot_size, year_built, video_url, attachment_url, id} = this.props.location.state;
                // console.log(elem_schools, elem_schools.map(item => { return item.name}))
                this.setState({
                    schools_e: elem_schools.map(item => {return { ...item, label: item.name, value: item['universal_id']} }),
                    schools_m: mid_schools.map(item => {return { ...item, label: item.name, value: item['universal_id']} }),
                    schools_h: hig_schools.map(item => {return { ...item, label: item.name, value: item['universal_id']} })
                });
                this.setState({
                    default_e: elem_schools.map(item => { return item['universal_id']}),
                    default_m: mid_schools.map(item => { return item['universal_id']}),
                    default_h: hig_schools.map(item => { return item['universal_id']})
                });
                this.setForm({ name, size_min, size_max, series_or_collection, purchase_restriction, deposit_requirement, builder, development_phase, home_type, typical_price_range_min, typical_price_range_max, typical_home_size_min, typical_home_size_max, typical_lot_size_min, typical_lot_size_max, tax_rate, metro, hoa, rental_restrictions, crime_level, website, swimming_pool, club_house, fitness_center, playground, dock, tennis_court, notes, elementary_school_id, middle_school_id, high_school_id, elementary_schools: JSON.stringify(elem_schools), middle_schools: JSON.stringify(mid_schools), high_schools: JSON.stringify(hig_schools), major_employers, POIs, additional_infomation, address, zip, country, state, latitude: Number(latitude), longitude: Number(longitude), hide_full_address, community_size, typical_price_range, typical_home_size, typical_lot_size, year_built, video_url, attachment_url, id, user_last_updated: this.props.userInfo.id });
                setTimeout(() => {
                    this.queryImages();
                }, 50);
            }
            this.getBuilders().then();
            // this.getSchools().then();
        }
    }
    
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { form, images, builders, schools_e, schools_m, schools_h, filterZipcode, default_e, default_m, default_h } = this.state;
        return (
            <div className="wrapper">
                <div
                    className="preloader"
                    style={{
                        display: 'none',
                    }}
                />
                <HeaderBar animate={false} />

                <SideBar />

                <section className="our-dashbord dashbord bgc-f7 pb50">
                    <div className="container-fluid ovh">
                        <div className="row">
                            <div className="col-lg-3 col-xl-2 dn-992 pl0" />
                            <div className="col-lg-9 col-xl-10 maxw100flex-992">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="breadcrumb_content style2">
                                            <h2 className="breadcrumb_title">Update New Commnunity</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="my_dashboard_review">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="mb30">Create Listing</h4>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            <span className="text-danger">*</span> Name
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.name}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        name: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Builder</label>
                                                        <Select
                                                            style={{width: '100%'}}
                                                            defaultValue={form.builder}
                                                            className="form-control"
                                                            title="Select a builder"
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        builder: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={builders}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyUrl">
                                                            Website
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyUrl"
                                                            required
                                                            value={form.website}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    website: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Community Size</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.community_size}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        community_size: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={commSize}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Purchase restriction</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.purchase_restriction}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        purchase_restriction: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={typePurchase}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Deposit Requirement</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.deposit_requirement}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        deposit_requirement: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={typeDeposit}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Development Phase</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.development_phase}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        development_phase: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={developmentPhase}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Typic Price Range (k$)</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.typical_price_range}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        typical_price_range: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={typicPriceRange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Typical Home Size (Sqft)</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.typical_home_size}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        typical_home_size: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={typicHomeSize}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Typical Lot Size (Acre)</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.typical_lot_size}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        typical_lot_size: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={typicLotSize}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Rental Restrictions</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.rental_restrictions}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        rental_restrictions: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={rentalRestrictions}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="year_built">Year Built</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="year_built"
                                                            value={form.year_built}
                                                            onChange={(e) => {
                                                                this.setForm({
                                                                    ...form,
                                                                    year_built: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.swimming_pool == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        swimming_pool: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertySwimmingPool" id="propertySwimmingPool" />
                                                                <label className="form-check-label" htmlFor="propertySwimmingPool">Swimming Pool</label>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.club_house == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        club_house: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertyClubHouse" id="propertyClubHouse" />
                                                                <label className="form-check-label" htmlFor="propertyClubHouse">Club House</label>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.fitness_center == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        fitness_center: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertyFitnessCenter" id="propertyFitnessCenter" />
                                                                <label className="form-check-label" htmlFor="propertyFitnessCenter">Fitness Center</label>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.playground == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        playground: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertyPlayground" id="propertyPlayground" />
                                                                <label className="form-check-label" htmlFor="propertyPlayground">Playground</label>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.dock == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        dock: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertyDock" id="propertyDock" />
                                                                <label className="form-check-label" htmlFor="propertyDock">Dock</label>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-2">
                                                            <span className="my_profile_setting_input form-group form-check">
                                                                <input type="checkbox" checked={form.tennis_court == '1'} onChange={(event) => {
                                                                    this.setForm({
                                                                        ...form,
                                                                        tennis_court: event.target.checked ? '1' : '0',
                                                                    })
                                                                }} className="form-check-input" name="propertyTennisCourt" id="propertyTennisCourt" />
                                                                <label className="form-check-label" htmlFor="propertyTennisCourt">Tennis Court</label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            POIs
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            value={form.POIs}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        POIs: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input ui_kit_select_search form-group">
                                                        <label>Crime Level</label>
                                                        <Select style={{width: '100%'}}
                                                            className="form-control"
                                                            title="Please select one"
                                                            defaultValue={form.crime_level}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        crime_level: e.value,
                                                                    }
                                                                })
                                                            }}
                                                            options={crimeLevel}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="my_profile_setting_textarea">
                                                        <label htmlFor="propertyDescription">
                                                            Play Back URL (zoom link)
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertyDescription"
                                                            rows="5"
                                                            value={form.video_url}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        video_url: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="my_profile_setting_textarea">
                                                        <label htmlFor="propertyDescription">
                                                            PDF URL
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="propertyDescription"
                                                            rows="5"
                                                            value={form.attachment_url}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    form: {
                                                                        ...form,
                                                                        attachment_url: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb30">
                                                    <Collapse defaultActiveKey={['0']} ghost>
                                                        <Panel header="More" key="1">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Series / collection
                                                                        </label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={form.series_or_collection}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        series_or_collection: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Home type
                                                                        </label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={form.home_type}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        home_type: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Tax rate(%)
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.tax_rate}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        tax_rate: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Metro
                                                                        </label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={form.metro}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        metro: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            HOA(&#36;/m)
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={form.hoa}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        hoa: +e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="my_profile_setting_textarea">
                                                                        <label htmlFor="propertyDescription">
                                                                            Overview
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="propertyDescription"
                                                                            rows="7"
                                                                            value={form.notes}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        notes: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="my_profile_setting_input form-group">
                                                                        <label htmlFor="propertyDescription">
                                                                            Major employers
                                                                        </label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={form.major_employers}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        major_employers: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="my_profile_setting_textarea">
                                                                        <label htmlFor="propertyDescription">
                                                                            Highlights
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="propertyDescription"
                                                                            rows="7"
                                                                            value={form.additional_infomation}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    form: {
                                                                                        ...form,
                                                                                        additional_infomation: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label htmlFor="propertyStreamUrl" style={{
                                                            width: '100%'
                                                        }}>
                                                        <h4 className="mb30" style={{
                                                            display: 'inline-block'
                                                        }}>Select Schools</h4>
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyDescription">
                                                            Filter Zipcode
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-lg-9">
                                                                <Input size="large" placeholder="Enter a 5-digit Zipcode to filter schools" value={filterZipcode} onChange={(e) => {
                                                                    this.setState({
                                                                        filterZipcode: e.target.value,
                                                                    });
                                                                }} />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <Button type="primary" size="large" block onClick={(e) => {
                                                                    if(!String(filterZipcode).match(/^\d{5}$/)) {
                                                                        toast.error('Please enter a 5-digit Zipcode')
                                                                        return false;
                                                                    }
                                                                    this.getSchoolsFromGreat().then();
                                                                }}>Filter</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyElementary">
                                                            Elementary School
                                                        </label>
                                                        <SelectAnt
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                            placeholder="Enter Zipcode and select schools."
                                                            value={default_e}
                                                            onChange={value => {
                                                                this.setState({
                                                                    default_e: value
                                                                })
                                                                this.setForm({
                                                                    ...form,
                                                                    elementary_schools : JSON.stringify(schools_e.filter(item => { return value.indexOf(item.value) > -1}))
                                                                })
                                                            }}
                                                            options={schools_e}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyMiddle">
                                                            Middle School
                                                        </label>
                                                        <SelectAnt
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                            placeholder="Enter Zipcode and select schools."
                                                            value={default_m}
                                                            onChange={value => {
                                                                this.setState({
                                                                    default_m: value
                                                                })
                                                                this.setForm({
                                                                    ...form,
                                                                    middle_schools : JSON.stringify(schools_m.filter(item => { return value.indexOf(item.value) > -1}))
                                                                })
                                                            }}
                                                            options={schools_m}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyMiddle">
                                                            High School
                                                        </label>
                                                        <SelectAnt
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                            placeholder="Enter Zipcode and select schools."
                                                            value={default_h}
                                                            onChange={value => {
                                                                this.setState({
                                                                    default_h: value
                                                                })
                                                                this.setForm({
                                                                    ...form,
                                                                    high_schools : JSON.stringify(schools_h.filter(item => { return value.indexOf(item.value) > -1}))
                                                                })
                                                            }}
                                                            options={schools_h}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_dashboard_review mt30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label htmlFor="propertyStreamUrl" style={{
                                                            width: '100%'
                                                        }}>
                                                        <h4 className="mb30" style={{
                                                            display: 'inline-block'
                                                        }}>Office address</h4>
                                                        {/* <span className="my_profile_setting_input form-group form-check" style={{
                                                            float: 'right'
                                                        }}>
                                                            <input type="checkbox" checked={form.hide_full_address == 'Y'} onChange={(event) => {
                                                            this.setForm({
                                                                ...form,
                                                                hide_full_address: event.target.checked ? 'Y' : 'N',
                                                            })
                                                                }} className="form-check-input" name="propertyStreamUrl" id="propertyAnnounceHome" />
                                                            <label className="form-check-label" htmlFor="propertyAnnounceHome">Don't show full address？</label>
                                                        </span> */}
                                                    </label>
                                                    <div className="my_profile_setting_input form-group">
                                                        <AutoComplete
                                                            className="form-control"
                                                            apiKey={
                                                                'AIzaSyDxH0A3dLQk8h11aZy9RmdrPwiPRdv27Hk'
                                                            }
                                                            defaultValue={form.address}
                                                            types={[]}
                                                            onPlaceSelected={(e) => {
                                                                if (!e.geometry) return;
                                                                const location =
                                                                    e.geometry.location;
                                                                const addr = {};
                                                                e.address_components.forEach(
                                                                    (item) => {
                                                                        addr[
                                                                            item.types[0]
                                                                            ] = item;
                                                                    }
                                                                );
                                                                this.setForm({
                                                                    ...this.state.form,
                                                                    latitude: location.lat(),
                                                                    longitude: location.lng(),
                                                                    address:
                                                                        addr.street_number?.long_name + ' ' + addr.route?.long_name,
                                                                    state:
                                                                        addr?.administrative_area_level_1?.short_name ||
                                                                        addr.political?.short_name,
                                                                    zip:
                                                                    addr.postal_code?.short_name,
                                                                    city: addr.locality?.long_name,
                                                                });
                                                            }}
                                                        />
                                                        {/*<label htmlFor="propertyAddress">*/}
                                                            {/*Address*/}
                                                        {/*</label>*/}
                                                        {/*<input*/}
                                                            {/*type="text"*/}
                                                            {/*className="form-control"*/}
                                                            {/*id="propertyAddress"*/}
                                                            {/*value={form.address}*/}
                                                            {/*onChange={() => {*/}
                                                                {/**/}
                                                            {/*}}*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyState">
                                                            County / State
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyState"
                                                            value={form.state}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="propertyCity">City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="propertyCity"
                                                            value={form.city}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="zipCode">Zip</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="zipCode"
                                                            value={form.zip}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="my_profile_setting_input form-group">
                                                        <div className="h400 bdrs8" id="map-canvas">
                                                            <GoogleMap
                                                                children={[]}
                                                                centerMark={true}
                                                                center={{
                                                                    lat: form.latitude,
                                                                    lng: form.longitude,
                                                                }}
                                                                onEvent={(e) => {
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLat">
                                                            Latitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLat"
                                                            value={form.latitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-xl-4">
                                                    <div className="my_profile_setting_input form-group">
                                                        <label htmlFor="googleMapLong">
                                                            Longitude (for Google Maps)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="googleMapLong"
                                                            value={form.longitude}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="my_profile_setting_input">
                                                        <button className="btn btn2 float-right" onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                if (typeof form.id !== 'undefined') {
                                                    return (
                                                        <div className="my_dashboard_review mt30">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="mb30">Property media</h4>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <ul className="mb0">
                                                                        {
                                                                            images?.map(item => {
                                                                                return (
                                                                                    <li className="list-inline-item" key={item.id}>
                                                                                        <div className="portfolio_item">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                src={item.src}
                                                                                                alt="404"
                                                                                            />
                                                                                            <div
                                                                                                className="edu_stats_list"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Delete"
                                                                                                data-original-title="Delete"
                                                                                            >
                                                                                                <a onClick={() => {
                                                                                                    this.deleteImage(item.id)
                                                                                                }}>
                                                                                                    <span className="flaticon-garbage" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="portfolio_upload" onClick={() => {
                                                                        document.getElementById('myfile').click()
                                                                    }}>
                                                                        <input type="file" id="myfile" multiple="multiple" onChange={async (e) => {
                                                                            for (const file of e.currentTarget.files) {
                                                                                await this.upload(file)
                                                                            }
                                                                        }}/>
                                                                        <div className="icon">
                                                                            <span className="flaticon-download" />
                                                                        </div>
                                                                        <p>Drag and drop images here</p>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-xl-6">*/}
                                                                {/*<div className="resume_uploader mb30">*/}
                                                                {/*<h4>Attachments</h4>*/}
                                                                {/*<form className="form-inline">*/}
                                                                {/*<input className="upload-path" />*/}
                                                                {/*<label className="upload">*/}
                                                                {/*<input type="file" />*/}
                                                                {/*Select Attachment*/}
                                                                {/*</label>*/}
                                                                {/*</form>*/}
                                                                {/*</div>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="col-xl-12">*/}
                                                                    {/*<div className="my_profile_setting_input">*/}
                                                                        {/*<button className="btn btn2 float-right" onClick={() => {*/}
                                                                            {/*this.submit()*/}
                                                                        {/*}}>*/}
                                                                            {/*Submit*/}
                                                                        {/*</button>*/}
                                                                    {/*</div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                                <div className="row mt50">
                                    <div className="col-lg-12">
                                        <div className="copyright-widget text-center">
                                            <p>© 2021 TopSky Home.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <a className="scrollToHome" href="#">
                    <i className="flaticon-arrows" />
                </a>
            </div>
        );
    }
}

export default PageMCommunityUpdate;
